import React, { useCallback, useMemo, useRef } from 'react';
import {
  postChatPurchaseFree,
  postChatRooms,
  putChatRoomJoin,
  putChatRoomsById,
} from '@/apis/chat';
import { getPartnersWithChatRoomsById } from '@/apis/partners';
import { Button } from '@/components';
import { queryKeys, strings } from '@/constants/strings';
import { useAuthStore } from '@/zustands/useAuthStore';
import useGlobalModal from '@/zustands/useGlobalModal';
import useSignInModal from '@/zustands/useSignInModal';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

export default function useJoinChatRoom() {
  const { isLogin } = useAuthStore();
  const { showSignInModal } = useSignInModal();
  const { showGlobalModal, hideGlobalModal, showErrorAlert } = useGlobalModal();
  const navigate = useNavigate();

  const partnerIdRef = useRef(0);

  const { refetch: refetchChatRoomData } = useQuery(
    [queryKeys.getPartnersWithChatRoomsById, partnerIdRef.current],
    // eslint-disable-next-line @typescript-eslint/return-await
    async () => await getPartnersWithChatRoomsById(partnerIdRef.current),
    { enabled: false },
  );

  const { mutate: putChatRooms, isLoading: isPutChatRoomsLoading } =
    useMutation(putChatRoomsById);
  const {
    mutate: putChatRoomJoinMutate,
    isLoading: isPutChatRoomJoinMutateLoading,
  } = useMutation(putChatRoomJoin);
  const { mutate: purchaseFreeChat, isLoading: isPurchaseFreeChatLoading } =
    useMutation(postChatPurchaseFree);
  const { mutate: generateChatRoom, isLoading: isGenerateChatRoomLoading } =
    useMutation(postChatRooms, {
      onSuccess: (res) => {
        putChatRooms(
          {
            id: res.row.id,
            requestBody: {
              isRead: true,
            },
          },
          {
            onSettled: () => {
              purchaseFreeChat(res.row.id, {
                onSettled: () => {
                  navigate(`/chatting/${res.row.id}`);
                  setTimeout(() => {
                    showGlobalModal({
                      title: strings.chat.firstNoticeModalTitle.ko,
                      description: undefined,
                      body: (
                        <div className="space-y-[10px]">
                          {strings.chat.firstNoticeModalDescription.map(
                            (desc) => (
                              <div key={desc.key} className="space-y-[10px]">
                                <h6 className="text-H9 text-Primary6">
                                  {desc.title.ko}
                                </h6>
                                <p className="text-B11 text-Gray8 whitespace-pre-line break-keep">
                                  {desc.description.ko}
                                </p>
                              </div>
                            ),
                          )}
                        </div>
                      ),
                      footer: (
                        <Button size={48} onClick={hideGlobalModal}>
                          {strings.chat.firstNoticeModalButtonText.ko}
                        </Button>
                      ),
                    });
                  }, 300);
                },
              });
            },
          },
        );
      },
      onError: (err: any) => {
        showErrorAlert('채팅방 생성 실패', err.response.data.translate);
      },
    });

  const isJoinChatRoomLoading = useMemo(
    () =>
      isPutChatRoomsLoading ||
      isPutChatRoomJoinMutateLoading ||
      isPurchaseFreeChatLoading ||
      isGenerateChatRoomLoading,
    [
      isPutChatRoomsLoading,
      isPutChatRoomJoinMutateLoading,
      isPurchaseFreeChatLoading,
      isGenerateChatRoomLoading,
    ],
  );

  const joinChatRoom = useCallback(
    async (partnerId: number) => {
      if (!isLogin) {
        // 비회원
        showSignInModal({
          replace: true,
        });
        return;
      }

      if (isJoinChatRoomLoading) {
        // 로딩 중
        return;
      }

      partnerIdRef.current = partnerId;
      const { data: chatRoomsData, isSuccess: isSuccessChatRoomData } =
        await refetchChatRoomData();

      if (isSuccessChatRoomData && chatRoomsData?.row?.chatRoom) {
        const id = chatRoomsData.row.chatRoom.id;

        if (chatRoomsData.row.chatRoom.chatRoomState === 'ONGOING') {
          // 진행중인 채팅방 이라면, 선점 시도
          putChatRoomJoinMutate(id, {
            onSettled: () => {
              navigate(`/chatting/${id}`);
            },
          });
        } else {
          // 시작된, 종료된 채팅방
          // 무료 채팅 시도
          purchaseFreeChat(id, {
            onSettled: () => {
              // 읽음 처리
              putChatRooms(
                {
                  id,
                  requestBody: { isRead: true },
                },
                {
                  onSettled: () => {
                    navigate(`/chatting/${id}`);
                  },
                },
              );
            },
          });
        }
      } else {
        generateChatRoom(partnerId);
      }
    },
    [
      isLogin,
      refetchChatRoomData,
      putChatRoomJoinMutate,
      purchaseFreeChat,
      putChatRooms,
      navigate,
      generateChatRoom,
      showSignInModal,
      isJoinChatRoomLoading,
    ],
  );

  return {
    joinChatRoom,
    isJoinChatRoomLoading,
  };
}
