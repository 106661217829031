import { create } from 'zustand';

export interface PersistAuthKeys {
  isMobile: boolean;
}
export interface PersistAuthFunctions extends Partial<PersistAuthKeys> {
  setIsMobile: (isMobile: boolean) => void;
}
export const useConfigStore = create<PersistAuthFunctions>((set, get) => ({
  isMobile: true,
  setIsMobile: (isMobile) => {
    set({ ...get(), isMobile });
  },
}));
