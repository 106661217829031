import React from 'react';
import Label from '@/components/Label/CardLabel';
import { IconProfile } from '@/assets/icons';
import Skeleton from '../Skeleton/CardSkeleton';
import { JellyLabel } from '@/components';

interface CardProps {
  img?: string;
  name?: string;
  count?: number;
  onClick?: () => void;
  isLoading?: boolean;
  chatJelly?: number;

  category?: {
    name: string;
    iconSrc: string;
  };
}

const Card = ({
  img,
  name,
  count,
  onClick,
  isLoading,
  chatJelly,
  category,
}: CardProps) => {
  return (
    <div
      onClick={onClick}
      className="flex flex-col justify-between h-full overflow-hidden items-start text-H8M gap-[6px] cursor-pointer"
    >
      <div className="relative w-full aspect-square h-full">
        {img ? (
          <img
            src={img}
            alt="thumb"
            className="w-full aspect-square object-cover rounded-[14px]"
          />
        ) : (
          <div className="rounded-[16px] w-full min-h-[140px]  h-full bg-Gray10 flex items-center justify-center">
            <IconProfile />
          </div>
        )}
        {!!count && (
          <div className="absolute left-[10px] bottom-[10px]">
            <Label count={count} />
          </div>
        )}
      </div>
      {isLoading && <Skeleton />}
      {!isLoading && (
        <div className="w-full flex items-center justify-between">
          <div className="space-y-[4px]">
            {category && (
              <div className="flex items-center gap-[2px]">
                <img
                  alt="category-icon"
                  width={16}
                  height={16}
                  src={category.iconSrc}
                />
                <span className="text-L3">{category.name}</span>
              </div>
            )}
            <span className="text-H9">{name}</span>
          </div>
          {!!chatJelly && (
            <div className="">
              <JellyLabel jelly={chatJelly} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Card;
