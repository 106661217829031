import { useCallback, useEffect, useRef } from 'react';
import { type To, unstable_useBlocker, useNavigate } from 'react-router-dom';

interface UseBlockerProps {
  isBlock?: React.MutableRefObject<boolean>;
  browserBackClick: (url: any, action: string) => void;
}

/**
 *
 * @param isBlock 블락 여부 판단 변수
 * @param browserBackClick 뒤로가기 핸들링 함수
 * @returns change navigation nextpath
 */
function useBlocker({ isBlock, browserBackClick }: UseBlockerProps) {
  const navigate = useNavigate();
  const navUrl = useRef<To>();

  const onSetUrl = useCallback(
    (url: To, state?: Record<string, any>) => {
      if (url === '-1') {
        navigate(-1);
      } else if (url) {
        navigate(url, { state });
      }
    },
    [navigate],
  );

  // useEffect(() => {
  //   if (navUrl.current === '-1') {
  //     navigate(-1);
  //   } else if (navUrl.current) {
  //     navigate(navUrl.current, { state: params });
  //   }
  // }, [navUrl, navigate, params]);

  const blockNavigation = useCallback(
    (tx: any) => {
      if (tx.historyAction === 'POP' && isBlock?.current) {
        browserBackClick(tx.nextLocation.pathname, tx.historyAction);
        return true;
      }
      if (tx.historyAction === 'POP' && !isBlock?.current) {
        return false;
      }
      if (tx.historyAction === 'PUSH' && !navUrl.current && isBlock?.current) {
        browserBackClick(tx.nextLocation.pathname, tx.historyAction);
        return true;
      }
      if (tx.historyAction === 'PUSH' && !navUrl.current && !isBlock?.current) {
        return false;
      }
      if (!isBlock?.current) return false;
      return false;
    },
    [browserBackClick, isBlock],
  );

  unstable_useBlocker(blockNavigation);

  const preventClose = useCallback(
    (e: BeforeUnloadEvent) => {
      if (isBlock?.current) {
        e.preventDefault();
        e.returnValue = ''; // chrome에서는 설정이 필요해서 넣은 코드
      }
    },
    [isBlock],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', preventClose);

    return () => {
      window.removeEventListener('beforeunload', preventClose);
    };
  }, [preventClose]);

  return { onSetUrl };
}

export default useBlocker;
