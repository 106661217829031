import React, { useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getNiceEncData } from '@/apis/nice';
import { queryKeys } from '@/constants/strings';
import useSignUpStore from '@/zustands/useSignUpStore';
import { useNavigate } from 'react-router-dom';
import { isUserAgentMobile } from '@/utils/etc';
import useGlobalModal from '@/zustands/useGlobalModal';

interface Props {
  returnPath?: string;
  isSignUp?: boolean;
  onSuccess?: (tokenVersionId: string) => void;
  onFail?: () => void;
  enabled?: boolean;
  retryToggle?: boolean;
}

const NiceForm: React.FC<Props> = ({
  returnPath,
  isSignUp,
  onSuccess,
  onFail,
  enabled = true,
  retryToggle = false,
}) => {
  const { setSignUpData } = useSignUpStore();
  const navigate = useNavigate();
  const { showErrorAlert } = useGlobalModal();
  const formRef = useRef<HTMLFormElement | null>(null);
  const niceWindow = useRef<Window | null>(null);
  const isMounted = useRef(false);

  const { refetch, isSuccess, data, isError, error } = useQuery(
    [queryKeys.getNiceEncData, returnPath],
    async () => await getNiceEncData(returnPath),
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  useEffect(() => {
    if (!enabled) {
      return;
    }

    if (isUserAgentMobile()) {
      // 모바일의 경우,
      refetch();
    } else {
      if (niceWindow.current?.closed === false) {
        // 창이 이미 열려있으면,
        niceWindow.current.focus();
      } else {
        // 창이 없으면 새창 열기
        refetch();
      }
    }
  }, [enabled, retryToggle, refetch]);

  useEffect(() => {
    if (!enabled) return;

    if (isError && error) {
      if (!isMounted.current) {
        isMounted.current = true;
        return;
      }

      showErrorAlert(
        '지금은 본인 인증이 어려워요',
        <>
          {
            '죄송합니다.\n현재 티카 내 본인인증 서비스가 점검 중입니다.\n\n아래 양식으로 성함과 연락처를 남겨 주시면,\n정상화 되는대로 안내드릴게요.\n\n정상화 이후 가입 시\n보상 10젤리를 추가 지급 예정입니다.'
          }
          <br />-{' '}
          <a
            className="underline"
            rel="noreferrer"
            target="_blank"
            href="https://forms.gle/osuED7A4tGwEhxfg9"
          >
            https://forms.gle/osuED7A4tGwEhxfg9
          </a>
        </>,
      );
    }
  }, [enabled, retryToggle, error, isError, showErrorAlert]);

  useEffect(() => {
    if (isSuccess && data) {
      if (!isMounted.current) {
        isMounted.current = true;
        return;
      }

      niceWindow.current = window.open(
        '',
        'nicePopup',
        'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no',
      );

      formRef?.current?.submit();
      if (isSignUp) {
        setSignUpData({
          tokenVersionId: data.row.tokenVersionId,
        });
      }
    } else {
      niceWindow.current = null;
    }
  }, [isSuccess, data, setSignUpData, isSignUp]);

  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      if (event?.data && typeof event?.data === 'string') {
        try {
          const message = JSON.parse(event.data);
          if (message.isSuccess) {
            niceWindow?.current?.close();

            if (message.isAdult && data?.row.tokenVersionId) {
              // 19세 이상
              onSuccess?.(data?.row.tokenVersionId);
            } else {
              // 19세 미만
              onFail?.();
            }
          }
        } catch (e) {
          console.error(e);
        }
      }
    };
    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [data, onSuccess, onFail, navigate]);

  return isSuccess ? (
    <form
      name="form"
      id="nice-form"
      className="hidden"
      action="https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb"
      target={'nicePopup'}
      ref={formRef}
    >
      <input type="hidden" id="m" name="m" value="service" />
      <input
        type="hidden"
        id="token_version_id"
        name="token_version_id"
        value={data?.row.tokenVersionId ?? ''}
      />
      <input
        type="hidden"
        id="enc_data"
        name="enc_data"
        value={data?.row.encData ?? ''}
      />
      <input
        type="hidden"
        id="integrity_value"
        name="integrity_value"
        value={data?.row.integrityValue ?? ''}
      />
    </form>
  ) : null;
};

export default NiceForm;
