import React from 'react';
import { cn } from '@/lib/utils';
import { strings } from '@/constants/strings';

export interface ChatTicketListItemProps {
  title: string;
  price: string;
  discountPrice?: string;
  imageSrc: string;
  selected?: boolean;
  onClick?: () => void;
}

export default function ChatTicketListItem(props: ChatTicketListItemProps) {
  const { title, imageSrc, price, discountPrice, selected, onClick } = props;

  return (
    <article
      className="duration-300 fill-mode-forwards transition-[transform,opacity] flex gap-[12px] items-center w-full px-[11px] py-[17px] border border-Gray4 rounded-[12px] cursor-pointer pointerhover:hover:data-[selected=false]:border-Gray5 data-[selected=true]:border-Primary"
      onClick={onClick}
      data-selected={selected}
    >
      <figure className={cn('w-[35px] h-[35px] flex-shrink-0')}>
        <img src={imageSrc} alt={title} />
      </figure>
      <div className="flex gap-[4px] items-center flex-1">
        <div className="flex items-center justify-between gap-[4px] flex-1">
          <div className="flex items-center justify-between">
            <h3 className="text-H6 text-White">{title}</h3>
          </div>
          <div className="flex items-center gap-[8px] flex-wrap self-end">
            {discountPrice && price && (
              <p className="text-B9 line-through text-Gray6">
                {price} {strings.common.jelly.ko}
              </p>
            )}
            <p className="text-H6 text-Primary">
              {discountPrice && discountPrice}
              {!discountPrice && price} {strings.common.jelly.ko}
            </p>
          </div>
        </div>
      </div>
    </article>
  );
}
