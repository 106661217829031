import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

export type ButtonVariants =
  | 'default'
  | 'light'
  | 'gray'
  | 'text'
  | 'outline'
  | 'ghost';

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        default:
          'text-Gray2 bg-Primary pointerhover:hover:bg-Primary2 pointerhover:active:bg-Primary2 active:bg-Primary2 disabled:bg-Gray10 disabled:text-Gray8',
        light:
          'text-Gray2 bg-Primary9 pointerhover:hover:bg-Primary8 pointerhover:active:bg-Primary8 active:bg-Primary8 disabled:bg-Gray10 disabled:text-Gray8',
        gray: 'text-Gray2 bg-Gray6 pointerhover:hover:bg-Gray5 pointerhover:active:bg-Gray5 active:bg-Gray5 disabled:bg-Gray10 disabled:text-Gray8',
        ghost:
          'border text-Gray2 pointerhover:hover:text-Primary2 pointerhover:active:text-Primary2 active:text-Primary2 border-Primary pointerhover:hover:border-Primary2 pointerhover:active:border-Primary2 active:border-Primary2 disabled:border-Gray3 disabled:text-Gray3',
        outline:
          'border text-White pointerhover:hover:text-Gray9 pointerhover:active:text-Gray6 active:text-Gray6 border-White pointerhover:hover:border-Gray9 pointerhover:active:border-Gray6 active:border-Gray6 disabled:border-Gray3 disabled:text-Gray3',
        text: '',
      },
      size: {
        56: cn('text-BTN2 py-[16px] px-[24px] rounded-[12px] h-[56px]'),
        48: cn('text-BTN3 py-[14px] px-[20px] rounded-[12px] h-[48px]'),
        40: cn('text-BTN3 py-[10px] px-[16px] rounded-[10px] h-[40px]'),
        32: cn('text-BTN4 py-[7px] px-[12px] rounded-[8px] h-[32px]'),
        28: cn('text-BTN5 py-[6px] px-[10px] rounded-[7px] h-[28px]'),
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 28,
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, loading, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        className={cn(
          buttonVariants({
            variant,
            size,
            className,
          }),
          loading ? 'pointer-events-none' : '',
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
