import React, { useState } from 'react';
import PrevHeader from '@/components/Header/PrevHeader';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryKeys, strings } from '@/constants/strings';
import { getProducts } from '@/apis/products';
import {
  postHectoPayments,
  postHectoPaymentsByIdInProgress,
} from '@/apis/hecto-financial';
import { useAuthStore } from '@/zustands/useAuthStore';
import JellyList from '@/components/JellyList';
import {
  Button,
  SectionTypeA,
  Notice,
  Modal,
  FooterButtonWrapper,
} from '@/components';
import { hectoPay } from '@/utils/payment';
import { type JellyItemProps } from '@/components/JellyItem/types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { paths } from '@/constants/paths';
import useCheckAuth from '@/hooks/useCheckAuth';
import { gtmNames, gtmPush } from '@/external/ga';

// options
// const usingPaymentList: Array<{
//   name: string;
//   key: HectoPayType;
//   isActive: boolean;
//   span?: number;
// }> = [
//   { name: '신용/체크카드', key: 'card', isActive: true, span: 2 },
//   { name: '휴대폰 결제(준비중)', key: 'mobile', isActive: false },
//   { name: '카카오페이(준비중)', key: 'kakao', isActive: false },
// ];

export default function PaymentsPage() {
  // hooks
  const navigate = useNavigate();
  const { auth } = useAuthStore();
  const [searchParams] = useSearchParams();
  useCheckAuth();

  // search params
  const cancel = searchParams.get('cancel');
  const message = searchParams.get('message');
  // -----------------------------------------------------------------------------------------------------------------------

  // state
  const [selectedJellyItem, setSelectedJellyItem] =
    useState<JellyItemProps | null>(); // 선택된 젤리 상품
  // -----------------------------------------------------------------------------------------------------------------------

  // react-query
  const { data: productsData } = useQuery(
    [queryKeys?.getProducts],
    getProducts,
  );
  const paymentsMutation = useMutation(postHectoPayments);
  const progressMutation = useMutation(postHectoPaymentsByIdInProgress);
  // -----------------------------------------------------------------------------------------------------------------------

  // handler
  const handlePaymentClick = () => {
    if (selectedJellyItem) {
      gtmPush(gtmNames.event.PAYMENTS_paymentButtonClick, {
        [gtmNames.parameter.productId]: selectedJellyItem.title,
      });

      paymentsMutation.mutate(
        {
          productName: selectedJellyItem.title,
          name: auth?.nick,
        },
        {
          onSuccess: (data: any) => {
            if (data?.row?.id) {
              progressMutation.mutate(+data?.row?.id, {
                onSuccess: (data: any) => {
                  hectoPay(data?.row?.payConfiguredData);
                },
              });
            }
          },
        },
      );
    }
  };
  // -----------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Modal
        open={cancel === 'true'}
        title="결제 실패"
        description={message}
        footer={
          <Button
            onClick={() => {
              navigate(paths.payments, { replace: true });
            }}
            size={48}
          >
            {strings?.common?.ok?.ko}
          </Button>
        }
      />
      <PrevHeader title={strings?.payments?.detailTitle?.ko} withHomeIcon />
      <div className="pt-header pc:overflow-auto pc:hide-scrollbar h-full">
        <section className="flex flex-col h-full">
          <SectionTypeA
            className="gap-[24px] [&>h2]:text-H3"
            title={strings?.payments?.paymentItemInfo?.ko}
          >
            <JellyList
              initialSelectedIndex={1}
              list={
                productsData?.rows?.map((item) => ({
                  title: item.name,
                  imageSrc: '/assets/images/img-jelly-primary5.png',
                  price: item?.originalPrice,
                  discountPrice: item?.discountPrice,
                })) ?? []
              }
              onSelect={(jellyItem) => {
                setSelectedJellyItem(jellyItem);
              }}
            />
          </SectionTypeA>

          <div className="bg-Background pt-[20px] px-[20px] pb-[116px] flex-1">
            <Notice
              title={strings.common.notice.ko}
              list={strings.payments.noticeList.map((n) => n.ko)}
            />
          </div>
        </section>
      </div>
      <FooterButtonWrapper className="rounded-t-[20px] pb-[32px]">
        <Button
          onClick={handlePaymentClick}
          className="w-full payments-detail-footer-button"
          disabled={!selectedJellyItem}
          size={56}
        >
          결제하기
        </Button>
      </FooterButtonWrapper>
    </>
  );
}
