import React from 'react';
import { FooterButton } from '@/components';
import schemas from '@/constants/schemas';
import { strings } from '@/constants/strings';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { putCustomersPasswordResetByNiceTokenVersionId } from '@/apis/customers';
import { useMutation } from '@tanstack/react-query';
import { type InferType } from 'yup';
import useGlobalModal from '@/zustands/useGlobalModal';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/constants/paths';
import Password from '@/components/Input/Password';
import { toast } from 'react-toastify';

interface Props {
  tokenVersionId: string;
  email: string;
}

type FormData = InferType<typeof schemas.resetPassword>;

const ResetPasswordForm = ({ tokenVersionId, email }: Props) => {
  const {
    mutate: resetPasswordByAuthToken,
    isLoading: resetPasswordByAuthTokenLoading,
  } = useMutation(putCustomersPasswordResetByNiceTokenVersionId);

  const { formState, register, handleSubmit, watch } = useForm({
    resolver: yupResolver(schemas.resetPassword),
    mode: 'onChange',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const { showErrorAlert } = useGlobalModal();
  const navigate = useNavigate();

  const onClickOk = (formData: FormData) => {
    resetPasswordByAuthToken(
      {
        email,
        tokenVersionId,
        newPassword: formData.password,
      },
      {
        onSuccess: () => {
          toast(strings.resetPassword.resetPasswordSuccess.ko);
          setTimeout(() => {
            navigate(paths.signIn);
          }, 300);
        },
        onError: (err: any) => {
          showErrorAlert(
            strings.error.errorOccurred.ko,
            err?.response?.data?.translate ?? strings.error.pleaseRetryLater.ko,
          );
        },
      },
    );
  };

  return (
    <>
      <div className="mt-header p-[20px] space-y-[24px]">
        <h3 className="text-H3 text-White whitespace-pre-line">
          {strings.resetPassword.enterPasswordForChange.ko}
        </h3>
        <form className="mt-[24px] space-y-[24px]">
          <Password
            autoFocus
            label={strings.common.password.ko}
            {...register('password')}
            error={
              formState.errors.password &&
              formState.errors.password?.type !== 'min'
            }
            message={
              formState.errors.password?.type !== 'min'
                ? formState.errors.password?.message
                : undefined
            }
            enableIcon={!!watch().password}
          />
          <Password
            label={strings.common.confirmPassword.ko}
            {...register('confirmPassword')}
            error={
              !!formState.errors.confirmPassword &&
              formState.errors.confirmPassword?.type !== 'min'
            }
            message={
              formState.errors.confirmPassword?.type !== 'min'
                ? formState.errors.confirmPassword?.message
                : undefined
            }
            enableIcon={!!watch().confirmPassword}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onPressEnter={handleSubmit(onClickOk)}
          />
        </form>
      </div>
      <div className="space-y-[6px] mt-[25px] px-[20px]">
        <p className="text-L2 text-Gray5">가입한 이메일</p>
        <p className="text-B5 text-White">{email.replace(/@.+/, '@***')}</p>
      </div>
      <FooterButton
        loading={resetPasswordByAuthTokenLoading}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={handleSubmit(onClickOk)}
        disabled={!formState.isValid}
        className="email-reset-password-form-footer-button"
      >
        {strings.common.ok.ko}
      </FooterButton>
    </>
  );
};

export default ResetPasswordForm;
