import { postCustomersMeLogout } from '@/apis/customers';
import { IconRightArrow } from '@/assets/icons';
import { Modal } from '@/components';
import PrevHeader from '@/components/Header/PrevHeader';
import { paths } from '@/constants/paths';
import { storageKey, strings } from '@/constants/strings';
import { useAuthStore } from '@/zustands/useAuthStore';
// import useVolatileChatStore from '@/zustands/useVolatileChatStore';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  prop?: string;
}

const SettingPage: React.FC<Props> = () => {
  const { removeToken, setIsLogin, setAuth, isLogin } = useAuthStore();
  const signOut = useMutation(postCustomersMeLogout);
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogin) {
      navigate(paths?.home, { replace: true });
    }
  }, [navigate, isLogin]);

  const handleSignout = () => {
    signOut.mutate(
      {},
      {
        onSettled: () => {
          removeToken();
          setIsLogin(false);
          setAuth(undefined);
          setShowSignOutModal(false);
          localStorage.removeItem(storageKey.local.disableTooltipInMateDetail);
          navigate(paths?.home);
        },
      },
    );
  };

  const openModal = () => {
    setShowSignOutModal(true);
  };
  const closeModal = () => {
    setShowSignOutModal(false);
  };

  const handlePrevClick = () => {
    navigate(-1);
  };

  const handleWithdrawalClick = () => {
    navigate(paths?.withdrawal);
  };

  const menu = [
    {
      key: 'signOut',
      label: strings?.setting?.signOut?.ko,
      onClick: openModal,
    },
    {
      key: 'withdrawal',
      label: strings?.setting?.withdrawal?.ko,
      onClick: handleWithdrawalClick,
    },
  ];

  return (
    <div className="flex flex-col">
      <PrevHeader
        title={strings?.setting?.title?.ko}
        onPrevClick={handlePrevClick}
      />
      <div className="pt-header">
        {menu?.map((item) => (
          <div
            className="flex items-center justify-between py-[16px] px-[20px] cursor-pointer"
            key={item?.key}
            onClick={item?.onClick}
          >
            <span className="text-H6M text-White">{item?.label}</span>
            <IconRightArrow stroke="white" width={24} height={24} />
          </div>
        ))}
      </div>
      <Modal
        title={strings?.setting?.reallySignOut?.ko}
        open={showSignOutModal}
        description=""
        primaryButtonText={strings?.setting?.confirmSignOut?.ko}
        cancelButtonText={strings?.setting?.cancelSignOut?.ko}
        onPrimaryClick={handleSignout}
        onCancelClick={closeModal}
        onClose={closeModal}
      />
    </div>
  );
};

export default SettingPage;
