import React, { type PropsWithChildren } from 'react';
import { Skeleton as SkeletonBase } from '@/components/ui/skeleton';

interface Props extends PropsWithChildren {
  isLoading: boolean;
  className?: string;
}

const Skeleton = ({ isLoading, className, children }: Props) => {
  return <>{isLoading ? <SkeletonBase className={className} /> : children}</>;
};

export default Skeleton;
