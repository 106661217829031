import { create } from 'zustand';

export interface GlobalLoadingKeys {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
}

export const useGlobalLoadingStore = create<GlobalLoadingKeys>((set, get) => ({
  isLoading: false,
  setIsLoading: (value: boolean) => {
    set({ isLoading: value });
  },
}));
