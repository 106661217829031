import { paths } from '@/constants/paths';
import { useAuthStore } from '@/zustands/useAuthStore';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useCheckAuth = () => {
  const { isLogin } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogin) {
      navigate(paths?.home, { replace: true });
    }
  }, [navigate, isLogin]);
};
export default useCheckAuth;
