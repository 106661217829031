import { postCustomersLogin } from '@/apis/customers';
import { Button, Modal } from '@/components';
import { paths } from '@/constants/paths';
import { storageKey, strings } from '@/constants/strings';
import type { Provider } from '@/models/customers';
import { useAuthStore } from '@/zustands/useAuthStore';
import useSignUpStore from '@/zustands/useSignUpStore';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SignUpSuccessLogo from '@/assets/images/signup-success-logo.png';
import useRedirectNavigate from '@/hooks/useRedirectNavigate';
import { isCloserApp } from '@/utils/etc';

interface Props {
  prop?: string;
}

const OAuthPage: React.FC<Props> = () => {
  const [showModal, setShowModal] = useState(false);
  const modalType = useRef<'error' | 'noNiceInfo'>('error');

  const navigate = useNavigate();
  const { setSignUpData } = useSignUpStore();
  const { setAuth, setToken, setIsLogin } = useAuthStore();
  const [searchParams] = useSearchParams();
  const isAlreadySignUp = searchParams.get('isAlreadySignUp');
  const provider = searchParams.get('provider');
  const pId = searchParams.get('pId');
  const oauthAccessToken = searchParams.get('accessToken');
  const redirectNavigate = useRedirectNavigate();

  const signInMutation = useMutation(postCustomersLogin);

  const handleOkClick = () => {
    redirectNavigate(paths.home, {
      replace: true,
      state: { isRightAfterSignIn: true },
    });
  };

  const handleLogin = useCallback(() => {
    if (provider && pId && oauthAccessToken) {
      signInMutation.mutate(
        { provider, pId, oauthAccessToken },
        {
          onSuccess: (data) => {
            localStorage.setItem(storageKey.local.provider, provider);

            setAuth(data?.data);
            setToken(data?.token);
            setIsLogin(true);
            if (!data.data.isAdult) {
              navigate(paths.requireCertificate, {
                replace: true,
                state: {
                  provider,
                },
              });
            } else {
              handleOkClick();
            }
          },
          onError: () => {
            modalType.current = 'error';
            setShowModal(true);
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, handleOkClick]);

  useEffect(() => {
    if (isAlreadySignUp === 'true') {
      setTimeout(() => {
        handleLogin();
      }, 2500);
    } else {
      if (pId && provider && oauthAccessToken) {
        setSignUpData({
          body: {
            oauth: {
              pId,
              provider: provider as Provider,
              oauthAccessToken,
            },
          },
          checkedTerms: [],
        });
        navigate(`${paths.signUpTerms}`, { replace: true });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAlreadySignUp]);

  return (
    <>
      <Modal
        open={showModal}
        title={strings.oAuth.networkError.ko}
        description={strings.oAuth.networkErrorContent.ko}
        footer={
          <Button size={48} onClick={handleOkClick}>
            {strings.common.ok.ko}
          </Button>
        }
      />
      {!isCloserApp() && (
        <div className="flex items-center justify-center h-full">
          <img alt="signup-success-image" src={SignUpSuccessLogo} width={178} />
        </div>
      )}
    </>
  );
};

export default OAuthPage;
