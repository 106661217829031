import { useCallback, useState } from 'react';

const useToggle = (): [boolean, () => void] => {
  const [flag, setFlag] = useState(false);

  const toggle = useCallback(() => {
    setFlag((prev) => !prev);
  }, []);

  return [flag, toggle];
};
export default useToggle;
