import React, {
  type PropsWithChildren,
  type ReactNode,
  useCallback,
} from 'react';
import {
  Accordion as AccordionBase,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';

interface Props extends PropsWithChildren {
  className?: string;
  itemClassName?: string;
  triggerClassName?: string;
  contentClassName?: string;
  renderTitle: () => ReactNode;
  value?: string;
  setValue?: (value: string) => void;
}

const Accordion = ({
  className,
  itemClassName,
  triggerClassName,
  contentClassName,
  renderTitle,
  children,
  value,
  setValue,
}: Props) => {
  const triggerClick = useCallback(() => {
    if (!setValue) return;

    if (value !== '1') {
      setValue('1');
    } else {
      setValue('0');
    }
  }, [value, setValue]);

  return (
    <AccordionBase
      className={className}
      type="single"
      collapsible
      value={value}
    >
      <AccordionItem className={itemClassName} value="1" onClick={triggerClick}>
        <AccordionTrigger className={triggerClassName}>
          {renderTitle()}
        </AccordionTrigger>
        <AccordionContent className={contentClassName}>
          {children}
        </AccordionContent>
      </AccordionItem>
    </AccordionBase>
  );
};

export default Accordion;
