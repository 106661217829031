import React from 'react';
import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';
import { Link, useParams } from 'react-router-dom';

interface Content {
  title: string;
  iconSrc: string;
  url: string;
}

interface Props {
  className?: string;
  contentList: Content[];
  isLoading?: boolean;
}

const ContentsNav = ({ className, contentList, isLoading }: Props) => {
  const { name } = useParams();

  return (
    <div className={cn('flex items-stretch gap-[14px]', className)}>
      {isLoading ? (
        <>
          <Skeleton className="rounded-[20px] w-[80px] h-[36px]" />
          <Skeleton className="rounded-[20px] w-[84px] h-[36px]" />
          <Skeleton className="rounded-[20px] w-[80px] h-[36px]" />
        </>
      ) : (
        contentList.map(({ title, iconSrc, url }) => {
          return (
            <Link
              key={`contents-nav-${title}`}
              to={`/contents/${url}`}
              className={cn(
                'border flex items-center gap-[6px] px-[11px] py-[5px] rounded-[20px] transition-colors pointerhover:hover:border-Gray2',
                url === name
                  ? 'border-Gray2 bg-Gray2'
                  : 'border-Gray9 bg-White',
              )}
            >
              <img
                className="w-[24px] h-[24px]"
                width={24}
                height={24}
                src={iconSrc}
                alt={title}
              />
              <span
                className={cn(
                  'text-BTN3',
                  url === name ? 'text-White' : 'text-White',
                )}
              >
                {title}
              </span>
            </Link>
          );
        })
      )}
    </div>
  );
};

export default ContentsNav;
