import React, { useEffect } from 'react';
import type * as PopoverPrimitive from '@radix-ui/react-popover';
import {
  Popover as PopoverBase,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';

interface PopoverProps extends PopoverPrimitive.PopoverProps {
  children: React.ReactNode;
  content?: React.ReactNode;
  onClose?: () => void;
}

const Popover = ({ children, content, open, onClose }: PopoverProps) => {
  useEffect(() => {
    const handleClick = () => {
      if (onClose) {
        onClose();
      }
    };
    const mobileWrapper = window.document.querySelector('#mobile-wrapper');
    if (open) {
      window.addEventListener('click', handleClick);
      mobileWrapper?.addEventListener('click', handleClick);
      return () => {
        window.removeEventListener('click', handleClick);
        mobileWrapper?.removeEventListener('click', handleClick);
      };
    }
  }, [onClose, open]);

  return (
    <PopoverBase open={open}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>{content}</PopoverContent>
    </PopoverBase>
  );
};

export default Popover;
