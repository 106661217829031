import React, { type ReactNode, useCallback, useEffect } from 'react';
import { Button } from '@/components';
import HomeBannerModal from '@/components/Modal/HomeBannerModal';
import { strings } from '@/constants/strings';
import useModalConfig from '@/hooks/useModalConfig';
import { PROMOTION_START_DAYJS } from '@/utils/promotion';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IconCloseCurrent } from '@/assets/icons';
import colors from '@/constants/colors';

interface Props {
  onClickButton?: () => void;
  buttonText?: string | ReactNode;
  clearSearchParam?: boolean;
}

const PromotionModal = ({
  onClickButton,
  buttonText,
  clearSearchParam = true,
}: Props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { open, handleCloseModal, handleOpenModal } = useModalConfig();

  const closeModal = useCallback(() => {
    if (clearSearchParam) {
      navigate('', { replace: true });
    } else {
      searchParams.set('promotion', '0');
      navigate(`?${searchParams.toString()}`, { replace: true });
    }

    handleCloseModal();
  }, [searchParams, clearSearchParam, navigate, handleCloseModal]);

  const onClickButtonWrapper = useCallback(() => {
    closeModal();
    onClickButton?.();
  }, [closeModal, onClickButton]);

  useEffect(() => {
    if (searchParams.get('promotion') === '1') {
      handleOpenModal();
    }
  }, [searchParams, handleOpenModal]);

  if (!open) {
    return null;
  }

  return (
    <HomeBannerModal
      dialogContentClassName="w-[250px] h-[250px] [&>div]:space-y-[16px]"
      open={open}
      onClose={closeModal}
      disableCloseButton
    >
      <div>
        <div className="flex items-center justify-end">
          <button onClick={closeModal}>
            <IconCloseCurrent width={20} height={20} stroke={colors.White} />
          </button>
        </div>
        <p className="text-H10M text-White">
          지금 방문하신 고객님, 축하합니다!
        </p>
        <p className="mt-[4px] text-H5 text-White">
          신규 회원가입하면
          <br />
          <span className="text-Primary">100회 대화 무료 제공</span>
        </p>
        <p className="mt-[12px] text-B8 text-White">
          {`기간 : ${PROMOTION_START_DAYJS.format('MM월 DD일')} ~ 소진시 마감`}
        </p>
        <p className="mt-[12px] text-Gray7 text-[10px] leading-[12px] font-medium">
          본 이벤트는 내부 사정에 따라 별도 고지 없이 종료되거나 세부 사항이
          변경될 수 있습니다.
        </p>
      </div>
      <Button
        className="w-full"
        variant="light"
        size={32}
        onClick={onClickButtonWrapper}
      >
        {buttonText ?? strings.common.ok.ko}
      </Button>
    </HomeBannerModal>
  );
};

export default PromotionModal;
