import { IconDotDotDot } from '@/assets/icons';
import { cn } from '@/lib/utils';
import React from 'react';

interface ListPlaceholderProps {
  description: string;
  className?: string;
  descriptionClassName?: string;
}

export default function ListPlaceholder(props: ListPlaceholderProps) {
  const { description, className, descriptionClassName } = props;

  return (
    <div
      className={cn(
        'w-full h-full flex-col flex gap-[12px] items-center justify-center',
        className,
      )}
    >
      <IconDotDotDot />
      <p className={descriptionClassName}>{description}</p>
    </div>
  );
}
