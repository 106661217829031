import React, {
  type FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import 'swiper/css';
import { Navigation } from 'swiper';
import { IconArrowLeftBg, IconArrowRightBg } from '@/assets/icons';

interface Props {
  lists: React.ReactNode[];
  initialSlide?: number;
  hasKeyboardEvent?: boolean;
}

const Carousel: FunctionComponent<Props> = function Carousel({
  lists = [],
  hasKeyboardEvent = false,
  initialSlide = 0,
}) {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [init, setInit] = useState(false);
  const [active, setActive] = useState(initialSlide);
  useEffect(() => {
    const handleRefClick = (e: KeyboardEvent) => {
      if (e?.code === 'ArrowLeft' && prevRef?.current) {
        (prevRef?.current as HTMLDivElement)?.click();
      }
      if (e?.code === 'ArrowRight' && nextRef?.current) {
        (nextRef?.current as HTMLDivElement)?.click();
      }
    };
    if (hasKeyboardEvent) {
      window.addEventListener('keydown', handleRefClick);
      return () => {
        window.removeEventListener('keydown', handleRefClick);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevRef?.current, nextRef?.current]);
  return (
    <div className="w-full min-w-min max-w-max h-full bg-Gray1">
      <Swiper
        className="h-full flex items-center justify-center "
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        modules={[Navigation]}
        onSlideChange={(e) => {
          setActive(e.activeIndex);
        }}
        onSwiper={(swiper) => {}}
        onInit={() => {
          setInit(true);
        }}
        initialSlide={initialSlide}
      >
        {lists?.map((item, i) => (
          <SwiperSlide key={i?.toString()}>
            <div className="flex items-center h-full">{item}</div>
          </SwiperSlide>
        ))}
      </Swiper>
      {lists?.length > 1 && (
        <>
          <div
            className={`absolute flex items-center justify-center top-[50%] w-[36px] h-[36px] 
          rounded-[50%]  border-none cursor-pointer z-40 translate-y-[-50%] left-[20px] ${
            active === 0 && 'hidden'
          }`}
            ref={prevRef}
          >
            <IconArrowLeftBg />
          </div>
          <div
            className={`absolute flex items-center justify-center top-[50%] w-[36px] h-[36px] 
          rounded-[50%] border-none cursor-pointer z-40 translate-y-[-50%] right-[20px] ${
            active === lists?.length - 1 && 'hidden'
          }`}
            ref={nextRef}
          >
            <IconArrowRightBg />
          </div>
        </>
      )}
    </div>
  );
};

export default Carousel;
