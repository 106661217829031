import { strings } from '@/constants/strings';
import { object, ref, string } from 'yup';

const schemaErrorMessages = {
  pleaseEnterEmail: { ko: '이메일 주소를 입력해주세요', en: '' },
  pleaseEnterEmailCorrectly: {
    ko: '이메일 주소를 올바르게 입력해주세요',
    en: '',
  },

  pleaseEnterPassword: { ko: '비밀번호를 입력해주세요', en: '' },
  pleaseEnterPasswordCorrectly: {
    ko: '비밀번호를 올바르게 입력해주세요',
    en: '',
  },
  pleaseEnterPasswordSame: {
    ko: '비밀번호와 동일하게 입력해주세요',
    en: '',
  },
};

const schemas = {
  signIn: object({
    email: string().required(),
    password: string().required(),
  }).required(),
  signUpByEmail: object({
    email: string()
      .min(1, 'min')
      .required(schemaErrorMessages.pleaseEnterEmail.ko)
      .matches(
        /^[^@\s]+@[^@\s]+\.[^@\s]{2,}$/,
        schemaErrorMessages.pleaseEnterEmailCorrectly.ko,
      ),
    password: string()
      .min(1, 'min')
      .required(schemaErrorMessages.pleaseEnterPassword.ko)
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#?$%^&*()_+-])[A-Za-z\d!@#?$%^&*()_+-]{8,20}$/,
        schemaErrorMessages.pleaseEnterPasswordCorrectly.ko,
      ),
    confirmPassword: string()
      .min(1)
      .oneOf([ref('password')], schemaErrorMessages.pleaseEnterPasswordSame.ko)
      .required(),
  }),
  resetPassword: object({
    password: string()
      .min(1, 'min')
      .required(schemaErrorMessages.pleaseEnterPassword.ko)
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#?$%^&*()_+-])[A-Za-z\d!@#?$%^&*()_+-]{8,20}$/,
        strings.signUpByEmail.passwordRule.ko,
      ),
    confirmPassword: string()
      .min(1)
      .oneOf([ref('password')], schemaErrorMessages.pleaseEnterPasswordSame.ko)
      .required(),
  }),
};

export default schemas;
