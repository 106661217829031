import Checkbox from '../Checkbox';
import React, { useId, useEffect, useState, useMemo } from 'react';
import { type ITerm, type TermsProps } from './types';
import { IconRightArrow } from '@/assets/icons';
import { externalLinks, strings } from '@/constants/strings';
import { cn } from '@/lib/utils';
import useSignUpStore from '@/zustands/useSignUpStore';

export default function Terms({
  className,
  terms,
  setAgreeOptionTermIds,
  setTermsPass,
  onChangeCheckedTerms,
  onDetailClick,
  hideAllCheck,
}: TermsProps) {
  const id = useId();
  const { data } = useSignUpStore();
  const [values, setValues] = useState<Array<'indeterminate' | boolean>>(
    Array.from(
      { length: terms.length },
      (value, i) => data?.checkedTerms?.[i] || false,
    ),
  );
  const allCheckValue = useMemo(() => {
    return values.find((item) => item === false) === undefined;
  }, [values]);

  const handleSetValues = (index: number, value: 'indeterminate' | boolean) => {
    setValues((prev) => {
      const next = prev.map((item, itemIndex) =>
        index === itemIndex ? value || false : item,
      );

      return next;
    });
  };

  const handleAllClick = () => {
    if (values.find((item) => item === false) !== undefined) {
      setValues(Array.from({ length: terms.length }, () => true));
    } else {
      setValues(Array.from({ length: terms.length }, () => false));
    }
  };

  const renderTerm = (item: ITerm, index: number) => {
    return (
      <React.Fragment key={item.id}>
        <li key={item.id} className="flex items-start gap-[8px]">
          <Checkbox
            id={`${id}-${item.id}`}
            className="flex-shrink-0"
            checked={values[index]}
            onCheckedChange={(e) => {
              handleSetValues(index, e);
            }}
          />
          <label
            htmlFor={`${id}-${item.id}`}
            className="cursor-pointer flex-1 text-b4"
          >
            <span className={`text-b4m ${!item.optionable && 'text-Primary4'}`}>
              [{item.optionable ? '선택' : '필수'}]
            </span>{' '}
            {item.title}
          </label>
          {!item.isMarketing && !item.isOlder19 && (
            <button
              type="button"
              className="flex-shrink-0 text-gray8 pointerhover:hover:text-gray5 transition-colors duration-200"
              onClick={() => {
                onDetailClick?.();

                // TODO: 약관 퍼블리싱 완료시 삭제 예정 -----
                if (item.title === '이용 약관') {
                  window.open(externalLinks.termsOfService);
                } else if (item.title === '개인정보처리방침') {
                  window.open(externalLinks.privacyPolicy);
                }
                // -----------------------------------
                // navigate(`${paths?.signUp}?step=3&id=${item?.id}`);
              }}
            >
              <IconRightArrow />
            </button>
          )}
        </li>
        {item.isMarketing && (
          <p className="text-Gray6 text-B10 pl-[32px]">
            {strings.signup.marketingContent.ko}
          </p>
        )}
      </React.Fragment>
    );
  };

  useEffect(() => {
    setAgreeOptionTermIds?.(
      terms
        .filter((item, idx) => item.optionable && values[idx] === true)
        .map((item) => item.id),
    );
    setTermsPass?.(
      terms.filter((item, idx) => !item.optionable && values[idx] === true)
        .length === terms.filter((item, idx) => !item.optionable).length,
    );
    useSignUpStore.getState().setSignUpData({ checkedTerms: values });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, terms]);

  useEffect(() => {
    onChangeCheckedTerms?.(values);
  }, [values, onChangeCheckedTerms]);

  return (
    <div className={cn('w-full', className)}>
      {!hideAllCheck && (
        <div className="flex items-center gap-[10px] mb-[20px]">
          <Checkbox
            id={`${id}-all`}
            shape="circle"
            checked={allCheckValue}
            onCheckedChange={handleAllClick}
          />
          <label className="cursor-pointer text-b4" htmlFor={`${id}-all`}>
            {strings.signup.agreeAll.ko}
          </label>
        </div>
      )}
      <ul className="flex flex-col gap-[16px]">
        {terms.map((item, idx) => renderTerm(item, idx))}
      </ul>
    </div>
  );
}
