import { Avatar } from '@/components';
import { cn } from '@/lib/utils';
import DotLoading from '@/assets/lottie/dot-loading.json';
import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';

interface Props {
  partnerProfileImage: string;
  partnerProfileImageForModal?: string;
  message?: string | React.ReactNode;
  isLoadingEnable?: boolean;
}

const PartnerResponseFake: React.FC<Props> = ({
  partnerProfileImage,
  partnerProfileImageForModal,
  message = (
    <>
      오늘 날씨는 어때요?
      <br />
      기분은?
    </>
  ),
  isLoadingEnable = false,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isLoadingEnable) {
      setTimeout(() => {
        setLoading(false);
      }, 6000);
    }
  }, [isLoadingEnable]);

  const [visible, setVisible] = useState(true);

  useEffect(() => {
    // 표시 시간 3~3.5초
    // 숨김 시간 1~1.2초
    const randomNumber = _.random(
      visible ? 3000 : 1000,
      visible ? 3500 : 1200,
      false,
    );
    setTimeout(() => {
      setVisible(!visible);
    }, randomNumber);
  }, [visible]);

  return (
    <div className="flex items-end gap-[8px] overflow-hidden">
      <Avatar
        src={partnerProfileImage}
        modalImageSrc={partnerProfileImageForModal}
        className="w-[32px] h-[32px]"
      />
      <div className={cn('flex-1 flex items-end gap-[2px]')}>
        {isLoadingEnable && loading ? (
          <div
            className={cn(
              'w-[62px] h-[48px] rounded-[14px] bg-Gray10 text-White relative fill-mode-forwards animate-in duration-300 slide-in-from-left-2 fade-in-0',
              visible
                ? 'animate-in slide-in-from-left-2 fade-in-0'
                : 'animate-out slide-out-to-left-2 fade-out-0',
            )}
          >
            <Lottie
              className="w-[62px] absolute top-0 bottom-0 right-0 left-0"
              size={34}
              animationData={DotLoading}
              loop
            />
          </div>
        ) : (
          <div
            className={cn(
              'py-[10px] px-[16px] rounded-[14px] bg-Gray10 text-White relative duration-300 animate-in slide-in-from-left-2 fade-in-0',
            )}
          >
            <div
              className={cn(
                'absolute top-0 bottom-0 left-0 right-0 rounded-[16px] bg-[rgba(255,255,255,0.6)] animate-chat-bubble-blur',
              )}
            ></div>
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

export default PartnerResponseFake;
