import { type ServerErrorBody, type ErrorMessage } from '@/models/common';
// import { isPromotionPeriod } from '@/utils/promotion';

export interface Strings {
  en: string;
  ko: string;
}

export interface NoticeListItem extends Strings {
  isLink?: boolean;
}

export const strings = {
  common: {
    ok: { ko: '확인', en: '' },
    cancel: { ko: '취소', en: '' },
    close: { ko: '닫기', en: '' },
    unit: { ko: '개', en: '' },
    chatCount: { ko: '회', en: '' },
    currency: { ko: '원', en: '' },
    mateCount: { ko: '명', en: '' },
    nickname: { ko: '닉네임', en: '' },
    edit: { ko: '수정하기', en: '' },
    jelly: { ko: '젤리', en: '' },
    budzy: { ko: '체리', en: '', emoji: '🍒' },
    chat: { ko: '대화', en: '' },
    chatting: { ko: '채팅', en: '' },
    voice: { ko: '음성', en: '' },
    notice: { ko: '유의사항', en: '' },
    next: { ko: '다음', en: '' },
    toHome: { ko: '홈으로', en: '' },
    email: { ko: '이메일', en: '' },
    password: { ko: '비밀번호', en: '' },
    confirmPassword: { ko: '비밀번호 확인', en: '' },
    funDate: { ko: '즐거운 데이트', en: '' },
    signIn: { ko: '로그인', en: '' },
    mate: { ko: '메이트', en: '' },
    free: { ko: '무료', en: '' },
    chatTicket: { ko: '채팅권', en: '' },
    remain: { ko: '잔여', en: '' },
    doCertificate: { ko: '본인인증하기', en: '' },
    yearsOld: { ko: '살', en: 'yo' },
    entryEvent: { ko: '이벤트 참여하기', en: '' },
  },
  message: {
    nick: {
      ko: '한글/숫자/영어를 조합하여 8자 이내로 설정해주세요',
      en: '',
    },
  },
  signIn: {
    pleaseSignIn: { ko: '로그인이 필요해요', en: '' },
    startDateWithMateAfterSignIn: {
      ko: '지금 로그인하여 무료 데이트를 시작해보세요!',
      en: '',
    },
    signInWithEmail: { ko: '이메일로 시작하기', en: '' },
    isFirstTimeCloser: { ko: '티카 서비스가 처음이신가요?', en: '' },
    goSignUp: { ko: '회원가입하기', en: '' },
    pageTitle: { ko: '이메일 로그인', en: '' },
    pageDescription: { ko: '계정을 입력해주세요', en: '' },
    forgotPassword: { ko: '비밀번호를 잊으셨나요?', en: '' },
    isFirstTimeInCloser: { ko: '티카가 처음이신가요?', en: '' },
    resetPassword: { ko: '비밀번호 재설정', en: '' },
    goSignIn: { ko: '로그인하기', en: '' },
    failSignIn: { ko: '로그인을 실패했어요', en: '' },
    checkEmailAndPassword: { ko: '이메일, 비밀번호를 확인해주세요', en: '' },
    enterEmailAndPassword: {
      ko: '이메일, 비밀번호를 모두 입력해주세요',
      en: '',
    },
  },
  resetPassword: {
    pageTitle: {
      ko: '비밀번호 재설정',
      en: '',
    },
    registeredEmailAccount: {
      ko: '가입된 이메일 계정',
      en: '',
    },
    pageDescription: {
      ko: '을 입력해주세요\n인증번호 메일이 발송돼요',
      en: '',
    },
    noRegisteredAccount: {
      ko: '티카에 가입된 정보가 없어요\n지금 바로 티카에 가입해볼까요?',
      en: '',
    },
    goRegisterDirectly: {
      ko: '가입할 수단을 선택 후\n본인인증하면 가입이 완료돼요',
      en: '',
    },
    enterPasswordForChange: {
      ko: '새로운 비밀번호를 입력해주세요',
      en: '',
    },
    resetPasswordSuccess: {
      ko: '재설정이 완료되었어요 다시 로그인해주세요',
      en: '',
    },
    pleaseReSignIn: {
      ko: '다시 로그인 해주세요!',
      en: '',
    },
  },
  signUpByEmail: {
    pageTitle: {
      ko: '이메일 회원가입',
      en: '',
    },
    pageDescription: {
      ko: '가입할 계정을 입력해주세요',
      en: '',
    },
    emailVerification: {
      ko: '이메일 인증',
      en: '',
    },
    duplicatedEmail: {
      ko: '이미 존재하는 이메일이에요',
      en: '',
    },
    passwordRule: {
      ko: '영어/숫자/특수문자를 조합하여 8~20자로 설정해주세요',
      en: '',
    },
    alreadyHasAccount: {
      ko: '이미 가입한 계정이 있어요',
      en: '',
    },
    alreadyHasAccountDetail: {
      ko: '아래 정보로 가입한 계정이 있어요.\n가입한 계정으로 로그인해 주세요.',
      en: '',
    },
    validEmailMessage: {
      ko: '사용 가능한 이메일이에요',
      en: '',
    },
    validPasswordMessage: {
      ko: '사용 가능한 비밀번호에요',
      en: '',
    },
    validConfirmPasswordMessage: {
      ko: '비밀번호가 일치해요',
      en: '',
    },
  },
  signUpTerms: {
    pageTitle: {
      ko: '약관 동의',
      en: '',
    },
    pageDescription: {
      ko: '약관에 동의해 주세요',
      en: '',
    },
  },
  signUpNick: {
    pageTitle: {
      ko: '닉네임 설정',
      en: '',
    },
    pageDescription: {
      ko: '메이트와의 데이트에서\n어떻게 불러드리면 될까요?',
      en: '',
    },
  },
  home: {
    pageDescription: {
      ko: '티카가 준비한\n컨텐츠를 소개해 드릴게요',
      en: '',
    },
    pageDescriptionForMember: {
      ko: '님이 관심있는\n컨텐츠를 즐겨보세요',
      en: '',
    },
    mateCount: { ko: '명의 메이트', en: '' },
    startDateNow: { ko: '지금 데이트를 시작해 볼까요?', en: '' },
    loginModalNeedLogin: { ko: '로그인이 필요해요', en: '' },
    recentLogin: { ko: '최근 로그인', en: '' },
    kakaoLogin: { ko: '카카오로', en: '' },
    instaLogin: { ko: '인스타로', en: '' },
    tiktalkLogin: { ko: '틱톡으로', en: '' },
    googleLogin: { ko: '구글로', en: '' },
    mateForYou: { ko: '님을 위한 메이트', en: '' },
    newMateCome: { ko: '새로운 메이트가 곧 찾아와요, 기대해 주세요!', en: '' },
    start: { ko: '시작하기', en: '' },
  },
  homeBanner: {
    freeChatModalTitle: { ko: '서비스 오픈 이벤트 !', en: '' },
    freeChatModalDescription: {
      ko: '회원가입 후 첫 채팅이 시작되면,\n무료로 메이트와 대화를 3번 해볼 수 있어요!\n은밀하고 섹시한 데이트❤️ 지금 시작해 볼까요?',
      en: '',
    },
  },
  contents: {},
  promotion: {
    bottomButtonText: { ko: '티카에서 대화하기', en: '' },
    noticeList: [
      // ...(isPromotionPeriod()
      //   ? [
      //       {
      //         ko: `본 이벤트는 ${PROMOTION_END_STR}에 종료되며, 이후 젤리는 정상가로 구매 가능합니다. (20개 기준 8,800원)`,
      //         en: '',
      //       },
      //     ]
      //   : []),
      {
        ko: '메이트와의 대화는 실시간으로 암호화되며 따로 저장되지 않습니다.',
        en: '',
      },
    ],
  },
  terms: {
    title: { ko: '서비스 약관', en: '' },
    agreeWord: { ko: '동의', en: '' },
    disagreeWord: { ko: '미동의', en: '' },
    agree: { ko: '동의하기', en: '' },
    agreeSuccess: { ko: '선택 약관에 동의했어요', en: '' },
    disagree: { ko: '동의 철회하기', en: '' },
    disagreeSuccess: { ko: '선택 약관 동의를 철회했어요', en: '' },
    requiredTerm: { ko: '필수 약관', en: '' },
    optionalTerm: { ko: '선택 약관', en: '' },
    marketingTerm: { ko: '마케팅 정보 수신', en: '' },
    marketingInfo: {
      ko: '  서비스와 관련된 새로운 소식, 이벤트/프로모션, 고객혜택 등 다양한 정보를 문자 알림, 카카오알림톡, 이메일로 수신하는데 동의합니다.',
      en: '',
    },
    marketingAgree: { ko: '마케팅 정보 수신 동의가 설정되었어요!', en: '' },
    marketingDisagree: { ko: '마케팅 정보 수신 동의가 해제되었어요!', en: '' },
  },
  signup: {
    step01HeaderTitle: { ko: '회원가입', en: '' },
    step02HeaderTitle: { ko: '약관 동의', en: '' },
    step03HeaderTitle: { ko: '닉네임 설정', en: '' },
    step01Title: {
      ko: '아직 티카에\n가입하지 않으셨군요',
      en: '',
    },
    step01Description: {
      ko: '본인인증 후 회원가입이 완료돼요',
      en: '',
    },
    step02Title: { ko: '약관에 동의해 주세요', en: '' },
    step03Title: {
      ko: '메이트와의 데이트에서\n사용할 닉네임을 설정해 주세요',
      en: '',
    },
    step01BottomButtonText: {
      ko: '회원가입하기',
      en: '',
    },
    countryCode: '국가번호',
    countryCodePlaceholder: '국가번호를 선택해 주세요',
    phoneNumber: '휴대폰 번호',
    phoneNumberValid: '휴대폰 번호를 확인해 주세요',
    phoneNumberPlaceholder: '번호를 입력해 주세요',
    certificationNumber: { ko: '인증번호', en: '' },
    certificationNumberPlaceholder: { ko: '인증번호를 입력해 주세요', en: '' },
    certificationNumberValid: '인증번호를 확인해 주세요',
    certificationInfo: '인증번호가 안왔나요?',
    certificationReSend: '인증번호 재발송',
    nickname: '닉네임',
    nicknamePlaceholder: '닉네임을 입력해 주세요',
    nicknameInfo: '닉네임 정책',
    nicknameValid: '닉네임 정책',
    alreadyUsedNick: { ko: '이미 사용중인 닉네임입니다.', en: '' },
    start: { ko: '시작하기', en: '' },
    alreadyHasAccount: '이미 가입한 계정이 있어요',
    alreadyHasAccountDescription:
      '아래 SNS로 가입한 계정이 있어요. \n아래 계정으로 로그인해 주세요.',
    welcome: { ko: '반가워요!', en: '' },
    chatWithCeleb: {
      ko: '님! \n티카와 함께\n즐거운 시간을 보내볼까요?',
      en: '',
    },
    isOlder19: { ko: '만 19세 이상입니다.', en: '' },
    marketing: { ko: '마케팅 활용 및 광고수신 동의', en: '' },
    marketingContent: {
      ko: '서비스와 관련된 새로운 소식, 이벤트/프로모션, 고객혜택 등 다양한 정보를 문자 메시지, 카카오알림톡, 이메일로 수신하는데 동의합니다.',
      en: '',
    },
    agreeAll: { ko: '전체 동의하기', en: '' },
    resendCertificationToast: {
      ko: '인증번호 발송 이후 1분 이후에 가능해요',
      en: '',
    },
    signUpSuccessToast: {
      ko: '10젤리가 지급되었어요! 지금 사용해보세요!',
      en: '',
    },
  },
  certificate: {
    certificateTitle: { ko: '앗! 이런...\n본인인증을 진행해 주세요', en: '' },
    certificateDescription: {
      ko: '티카 서비스는 19세 이상 이용이 가능해요\n서비스 이용을 위해 본인인증이 필요해요',
      en: '',
    },
  },
  duplicateCertificate: {
    pageTitle: { ko: '가입한 다른 계정이 있어요', en: '' },
    pageDescription: {
      ko: '본인인증한 다른 계정이 있어 로그인이 불가능해요.\n가입한 계정으로 로그인해 주세요.',
      en: '',
    },
  },
  adultOnly: {
    adultOnlyHeader: { ko: '본인인증', en: '' },
    adultOnlyTitle: { ko: '앗! 이런...\n만 19세 미만이시네요', en: '' },
    adultOnlyDescription: {
      ko: '만 19세 이상만 티카를 이용할 수 있어요',
      en: '',
    },
  },
  oAuth: {
    networkError: { ko: '통신 상태가 불안정해요', en: '' },
    networkErrorContent: {
      ko: '네트워크 연결 상태 확인 후 다시 시도해주세요.',
      en: '',
    },
    needNiceCertificate: { ko: '본인인증을 진행해 주세요', en: '' },
    needNiceCertificateContent: {
      ko: '티카 서비스는 19세 이상 이용이 가능해요\n서비스 이용을 위해 본인인증이 필요해요',
      en: '',
    },
  },
  myPage: {
    pleaseSignIn: { ko: '로그인을 해주세요', en: '' },
    title: { ko: '마이페이지', en: '' },
    chatTicket: { ko: '채팅권 구매', en: '' },
    drawer: { ko: '사진 보관함', en: '' },
    jelly: { ko: '젤리 내역', en: '' },
    useHistory: { ko: '사용내역', en: '' },
    payHistory: { ko: '충전내역', en: '' },
    guide: { ko: '이용 가이드', en: '' },
    terms: { ko: '서비스 약관', en: '' },
    coupon: { ko: '쿠폰 코드 입력', en: '' },
    cs: { ko: '고객센터', en: '' },
    editNick: { ko: '닉네임 수정', en: '' },
    editProfile: { ko: '프로필 편집', en: '' },
    editProfileConfirm: { ko: '프로필을 수정 하시겠어요?', en: '' },
    successEdit: { ko: '프로필이 수정되었어요!', en: '' },
    selectPhoto: { ko: '사진 보관함', en: '' },
    deletePhoto: { ko: '사진 삭제', en: '' },
    pleaseEnterCouponCode: { ko: '쿠폰 코드를 입력해주세요', en: '' },
    enterCouponCode: { ko: '코드 입력', en: '' },
    invalidCouponCode: { ko: '유효하지 않은 쿠폰이에요', en: '' },
    alreadyUsedCouponCode: { ko: '이미 등록된 쿠폰이에요', en: '' },
    registerCouponCode: { ko: '쿠폰 등록', en: '' },
    couponSuccess: {
      ko: '젤리가 {{couponJelly}}개 지급되었어요!',
      en: '',
      replaceText: {
        couponJelly: '{{couponJelly}}',
      },
    },
  },
  mateDetail: {
    goDateButtonText: {
      ko: '데이트 하기',
      en: '',
    },
    goDateButtonTextWithMateNickName: {
      ko: '대화하기',
      en: '',
    },
    dateNotice: {
      ko: '',
      en: '',
    },
    serviceNotice: {
      title: {
        ko: '서비스 이용안내',
        en: '',
      },
      list: [
        {
          ko: '1:1 채팅 데이트는 티카 서비스 이용 시 사용되는 젤리를 사용하여 시작할 수 있어요',
          en: '',
        },
        {
          ko: '개인정보는 메이트가 요구하더라도 절대! 공유하지 마세요',
          en: '',
        },
      ],
      notionLinkText: {
        ko: '더 자세한 사항을 알고 싶다면?',
        en: '',
      },
    },
    caution: {
      title: {
        ko: '유의사항',
        en: '',
      },
      list: [
        {
          ko: '모든 혜택은 젤리 구매자에 한해 제공되며, 이를 타인에게 판매 및 양도 할 수 없습니다.',
          en: '',
        },
        {
          ko: '티카에서 제공되는 메이트의 메시지, 사진, 음성 등 모든 컨텐츠는 독점 컨텐츠로, 외부로 유출할 경우 법적 책임을 질 수 있습니다.',
          en: '',
        },
        {
          ko: '인종, 성별, 지역, 종교 차별적 및 폭력적 내용을 포함한 비정상적인 대화를 시도하거나 답변을 유도할 경우 일부 기능이 제한되거나, 강제 탈퇴 될 수 있습니다.',
          en: '',
        },
        {
          ko: '위 내용을 위반할 시, 서비스 이용약관에 의한 제재 및 관련 법률에 의해 처벌받을 수 있습니다.',
          en: '',
        },
      ],
      notionLinkText: {
        ko: '더 자세한 사항을 알고 싶다면?',
        en: '',
      },
    },
    chatNotice: {
      title: {
        ko: '대화 전 유의사항을 확인해주세요',
        en: '',
      },
      list: [
        {
          ko: '1:1 채팅 데이트는 티카 서비스 이용 시 사용되는 젤리를 사용하여 시작할 수 있어요',
          en: '',
        },
        {
          ko: '개인정보는 메이트가 요구하더라도 절대! 공유하지 마세요',
          en: '',
        },
        {
          ko: '위 내용을 위반할 시, 서비스 이용약관에 의한 제재 및 관련 법률에 의해 처벌받을 수 있습니다.',
          en: '',
        },
        {
          ko: '모든 혜택은 젤리 구매자에 한해 제공되며, 이를 타인에게 판매 및 양도 할 수 없습니다.',
          en: '',
        },
        {
          ko: '인종, 성별, 지역, 종교 차별적 및 폭력적 내용을 포함한 비정상적인 대화를 시도하거나 답변을 유도할 경우 일부 기능이 제한되거나, 강제 탈퇴 될 수 있습니다.',
          en: '',
        },
        {
          ko: '더 자세한 사항을 알고 싶다면?',
          en: '',
          linkTo: 'https://slogup.notion.site/d26d726bfa8f4ae88d1f6231edbe5f6c',
        },
      ],
    },
    chatCategoryExampleModalTitle: {
      ko: '이렇게 대화할 수 있어요!',
      en: '',
    },
  },
  jelly: {
    title: { ko: '나의 젤리내역', en: '' },
    purchaseJelly: { ko: '충전하기', en: '' },
    jellyCharge: { ko: '젤리 충전', en: '' },
    myJelly: { ko: '보유 젤리', en: '' },
    jellyHistoryDescription: { ko: '메이트와의 데이트', en: '' },
  },
  setting: {
    title: { ko: '설정', en: '' },
    signOut: { ko: '로그아웃', en: '' },
    withdrawal: { ko: '회원탈퇴', en: '' },
    reallySignOut: { ko: '로그아웃 하시겠어요?', en: '' },
    confirmSignOut: { ko: '로그아웃하기', en: '' },
    cancelSignOut: { ko: '취소', en: '' },
  },
  withdrawal: {
    title: { ko: '회원탈퇴', en: '' },
    hasComplaint: {
      ko: '정말 티카를 탈퇴하시겠어요? 🥹',
      en: '',
    },
    personalInformationNoticeTitle: {
      ko: '탈퇴 전, 아래 내용을 확인해주세요',
      en: '',
    },
    personalInformationNoticeList: [
      {
        ko: '탈퇴 후 고객님의 정보는 전자상거래 소비자보호법에 의거한 개인정보보호정책에 따라 관리돼요',
        en: '',
      },
      {
        ko: '탈퇴 후 일정기간 재가입 여부판단을 목적으로 고객님의 최소정보인 닉네임과 성명을 보존해요',
        en: '',
      },
      {
        ko: '탈퇴 시 보유하신 젤리와 사진 보관함 내에 컨텐츠는 모두 소멸되어 복구할 수 없어요',
        en: '',
      },
    ],
    refundNoticeTitle: {
      ko: '충전된 젤리는 결제수단으로만 환불 가능해요',
      en: '',
    },
    checkRefundableJelly: {
      ko: '현재 보유 중인 젤리를 확인 해주세요',
      en: '',
    },
    currentJelly: {
      ko: '보유 젤리',
      en: '',
    },
    refundableJelly: {
      ko: '환불 가능 젤리',
      en: '',
    },
    isCheckAll: {
      ko: '회원탈퇴 안내를 모두 확인했어요',
      en: '',
    },
    doubleCheck: {
      ko: '정말 티카를 탈퇴하시겠어요?',
      en: '',
    },
    doubleCheckDescription: {
      ko: '탈퇴하면 보유하신 젤리와 사진은 모두 소멸돼요',
      en: '',
    },
    success: {
      ko: '성공적으로 탈퇴가 완료되었어요!',
      en: '',
    },
  },
  purchase: {
    headerTitle: {
      ko: '채팅권 구매하기',
      en: '',
    },
    heading: {
      ko: '젤리를 사용하여\n채팅권을 구매할 수 있어요',
      en: '',
    },
    chatTicketListTitle: {
      ko: '구매할 채팅권',
      en: '',
    },
    tooltip: {
      ko: '채팅권은 보낸 대화 기준이에요',
      en: '',
    },
    noticeList: [
      {
        ko: '채팅권 구매 시 티카 메이트들과 대화를 진행할 수 있어요',
        en: '',
      },
      {
        ko: '채팅권은 구매 후 환불이 불가능해요',
        en: '',
      },
      {
        ko: '남은 채팅권은 더보기에서 확인 가능해요',
        en: '',
      },
    ],
    disableButtonText: {
      ko: '젤리 충전이 필요해요',
      en: '',
    },
    toast: {
      ko: '구매한 대화권으로 채팅을 시작해보세요',
      en: '',
    },
  },
  payments: {
    title: { ko: '젤리 충전', en: '' },
    paymentItemInfo: {
      ko: '메이트와 데이트하기 위해\n충전할 젤리를 선택해 주세요',
      en: '',
    },
    paymentSuccess: {
      ko: '개가 충전 되었어요!',
      en: '',
    },
    paymentSuccessContent: {
      ko: '메이트와 데이트하러 가볼까요?',
      en: '',
    },
    purchasedJelly: {
      ko: '충전 젤리',
      en: '',
    },
    paymentAmount: {
      ko: '결제 금액',
      en: '',
    },
    paymentInfo: {
      ko: '결제 정보',
      en: '',
    },
    paymentRetry: {
      ko: '다시 결제 시도하기',
      en: '',
    },
    paymentFailureModalTitle: {
      ko: '결제를 실패했어요',
      en: '',
    },
    paymentFailureModalDescription: {
      ko: '다른 수단을 선택하여 다시 결제해 주세요',
      en: '',
    },
    paymentFailurePageTitle: {
      ko: '젤리 결제가 실패했어요',
      en: '',
    },
    paymentFailurePageDescription: {
      ko: '확인 후 다시 시도해주시겠어요?',
      en: '',
    },
    paymentFailureReason: {
      ko: '결제 실패 사유',
      en: '',
    },
    finalPayment: { ko: '최종 결제 금액', en: '' },
    purchaseProduct: { ko: '충전 젤리', en: '' },
    detailTitle: { ko: '충전하기', en: '' },
    wrongUrl: { ko: '앗! 잘못된 주소로 접근하셨네요', en: '' },
    wrongUrlDescription: { ko: '처음부터 다시 시도해주세요', en: '' },
    noticeList: [
      {
        ko: '젤리 1개 = 정가 1,100원',
        en: '',
      },
      {
        ko: '메이트에게 사용한 젤리는 환불이 불가능합니다.',
        en: '',
      },
      {
        ko: '충전된 젤리는 결제수단으로만 환불이 가능합니다.',
        en: '',
      },
      {
        ko: '충전 후 7일 이내 전액 취소 시 100% 환불 ',
        en: '',
      },
      {
        ko: '충전 후 7일 이후 ~ 60일 이내 취소 시 90% 환불',
        en: '',
      },
      {
        ko: '충전 후 60일 이후 취소 시 80% 환불',
        en: '',
      },
      {
        ko: '충전 후 180일 경과 시 환불 불가',
        en: '',
      },
    ],
    whatIsJellyQuestion: { ko: '젤리란?', en: '' },
    whatIsJellyAnswer: {
      ko: '티카 서비스 이용 시 필요한 전용 결제수단입니다.',
      en: '',
    },
  },
  chatRoomList: {
    goFindMate: {
      ko: '메이트 찾으러 가기',
      en: '',
    },
  },
  chat: {
    topNoticeTitleEmoji: {
      ko: '💬',
      en: '',
    },
    topNotice: {
      ko: '️메이트가 보내는 내용은 모두 생성된 내용이며, 비밀이 유지돼요',
      en: '',
    },

    startChat: { ko: '님과\n데이트를 시작해 볼까요?', en: '' },
    suggestAfterChat: { ko: '님께\n애프터 신청을 해볼까요?', en: '' },
    resumeChat: { ko: '님과\n데이트를 계속해 볼까요?', en: '' },

    needSignIn: { ko: '데이트를 시작하려면 로그인이 필요해요', en: '' },

    needPurchaseJelly: { ko: ' 충전이 필요해요', en: '' },
    needUseJelly: { ko: '가 차감돼요', en: '' },
    waitYou: { ko: '님을 기다리고 있어요!', en: '' },

    goPurchase: { ko: '충전하기', en: '' },
    goDate: { ko: '데이트 하기', en: '' },
    jellyCount: { ko: '사용 가능한 젤리 :', en: '' },
    occupiedModalTitle: { ko: '메이트가 잠시 자리를 비웠어요', en: '' },
    occupiedModalContent: {
      ko: '잠시 후 다시 데이트를 진행해 주세요',
      en: '',
    },
    occupiedModalButton: { ko: '다시 올게요', en: '' },
    chatEnded: { ko: '채팅이 종료되었어요', en: '' },
    refundNotice: { ko: '*사용된 젤리는 환불되지 않아요', en: '' },
    buyJellyModalTitle1: { ko: '데이트를 이어가기 위해', en: '' },
    buyJellyModalTitle2: { ko: '가 필요해요', en: '' },
    buyJellyModalContent: {
      ko: '젤리를 추가 구입해서 대화를 이어가세요',
      en: '',
    },
    errorWithJoinChatRoom: {
      ko: '채팅방 열기 실패',
      en: '',
    },
    remain: { ko: '남음', en: '' },
    noDateHistory: {
      ko: '아직 채팅중인 메이트가 없네요!\n원하는 메이트를 찾아볼까요?',
      en: '',
    },

    startedChatRooms: { ko: '데이트가 시작되었어요', en: '' },
    ongoingChatRooms: { ko: '데이트가 진행중이에요', en: '' },
    endedChatRooms: { ko: '데이트가 종료되었어요', en: '' },

    firstNoticeModalTitle: {
      ko: '채팅 이용 안내',
      en: '',
    },
    firstNoticeModalDescription: [
      {
        key: 'first-notice-modal-description-1',
        title: {
          ko: '1. 메이트와의 대화는 100% 암호화 ',
          en: '',
        },
        description: {
          ko: '메이트와 나누는 대화는 아무도 볼 수 없도록 암호화돼요\n두분의 대화는 아무도 모르는 비밀 대화에요',
          en: '',
        },
      },
      {
        key: 'first-notice-modal-description-2',
        title: {
          ko: '2. 채팅창 내 컨텐츠 유출 및 배포 금지',
          en: '',
        },
        description: {
          ko: '메이트와의 대화, 음성, 이미지 등을 포함한 정보를 무단으로\n유출하거나 배포하여 저작권을 침해하거나 메이트와 티카 서비스의 평판을 훼손하는 경우, 저작권 및 형법 등에 의거 처벌받을 수 있어요',
          en: '',
        },
      },
    ],
    firstNoticeModalButtonText: {
      ko: '네, 확인했어요',
      en: '',
    },
    guideModalTitle: {
      ko: '메이트와 어떤 대화를 하면 좋을까요?',
      en: '',
    },
    guideModalDescription: {
      ko: '당신의 가까운 사람이 되고 싶은 ai 메이트와는\n여러 카테고리의 대화가 가능해요!\n친구도, 연인도 때로는 당신의 운세도!\n지금! 메이트와 대화를 시작해볼래요?',
      en: '',
    },
    guideModalButtonText: {
      ko: '메이트 둘러보기',
      en: '',
    },
    openGuideModalButtonText: {
      ko: '가이드 보기',
      en: '',
    },
    toastRemainCountsText: {
      ko: '채팅 {{remainChatCount}}회와 음성 {{remainVoiceCount}}회가 남았어요 !',
      en: '',
      replaceText: {
        remainChatCount: '{{remainChatCount}}',
        remainVoiceCount: '{{remainVoiceCount}}',
      },
    },
    errorChatLogText: {
      ko: '미안해, 방금 보낸 메시지가 안왔어! 혹시 다시 보내줄 수 있어?',
      en: '',
    },
    checkCount: {
      ko: '횟수를 확인해보세요!',
      en: '',
    },
    checkRemainTime: {
      ko: '시간을 확인해보세요!',
      en: '',
    },
    reallyLeaveChatRoom: {
      ko: '정말 대화를 종료하시겠어요?',
      en: '',
    },
    continueChat: {
      ko: '계속 대화하기',
      en: '',
    },
    purchaseChatTicket: {
      ko: '채팅권 구매하기',
      en: '',
    },

    purchaseChatTicketTitle: {
      ko: '와 채팅을 계속 하시겠어요?',
      en: '',
    },
    purchaseChatTicketDescription: {
      ko: '채팅권을 구매하고 더 많은 이야기를 나눠보세요!',
      en: '',
    },
  },
  notFound: {
    pageDescription: {
      ko: '찾을 수 없는 페이지 입니다\n요청하신 페이지가 사라졌거나,\n잘못된 경로로 접근했어요',
      en: '',
    },
  },
  error: {
    errorOccurred: {
      ko: '오류가 발생했어요',
      en: '',
    },
    errorOccurredInChatRoom: {
      ko: '메이트와의 채팅방에 오류가 발생했어요',
      en: '',
    },
    errorOccurredInSendMessage: {
      ko: '메이트에게 메시지 전송 중 오류가 발생했어요',
      en: '',
    },
    notAvailableWithError: {
      ko: '오류가 발생하여 정상적으로 데이트가 어려워요',
      en: '',
    },
    pleaseRetryLater: {
      ko: '잠시 후 다시 시도해주세요',
      en: '',
    },
    pleaseLoginAfterRefresh: {
      ko: '새로고침 후 다시 로그인해주세요',
      en: '',
    },
    pleaseRetryAfterRefresh: {
      ko: '새로고침 후 다시 시도해주세요',
      en: '',
    },
    alreadyRunOutChatTicket: {
      ko: '채팅권을 이미 소진했어요',
      en: '',
    },
    keepDateWithPurchaseChatTicket: {
      ko: '젤리 충전 후 채팅권 구매를 진행하여\n데이트를 이어해보세요',
      en: '',
    },
    alreadyHaveChatTicket: {
      ko: '보유한 채팅권이 있어 데이트가 가능해요',
      en: '',
    },
    contactCustomerServiceWhenContinuesError: {
      ko: '오류가 계속 발생할 경우 고객센터로 문의주세요',
      en: '',
    },
    reAuthenticationWithIn1Minute: {
      ko: '1분 이내에 재인증을 요청하였습니다.',
      en: '',
    },
  },
};

export const queryKeys = {
  kakaoLogin: 'kakaoLogin',
  terms: 'terms',
  customersTerms: 'customerTerms',
  me: 'me',
  nickDuplicateCheck: 'nickDuplicateCheck',
  emailDuplicateCheck: 'emailDuplicateCheck',
  checkProviderByNice: 'checkProviderByNice',
  getProducts: 'getProducts',
  customersMePayments: 'customersMePayments',
  customersMeCreditHistories: 'customerMeCreditHistories',
  categories: 'getCategories',
  partners: 'getPartners',
  getChatRooms: 'getChatRooms',
  getChatRoomsByIdHistories: 'getChatRoomsByIdHistories',
  getLastChatLog: 'getLastChatLog',
  getCustomersMeChatLogsById: 'getCustomersMeChatLogsById',
  getChatRoomsById: 'getChatRoomsById',
  getPartnersWithChatRoomsById: 'getPartnersWithChatRoomsById',
  getNiceEncData: 'getNiceEncData',
  getNiceDecData: 'getNiceDecData',
  getContents: 'getContents',
  getPaidChatLogMedia: 'getPaidChatLogMedia',
  getProductsChatTickets: 'getProductsChatTickets',
  getAttendanceList: 'getAttendanceList',
};

export const pageStatus = {
  payments: {
    failure: 'failure',
  },
};

export const cookieKeys = {
  ChatFirstNoticeModal: 'ChatFirstNoticeModal',
};

export const storageKey = {
  local: {
    provider: 'provider',
    mateId: 'mateId',
    disableTooltipInMateDetail: 'disableTooltipInMateDetail',
  },
  session: {
    payments: 'payments',
    redirectUrl: 'redirectUrl',
    redirectUrlAfterPurchase: 'redirectUrlAfterPurchase',
    history: 'history',
    firstMessageFromVolatileChatRoom: 'firstMessageFromVolatileChatRoom',
  },
};

export const externalLinks = {
  termsOfService: 'https://slogup.notion.site/f1aba034c70d48188ceabcf228ee15b0',
  privacyPolicy: 'https://slogup.notion.site/62a103e155534f1f91fe6369c8252d5a',
};

export const getAlertTextByErrorMessages = (
  errorMessage?: ErrorMessage | string,
  translate?: string,
): { title: string; description: string } => {
  switch (errorMessage) {
    case 'FAIL_TO_CREATE_CHAT_ROOM':
    case 'NOT_FOUND_CHAT_ROOM':
    case 'DUPLICATED_CHAT_ROOM':
    case 'ALREADY_JOINED_CHAT_ROOM':
    case 'DUPLICATED_USER_CHAT_MESSAGE':
      return {
        title: strings.error.pleaseRetryAfterRefresh.ko,
        description: strings.error.errorOccurredInChatRoom.ko,
      };

    case 'INSUFFICIENT_CREDITS_TO_PURCHASE_CHAT_TICKET':
    case 'NOT_ENOUGH_CHAT_COUNT':
    case 'ALREADY_ENDED_CHAT_ROOM':
      return {
        title: strings.error.alreadyRunOutChatTicket.ko,
        description: strings.error.keepDateWithPurchaseChatTicket.ko,
      };

    case 'CANNOT_BUY_NEW_CHAT_TICKET_WITHOUT_CONSUMING_ALL_EXISTING_TICKETS':
      return {
        title: strings.error.pleaseRetryAfterRefresh.ko,
        description:
          strings.error.alreadyHaveChatTicket.ko +
          '\n' +
          strings.error.contactCustomerServiceWhenContinuesError.ko,
      };

    case 'ALREADY_EXIST_FIRST_MESSAGE':
    case 'NOT_FOUND_USER_MESSAGE':
    case 'NOT_FOUND_USER_FIRST_MESSAGE':
    case 'MISSING_USER_MESSAGE':
      return {
        title: strings.error.pleaseRetryAfterRefresh.ko,
        description: strings.error.errorOccurredInSendMessage.ko,
      };

    case 'CHAT_ROOM_UNAVAILABLE':
    case 'UNAUTHORIZED_CUSTOMER':
      return {
        title: strings.error.pleaseLoginAfterRefresh.ko,
        description: strings.error.notAvailableWithError.ko,
      };

    default:
      return {
        title: strings.error.errorOccurred.ko,
        description: translate ?? strings.error.pleaseRetryLater.ko,
      };
  }
};

export const getAlertTextByError = (
  err: any,
): {
  title: string;
  description: string;
} => {
  if (err?.response) {
    const error = err?.response.data as ServerErrorBody;

    const { title, description } = getAlertTextByErrorMessages(
      error?.message,
      error?.translate,
    );

    return {
      title,
      description,
    };
  } else if (err?.request) {
    return {
      title: strings.error.pleaseRetryAfterRefresh.ko,
      description: strings.error.errorOccurredInChatRoom.ko,
    };
  } else {
    return {
      title: strings.error.errorOccurred.ko,
      description: strings.error.pleaseRetryLater.ko,
    };
  }
};
