import React from 'react';
import MateContainer, {
  type MateContainerProps,
} from '@/pages/Home/components/MateContainer';
import { type Partner } from '@/models/partners';
import { Link } from 'react-router-dom';
import { paths } from '@/constants/paths';
import { Skeleton } from '@/components';
import { cn } from '@/lib/utils';
import { IconRightArrow, IconTalkOutline } from '@/assets/icons';

interface Props extends MateContainerProps {
  mate?: Partner;
  mateList?: Partner[];
  isLoading?: boolean;
  isBlur?: boolean;
}

const MateSolo = ({
  mate,
  mateList,
  subTitle,
  mainTitle,
  isLoading = false,
  isBlur = false,
}: Props) => {
  const renderCard = (mate: Partner) => (
    <Link
      key={`home-mate-${mate.id}`}
      className={cn(
        'relative block w-full aspect-square rounded-[20px] overflow-hidden',
        isBlur
          ? 'pointer-events-none'
          : 'cursor-pointer transition-transform pointerhover:hover:scale-[102%]',
      )}
      to={`${paths.celeb}/${mate.id}`}
    >
      <img
        className="w-full h-full object-cover"
        width="100%"
        src={mate.profileMedia.mediumFullUrl}
        alt="mate-profile-image"
      />
      <div className="absolute rounded-b-[16px] left-0 right-0 bottom-0 space-y-[8px] text-White p-[20px] bg-gradient-to-b from-[rgba(40,31,1,0.00)_0%] to-[rgba(40,31,1,0.60)_100%]">
        <h3 className="text-D6 flex items-center gap-[2px]">
          {mate.nick} <IconRightArrow width={24} stroke="#FFFFFF" />
        </h3>
        <div className="h-px w-full bg-White"></div>
        <p className="whitespace-pre-line text-B9">{mate.dailyMessage}</p>
      </div>
      {isBlur && (
        <div className="z-20 absolute top-0 left-0 w-full h-full bg-[rgba(255,255,255,0.04)] backdrop-blur-[8px] rounded-[20px]"></div>
      )}
      <div className="absolute flex items-center gap-[4px] right-[15px] top-[15px] bg-White py-[4px] px-[10px] bg-opacity-70 rounded-[25px] shadow-md">
        <IconTalkOutline width={20} />
        <span className="text-H8M text-Gray2">TALK</span>
      </div>
    </Link>
  );

  return (
    <MateContainer subTitle={subTitle} mainTitle={mainTitle}>
      <Skeleton
        className="w-full h-auto aspect-square rounded-[20px]"
        isLoading={isLoading}
      >
        {mate && renderCard(mate)}
        <div className="space-y-[32px]">
          {(mateList?.length ?? 0) > 0 && mateList?.map(renderCard)}
        </div>
      </Skeleton>
    </MateContainer>
  );
};

export default MateSolo;
