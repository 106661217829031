import React from 'react';
import SocialButton, {
  type SocialType,
} from '@/components/Button/SocialButton';
import Button from '@/components/Button';
import { IconEmail } from '@/assets/icons';
import { storageKey, strings } from '@/constants/strings';
import { Link, useLocation } from 'react-router-dom';
import { paths } from '@/constants/paths';
import { Tooltip } from '@/components';
import colors from '@/constants/colors';

interface Props {
  className?: string;
  replace?: boolean;
  socialList?: SocialType[];
  onClick?: () => void;
}

const SocialButtonGroup = ({
  className,
  replace,
  socialList = [
    'kakao',
    // 'google',
    // 'instagram'
  ],
  onClick,
}: Props) => {
  const location = useLocation();
  const recentProvider = localStorage.getItem(storageKey.local.provider);
  return (
    <div className={className ?? 'flex flex-col gap-[12px]'}>
      {socialList.map((social) => (
        <SocialButton
          key={`social-button-${social}`}
          size={48}
          socialType={social}
          replace={replace}
          onClick={onClick}
        />
      ))}
      <Link
        to={paths.signIn}
        replace={replace}
        onClick={() => {
          onClick?.();

          sessionStorage.setItem(
            storageKey.session.redirectUrl,
            location.pathname,
          );
        }}
      >
        <Button
          size={48}
          variant="light"
          className="relative flex items-center justify-center w-full"
        >
          <IconEmail
            width={18}
            height={18}
            className="absolute left-0"
            stroke={colors.Gray2}
          />
          {recentProvider === 'email' && (
            <Tooltip className="top-[-32px]">
              {strings?.home?.recentLogin.ko}
            </Tooltip>
          )}
          <span className="text-H8M">{strings.signIn.signInWithEmail.ko}</span>
        </Button>
      </Link>
    </div>
  );
};

export default SocialButtonGroup;
