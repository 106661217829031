import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import Button from '../Button';
import { cn } from '@/lib/utils';
import { type ButtonVariants } from '@/components/ui/button';

export interface ModalProps {
  open?: boolean;
  containerClassName?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
  primaryButtonText?: string | React.ReactNode;
  cancelButtonText?: string | React.ReactNode;
  primaryButtonVariant?: ButtonVariants;
  cancelButtonVariant?: ButtonVariants;
  onPrimaryClick?: () => void;
  onCancelClick?: () => void;
  onClickLoading?: boolean;
  onClose?: () => void;
  buttonDirection?: 'row' | 'col';
  buttonOrder?: 'primary2cancel' | 'cancel2primary';
}

const Modal = ({
  open = false,
  containerClassName,
  title,
  description,
  body,
  primaryButtonText = '가입 계속하기',
  cancelButtonText = '뒤로가기',
  primaryButtonVariant = 'default',
  cancelButtonVariant = 'outline',
  onPrimaryClick,
  onCancelClick,
  onClickLoading,
  onClose, // undefined일 때, 필수 모달
  footer,
  buttonDirection = 'col',
  buttonOrder = 'primary2cancel',
}: ModalProps) => {
  return (
    <Dialog open={open}>
      <DialogContent
        onClose={onClose}
        className={cn(
          'bg-Gray1 border border-Gray8 border-opacity-30',
          containerClassName,
        )}
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle className="whitespace-pre-line">{title}</DialogTitle>
          {description && (
            <div className="text-Gray8 text-H8M whitespace-pre-line">
              {description}
            </div>
          )}
        </DialogHeader>
        {body}
        <DialogFooter
          className={cn(
            buttonDirection === 'row' && 'flex-row items-center',
            buttonOrder === 'cancel2primary' &&
              (buttonDirection === 'row'
                ? 'flex-row-reverse'
                : 'flex-col-reverse'),
          )}
        >
          {footer ?? (
            <>
              <Button
                className={buttonDirection === 'row' ? 'flex-1' : undefined}
                variant={primaryButtonVariant}
                size={48}
                onClick={onPrimaryClick}
                loading={onClickLoading}
              >
                {primaryButtonText}
              </Button>
              <Button
                className={buttonDirection === 'row' ? 'flex-1' : undefined}
                variant={cancelButtonVariant}
                size={48}
                onClick={onCancelClick}
              >
                {cancelButtonText}
              </Button>
            </>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
