import request from '@/apis/axios';
import { type ObjectResponse } from '@/interface/common';
import { type NiceEncData } from '@/models/nice';

const url = '/nice-certification';

export const getNiceEncData = async (returnPath?: string) => {
  const { data } = await request<ObjectResponse<NiceEncData>>({
    method: 'get',
    url: `${url}/encryption-token`,
    queryParams: {
      returnPath,
    },
  });
  return data;
};
