import React, { useState } from 'react';
import { getTerms } from '@/apis/terms';
import { FooterButton, Terms } from '@/components';
import type { ITerm } from '@/components/Terms/types';
import { queryKeys, strings } from '@/constants/strings';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { paths } from '@/constants/paths';
import PrevHeader from '@/components/Header/PrevHeader';
import useSignUpStore from '@/zustands/useSignUpStore';
import useNiceCertificate from '@/hooks/useNiceCertificate';
import { getCustomersProviderByNice } from '@/apis/customers';

export default function SignUpTerms() {
  const [pass, setPass] = useState(false);
  const navigate = useNavigate();
  const [optionalTerms, setOptionalTerms] = useState<number[]>([]);
  const [searchParams] = useSearchParams();
  const termsId = searchParams.get('id');
  const { setSignUpData, data: signUpData } = useSignUpStore();
  const { data } = useQuery([queryKeys.terms], getTerms, {
    retry: false,
    select: (data) => {
      return [
        {
          id: 'age',
          optionable: false,
          title: strings.signup.isOlder19.ko,
          history: '',
          isMarketing: false,
          isOlder19: true,
        },
        ...data?.rows?.map((term) => ({
          id: term?.id,
          optionable: !!(term?.type === 'OPTIONAL'),
          title: term?.title,
          history: term?.termsHistories?.[0]?.body,
          isMarketing: false,
        })),
        {
          id: 'marketing',
          optionable: true,
          title: strings.signup.marketing.ko,
          history: '',
          isMarketing: true,
        },
      ];
    },
  });

  const { mutate: getProviders } = useMutation(getCustomersProviderByNice);

  const { showNicePopup, renderNiceForm } = useNiceCertificate({
    onSuccess: (tokenVersionId) => {
      getProviders(tokenVersionId, {
        onSuccess: (res) => {
          navigate(paths.hasAccount, {
            replace: true,
            state: {
              provider: res.row.provider,
            },
          });
        },
        onError: (err: any) => {
          if (err?.response?.status === 404) {
            setSignUpData({
              termsIds: optionalTerms,
              tokenVersionId,
            });
            navigate(
              signUpData.body.oauth ? paths.signUpNick : paths.signUpByEmail,
              { replace: true },
            );
          }
        },
      });
    },
    onFail: () => {
      navigate(paths.adultOnly, { replace: true });
    },
    failPath: paths.adultOnly,
  });

  const handleNextClick = () => {
    showNicePopup();
  };

  return (
    <>
      <PrevHeader title={strings.signUpTerms.pageTitle.ko} />
      <div className="mt-header">
        {termsId ? (
          <div className="p-[20px] flex flex-col ">
            <h3 className="text-H4 mt-0">
              {data?.filter((item) => item?.id === Number(termsId))?.[0]?.title}
            </h3>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  data?.filter((item) => item?.id === Number(termsId))?.[0]
                    ?.history ?? '',
              }}
            />
          </div>
        ) : (
          <>
            <section className="flex flex-col gap-[28px] p-[20px]">
              <h3 className="text-H3 text-White">
                {strings.signUpTerms.pageDescription.ko}
              </h3>
              <div className="flex flex-col gap-[16px]">
                {data && (
                  <Terms
                    terms={data as ITerm[]}
                    setTermsPass={setPass}
                    setAgreeOptionTermIds={(values) => {
                      setOptionalTerms([...values]);
                    }}
                  />
                )}
              </div>
            </section>
            <FooterButton
              disabled={!pass}
              onClick={handleNextClick}
              className="email-signup-terms-footer-button"
            >
              {strings.common.next.ko}
            </FooterButton>
            {renderNiceForm()}
          </>
        )}
      </div>
    </>
  );
}
