export const isUserAgentMobile = () => {
  return navigator.userAgent.toLowerCase().includes('mobile');
};

export const isInAppBrowserOfFacebookOrInstagram = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  return userAgent.includes('[fb') || userAgent.includes('instagram');
};

export const isIos = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  return (
    userAgent.includes('iphone') ||
    userAgent.includes('ipad') ||
    userAgent.includes('ipod')
  );
};

export const getLastElementInArray = <T = any>(array: T[]): T | undefined => {
  if (array.length > 0) {
    return array[array.length - 1];
  } else {
    return undefined;
  }
};

export const isNumber = (x: number | undefined | null) => {
  if (!x || typeof x !== 'number' || isNaN(x)) return false;

  return true;
};

export const isDevelopment = () => {
  return process.env.REACT_APP_ENVIRONMENT === 'development';
};

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min; // 최댓값은 제외, 최솟값은 포함
};

export const isCloserApp = () => {
  return !!window.ReactNativeWebView;
};

export const postMessageToRN = (
  type: 'NICE_CERTIFICATION' | string,
  data?: Record<string, any>,
) => {
  window?.ReactNativeWebView?.postMessage?.(
    JSON.stringify({
      from: 'closer-web',
      type,
      ...data,
    }),
  );
};

export const getDayOfYear = () => {
  // 현재 날짜 가져오기
  const currentDate = new Date();

  // 현재 년도의 1월 1일 날짜 가져오기
  const startOfYear = new Date(currentDate.getFullYear(), 0, 0);

  // 현재 날짜와 1월 1일 사이의 경과 일수 계산
  const elapsedDays =
    (currentDate.getTime() - startOfYear.getTime()) / (1000 * 60 * 60 * 24);

  return Math.floor(elapsedDays);
};
