import {
  IconBottomNavChatting,
  IconBottomNavExplore,
  IconBottomNavMyPage,
  IconBottomNavNotification,
} from '@/assets/icons';
import colors from '@/constants/colors';
import { type ActiveKeyType, paths } from '@/constants/paths';
import { useAuthStore } from '@/zustands/useAuthStore';
import useSignInModal from '@/zustands/useSignInModal';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const navList: Array<{
  key: ActiveKeyType;
  label: string;
  activeIcon: React.ReactNode;
  icon: React.ReactNode;
  needAuth?: boolean;
}> = [
  {
    key: paths.home,
    label: '탐색',
    activeIcon: (
      <IconBottomNavExplore width={24} height={24} fill={colors.White} />
    ),
    icon: <IconBottomNavExplore width={24} height={24} fill={colors.Gray6} />,
  },
  {
    key: paths.chatting,
    label: '채팅',
    activeIcon: (
      <IconBottomNavChatting width={24} height={24} fill={colors.White} />
    ),
    icon: <IconBottomNavChatting width={24} height={24} fill={colors.Gray6} />,
  },
  {
    key: paths.notifications,
    label: '알림',
    activeIcon: (
      <IconBottomNavNotification width={24} height={24} fill={colors.White} />
    ),
    icon: (
      <IconBottomNavNotification width={24} height={24} fill={colors.Gray6} />
    ),
    needAuth: true,
  },
  {
    key: paths.profile,
    label: '마이',
    activeIcon: (
      <IconBottomNavMyPage width={24} height={24} fill={colors.White} />
    ),
    icon: <IconBottomNavMyPage width={24} height={24} fill={colors.Gray6} />,
  },
];
interface NavProps {
  activeKey?: ActiveKeyType;
}

const Nav = ({ activeKey = '/' }: NavProps) => {
  const navigate = useNavigate();
  const { isLogin } = useAuthStore();
  const { showSignInModal } = useSignInModal();

  const handleClick = (key: ActiveKeyType, needAuth?: boolean) => {
    if (needAuth && !isLogin) {
      // 로그인 필요한데 비로그인 -> 로그인 팝업
      showSignInModal();
    } else {
      navigate({ pathname: key });
    }
  };

  return (
    <div className="fixed bottom-0 flex w-full max-w-max min-w-min rounded-t-[24px] px-[24px] pt-[12px] pb-[32px] justify-between text-H9M bg-Gray1 z-header">
      {navList?.map((item) => (
        <div
          className="flex flex-col items-center basis-[48px] gap-[4px] cursor-pointer"
          key={item?.key}
          onClick={() => {
            handleClick(item?.key, item?.needAuth);
          }}
        >
          {item?.key === activeKey ? item.activeIcon : item.icon}
          <span
            className={`${
              item?.key === activeKey ? 'text-White' : 'text-Gray6'
            } text-[12px] font-medium leading-[normal]`}
          >
            {item?.label}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Nav;
