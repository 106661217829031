import React, {
  type FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import 'swiper/css';
import { Navigation } from 'swiper';
import { IconArrowLeftBg, IconArrowRightBg } from '@/assets/icons';
import { type CarouselImageObject } from '@/components/Modal/CarouselModal';
import { cn } from '@/lib/utils';
import { strings } from '@/constants/strings';

interface Props {
  className?: string;
  swiperClassName?: string;
  list: CarouselImageObject[];
  initialSlide?: number;
  hasKeyboardEvent?: boolean;
}

const ImageCarouselInModal: FunctionComponent<Props> =
  function ImageCarouselInModal({
    className,
    swiperClassName,
    list = [],
    hasKeyboardEvent = false,
    initialSlide = 0,
  }) {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [init, setInit] = useState(false);
    const [active, setActive] = useState(initialSlide);
    useEffect(() => {
      const handleRefClick = (e: KeyboardEvent) => {
        if (e?.code === 'ArrowLeft' && prevRef?.current) {
          (prevRef?.current as HTMLDivElement)?.click();
        }
        if (e?.code === 'ArrowRight' && nextRef?.current) {
          (nextRef?.current as HTMLDivElement)?.click();
        }
      };
      if (hasKeyboardEvent) {
        window.addEventListener('keydown', handleRefClick);
        return () => {
          window.removeEventListener('keydown', handleRefClick);
        };
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevRef?.current, nextRef?.current]);
    return (
      <>
        <h4 className="text-H4 text-White">
          <span className="text-Primary">{list[active]?.title}</span>{' '}
          {strings.mateDetail.chatCategoryExampleModalTitle.ko}
        </h4>
        <div className={cn('relative', className)}>
          <Swiper
            autoHeight
            className={cn('flex items-center justify-center', swiperClassName)}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            modules={[Navigation]}
            onSlideChange={(e) => {
              setActive(e.activeIndex);
            }}
            onSwiper={(swiper) => {}}
            onInit={() => {
              setInit(true);
            }}
            initialSlide={initialSlide}
          >
            {list?.map((item, i) => (
              <SwiperSlide key={i?.toString()}>
                <div className="flex items-center">
                  {typeof item.image === 'string' ? (
                    <img
                      alt="image"
                      src={item.image}
                      width="100%"
                      className=""
                    />
                  ) : (
                    item.image
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {list?.length > 1 && (
            <>
              <div
                className={`absolute flex items-center justify-center top-[50%] w-[24px] h-[24px] 
            rounded-[50%]  border-none cursor-pointer z-40 translate-y-[-50%] left-0 ${
              active === 0 && 'hidden'
            }`}
                ref={prevRef}
              >
                <IconArrowLeftBg />
              </div>
              <div
                className={`absolute flex items-center justify-center top-[50%] w-[24px] h-[24px] 
            rounded-[50%] border-none cursor-pointer z-40 translate-y-[-50%] right-0 ${
              active === list?.length - 1 && 'hidden'
            }`}
                ref={nextRef}
              >
                <IconArrowRightBg />
              </div>
            </>
          )}
        </div>
      </>
    );
  };

export default ImageCarouselInModal;
