import React from 'react';
import { Outlet } from 'react-router-dom';
import useAuth from '@/hooks/useAuth';
interface Props {
  prop?: string;
}

const Auth: React.FC<Props> = () => {
  useAuth();

  return <Outlet />;
};

export default Auth;
