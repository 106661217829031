import {
  getCustomerTerms,
  postCustomersTermsByIdAgree,
  postCustomersTermsByIdDisagree,
} from '@/apis/terms';
import { FooterButton } from '@/components';
import PrevHeader from '@/components/Header/PrevHeader';
import { queryKeys, strings } from '@/constants/strings';
import useCheckAuth from '@/hooks/useCheckAuth';
import { useMutation, useQuery } from '@tanstack/react-query';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

interface Props {
  prop?: string;
}

const TermsDetailPage: React.FC<Props> = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data } = useQuery(
    [queryKeys?.customersTerms],
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    () => getCustomerTerms(),
    {
      select: (data) => {
        return data?.rows?.filter((item) => item?.id === Number(id));
      },
    },
  );
  useCheckAuth();
  const agreeMutation = useMutation(postCustomersTermsByIdAgree);
  const disagreeMutation = useMutation(postCustomersTermsByIdDisagree);

  const handleButtonClick = () => {
    if (id) {
      if (data?.[0]?.isAgree) {
        disagreeMutation.mutate(Number(id), {
          onSuccess: () => {
            toast(strings.terms.disagreeSuccess.ko);
            navigate(-1);
          },
        });
      } else {
        agreeMutation.mutate(Number(id), {
          onSuccess: () => {
            toast(strings.terms.agreeSuccess.ko);
            navigate(-1);
          },
        });
      }
    }
  };

  return (
    <>
      <PrevHeader
        title={strings?.terms?.title?.ko}
        onPrevClick={() => {
          navigate(-1);
        }}
      />

      <div className="pt-header pb-[96px] overflow-auto h-full hide-scrollbar">
        <div className="p-[20px] flex flex-col ">
          <h3 className="text-H4 mt-0">{data?.[0]?.title}</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: data?.[0]?.termsHistories?.[0]?.body ?? '',
            }}
          />
        </div>
      </div>
      {data?.[0]?.type === 'OPTIONAL' && (
        <FooterButton
          variant={data?.[0]?.isAgree ? 'light' : 'default'}
          onClick={handleButtonClick}
        >
          {data?.[0]?.isAgree
            ? strings?.terms?.disagree?.ko
            : strings?.terms?.agree?.ko}
        </FooterButton>
      )}
    </>
  );
};

export default TermsDetailPage;
