import React, { forwardRef } from 'react';
import {
  Checkbox as CheckboxBase,
  type CheckboxProps,
} from '@/components/ui/checkbox';

interface InputProps extends CheckboxProps {
  shape?: 'normal' | 'square' | 'circle';
}

const Checkbox = forwardRef<HTMLInputElement, InputProps>(
  ({ shape = 'normal', ...rest }, ref) => {
    return (
      <>
        <CheckboxBase shape={shape} {...rest} />
      </>
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
