import { IconChatting } from '@/assets/icons';
import React from 'react';

interface LabelProps {
  count?: number;
}

const CardLabel = ({ count = 0 }: LabelProps) => {
  return (
    <div className="inline-flex  h-[18px] rounded-[64px] items-center text-H11M  bg-Primary10 px-[4px] py-[1px] gap-[2px]">
      <IconChatting fill="#F34770" width={14} height={14} />
      <span>{count > 999 ? '+999' : count}</span>
    </div>
  );
};

export default CardLabel;
