export interface PromotionInfo {
  imageSrc: string;
  notice?: {
    title: string;
    list: Array<{
      ko: string;
      en: string;
    }>;
  };
  cta?: {
    text: string;
    linkTo: string;
    isExternalLink?: boolean;
  };
}

export const promotionList: Record<string, PromotionInfo> = {
  spring: {
    imageSrc:
      'https://d2nn6pdgrbkdz2.cloudfront.net/promotion/images/spring-promotion-detail.png',
  },
  signup: {
    imageSrc:
      'https://d2nn6pdgrbkdz2.cloudfront.net/promotion/images/signup-promotion-detail.png',
  },
};
