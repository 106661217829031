import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '@/components/ui/dialog';
// import Button from '../Button';
import { strings } from '@/constants/strings';
import SocialButtonGroup from '@/components/Button/SocialButtonGroup';

export interface SignInModalProps {
  open: boolean;
  onClose: () => void;
  replace?: boolean;
  onClick?: () => void;
}

const SignInModal: React.FC<SignInModalProps> = ({
  open,
  onClose,
  replace,
  onClick,
}) => {
  return (
    <Dialog open={open}>
      <DialogContent
        className="bg-Gray1 border border-Gray8 border-opacity-30"
        onClose={onClose}
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader className="space-y-[10px]">
          <DialogTitle className="whitespace-pre-line text-White">
            {strings.signIn.pleaseSignIn.ko}
          </DialogTitle>
          <DialogDescription>
            {strings.signIn.startDateWithMateAfterSignIn.ko}
          </DialogDescription>
        </DialogHeader>

        <DialogFooter className="gap-[12px]">
          <SocialButtonGroup
            onClick={() => {
              onClick?.();
              onClose();
            }}
            replace={replace}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SignInModal;
