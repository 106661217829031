import type { ListResponse } from '@/interface/common';
import type { GetTermsParams, TermsDto } from '@/models/terms';
import request from '../axios';

const url = '/terms';

export const getTerms = async () => {
  const { data } = await request<ListResponse<TermsDto>>({
    method: 'get',
    url,
  });

  return data;
};

export const getCustomerTerms = async (queryParams?: GetTermsParams) => {
  const { data } = await request<ListResponse<TermsDto>>({
    method: 'get',
    url: `/customers/me${url}`,
    queryParams,
  });

  return data;
};

export const postCustomersTermsByIdAgree = async (id: number) => {
  const { data } = await request({
    method: 'post',
    url: `/customers/me${url}/${id}/agree`,
  });

  return data;
};
export const postCustomersTermsByIdDisagree = async (id: number) => {
  const { data } = await request({
    method: 'post',
    url: `/customers/me${url}/${id}/disagree`,
  });

  return data;
};
