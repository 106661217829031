import { getCustomersMePaymentsById } from '@/apis/payments';
import { IconPaymentsSuccess } from '@/assets/icons';
import { Button, FooterButtonWrapper, Lottie } from '@/components';
import colors from '@/constants/colors';
// import Loading from '@/components/Loading';
import { paths } from '@/constants/paths';
import { queryKeys, storageKey, strings } from '@/constants/strings';
import useBlockBrowserBack from '@/hooks/useBlockBrowserBack';
import useCheckAuth from '@/hooks/useCheckAuth';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function PaymentsSuccessPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const id = searchParams.get('id');
  const queryClient = useQueryClient();

  useCheckAuth();

  useEffect(() => {
    sessionStorage?.removeItem(storageKey.session.payments);
  }, []);

  const { data, isSuccess } = useQuery(
    [queryKeys?.customersMePayments],
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    () => {
      if (id) {
        return getCustomersMePaymentsById(+id, 'confirm');
      }
    },
    // 핵토 결제내역이 서버에 반영되는 시간이 있을수있어서 3번까지 시도
    // TODO 로딩 달기!!!!!@!@!@ 데이터 가져오는거 성공할때까지
    { retry: true },
  );

  useEffect(() => {
    if (isSuccess) {
      setIsLoading(false);
    }
  }, [isSuccess]);

  const handleOkClick = () => {
    // 결제 완료시 이전의 purchase 내역 캐시 삭제
    queryClient.invalidateQueries([
      queryKeys.customersMeCreditHistories,
      'purchase',
    ]);

    queryClient.invalidateQueries([queryKeys.me]).then(() => {
      const url = sessionStorage?.getItem(storageKey.session.redirectUrl);
      sessionStorage.removeItem(storageKey.session.redirectUrl);

      if (url) {
        // 젤리 내역 페이지에서 왔다면, 충전내역으로 이동
        if (url.includes('/profile/jelly')) {
          navigate(`${paths.profileJelly}?tab=purchase`);
        } else {
          navigate({ pathname: url });
        }
      } else {
        navigate({ pathname: paths?.home });
      }
    });
  };

  useBlockBrowserBack();

  return isLoading ? (
    <div className="flex w-[100%] items-center justify-center h-[100vh]">
      <Lottie name="loading" />
    </div>
  ) : (
    <>
      <section className="flex flex-col items-center gap-[32px] px-[20px] py-[60px]">
        <div className="flex flex-col gap-[24px] text-center w-full items-center">
          <figure className="w-[64px] h-[64px]">
            <IconPaymentsSuccess
              width={64}
              height={64}
              fill={colors.Background}
            />
          </figure>
          <div className="flex flex-col gap-[8px]">
            <h1 className="text-D6">
              {strings.common.jelly.ko}{' '}
              {data?.row?.productName?.split(' ')?.[0]}
              {strings?.payments?.paymentSuccess?.ko}
            </h1>
            <p className="text-H7M text-Gray8 whitespace-pre">
              {strings?.payments?.paymentSuccessContent?.ko}
            </p>
          </div>
        </div>
        <div className="w-full">
          <ol className="flex flex-col gap-[12px]">
            <li className="flex gap-[4px] items-center justify-between">
              <h3 className="text-H6M">
                {strings?.payments?.purchasedJelly?.ko}
              </h3>
              <p className="text-H6M">
                {data?.row?.productName?.split(' ')?.[0]}
                {strings?.common?.unit?.ko}
              </p>
            </li>
            <li className="flex gap-[4px] items-center justify-between">
              <h3 className="text-H6M">
                {strings?.payments?.paymentAmount?.ko}
              </h3>
              <p className="text-H6M">
                {Number(data?.row?.tradeAmount)?.toLocaleString()}
                {strings?.common?.currency?.ko}
              </p>
            </li>
          </ol>
        </div>
      </section>
      <FooterButtonWrapper>
        <Button
          onClick={handleOkClick}
          className="payments-success-footer-button- w-full"
          size={56}
        >
          {strings?.common?.ok?.ko}
        </Button>
      </FooterButtonWrapper>
    </>
  );
}
