import { storageKey } from '@/constants/strings';
import { useCallback } from 'react';
import { type NavigateOptions, type To, useNavigate } from 'react-router-dom';

const useRedirectNavigate = () => {
  const navigate = useNavigate();

  const redirectNavigate = useCallback(
    (to: To, options?: NavigateOptions) => {
      const redirectUrl = sessionStorage.getItem(
        storageKey.session.redirectUrl,
      );

      if (redirectUrl) {
        sessionStorage.removeItem(storageKey.session.redirectUrl);
        navigate(redirectUrl, options);
      } else {
        navigate(to, options);
      }
    },
    [navigate],
  );

  return redirectNavigate;
};
export default useRedirectNavigate;
