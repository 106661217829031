import DefaultImageMild from '@/assets/images/chat-log-media-default-mild.png';
import DefaultImageSweet from '@/assets/images/chat-log-media-default-sweet.png';
import DefaultImageHot from '@/assets/images/chat-log-media-default-hot.png';

export const chatCategoryImageList: Record<
  string,
  Array<{
    title: string;
    image: string;
  }>
> = {
  롤플레잉: [
    {
      title: '롤플레잉',
      image: '/assets/images/chat-category-example/example-role-playing-1.png',
    },
    {
      title: '롤플레잉',
      image: '/assets/images/chat-category-example/example-role-playing-2.png',
    },
    {
      title: '롤플레잉',
      image: '/assets/images/chat-category-example/example-role-playing-3.png',
    },
  ],
  밸런스게임: [
    {
      title: '밸런스게임',
      image: '/assets/images/chat-category-example/example-balance-game-1.png',
    },
    {
      title: '밸런스게임',
      image: '/assets/images/chat-category-example/example-balance-game-2.png',
    },
    {
      title: '밸런스게임',
      image: '/assets/images/chat-category-example/example-balance-game-3.png',
    },
  ],
  연애상담: [
    {
      title: '연애상담',
      image:
        '/assets/images/chat-category-example/example-love-counseling-1.png',
    },
    {
      title: '연애상담',
      image:
        '/assets/images/chat-category-example/example-love-counseling-2.png',
    },
    {
      title: '연애상담',
      image:
        '/assets/images/chat-category-example/example-love-counseling-3.png',
    },
  ],
  일상대화: [
    {
      title: '일상대화',
      image: '/assets/images/chat-category-example/example-daily-chat-1.png',
    },
    {
      title: '일상대화',
      image: '/assets/images/chat-category-example/example-daily-chat-2.png',
    },
    {
      title: '일상대화',
      image: '/assets/images/chat-category-example/example-daily-chat-3.png',
    },
  ],
  운동루틴: [
    {
      title: '운동루틴',
      image:
        '/assets/images/chat-category-example/example-workout-routine-1.png',
    },
    {
      title: '운동루틴',
      image:
        '/assets/images/chat-category-example/example-workout-routine-2.png',
    },
    {
      title: '운동루틴',
      image:
        '/assets/images/chat-category-example/example-workout-routine-3.png',
    },
  ],
  플레이리스트: [
    {
      title: '플레이리스트',
      image: '/assets/images/chat-category-example/example-play-list-1.png',
    },
    {
      title: '플레이리스트',
      image: '/assets/images/chat-category-example/example-play-list-2.png',
    },
    {
      title: '플레이리스트',
      image: '/assets/images/chat-category-example/example-play-list-3.png',
    },
  ],
  '별자리 운세': [
    {
      title: '별자리 운세',
      image: '/assets/images/chat-category-example/example-horoscope-1.png',
    },
    {
      title: '별자리 운세',
      image: '/assets/images/chat-category-example/example-horoscope-2.png',
    },
    {
      title: '별자리 운세',
      image: '/assets/images/chat-category-example/example-horoscope-3.png',
    },
  ],
  사주팔자: [
    {
      title: '사주팔자',
      image:
        '/assets/images/chat-category-example/example-fortune-telling-1.png',
    },
    {
      title: '사주팔자',
      image:
        '/assets/images/chat-category-example/example-fortune-telling-2.png',
    },
    {
      title: '사주팔자',
      image:
        '/assets/images/chat-category-example/example-fortune-telling-3.png',
    },
  ],
};

export const getChatCategoryImageList = (categoryNameList: string[]) => {
  const init: Array<{
    title: string;
    image: string;
  }> = [];

  return categoryNameList.reduce((acc, cur) => {
    const images = chatCategoryImageList[cur];

    if (images) return [...acc, ...images];
    else return acc;
  }, init);
};

export const getMediaSoldOutImage = (
  nick?: string,
): { mild: string; sweet: string; hot: string } => {
  switch (nick) {
    case '겨울':
      return {
        mild: 'https://d2nn6pdgrbkdz2.cloudfront.net/dev/profile/m_2024_1_11fdaa2g70de817.png',
        sweet:
          'https://d2nn6pdgrbkdz2.cloudfront.net/dev/profile/m_2024_1_11a0g880faciaa.png',
        hot: 'https://d2nn6pdgrbkdz2.cloudfront.net/dev/profile/m_2024_1_1180914i00i206c.png',
      };

    case '서아':
      return {
        mild: 'https://d2nn6pdgrbkdz2.cloudfront.net/dev/profile/m_2024_1_107ecfb1a9eb0b.png',
        sweet:
          'https://d2nn6pdgrbkdz2.cloudfront.net/dev/profile/m_2024_1_102g37b2690f09e.png',
        hot: 'https://d2nn6pdgrbkdz2.cloudfront.net/dev/profile/m_2024_1_107jd226ej39j1e.png',
      };
    case '예진':
      return {
        mild: 'https://d2nn6pdgrbkdz2.cloudfront.net/prod/profile/m_2024_1_104gch675bb5492.png',
        sweet:
          'https://d2nn6pdgrbkdz2.cloudfront.net/prod/profile/m_2024_1_1079cgg7f9d83be.png',
        hot: 'https://d2nn6pdgrbkdz2.cloudfront.net/prod/profile/m_2024_1_10h46bdc5i878d.png',
      };
    case '여름':
      return {
        mild: 'https://d2nn6pdgrbkdz2.cloudfront.net/dev/profile/m_2024_1_10he1a1ejehb216.png',
        sweet:
          'https://d2nn6pdgrbkdz2.cloudfront.net/dev/profile/m_2024_1_1013a6aa1fc0be2b.png',
        hot: 'https://d2nn6pdgrbkdz2.cloudfront.net/dev/profile/m_2024_1_102b09hdja99a49.png',
      };
    case '아라':
      return {
        mild: 'https://d2nn6pdgrbkdz2.cloudfront.net/prod/profile/m_2024_1_105hgi059bbc92d.png',
        sweet:
          'https://d2nn6pdgrbkdz2.cloudfront.net/prod/profile/m_2024_1_10h35j34c91hj49.png',
        hot: 'https://d2nn6pdgrbkdz2.cloudfront.net/prod/profile/m_2024_1_10ai4jc10bca7a.png',
      };
    case '유키':
      return {
        mild: 'https://d2nn6pdgrbkdz2.cloudfront.net/prod/profile/m_2024_1_10523h4dc7b4gc2.png',
        sweet:
          'https://d2nn6pdgrbkdz2.cloudfront.net/prod/profile/m_2024_1_10ih2gg38cb04.png',
        hot: 'https://d2nn6pdgrbkdz2.cloudfront.net/prod/profile/m_2024_1_102hafd6314hbaa.png',
      };
    case '소연':
      return {
        mild: '	https://d2nn6pdgrbkdz2.cloudfront.net/dev/partnerMedia/m_2024_6_100j0h936fh7aidb.png',
        sweet:
          'https://d2nn6pdgrbkdz2.cloudfront.net/dev/partnerMedia/m_2024_6_1080i9fb81c69g3.png',
        hot: '	https://d2nn6pdgrbkdz2.cloudfront.net/dev/partnerMedia/m_2024_6_10hdgbfa0adh31.png',
      };
    default:
      return {
        mild: DefaultImageMild,
        sweet: DefaultImageSweet,
        hot: DefaultImageHot,
      };
  }
};
