import { strings } from '@/constants/strings';
import React from 'react';
import { type JellyItemProps } from './types';
import { cn } from '@/lib/utils';
// import Badge from '@/components/Badge';

export default function JellyItem(props: JellyItemProps) {
  const {
    title,
    imageSrc,
    price,
    discountPrice,
    isAnimate,
    selected,
    onClick,
  } = props;

  return (
    <article
      className={cn(
        'duration-300 fill-mode-forwards transition-[transform,opacity] flex gap-[12px] items-center w-full px-[11px] py-[17px] border border-Gray4 rounded-[12px] cursor-pointer pointerhover:hover:data-[selected=false]:border-Gray5 data-[selected=true]:border-Primary',
      )}
      onClick={onClick}
      data-selected={selected}
    >
      <figure
        className={cn(
          'w-[35px] h-[35px] flex-shrink-0',
          isAnimate ? 'animate-card-show' : '',
        )}
      >
        <img src={imageSrc} alt={title} />
      </figure>
      <div className="flex gap-[4px] items-center flex-1">
        <div className="flex items-center justify-between gap-[4px] flex-1">
          <div className="flex items-center justify-between">
            <h3 className={cn('text-H6', isAnimate ? 'animate-card-show' : '')}>
              {title}
            </h3>
          </div>
          <div className="flex items-center gap-[8px] flex-wrap self-end">
            {discountPrice && price && (
              <p
                className={cn(
                  'text-B9 line-through text-Gray6',
                  isAnimate ? 'animate-card-show' : '',
                )}
              >
                {Number(price).toLocaleString()}
                {strings?.common?.currency?.ko}
              </p>
            )}
            <p
              className={cn(
                'text-H6 text-Primary',
                isAnimate ? 'animate-card-show' : '',
              )}
            >
              {discountPrice && (+discountPrice).toLocaleString()}
              {!discountPrice && (+price)?.toLocaleString()}
              {strings?.common?.currency?.ko}
            </p>
          </div>
        </div>
      </div>
    </article>
  );
}
