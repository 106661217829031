import React from 'react';
import { Switch as SwitchBase } from '@/components/ui/switch';
import type * as SwitchPrimitives from '@radix-ui/react-switch';

interface SwitchProps
  extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {}

const Switch = ({ ...props }: SwitchProps) => {
  return <SwitchBase {...props} />;
};

export default Switch;
