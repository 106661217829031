import React from 'react';
import { type Partner } from '@/models/partners';
import MateContainer, {
  type MateContainerProps,
} from '@/pages/Home/components/MateContainer';
import { Avatar, Skeleton } from '@/components';
import { Link } from 'react-router-dom';
import { paths } from '@/constants/paths';

interface Props extends MateContainerProps {
  list: Partner[];
  isLoading?: boolean;
}

const MateAvatarList = ({
  list,
  subTitle,
  mainTitle,
  isLoading = false,
}: Props) => {
  return (
    <MateContainer subTitle={subTitle} mainTitle={mainTitle}>
      <div className="flex items-center justify-around">
        {list.map((mate) => {
          return (
            <Link
              key={mate.id}
              to={`${paths.celeb}/${mate.id}`}
              className="flex-shrink flex-col-center gap-[8px] pointerhover:hover:animate-wiggle pointerhover:hover:repeat-infinite"
            >
              <Skeleton
                className="w-[76px] h-[76px] rounded-full"
                isLoading={isLoading}
              >
                <Avatar
                  className="w-[76px] h-[76px]"
                  src={mate.profileMedia.smallFullUrl}
                />
              </Skeleton>
              <Skeleton isLoading={isLoading} className="h-[24px] w-[30px]">
                <p className="text-H7M">{mate.nick}</p>
              </Skeleton>
            </Link>
          );
        })}
      </div>
    </MateContainer>
  );
};

export default MateAvatarList;
