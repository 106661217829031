import { putCustomersMe } from '@/apis/customers';
import { getCustomerTerms } from '@/apis/terms';
import { IconRightArrow } from '@/assets/icons';
import { Switch } from '@/components';
import PrevHeader from '@/components/Header/PrevHeader';
import { paths } from '@/constants/paths';
import { externalLinks, queryKeys, strings } from '@/constants/strings';
import useCheckAuth from '@/hooks/useCheckAuth';
import { useAuthStore } from '@/zustands/useAuthStore';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

interface Props {
  prop?: string;
}

const TermsPage: React.FC<Props> = () => {
  const { auth } = useAuthStore();
  const queryClient = useQueryClient();
  useCheckAuth();
  // eslint-disable-next-line @typescript-eslint/promise-function-async
  const { data } = useQuery([queryKeys?.customersTerms], () =>
    getCustomerTerms(),
  );

  const marketingMutation = useMutation(putCustomersMe, {
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys?.me]);
    },
  });

  const handleMarketingSwitch = (value: boolean) => {
    marketingMutation.mutate(
      { isAllowMarketingUse: value },
      {
        onSuccess: () => {
          toast(
            value
              ? strings.terms.marketingAgree.ko
              : strings.terms.marketingDisagree.ko,
          );
        },
      },
    );
  };

  const navigate = useNavigate();

  const handleTermsClick = (id: number) => {
    navigate({ pathname: `${paths?.terms}/${id}` });
  };

  return (
    <>
      <PrevHeader
        title={strings?.terms?.title?.ko}
        onPrevClick={() => {
          navigate(-1);
        }}
      />

      <div className="pt-header">
        <div className="py-[12px] px-[20px] text-H7 text-Gray7">
          {strings?.terms?.requiredTerm?.ko}
        </div>
        <div className="min-h-[112px]">
          {data?.rows?.map((item) => {
            if (item?.type === 'REQUIRED') {
              return (
                <div
                  className="flex items-center justify-between py-[16px] px-[20px] cursor-pointer"
                  key={item?.id}
                  onClick={() => {
                    // TODO: 약관 퍼블리싱 완료시 삭제 예정 -----
                    if (item.title === '이용 약관') {
                      window.open(externalLinks.termsOfService);
                      return;
                    } else if (item.title === '개인정보처리방침') {
                      window.open(externalLinks.privacyPolicy);
                      return;
                    }
                    // -----------------------------------

                    handleTermsClick(item?.id);
                  }}
                >
                  <span className="text-H6M">{item?.title}</span>
                  <IconRightArrow width={24} height={24} stroke="white" />
                </div>
              );
            }
            return null;
          })}
        </div>
        <div className="w-full h-[12px] bg-Gray1 " />
        <div className="py-[12px] px-[20px] text-H7 text-Gray7">
          {strings?.terms?.optionalTerm?.ko}
        </div>
        {data?.rows?.map((item) => {
          if (item?.type === 'OPTIONAL') {
            return (
              <div
                className="flex items-center justify-between py-[16px] px-[20px] cursor-pointer"
                key={item?.id}
                onClick={() => {
                  handleTermsClick(item?.id);
                }}
              >
                <span className="text-H6M">{item?.title}</span>
                <div className="flex">
                  <span className="text-H6M text-Gray6">
                    {item?.isAgree
                      ? strings?.terms?.agreeWord?.ko
                      : strings?.terms?.disagreeWord?.ko}
                  </span>
                  <IconRightArrow width={24} height={24} stroke="white" />
                </div>
              </div>
            );
          }
          return null;
        })}
        <div className="flex flex-col gap-[6px] items-center justify-between py-[16px] px-[20px] bg-Gray1">
          <div className="flex justify-between items-center w-full">
            <span className="text-H6M">{strings?.terms.marketingTerm.ko}</span>
            <Switch
              checked={auth?.account?.isAllowMarketingUse}
              onCheckedChange={handleMarketingSwitch}
              disabled={marketingMutation?.isLoading}
            />
          </div>
          <p className="text-B8 text-Gray6">
            {strings?.terms.marketingInfo.ko}
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsPage;
