import { Button } from '@/components';
import React from 'react';

interface ChatNoticeCardProps {
  title: string;
  description: string;
  imageSrc?: string;
  buttonsElement?: React.ReactNode;
  subFooter?: React.ReactNode;
}

export default function ChatNoticeCard(props: ChatNoticeCardProps) {
  const { title, description, buttonsElement, subFooter, imageSrc } = props;

  return (
    <article className="flex flex-col items-center text-center gap-[20px] p-[24px] bg-Gray10 rounded-[24px] w-full">
      {imageSrc && (
        <figure className="w-[64px] h-[64px] rounded-full overflow-hidden">
          <img
            src={imageSrc}
            alt={title}
            className="w-full h-full object-cover"
            width={64}
            height={64}
          />
        </figure>
      )}
      <div className="flex flex-col gap-[16px]">
        <div>
          <h3 className="text-H6">{title}</h3>
          <p className="text-H8M">{description}</p>
        </div>
        <div>{buttonsElement}</div>
        <div>{subFooter}</div>
      </div>
    </article>
  );
}

export function demo() {
  return (
    <ChatNoticeCard
      imageSrc="/assets/images/img-sample-profile.png"
      title="Celine0_0님과 채팅을 이어하시겠어요?"
      description="젤리 40개를 사용하여 채팅을 이어할 수 있어요."
      buttonsElement={<Button size={40}>이어서 썸타기</Button>}
      subFooter={
        <p className="flex justify-center gap-[2px] text-H7M">
          <figure className="w-[24px] h-[24px]">
            <img
              src="/assets/images/img-jelly.png"
              alt="jelly"
              width={24}
              height={24}
            />
          </figure>
          사용 가능한 젤리 : 40개
        </p>
      }
    />
  );
}
