import React, { type PropsWithChildren, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RemainIndicator } from '@/components';
import { type ChatRoomStateType } from '@/models/chat';
import { IconClockPrimary, IconJelly, IconLeftArrow } from '@/assets/icons';
import useTimer from '@/hooks/useTimer';
import dayjs from 'dayjs';
import { timeFormatter } from '@/utils/strings';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@/constants/strings';
import { putChatRoomJoin } from '@/apis/chat';
import { cn } from '@/lib/utils';

interface HeaderProps extends PropsWithChildren {
  chatRoomId?: number;
  img: string;
  modalImageSrc?: string;
  nick: string;
  chatRoomState: ChatRoomStateType;
  onPrevClick?: () => void;
  remainingChatCount?: number;
  jellyCount?: number;
  voiceCount?: number;
  isLoading?: boolean;
  isFree?: boolean;
  expirationAt?: string;
  className?: string;
}

const ChatRoomHeader = ({
  chatRoomId,
  img = '',
  modalImageSrc,
  nick = 'Celine0_0',
  onPrevClick,
  remainingChatCount,
  jellyCount,
  isLoading,
  children,
  isFree,
  expirationAt,
  className,
}: HeaderProps) => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { mutate: joinChatRoom } = useMutation(putChatRoomJoin);

  const onExpire = useCallback(() => {
    if (chatRoomId) {
      // JOIN API 요청
      joinChatRoom(chatRoomId, {
        onSettled: () => {
          // 채팅방 정보 새로고침
          queryClient.invalidateQueries([
            queryKeys.getChatRoomsById,
            chatRoomId,
          ]);
        },
      });
    }
  }, [queryClient, joinChatRoom, chatRoomId]);

  const { isStart, time, reset } = useTimer(
    dayjs(expirationAt).diff(dayjs(), 's'),
    onExpire,
  );

  const prevClickHandler = useCallback(() => {
    if (onPrevClick) onPrevClick();
    else navigate(-1);
  }, [onPrevClick, navigate]);

  useEffect(() => {
    if (expirationAt && !isStart) {
      reset();
    }
  }, [expirationAt, isStart, reset]);

  return (
    <div className={cn('fixed z-header min-w-min max-w-max w-full', className)}>
      <div className="bg-Background flex w-full h-header text-H6 px-[20px] justify-between items-center">
        <div className="flex items-center gap-[10px] overflow-hidden">
          <IconLeftArrow
            onClick={prevClickHandler}
            className="cursor-pointer flex-shrink-0"
            stroke="white"
          />
          <span className="text-H4 text-White">{nick}</span>
        </div>
        {isFree && expirationAt ? (
          <RemainIndicator
            isLoading={isLoading}
            dataList={[
              {
                icon: <IconClockPrimary width={16} height={16} />,
                data: timeFormatter(time, true, true),
                key: 'remain-time',
              },
            ]}
          />
        ) : (
          <RemainIndicator
            isLoading={isLoading}
            dataList={[
              {
                icon: (
                  <span className="text-[11px] leading-[16px] mt-px">💬</span>
                ),
                data: remainingChatCount ?? 0,
                key: 'remain-chat-count',
              },
              {
                icon: <IconJelly width={16} height={16} />,
                data: jellyCount ?? 0,
                key: 'remain-jelly-count',
              },
            ]}
          />
        )}
      </div>
      {children}
    </div>
  );
};

export default ChatRoomHeader;
