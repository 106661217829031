import React from 'react';
import { IconJellyFit } from '@/assets/icons';
import { cn } from '@/lib/utils';

interface Props {
  className?: string;
  jelly: number;
}

const JellyLabel: React.FC<Props> = ({ className, jelly }) => {
  return (
    <div
      className={cn(
        'flex rounded-[8px] items-center text-White bg-Primary9 px-[8px] py-[6px] gap-[8px]',
        className,
      )}
    >
      <IconJellyFit className="flex-shrink-0" width={11} height={14} />
      <span className="text-H10M text-White">{jelly}</span>
    </div>
  );
};

export default JellyLabel;
