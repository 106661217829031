import React from 'react';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import Carousel from '../Carousel';
import { IconCloseCurrent } from '@/assets/icons';

export interface ImageModalProps {
  open?: boolean;
  onClose?: () => void;
  lists?: React.ReactNode[];
  initialSlide?: number;
  hasKeyboardEvent?: boolean;
}

const ImageModal = ({
  open = false,
  onClose,
  lists = [],
  initialSlide = 0,
  hasKeyboardEvent = false,
}: ImageModalProps) => {
  return (
    <Dialog open={open}>
      <DialogContent
        className="rounded-[0px] sm:rounded-[0px] p-0 w-full h-full border-none"
        onClose={onClose}
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <IconCloseCurrent
          stroke={'#FFFFFF'}
          className="absolute top-[20px] z-50 right-[20px] cursor-pointer"
          onClick={onClose}
        />
        <Carousel
          lists={lists}
          initialSlide={initialSlide}
          hasKeyboardEvent={hasKeyboardEvent}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ImageModal;
