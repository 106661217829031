import React, { type PropsWithChildren, type ReactNode } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';

interface Props extends PropsWithChildren {
  className?: string;
  renderTitle: () => ReactNode;
}

const ChatRoomTopNoticeAccordion: React.FC<Props> = ({
  className,
  renderTitle,
  children,
}) => {
  return (
    <Accordion className={className} type="single" collapsible>
      <AccordionItem
        className="border-b-Gray9 bg-Gray10 px-[20px]"
        value="item-1"
      >
        <AccordionTrigger className="space-x-[6px] py-[12px]">
          {renderTitle()}
        </AccordionTrigger>
        <AccordionContent>{children}</AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default ChatRoomTopNoticeAccordion;
