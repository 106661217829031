import React from 'react';
import { type ContentDto } from '@/models/contents';
import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { IconArrowRightMedium } from '@/assets/icons';
import { Skeleton } from '@/components/ui/skeleton';

interface HomeContentCardProps {
  title: string;
  description: string;
  iconSrc: string;
  url: string;
}

const HomeContentCardLoading = () => (
  <Skeleton className="flex-1 basis-[160px] aspect-square rounded-[12px]" />
);

const HomeContentCard = ({
  title,
  description,
  iconSrc,
  url,
}: HomeContentCardProps) => {
  return (
    <Link
      to={`/contents/${url}`}
      className="p-[16px] flex-1 basis-[160px] flex flex-col justify-between gap-[14px] aspect-square rounded-[12px] shadow-[0_4px_32px_0_rgba(58,63,68,0.10)] transition-transform pointerhover:hover:scale-105 bg-Gray11"
    >
      <div className="space-y-px">
        <div className="flex items-center gap-[2px] text-H6 text-White">
          {title}
          <IconArrowRightMedium width={16} height={16} />
        </div>
        <p className="text-H9M text-Gray6 whitespace-pre-line">{description}</p>
      </div>
      <div className="flex items-center justify-end">
        <img width={52} height={52} alt="content-image" src={iconSrc} />
      </div>
    </Link>
  );
};

const HomeContentComingSoonCard = () => {
  return (
    <div className="p-[16px] flex-1 basis-[160px] flex-col-center gap-[8px] aspect-square rounded-[12px] shadow-[0_4px_32px_0_rgba(58,63,68,0.10)] bg-Gray11">
      <p className="text-Gray4 text-H10M text-center">
        새로운 컨텐츠를
        <br />
        준비 중이에요
        <br />
        조금만 기다려주세요
      </p>
      <div className="rounded-[20px] flex-center bg-Gray9 py-[4px] px-[10px] text-Gray6 text-H11M">
        Coming Soon
      </div>
    </div>
  );
};

interface Props {
  className?: string;
  contentsList: ContentDto[];
  isLoading?: boolean;
}

const HomeContentsList = ({ className, contentsList, isLoading }: Props) => {
  return (
    <div className={cn('flex flex-wrap gap-[12px]', className)}>
      {isLoading ? (
        <>
          <HomeContentCardLoading />
          <HomeContentCardLoading />
          <HomeContentCardLoading />
          <HomeContentCardLoading />
        </>
      ) : (
        <>
          {contentsList.map(({ id, title, homeDescription, media, url }) => (
            <HomeContentCard
              key={`content-card-${id}`}
              title={title}
              description={homeDescription}
              iconSrc={media.bufferFullUrl ?? ''}
              url={url}
            />
          ))}
          {contentsList.length % 2 === 1 && <HomeContentComingSoonCard />}
        </>
      )}
    </div>
  );
};

export default HomeContentsList;
