import React, { type PropsWithChildren } from 'react';
import { Dialog, DialogPortal, DialogOverlay } from '@/components/ui/dialog';
import { cn } from '@/lib/utils';

import * as DialogPrimitive from '@radix-ui/react-dialog';

interface DialogContentProps
  extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> {
  onClose?: () => void;
}

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  DialogContentProps
>(({ className, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay onClose={props?.onClose} />
    <DialogPrimitive.Content
      onOpenAutoFocus={(e) => {
        e.preventDefault();
      }}
      ref={ref}
      className={cn(
        'dialog-content',
        'rounded-[20px] fixed pc:absolute max-w-max left-[50%] top-[50vh] z-50 grid w-[calc(100%-40px)] translate-x-[-50%] translate-y-[-50%] gap-[24px] border bg-background p-[20px] shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=open]:slide-in-from-left-1/2 sm:rounded-[20px]',
        className,
      )}
      {...props}
    >
      {children}
    </DialogPrimitive.Content>
  </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

export interface BottomModalProps extends PropsWithChildren {
  open?: boolean;
  onClose?: () => void;
}

const BottomModal: React.FC<BottomModalProps> = ({
  open,
  onClose,
  children,
}) => {
  return (
    <Dialog open={open}>
      <DialogContent
        onClose={onClose}
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
        className={cn(
          'fixed translate-y-0 top-auto bottom-[20px] left-1/2 -translate-x-1/2 min-w-[335px] max-w-[378px] bg-Background border-none p-[24px] pc:bottom-auto pc:absolute pc:left-1/2 pc:top-[calc(100vh-20px)] pc:-translate-y-full pc:-translate-x-1/2 focus:outline-0 focus-visible:outline-0',
          'data-[state=closed]:slide-out-to-bottom-[48%] data-[state=open]:slide-in-from-bottom-[48%] pc:data-[state=closed]:slide-out-to-top-[48%] pc:data-[state=open]:slide-in-from-top-[48%]',
        )}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default BottomModal;
