import React, { type ComponentProps } from 'react';
import { cn } from '@/lib/utils';
import PeachImage from '@/assets/images/peach.png';
import EggPlantImage from '@/assets/images/egg-plant.png';
// import MagicWandImage from '@/assets/images/magic-wand.png';

const TabButton = ({
  className,
  ...rest
}: ComponentProps<'button'> & { selected: boolean }) => {
  return (
    <button
      {...rest}
      className={cn(
        'rounded-full flex items-center justify-center gap-[4px] flex-1 h-[38px] border border-Gray4 text-Gray4 transition-colors',
        className,
      )}
    ></button>
  );
};

interface Props {
  className?: string;
  selectedTab: TabType;
  setSelectedTab: (tab: TabType) => void;
}

export type TabType = 'FEMALE' | 'MALE' | 'EVENT';

const tabList: Array<{
  key: TabType;
  imgSrc: string;
  imgAlt: string;
  text: string;
  selectedClassName: string;
}> = [
  {
    key: 'FEMALE',
    imgSrc: PeachImage,
    imgAlt: '여성 메이트',
    text: 'TIKI',
    selectedClassName: 'text-[#F4A985] border-[#F4A985]',
  },
  {
    key: 'MALE',
    imgSrc: EggPlantImage,
    imgAlt: '남성 메이트',
    text: 'TACA',
    selectedClassName: 'text-[#8B528B] border-[#8B528B]',
  },
  // {
  //   key: 'EVENT',
  //   imgSrc: MagicWandImage,
  //   imgAlt: '이벤트 메이트',
  //   text: 'Event',
  //   selectedClassName: 'text-[#FBCF63] border-[#FBCF63]',
  // },
];

const Tabs = ({ className, selectedTab, setSelectedTab }: Props) => {
  return (
    <div
      className={cn(
        'sticky -mx-[20px] px-[20px] top-header pc:top-0 z-header bg-Background py-[12px] flex w-100vw gap-[8px] items-center',
        className,
      )}
    >
      {tabList.map((tab) => {
        const isSelected = selectedTab === tab.key;

        return (
          <TabButton
            key={tab.key}
            selected={isSelected}
            onClick={() => {
              setSelectedTab(tab.key);
            }}
            className={cn(isSelected ? tab.selectedClassName : '')}
          >
            <img
              className={cn(isSelected ? '' : 'grayscale')}
              width={16}
              height={16}
              src={tab.imgSrc}
              alt={tab.imgAlt}
            />
            <span className={cn('text-H9')}>{tab.text}</span>
          </TabButton>
        );
      })}
    </div>
  );
};

export default Tabs;
