import { create } from 'zustand';

export interface PersistAuthKeys {
  audioInfo: { id: number };
}
export interface PersistAuthFunctions extends PersistAuthKeys {
  setAudioInfo: (audioInfo: { id: number }) => void;
}
export const useAudioStore = create<PersistAuthFunctions>((set, get) => ({
  audioInfo: { id: -1 },
  setAudioInfo: (audioInfo: { id: number }) => {
    set({ audioInfo });
  },
}));
