import React from 'react';
import { Skeleton as SkeletonBase } from '@/components/ui/skeleton';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SkeletonProps {}

// eslint-disable-next-line no-empty-pattern
const CardSkeleton = ({}: SkeletonProps) => {
  return <SkeletonBase className="w-[78px] h-[20px] rounded-full" />;
};

export default CardSkeleton;
