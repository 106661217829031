import type {
  CommonSignUpDto,
  EmailSignUpDto,
  SocialSignUpDto,
} from '@/models/customers';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface DataType extends CommonSignUpDto {
  checkedTerms: Array<'indeterminate' | boolean>;
  body: {
    oauth?: Omit<SocialSignUpDto, keyof CommonSignUpDto>;
    email?: Omit<EmailSignUpDto, keyof CommonSignUpDto>;
  };
}

interface SignUpStoreProps {
  data: DataType;
  setSignUpData: (props: Partial<DataType>) => void;
  resetData: () => void;
}

const initialState: DataType = {
  body: {
    oauth: undefined,
    email: undefined,
  },
  nick: '',
  isAllowMarketingUse: false,
  termsIds: [],
  tokenVersionId: '',
  checkedTerms: [],
};

export const useSignUpStore = create<SignUpStoreProps>()(
  persist(
    (set, get) => ({
      data: initialState,
      setSignUpData: (props) => {
        const prevData = get().data;
        set(() => ({ data: { ...prevData, ...props } }));
      },
      resetData: () => {
        set(() => ({
          data: initialState,
        }));
      },
    }),
    {
      name: 'signup', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    },
  ),
);
export default useSignUpStore;
