import { useCallback, useState } from 'react';

interface useModalConfigOptions {
  onOpen?: () => void;
  onClose?: () => void;
}

const useModalConfig = (options?: useModalConfigOptions) => {
  const [open, setOpen] = useState(false);
  const onOpen = options?.onOpen;
  const onClose = options?.onClose;

  const handleOpenModal = useCallback(() => {
    if (onOpen) onOpen();
    setOpen(true);
  }, [onOpen]);

  const handleCloseModal = useCallback(() => {
    if (onClose) onClose();
    setOpen(false);
  }, [onClose]);

  return {
    open,
    handleOpenModal,
    handleCloseModal,
  };
};
export default useModalConfig;
