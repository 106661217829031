import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

const customTwMerge = extendTailwindMerge({
  classGroups: {
    'font-size': [
      'text-D1',
      'text-D2',
      'text-D3',
      'text-D4',
      'text-D5',
      'text-D6',
      'text-H1',
      'text-H1M',
      'text-H2',
      'text-H2M',
      'text-H3',
      'text-H3M',
      'text-H4',
      'text-H4M',
      'text-H5',
      'text-H5M',
      'text-H6',
      'text-H6M',
      'text-H7',
      'text-H7M',
      'text-H8',
      'text-H8M',
      'text-H9',
      'text-H9M',
      'text-H10',
      'text-H10M',
      'text-H11',
      'text-H11M',
      'text-B1',
      'text-B2',
      'text-B3',
      'text-B4',
      'text-B5',
      'text-B6',
      'text-B7',
      'text-B8',
      'text-B9',
      'text-B10',
      'text-B11',
      'text-BTN1',
      'text-BTN2',
      'text-BTN3',
      'text-BTN4',
      'text-BTN5',
      'text-L1',
      'text-L2',
      'text-L3',
    ],
    'font-color': [
      'text-dim',
      'text-Transparent',
      'text-Current',
      'text-White',
      'text-Primary',
      'text-Primary1',
      'text-Primary2',
      'text-Primary3',
      'text-Primary4',
      'text-Primary5',
      'text-Primary6',
      'text-Primary7',
      'text-Primary8',
      'text-Primary9',
      'text-Primary10',
      'text-Gray1',
      'text-White',
      'text-Gray3',
      'text-Gray4',
      'text-Gray5',
      'text-Gray6',
      'text-Gray7',
      'text-Gray8',
      'text-Gray9',
      'text-Gray10',
      'text-Gray11',
      'text-Gray12',
      'text-Red1',
      'text-Red2',
      'text-Red3',
      'text-Red4',
      'text-Red5',
      'text-Red6',
      'text-Yellow1',
      'text-Yellow2',
      'text-Yellow3',
      'text-Yellow4',
      'text-Yellow5',
      'text-Yellow6',
      'text-Green1',
      'text-Green2',
      'text-Green3',
      'text-Green4',
      'text-Green5',
      'text-Green6',
    ],
  },
});

export function cn(...inputs: ClassValue[]) {
  return customTwMerge(clsx(inputs));
}
