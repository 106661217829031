import { IconTooltipArrow, IconTooltipArrowSmall } from '@/assets/icons';
import colors from '@/constants/colors';
import { cn } from '@/lib/utils';
import React from 'react';

interface TooltipProps {
  children?: React.ReactNode;
  arrowDirection?: 'left' | 'bottom' | 'right' | 'top';
  className?: string;
  tooltipArrowClassName?: string;
  tooltipArrowSize?: 'sm' | 'md';

  onAnimationEnd?: () => void;
}

const Tooltip = ({
  children,
  arrowDirection = 'bottom',
  className,
  tooltipArrowClassName,
  tooltipArrowSize = 'sm',
  onAnimationEnd,
}: TooltipProps) => {
  const Icon =
    tooltipArrowSize === 'sm' ? IconTooltipArrowSmall : IconTooltipArrow;

  return (
    <div
      className={cn(
        'absolute text-H11M text-White bg-Red1 py-[4px] px-[6px] rounded-[4px]',
        className,
      )}
      onAnimationEnd={onAnimationEnd}
    >
      {arrowDirection === 'bottom' && (
        <Icon
          fill={colors.Red1}
          className={cn(
            'absolute left-[50%] translate-x-[-50%]',
            tooltipArrowSize === 'sm' ? '-bottom-[4px]' : '-bottom-[8px]',
            tooltipArrowClassName,
          )}
        />
      )}
      {arrowDirection === 'left' && (
        <Icon
          fill={colors.Red1}
          className={cn(
            'absolute bottom-[50%] left-[-6px] translate-y-[50%] rotate-90',
            tooltipArrowSize === 'sm' ? '' : '',
            tooltipArrowClassName,
          )}
        />
      )}
      {arrowDirection === 'right' && (
        <Icon
          fill={colors.Red1}
          className={cn(
            'absolute bottom-[50%] right-[-6px] translate-y-[50%] rotate-[-90deg]',
            tooltipArrowSize === 'sm' ? '' : '',
            tooltipArrowClassName,
          )}
        />
      )}
      {arrowDirection === 'top' && (
        <Icon
          fill={colors.Red1}
          className={cn(
            'absolute left-[50%] translate-x-[-50%] rotate-180',
            tooltipArrowSize === 'sm' ? '-bottom-[4px]' : '-top-[8px]',
            tooltipArrowClassName,
          )}
        />
      )}
      {children}
    </div>
  );
};

export default Tooltip;
