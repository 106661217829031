import * as React from 'react';

import { cn } from '@/lib/utils';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onPressEnter?: () => void;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, onPressEnter, ...props }, ref) => {
    return (
      <input
        autoComplete="off"
        type={type}
        className={cn(
          'flex w-full rounded-[10px] text-White border border-input bg-Transparent text-B5 ring-offset-background placeholder:text-B5 disabled:cursor-not-allowed disabled:opacity-50 autofill:!shadow-[0_0_0px_1000px_transparent_inset] autofill:![-webkit-text-fill-color:#D5D8DB]',
          className,
        )}
        ref={ref}
        {...props}
        onKeyDown={(e) => {
          if (props.onKeyDown) props.onKeyDown(e);
          if (e.key === 'Enter')
            if (onPressEnter) {
              if (e.nativeEvent.isComposing) {
                return;
              }
              onPressEnter();
            }
        }}
      />
    );
  },
);
Input.displayName = 'Input';

export { Input };
