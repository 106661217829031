import { getCustomerMeCreditHistories } from '@/apis/customers';
import { Button, Tabs } from '@/components';
import PrevHeader from '@/components/Header/PrevHeader';
import { paths } from '@/constants/paths';
import { queryKeys, storageKey, strings } from '@/constants/strings';
import type { CreditHistoryDto } from '@/models/customers';
import { useAuthStore } from '@/zustands/useAuthStore';
import { useInfiniteQuery } from '@tanstack/react-query';
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import ListPlaceholder from '@/components/ListPlaceholder';
// import {
//   IconJelly120,
//   IconJelly20,
//   IconJelly40,
//   IconJelly80,
// } from '@/assets/jelly';
import { IconJellyFit, IconMyPageCharge } from '@/assets/icons';
import colors from '@/constants/colors';
interface Props {
  prop?: string;
}

const PAGE_SIZE = 20;

const ProfileJellyPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLogin } = useAuthStore();
  const { auth } = useAuthStore();
  const pageBottomRef = useRef(null);
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const { data, isError, fetchNextPage, isFetching } = useInfiniteQuery({
    queryKey: [queryKeys.customersMeCreditHistories, tab],
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    queryFn: async ({ pageParam = 1 }) => {
      const { rows, count } = await getCustomerMeCreditHistories({
        isValuePositive: tab !== 'used',
        page: pageParam,
        pageSize: PAGE_SIZE,
      });

      const isLast = rows.length < PAGE_SIZE;

      return {
        rows,
        count,
        isLast,
        nextPage: isLast ? pageParam : pageParam + 1,
      };
    },
    getNextPageParam: (lastPage) => {
      return lastPage.isLast ? undefined : lastPage.nextPage;
    },
    enabled: !!tab,
  });
  useEffect(() => {
    if (!isLogin) {
      navigate(paths?.home);
    }
  }, [navigate, isLogin]);

  useEffect(() => {
    if (!tab) {
      navigate(
        { pathname: paths?.profileJelly, search: 'tab=used' },
        { replace: true },
      );
    }
  }, [tab, navigate]);

  useEffect(() => {
    let observer: IntersectionObserver;
    if (pageBottomRef.current) {
      observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isFetching && !isError) {
            fetchNextPage();
          }
        });
      });

      observer.observe(pageBottomRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageBottomRef.current, isFetching]);

  const handleTabOnChange = (value: string) => {
    navigate(
      { pathname: paths?.profileJelly, search: `tab=${value}` },
      { replace: true },
    );
  };

  const renderList = (item: CreditHistoryDto, tab: 'used' | 'purchase') => {
    return (
      <div
        className="flex py-[12px] items-center justify-between h-full"
        key={item?.id}
      >
        <div className="flex gap-[12px] items-center">
          {/* {tab === 'purchase' && (
            <>
              {item.creditHistoryTemplate && (
                <div className="w-[40px] h-[40px]">
                  <img
                    alt=""
                    src={item.creditHistoryTemplate.media?.bufferFullUrl}
                    width={40}
                    height={40}
                  />
                </div>
              )}

              {!item.creditHistoryTemplate && Math.abs(item?.value) === 10 && (
                <IconJelly20 />
              )}
              {!item.creditHistoryTemplate && Math.abs(item?.value) === 20 && (
                <IconJelly40 />
              )}
              {!item.creditHistoryTemplate && Math.abs(item?.value) === 40 && (
                <IconJelly80 />
              )}
              {!item.creditHistoryTemplate && Math.abs(item?.value) === 80 && (
                <IconJelly120 />
              )}
            </>
          )} */}
          {/* {tab === 'used' && (
            <div className="w-[40px] h-[40px]">
              {item.creditHistoryTemplate ? (
                <img
                  alt=""
                  src={item.creditHistoryTemplate.media?.bufferFullUrl}
                  width={40}
                  height={40}
                />
              ) : (
                <IconJelly20 />
              )}
            </div>
          )} */}
          <div className="flex flex-col gap-[4px]">
            {tab === 'used' && (
              <span className="text-H9M text-Gray6">{item.subDescription}</span>
            )}
            <span className="text-H5 text-White">{item?.description}</span>
            <span className="text-H9M text-Gray4">
              {dayjs(item?.createdAt)?.format('YYYY.MM.DD HH:mm')}
            </span>
          </div>
        </div>
        {tab === 'purchase' && (
          <div className="flex flex-col gap-[4px] items-end">
            <span className="text-H5M text-White">
              {item.value}
              {strings.common.unit.ko}
            </span>
            <span className="text-H9M text-Gray4">
              {item?.type !== 'ATTENDANCE' &&
                (item?.type === 'EVENT_CHARGE' ||
                item?.type === 'COUPON' ||
                item?.type === 'EXTINGUISHED'
                  ? `소멸일 : ${dayjs(item?.expirationAt).format('YYYY.MM.DD')}`
                  : Number(
                      item?.payload.discountPrice ||
                        item?.payload.originalPrice,
                    ).toLocaleString() + strings.common.currency.ko)}
            </span>
          </div>
        )}
        {tab === 'used' && (
          <span className="text-H5M text-Primary">
            {item.value + strings.common.jelly.ko}
          </span>
        )}
      </div>
    );
  };

  const handlePurchaseClick = () => {
    sessionStorage.setItem(storageKey.session.redirectUrl, location.pathname);

    navigate({ pathname: paths?.payments });
  };

  return (
    <div className="flex flex-col h-full">
      <PrevHeader title="&nbsp;" />
      <div className="pt-header h-full overflow-auto hide-scrollbar">
        <div className="pt-[12px] px-[20px] flex flex-col gap-[24px] h-full ">
          <div className="space-y-[14px]">
            <h4 className="text-H4 text-White">{strings.jelly.title.ko}</h4>
            <div className="p-[20px] bg-Gray4 w-full rounded-[14px] flex items-center justify-between">
              <div className="space-y-[4px]">
                <h5 className="text-H9M text-White">
                  {strings.jelly.myJelly.ko}
                </h5>
                <h4 className="text-H4 text-Primary flex items-center gap-[8px]">
                  <IconJellyFit
                    className="flex-shrink-0"
                    width={11}
                    height={14}
                  />
                  {auth?.creditCount} {strings.common.jelly.ko}
                </h4>
              </div>
              <Button
                size={32}
                className="flex-center gap-[4px] bg-Primary"
                onClick={handlePurchaseClick}
              >
                <IconMyPageCharge fill={colors.Gray2} width={16} height={16} />
                {strings.jelly.jellyCharge.ko}
              </Button>
            </div>
          </div>
          <Tabs
            value={tab as string}
            items={[
              {
                title: strings.myPage.useHistory.ko,
                content:
                  !isError &&
                  data?.pages?.map((item) => {
                    return item?.rows?.map((item) => renderList(item, 'used'));
                  }),
                value: 'used',
              },
              {
                title: strings.myPage.payHistory.ko,
                content:
                  !isError &&
                  data?.pages?.map((item) => {
                    return item?.rows?.map((item) =>
                      renderList(item, 'purchase'),
                    );
                  }),
                value: 'purchase',
              },
            ]}
            onValueChange={(value) => {
              handleTabOnChange(value);
            }}
          />
          <div
            ref={pageBottomRef}
            style={{ width: '100%', minHeight: 10 }}
          ></div>
          {isError && (
            <ListPlaceholder
              descriptionClassName="text-H7M text-Gray6"
              description={`${
                tab === 'used' ? '사용내역' : '충전내역'
              }이 없어요`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileJellyPage;
