import React from 'react';
import { FooterButton } from '@/components';
import PrevHeader from '@/components/Header/PrevHeader';
import { strings } from '@/constants/strings';
import useSignInModal from '@/zustands/useSignInModal';

const NoAccount = () => {
  const { showSignInModal } = useSignInModal();

  return (
    <>
      <PrevHeader title={strings.resetPassword.pageTitle.ko} />
      <div className="mt-header px-[20px] py-[32px] space-y-[8px]">
        <h3 className="text-H3 text-White whitespace-pre-line">
          {strings.resetPassword.noRegisteredAccount.ko}
        </h3>
        <p className="whitespace-pre-line text-H7M text-Gray8">
          {strings.resetPassword.goRegisterDirectly.ko}
        </p>
      </div>
      <FooterButton
        onClick={() => {
          showSignInModal();
        }}
      >
        {strings.signIn.goSignUp.ko}
      </FooterButton>
    </>
  );
};

export default NoAccount;
