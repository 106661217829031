import {
  SocialKakaoTalk,
  SocialEmail,
  // SocialGoogle,
  // SocialInstaDefault,
  //   SocialTikTalkDefault,
} from '@/assets/socials';

export const providers = [
  { key: 'kakao', icon: SocialKakaoTalk, label: '카카오' },
  // { key: 'google', icon: SocialGoogle, label: '구글' },
  { key: 'email', icon: SocialEmail, label: '이메일' },
  // { key: 'instagram', icon: SocialInstaDefault, label: '인스타그램' },
  //   {
  //     key: 'tiktalk',
  //     icon: SocialTikTalkDefault,
  //     label: '틱톡',
  //   },
] as const;
