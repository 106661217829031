import { deleteCustomersMe } from '@/apis/customers';
import { Button, Checkbox } from '@/components';
import PrevHeader from '@/components/Header/PrevHeader';
import { paths } from '@/constants/paths';
import { getAlertTextByError, strings } from '@/constants/strings';
import { useAuthStore } from '@/zustands/useAuthStore';
import useGlobalModal from '@/zustands/useGlobalModal';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface Props {
  prop?: string;
}

const SettingWithdrawalPage: React.FC<Props> = () => {
  const { isLogin, auth, signOut } = useAuthStore();
  const navigate = useNavigate();
  const { showGlobalModal, hideGlobalModal, showErrorAlert } = useGlobalModal();

  const [isChecked, setIsChecked] = useState(false);

  const { mutateAsync } = useMutation(deleteCustomersMe);

  const onClickWithdrawalInModal = useCallback(() => {
    mutateAsync()
      .then(() => {
        hideGlobalModal();
        signOut();
        navigate(paths.home, { replace: true });
        toast(strings.withdrawal.success.ko);
      })
      .catch((err) => {
        const { title, description } = getAlertTextByError(err);
        showErrorAlert(title, description);
      });
  }, [mutateAsync, showErrorAlert, signOut, navigate, hideGlobalModal]);

  useEffect(() => {
    if (!isLogin) {
      navigate(paths.home, { replace: true });
    }
  }, [navigate, isLogin]);

  return (
    <div className="flex flex-col">
      <PrevHeader title={strings.withdrawal.title.ko} />
      <div className="pt-header pb-[140px]">
        <div className="px-[20px] pb-[20px] pt-[12px] flex flex-col gap-[36px]">
          <div className="space-y-[16px]">
            <h6 className="text-White text-H6">
              {strings.withdrawal.hasComplaint.ko}
            </h6>
            <p className="flex items-center gap-[4px] text-Gray7 text-H9M">
              {strings.withdrawal.personalInformationNoticeTitle.ko}
            </p>
            <ol className="list-decimal pl-[1.4em] text-Gray7 text-H9M space-y-[16px]">
              {strings.withdrawal.personalInformationNoticeList.map(
                (notice) => (
                  <li className="break-keep" key={notice.ko}>
                    {notice.ko}
                  </li>
                ),
              )}
            </ol>
          </div>
          {auth && auth.creditCount > 0 && (
            <div className="space-y-[16px]">
              <h6 className="text-White text-H6">
                {strings.withdrawal.checkRefundableJelly.ko}
              </h6>
              <div className="py-[18px] px-[20px] bg-Gray1 rounded-[12px] space-y-[16px]">
                <p className="flex justify-between text-H9M text-White">
                  <span>{strings.withdrawal.currentJelly.ko}</span>
                  <span className="text-[#D1061B]">
                    {auth?.creditCount}
                    {strings.common.jelly.ko}
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="fixed bottom-0 space-y-[20px] w-full max-w-max min-w-min z-header p-[20px] bg-Gray1">
          <div className="flex items-center gap-[10px]">
            <Checkbox
              id="withdrawal-check"
              shape="circle"
              checked={isChecked}
              onCheckedChange={() => {
                setIsChecked((prev) => !prev);
              }}
            />
            <label
              htmlFor="withdrawal-check"
              className="text-H6M text-White cursor-pointer"
            >
              {strings.withdrawal.isCheckAll.ko}
            </label>
          </div>
          <Button
            disabled={!isChecked}
            className="w-full"
            size={56}
            onClick={() => {
              showGlobalModal({
                title: strings.withdrawal.doubleCheck.ko,
                description: strings.withdrawal.doubleCheckDescription.ko,
                primaryButtonText: strings.withdrawal.title.ko,
                onPrimaryClick: onClickWithdrawalInModal,
                cancelButtonText: strings.common.cancel.ko,
                onCancelClick: hideGlobalModal,
              });
            }}
          >
            {strings?.withdrawal?.title?.ko}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SettingWithdrawalPage;
