import React, { type PropsWithChildren } from 'react';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { IconCloseCurrent } from '@/assets/icons';
import { cn } from '@/lib/utils';
import colors from '@/constants/colors';

export interface HomeBannerProps extends PropsWithChildren {
  open: boolean;
  dialogContentClassName?: string;
  onClose: () => void;
  disableCloseButton?: boolean;
}

const HomeBannerModal: React.FC<HomeBannerProps> = React.memo(
  ({
    open,
    dialogContentClassName,
    onClose,
    disableCloseButton = false,
    children,
  }) => {
    return (
      <Dialog open={open}>
        <DialogContent
          onClose={onClose}
          className={cn(
            'bg-Gray1 border border-Gray8 border-opacity-30',
            dialogContentClassName,
          )}
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
        >
          {!disableCloseButton && (
            <button
              className="absolute right-[20px] top-[20px] z-10"
              onClick={onClose}
            >
              <IconCloseCurrent stroke={colors.White} width={24} height={24} />
            </button>
          )}
          <div
            className={cn(
              'space-y-[24px]',
              !disableCloseButton ? 'mt-[32px]' : '',
            )}
          >
            {children}
          </div>
        </DialogContent>
      </Dialog>
    );
  },
);

HomeBannerModal.displayName = 'HomeBannerModal';

export default HomeBannerModal;
