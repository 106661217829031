import { Avatar } from '@/components';
import { Skeleton } from '@/components/ui/skeleton';
import { formatLastChatTime } from '@/utils/strings';
import React, { memo } from 'react';

export interface ChatRoomListItemProps {
  img?: string;
  name?: string;
  lastMessage?: string;
  lastMessageSentAt: string;
  isRead?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  onImageClick?: () => void;
}

const ChatRoomListItem = memo(
  ({
    img = '/assets/card-profile.png',
    name = 'Celine0_0',
    lastMessage = '나랑 얘기하다 어디간거얏?',
    lastMessageSentAt,
    isRead = false,
    isLoading = false,
    onClick,
    onImageClick,
  }: ChatRoomListItemProps) => {
    return (
      <div
        className="flex items-center gap-[12px] py-[12px] justify-between cursor-pointer w-full"
        onClick={onClick}
      >
        <div className="flex items-center gap-[12px] overflow-hidden w-full">
          {isLoading ? (
            <Skeleton className="w-[52px] h-[52px] rounded-full flex-shrink-0" />
          ) : (
            <div
              className="w-[52px] h-[52px] flex-shrink-0 overflow-hidden rounded-full flex items-start justify-center cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                if (onImageClick) onImageClick();
              }}
            >
              <Avatar src={img} />
            </div>
          )}

          <div className="flex flex-col overflow-hidden flex-1 gap-[6px]">
            {isLoading ? (
              <>
                <Skeleton className="w-1/2 h-[20px] my-[2px]" />
                <Skeleton className="w-2/3 h-[14px] my-[2px]" />
              </>
            ) : (
              <>
                <div className="flex items-center justify-between">
                  <p className="text-H7 text-White flex items-center gap-[4px]">
                    {name}
                    {!isRead && (
                      <div className="flex-shrink-0 w-[4px] h-[4px] rounded-full bg-Red1"></div>
                    )}
                  </p>
                  <p className="text-B10 text-Gray5 self-end">
                    {formatLastChatTime(lastMessageSentAt)}
                  </p>
                </div>
                <p className="text-B9 break-keep text-White [display:-webkit-box] [-webkit-box-orient:vertical] [-webkit-line-clamp:2]">
                  {lastMessage}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    );
  },
);
ChatRoomListItem.displayName = 'ChatRoomListItem';

export default ChatRoomListItem;
