import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconLeftArrow, IconNavHome } from '@/assets/icons';
import { paths } from '@/constants/paths';
import { cn } from '@/lib/utils';

interface HeaderProps {
  title?: React.ReactNode;
  onPrevClick?: () => void;
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  onIconClick?: () => void;
  withHomeIcon?: boolean;
  className?: string;
}

const PrevHeader = ({
  title = '약관 동의',
  onPrevClick,
  Icon,
  onIconClick,
  withHomeIcon,
  className,
}: HeaderProps) => {
  const navigate = useNavigate();

  const onPrevClickWrapper = useCallback(() => {
    if (onPrevClick) {
      onPrevClick();
    } else {
      navigate(-1);
    }
  }, [onPrevClick, navigate]);

  const handleHomeIconClick = useCallback(() => {
    navigate(paths.home);
  }, [navigate]);

  return (
    <div
      className={cn(
        'fixed bg-Background flex min-w-min max-w-max w-full h-header z-header text-H7M px-[20px] justify-center items-center',
        className,
      )}
    >
      <IconLeftArrow
        width={24}
        height={24}
        onClick={onPrevClickWrapper}
        stroke="white"
        className="absolute left-[20px] cursor-pointer"
      />
      {withHomeIcon && (
        <IconNavHome
          className="absolute cursor-pointer left-[62px]"
          width={28}
          height={28}
          onClick={handleHomeIconClick}
          fill="white"
        />
      )}
      <h1 className="text-H6M text-White">{title}</h1>
      {Icon && (
        <Icon
          onClick={onIconClick}
          className="absolute right-[20px] cursor-pointer"
        />
      )}
    </div>
  );
};

export default PrevHeader;
