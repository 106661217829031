import React from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { strings } from '@/constants/strings';

const DateNoticeAccordion: React.FC = () => {
  return (
    <Accordion type="single" collapsible>
      <AccordionItem className="border-b-0" value="item-1">
        <AccordionTrigger className="text-H9M text-White">
          데이트 유의사항
        </AccordionTrigger>
        <AccordionContent className="-mx-[20px]">
          <div className="bg-Gray10 space-y-[10px] py-[12px]">
            <div className="space-y-[10px] px-[20px]">
              <p className="text-H11M text-White">
                {strings.mateDetail.serviceNotice.title.ko}
              </p>
              <ul className="list-disc text-B11 text-Gray5 pl-[1.5em]">
                {strings.mateDetail.serviceNotice.list.map((notice) => (
                  <li key={notice.ko}>{notice.ko}</li>
                ))}
                <li
                  className="text-Primary mt-[10px] underline underline-offset-2 cursor-pointer"
                  onClick={() => {
                    window.open(
                      `https://slogup.notion.site/d26d726bfa8f4ae88d1f6231edbe5f6c`,
                    );
                  }}
                >
                  {strings.mateDetail.serviceNotice.notionLinkText.ko}
                </li>
              </ul>
            </div>
            <div className="space-y-[10px] px-[20px]">
              <p className="text-H11M text-White">
                {strings.mateDetail.caution.title.ko}
              </p>
              <ul className="list-disc text-B11 text-Gray5 pl-[1.5em]">
                {strings.mateDetail.caution.list.map((notice) => (
                  <li key={notice.ko}>{notice.ko}</li>
                ))}
              </ul>
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default DateNoticeAccordion;
