import React, { useEffect, useRef, useState } from 'react';
import { getCustomersMeChatLogsById, postChatLogsByIdVoice } from '@/apis/chat';
import {
  IconChatLoading,
  IconMicBg,
  IconMicBgDisabled,
  IconPaused,
  IconPlay,
} from '@/assets/icons';
import { queryKeys } from '@/constants/strings';
import {
  useMutation,
  useQuery,
  // useQueryClient
} from '@tanstack/react-query';
import { useAudioStore } from '@/zustands/useAudioStore';
import { gtmNames, gtmPush } from '@/external/ga';

interface Props {
  mateId?: number;
  messageId?: number;
  disabled: boolean;
  audioSrc?: string;
}

interface AudioConfig {
  isPlay: boolean;
  src?: string;
}

const VoiceButton = ({ messageId, disabled, audioSrc, mateId }: Props) => {
  // const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(false);
  const [audio, setAudio] = useState<AudioConfig>({
    isPlay: false,
    src: audioSrc,
  });

  const audioRef = useRef<HTMLAudioElement>(null);
  const { setAudioInfo, audioInfo } = useAudioStore();

  const { refetch } = useQuery(
    [queryKeys.getCustomersMeChatLogsById],
    async () => await getCustomersMeChatLogsById(messageId ?? -1),
    { enabled: false },
  );

  const handleRefetch = () => {
    refetch().then((data) => {
      if (!data?.data?.row.voiceMedia) {
        setTimeout(() => {
          handleRefetch();
        }, 1000);
        return;
      }
      if (data?.data?.row.voiceMedia.bufferFullUrl) {
        // queryClient.invalidateQueries([queryKeys.getChatRoomsById, chatRoomId]);
        setIsLoading(false);
        setAudioInfo({ id: messageId ?? -2 });
        setAudio({
          isPlay: true,
          src: data?.data?.row.voiceMedia.bufferFullUrl,
        });
      }
    });
  };
  const voiceMutation = useMutation(postChatLogsByIdVoice);

  const onClickPause = () => {
    setAudio((prev) => ({
      ...prev,
      isPlay: false,
    }));
  };

  const onClickMic = () => {
    setAudioInfo({ id: messageId ?? -2 });
    if (audio.src) {
      // 오디오가 이미 있는 경우, voice 클릭 이벤트
      if (mateId) {
        gtmPush(gtmNames.event.CHATROOM_voiceClick, {
          [gtmNames.parameter.mateId]: mateId,
          [gtmNames.parameter.isReplay]: 'replay',
        });
      }

      setAudio((prev) => ({
        ...prev,
        isPlay: true,
      }));
    } else {
      // audio.src가 없고 비활성화 아닌 경우에만 실행
      if (!disabled) {
        // 자동 재생 이슈로 먼저 재생 처리하고 음성 데이터 load
        setAudio((prev) => ({
          ...prev,
          isPlay: true,
        }));

        // 오디오 없는 경우, voice 클릭 이벤트
        if (mateId) {
          gtmPush(gtmNames.event.CHATROOM_voiceClick, {
            [gtmNames.parameter.mateId]: mateId,
            [gtmNames.parameter.isReplay]: 'first play',
          });
        }

        setIsLoading(true);
        voiceMutation.mutate(messageId ?? -1, {
          onSuccess: () => {
            handleRefetch();
          },
          onError: () => {
            setIsLoading(false);
          },
        });
      }
    }
  };

  useEffect(() => {
    if (audioRef && audio.src) {
      const target = audioRef.current;
      if (audio.isPlay) {
        target?.play();
      } else {
        target?.pause();
      }
    }
  }, [audioRef, audio]);

  useEffect(() => {
    if (audioInfo.id > 0 && messageId !== audioInfo.id) {
      if (audioRef.current) {
        audioRef.current?.pause();
        audioRef.current.currentTime = 0;
        setAudio((prev) => ({ ...prev, isPlay: false }));
      }
    }
  }, [audioInfo, messageId]);

  const renderIcon = () => {
    if (disabled) {
      // 보이스 카운트가 없고
      if (!audio.src) {
        // src가 없을때
        return (
          <IconMicBgDisabled
            width={32}
            height={32}
            className="cursor-not-allowed"
          />
        );
      } else {
        return audio.isPlay ? (
          <IconPaused
            width={32}
            height={32}
            className="cursor-pointer"
            onClick={onClickPause}
          />
        ) : (
          <IconPlay
            width={32}
            height={32}
            className="cursor-pointer"
            onClick={onClickMic}
          />
        );
      }
    } else {
      // 보이스 카운트가 있고
      if (isLoading) {
        // 로딩중일때,
        return <IconChatLoading className="animate-spin" />;
      } else {
        if (audio.isPlay) {
          return (
            <IconPaused
              width={32}
              height={32}
              className="cursor-pointer"
              onClick={onClickPause}
            />
          );
        } else {
          return audio.src ? (
            <IconPlay
              width={32}
              height={32}
              className="cursor-pointer"
              onClick={onClickMic}
            />
          ) : (
            <IconMicBg
              width={32}
              height={32}
              className="cursor-pointer"
              onClick={onClickMic}
            />
          );
        }
      }
    }
  };

  return (
    <div className="flex-shrink-0">
      {renderIcon()}
      <audio
        ref={audioRef}
        className="hidden"
        src={audio.src ?? ''}
        onEnded={() => {
          setAudio((prev) => ({
            ...prev,
            isPlay: false,
          }));
        }}
      />
    </div>
  );
};

export default VoiceButton;
