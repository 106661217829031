import React, { useState, type ReactNode } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import { Autoplay } from 'swiper';
import { cn } from '@/lib/utils';
import { Skeleton } from '@/components';

export interface HomeBannerObject {
  child: string | ReactNode;
  onClick?: () => void;
}

interface Props {
  className?: string;
  list: HomeBannerObject[];
  isLoading?: boolean;
}

const HomeBannerCarousel = ({ className, list, isLoading }: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={className}>
      <Skeleton
        className="w-full aspect-[335/86] rounded-[20px]"
        isLoading={isLoading ?? false}
      >
        <Swiper
          loop
          className="rounded-[20px]"
          modules={[Autoplay]}
          autoplay={{
            delay: 5000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          onSlideChangeTransitionEnd={(swiper) => {
            setActiveIndex(swiper.realIndex);
          }}
        >
          {list.map((element, i) => (
            <SwiperSlide
              key={`home-banner-carousel-${i + 1}`}
              className="w-full overflow-hidden aspect-[335/86]"
            >
              {typeof element.child === 'string' ? (
                <img
                  alt="home-banner-image"
                  width="100%"
                  src={element.child}
                  className={cn(
                    'object-cover w-full h-full',
                    element.onClick && 'cursor-pointer',
                  )}
                  onClick={element.onClick}
                />
              ) : (
                element.child
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </Skeleton>

      <div className="h-[6px] flex items-center gap-[8px] mt-[8px] justify-center w-full">
        {!isLoading &&
          list.length > 1 &&
          list.map((_, i) => (
            <span
              key={`home-banner-carousel-indicator-${i + 1}`}
              className={cn(
                'block w-[6px] h-[6px] rounded-full',
                !isLoading && activeIndex === i ? 'bg-Gray10' : 'bg-Gray4',
              )}
            ></span>
          ))}
      </div>
    </div>
  );
};

export default HomeBannerCarousel;
