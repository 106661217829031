import type { ListResponse } from '@/interface/common';
import type { ProductChatTicketDto, ProductDto } from '@/models/products';
import request from '../axios';

const url = '/products';

export const getProducts = async () => {
  const { data } = await request<ListResponse<ProductDto>>({
    method: 'get',
    url: `${url}`,
  });

  return data;
};

export const getProductsChatTickets = async () => {
  const { data } = await request<ListResponse<ProductChatTicketDto>>({
    method: 'get',
    url: `${url}/chat-tickets`,
  });
  return data;
};
