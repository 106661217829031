import React, { type ComponentProps } from 'react';
import { cn } from '@/lib/utils';
import { IconJellyStampActive, IconJellyStampInactive } from '@/assets/icons';
import dayjs from 'dayjs';
import { Skeleton } from '@/components';

export interface AttendanceStampProps extends ComponentProps<'div'> {
  className?: string;
  index: number;
  stampedAt?: string;
  badge?: string;
  isLoading?: boolean;
}

const AttendanceStamp = ({
  index,
  className,
  badge,
  isLoading,
  stampedAt,
}: AttendanceStampProps) => {
  return (
    <Skeleton
      className="w-[70px] aspect-square rounded-full"
      isLoading={isLoading ?? false}
    >
      <div
        className={cn(
          'w-[70px] aspect-square rounded-full flex-col-center relative select-none',
          stampedAt ? 'bg-Gray10' : 'bg-Gray10',
          className,
        )}
      >
        {stampedAt ? (
          <>
            <IconJellyStampActive width={21} />
            <p className="text-H11 text-Gray2 mt-px text-center">
              {dayjs(stampedAt).format('M/D')}
            </p>
          </>
        ) : (
          <>
            <IconJellyStampInactive className="absolute z-0" width={21} />
            <span className="z-[1] text-Gray6 text-H11">
              {String(index).padStart(2, '0')}
            </span>
          </>
        )}
        {badge && (
          <div className="absolute px-[4px] py-[2px] bg-Gray2 rounded-[11px] text-L3 right-[7px] top-[16px] text-White z-20">
            {badge}
          </div>
        )}
      </div>
    </Skeleton>
  );
};

export default AttendanceStamp;
