import React, { useCallback } from 'react';
import { queryKeys, strings } from '@/constants/strings';
import { Button, Input, Modal } from '@/components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { postCustomersCoupon } from '@/apis/customers';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { type InferType, object, string } from 'yup';
import { toast } from 'react-toastify';

const schema = object({
  code: string().required().trim(),
});

type FormData = InferType<typeof schema>;

interface Props {
  open: boolean;
  onClose: () => void;
  disableBackgroundClose?: boolean;
}

const CouponModal: React.FC<Props> = ({
  open,
  onClose,
  disableBackgroundClose,
}) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(postCustomersCoupon);

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setFocus,
    setValue,
    watch,
    formState,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      code: '',
    },
  });

  const onPrimaryClick = (formData: FormData) => {
    mutate(formData.code, {
      onSuccess: (res) => {
        toast(
          strings.myPage.couponSuccess.ko.replace(
            strings.myPage.couponSuccess.replaceText.couponJelly,
            res.row.value.toString(),
          ),
        );
        queryClient?.invalidateQueries([queryKeys?.me]);
        resetAndClose();
      },
      onError: (err: any) => {
        let toastMessage = strings.myPage.invalidCouponCode.ko;
        if (err?.response?.data?.message === 'COUPON_ALREADY_USED') {
          toastMessage = strings.myPage.alreadyUsedCouponCode.ko;
        }

        setError('code', {
          type: 'invalid-coupon-code',
          message: toastMessage,
        });
        setFocus('code');
      },
    });
  };

  const resetAndClose = useCallback(() => {
    onClose();
    setTimeout(() => {
      setValue('code', '');
      clearErrors('code');
    }, 100);
  }, [onClose, setValue, clearErrors]);

  return (
    <Modal
      open={open}
      onClose={disableBackgroundClose ? undefined : onClose}
      title={strings.myPage.pleaseEnterCouponCode.ko}
      description={
        <Input
          {...register('code')}
          label={strings.myPage.enterCouponCode.ko}
          maxLength={8}
          autoFocus
          error={formState.errors.code?.type === 'invalid-coupon-code'}
          message={
            formState.errors.code?.type === 'invalid-coupon-code'
              ? formState.errors.code?.message
              : undefined
          }
          defaultRightAccessory={!!watch().code}
          onDefaultRightAccessoryClick={() => {
            setValue('code', '');
            clearErrors('code');
            setTimeout(() => {
              setFocus('code');
            }, 0);
          }}
        />
      }
      footer={
        <div className="flex items-center gap-[8px]">
          <Button
            className="flex-1"
            size={48}
            variant="outline"
            onClick={resetAndClose}
          >
            {strings.common.close.ko}
          </Button>
          <Button
            className="flex-1"
            size={48}
            loading={isLoading}
            onClick={handleSubmit(onPrimaryClick)}
            disabled={!watch().code}
          >
            {strings.myPage.registerCouponCode.ko}
          </Button>
        </div>
      }
    />
  );
};

export default CouponModal;
