import React, { useMemo, type PropsWithChildren, useCallback } from 'react';
import { cn } from '@/lib/utils';
import { type ChatLogMediaTheme } from '@/models/chat';
import {
  IconJellyFit,
  IconLock,
  IconChatLogMediaThemeFontMild,
  IconChatLogMediaThemeFontSweet,
  IconChatLogMediaThemeFontHot,
  IconChatLogMediaThemeLabelHot,
  IconChatLogMediaThemeLabelSweet,
  IconChatLogMediaThemeLabelMild,
} from '@/assets/icons';
import soldOutStampImage from '@/assets/images/sold-out-stamp.png';

interface Props extends PropsWithChildren {
  className?: string;
  id: number;
  theme: ChatLogMediaTheme;
  isSoldOut?: boolean;
  isBlurred?: boolean;
  imgSrc?: string;
  onClick?: (id: number, jellyCount: number, theme: ChatLogMediaTheme) => void;
  onImageLoad?: () => void;
  isFree?: boolean;
}

const ChatLogMedia = ({
  className,
  id,
  theme,
  isBlurred,
  isSoldOut,
  imgSrc,
  onClick,
  onImageLoad,
  isFree,
}: Props) => {
  const themeInfo = useMemo(() => {
    switch (theme) {
      case 'HOT':
        return {
          jellyCount: isFree ? 0 : 12,
          color: 'bg-[#D32750]',
          fontIcon: IconChatLogMediaThemeFontHot,
          labelIcon: IconChatLogMediaThemeLabelHot,
        };
      case 'SWEET':
        return {
          jellyCount: isFree ? 0 : 6,
          color: 'bg-[#F67493]',
          fontIcon: IconChatLogMediaThemeFontSweet,
          labelIcon: IconChatLogMediaThemeLabelSweet,
        };
      case 'MILD':
      default:
        return {
          jellyCount: isFree ? 0 : 3,
          color: 'bg-[#FEDFE6]',
          fontIcon: IconChatLogMediaThemeFontMild,
          labelIcon: IconChatLogMediaThemeLabelMild,
        };
    }
  }, [theme, isFree]);

  const onClickWrapper = useCallback(() => {
    onClick?.(id, themeInfo.jellyCount, theme);
  }, [id, theme, themeInfo, onClick]);

  return (
    <div
      className={cn(
        'relative flex-col-center justify-between aspect-square flex-shrink-0 rounded-[16px] overflow-hidden transition-all duration-500',
        isSoldOut ? '' : 'cursor-pointer',
        className,
      )}
      onClick={onClickWrapper}
    >
      <img
        className={cn(
          'absolute z-0 left-0 top-0 w-full h-full object-cover object-top transition-all duration-500',
          !isSoldOut && isBlurred ? 'blur-[8px]' : '',
        )}
        alt="chat-media-image"
        src={imgSrc}
        width="100%"
        height="100%"
        onLoad={onImageLoad}
        draggable={false}
      />
      {!isBlurred && !isSoldOut && (
        <themeInfo.labelIcon
          className="absolute right-[12px] bottom-[12px] z-20"
          width={57}
          height={31}
        />
      )}
      {(!!isBlurred || !!isSoldOut) && (
        <>
          {isSoldOut && (
            <img
              className="z-20 absolute bottom-[34px]"
              width={154}
              height={75}
              alt="sold-out-stamp"
              src={soldOutStampImage}
            />
          )}
          <div className="z-10 flex-1 flex-col-center">
            <div className="p-[4px]">
              <div
                className={cn(
                  'rounded-full w-[40px] h-[40px] flex-center',
                  themeInfo.color,
                )}
              >
                <IconLock width={16} height={18} />
              </div>
            </div>
            <themeInfo.fontIcon width={110} height={44} />
          </div>
          <div className="self-stretch flex-shrink-0 bg-Gray4 bg-opacity-60 h-[40px] z-10 flex-center gap-[5px]">
            <IconJellyFit width={11} height={14} />
            <span className="text-White text-H7M">{themeInfo.jellyCount}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default ChatLogMedia;
