import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function PaymentNotiPage() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const data: any = {};

    searchParams.forEach((value, key) => {
      data[key] = value;
    });

    parent.postMessage(
      {
        action: 'HECTO_IFRAME_CLOSE',
        params: data,
      },
      '*',
    );
  });

  return <></>;
}
