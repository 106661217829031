import React from 'react';
import Label from '@/components/Label/CardLabel';

interface ChattingCardProps {
  img?: string;
  name?: string;
  count?: number;
}

const ChattingCard = ({
  img = '/assets/card-profile.png',
  name = 'Celine0_0',
  count = 10,
}: ChattingCardProps) => {
  return (
    <div className="flex w-[100%] h-[154px] flex-col rounded-[16px] items-center text-H8M gap-[6px] p-[24px] bg-Gray10">
      <div className="relative">
        <img
          src="/assets/card-profile.png"
          width={106}
          height={128}
          alt="thumb"
        />
        {count && (
          <div className="absolute left-[10px] bottom-[10px]">
            <Label count={count} />
          </div>
        )}
      </div>
      <span>{name}</span>
    </div>
  );
};

export default ChattingCard;
