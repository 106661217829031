import React from 'react';
import type * as SelectPrimitive from '@radix-ui/react-select';
import {
  Select as SelectUI,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Label } from '../ui/label';

export interface SelectProps extends SelectPrimitive.SelectProps {
  options: Array<{ label: string; value: string }>;
  label?: string;
  triggerProps?: SelectPrimitive.SelectTriggerProps;
}

export default function Select(props: SelectProps) {
  const { options, label, triggerProps, ...rest } = props;

  return (
    <SelectUI {...rest}>
      <div>
        {label && <Label className="text-L2 text-Gray5">{label}</Label>}
        <SelectTrigger className="w-full" {...triggerProps}>
          <SelectValue
            placeholder={triggerProps?.placeholder}
            defaultValue={rest.defaultValue}
          />
        </SelectTrigger>
      </div>
      <SelectContent
        onCloseAutoFocus={(e) => {
          e.preventDefault();
        }}
      >
        <SelectGroup>
          {options.map((item, idx) => (
            <SelectItem key={idx.toString()} value={item.value}>
              {item.label}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </SelectUI>
  );
}
