import React, {
  type RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import PrevHeader from '@/components/Header/PrevHeader';
import { queryKeys, strings } from '@/constants/strings';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { getChatLogMediaByPartnerNick } from '@/apis/chat';
import { getPartners } from '@/apis/partners';
import { cn } from '@/lib/utils';
import IconPartnerFilterAllSrc from '@/assets/icons/partner-filter-all.svg';
import { useLocation } from 'react-router-dom';
import ListPlaceholder from '@/components/ListPlaceholder';
import { ImageModal } from '@/components';
import { type ChatLogMediaDto, type ChatLogMediaTheme } from '@/models/chat';
import {
  IconChatLogMediaThemeLabelHot,
  IconChatLogMediaThemeLabelMild,
  IconChatLogMediaThemeLabelSweet,
} from '@/assets/icons';
import useCheckAuth from '@/hooks/useCheckAuth';

interface MateFilterItemProps {
  id: number;
  profileImageSrc: string;
  nick: string;
  selected: boolean;
  onClick: (nick: string, ref: RefObject<HTMLDivElement>) => void;
}

const MateFilterItem = ({
  id,
  profileImageSrc,
  nick,
  selected,
  onClick,
}: MateFilterItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const onClickWrapper = useCallback(() => {
    onClick(nick, ref);
  }, [onClick, nick, ref]);

  useEffect(() => {
    if (location.state?.nick === nick && ref?.current) {
      ref.current.click();
    }
  }, [location, location.state, nick, ref]);

  return (
    <div
      ref={ref}
      className={cn(
        'px-[6px] pb-[4px] border-b flex-col-center gap-[4px] cursor-pointer transition-colors duration-300',
        selected ? 'border-White' : 'border-Transparent',
      )}
      onClick={onClickWrapper}
    >
      <div className="rounded-[20px] overflow-hidden w-[68px] h-[68px]">
        <img
          className="w-full h-full object-cover object-top"
          width="100%"
          height="100%"
          alt="mate-profile-image"
          src={profileImageSrc}
          draggable={false}
        />
      </div>
      <span className="text-L3 text-White">{nick}</span>
    </div>
  );
};

const getThemeLabelIcon = (theme: ChatLogMediaTheme) => {
  switch (theme) {
    case 'HOT':
      return IconChatLogMediaThemeLabelHot;
    case 'SWEET':
      return IconChatLogMediaThemeLabelSweet;
    case 'MILD':
    default:
      return IconChatLogMediaThemeLabelMild;
  }
};

const DrawerPage = () => {
  const PAGE_SIZE = 18;
  const location = useLocation();
  const filterContainerRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<HTMLDivElement>(null);
  useCheckAuth();

  const [selectedMateNick, setSelectedMateNick] = useState(
    location.state?.nick ?? '전체',
  );

  const [filterContainerScrollLeft, setFilterContainerScrollLeft] = useState(0);

  const [imageModalState, setImageModalState] = useState<{
    visible: boolean;
    lists: React.ReactNode[];
    index: number;
  }>({
    visible: false,
    lists: [],
    index: 0,
  });

  const handleImageClick = (lists: React.ReactNode[], index: number) => {
    setImageModalState({
      visible: true,
      lists,
      index,
    });
  };

  const { data: partnersData } = useQuery([queryKeys.partners], () =>
    getPartners({ excludeNick: '희야' }),
  );

  const {
    data: paidChatLogMediaData,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchedAfterMount,
    remove,
    refetch,
  } = useInfiniteQuery({
    queryKey: [queryKeys.getPaidChatLogMedia, selectedMateNick],
    queryFn: async ({ pageParam = 1 }) => {
      const { rows, count } = await getChatLogMediaByPartnerNick({
        isBlurred: false,
        partnerNick: selectedMateNick === '전체' ? undefined : selectedMateNick,
        page: pageParam,
        pageSize: PAGE_SIZE,
      });

      return {
        rows,
        count,
        nextPage: pageParam + 1,
        isLast: rows.length < PAGE_SIZE,
      };
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage.isLast) return lastPage.nextPage;
      return undefined;
    },
  });

  useEffect(() => {
    if (filterContainerRef?.current) {
      filterContainerRef.current.scrollTo({
        left:
          filterContainerScrollLeft -
          filterContainerRef.current.getBoundingClientRect().width / 2,
        behavior: 'smooth',
      });
    }
  }, [filterContainerRef, filterContainerScrollLeft]);

  useEffect(() => {
    let observer: IntersectionObserver;
    if (observerRef.current) {
      observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isFetching && !isError && hasNextPage) {
            fetchNextPage();
          }
        });
      });

      observer.observe(observerRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [isFetching, fetchNextPage, isError, hasNextPage]);

  const flatMediaList = useMemo(() => {
    const result: ChatLogMediaDto[] = [];
    paidChatLogMediaData?.pages.forEach((page) => {
      page.rows.forEach((media) => {
        result.push(media);
      });
    });

    return result;
  }, [paidChatLogMediaData]);

  return (
    <>
      <PrevHeader title={strings.myPage.drawer.ko} />
      <div className="pt-header h-full px-[20px] flex flex-col pc:h-[calc(100%-var(--header-height))] pc:overflow-auto pc:hide-scrollbar">
        <div
          ref={filterContainerRef}
          className="py-[12px] px-[20px] -mx-[20px] flex items-center overflow-auto hide-scrollbar flex-shrink-0"
        >
          <MateFilterItem
            id={-1}
            profileImageSrc={IconPartnerFilterAllSrc}
            nick="전체"
            onClick={(nick) => {
              remove();
              setFilterContainerScrollLeft(0);
              setSelectedMateNick(nick);
              refetch();
            }}
            selected={selectedMateNick === '전체'}
          />
          {partnersData?.rows.map((partner) => (
            <MateFilterItem
              key={`partner-filter-${partner.id}`}
              id={partner.id}
              profileImageSrc={partner.profileMedia.smallFullUrl}
              nick={partner.nick}
              onClick={(nick, ref) => {
                remove();
                setFilterContainerScrollLeft(ref.current?.offsetLeft ?? 0);
                setSelectedMateNick(nick);
                refetch();
              }}
              selected={partner.nick === selectedMateNick}
            />
          ))}
        </div>
        <div className="flex-1 flex flex-col py-[12px]">
          {isError && (
            <ListPlaceholder
              descriptionClassName="text-H7M text-Gray6 whitespace-pre-line text-center"
              description={
                '메이트에게 받은 사진이 없어요!\n지금 대화를 시작해보세요'
              }
            />
          )}
          {!isError && isFetchedAfterMount && paidChatLogMediaData && (
            <div className="space-y-[10px]">
              <span className="text-L3 text-White">
                {paidChatLogMediaData.pages?.[0]?.count ?? 0}
                {strings.common.unit.ko}
              </span>
              <div className="grid grid-cols-3 gap-[4px]">
                {flatMediaList.map((media, idx) => {
                  const Icon = getThemeLabelIcon(media.partnerMedia.theme);

                  return (
                    <div
                      key={`paid-media-${selectedMateNick}-${media.id}`}
                      className="w-full aspect-square cursor-pointer relative"
                      onClick={() => {
                        handleImageClick(
                          flatMediaList.map((item) => (
                            <img
                              key={`drawer-image-modal-${item.id}`}
                              width="100%"
                              alt="intro"
                              src={item.partnerMedia.media.bufferFullUrl}
                              draggable={false}
                            />
                          )),
                          idx,
                        );
                      }}
                    >
                      <img
                        className="w-full h-full object-cover object-top"
                        width="100%"
                        height="100%"
                        alt="chat-media-image"
                        src={media.partnerMedia.media.mediumFullUrl}
                        draggable={false}
                      />
                      <Icon
                        width={36}
                        height={20}
                        className="absolute right-[4px] bottom-[4px]"
                      />
                    </div>
                  );
                })}

                {isFetchedAfterMount && <div ref={observerRef}></div>}
              </div>
            </div>
          )}
        </div>
      </div>
      <ImageModal
        open={imageModalState?.visible}
        lists={imageModalState?.lists}
        initialSlide={imageModalState?.index}
        onClose={() => {
          setImageModalState({ index: 0, lists: [], visible: false });
        }}
        hasKeyboardEvent={true}
      />
    </>
  );
};

export default DrawerPage;
