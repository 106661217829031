import React, { forwardRef } from 'react';
import {
  Input as InputBase,
  type InputProps as UiInputProps,
} from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { Label } from '../ui/label';
import {
  IconCheckFill,
  IconCloseInInput,
  IconInfo,
  IconWarningWhiteFillRed,
} from '@/assets/icons';
import colors from '@/constants/colors';

export interface InputProps extends UiInputProps {
  className?: string;
  error?: boolean;
  message?: React.ReactNode;
  defaultMessage?: string;
  isValid?: boolean;
  validMessage?: string;
  label?: React.ReactNode;
  rightAccessory?: React.ReactNode;
  defaultRightAccessory?: boolean;
  onDefaultRightAccessoryClick?: () => void;
  onPressEnter?: () => void;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      error = false,
      message,
      isValid,
      validMessage,
      label,
      rightAccessory,
      defaultRightAccessory,
      onDefaultRightAccessoryClick,
      onPressEnter,
      ...rest
    },
    ref,
  ) => {
    return (
      <div className={cn('flex w-full relative flex-col gap-[6px]', className)}>
        {label && (
          <Label className="text-L2 text-Gray7" htmlFor="picture">
            {label}
          </Label>
        )}
        <div
          className="flex items-center gap-[4px] border-b-Gray5 border-b pb-[8px] data-[valid=true]:border-b-Green2 data-[error=true]:border-b-Red2"
          data-valid={isValid}
          data-error={error}
        >
          <InputBase
            ref={ref}
            className="peer rounded-none flex-1 border-none px-0 outline-none"
            onPressEnter={onPressEnter}
            {...rest}
          />
          {!defaultRightAccessory && rightAccessory}
          {defaultRightAccessory && (
            <div
              className="cursor-pointer hidden peer-focus:block"
              // peer-focus 해제가 onClick 보다 선행되기 때문에 더 앞단인 mousedown 이벤트에 실행
              onMouseDown={onDefaultRightAccessoryClick}
            >
              <IconCloseInInput fill={colors.White} width={24} height={24} />
            </div>
          )}
        </div>
        {isValid && (
          <div className="flex gap-[4px] items-center">
            <IconCheckFill width={20} height={20} />
            <span className="text-L2 text-Green2">{validMessage}</span>
          </div>
        )}
        {!isValid && error && (
          <div className="flex gap-[4px] items-center">
            <IconWarningWhiteFillRed width={20} height={20} />
            <span className="text-L2 text-Red2">{message}</span>
          </div>
        )}
        {!isValid && !error && !!message && (
          <div className="flex gap-[4px] items-center">
            <IconInfo width={20} height={20} />
            <span className="text-L2 text-Gray6">{message}</span>
          </div>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';

export default Input;
