import { IconTooltipClose } from '@/assets/icons';
import Tooltip from '@/components/Tooltip';
import { strings } from '@/constants/strings';
import { cn } from '@/lib/utils';
import React, { useCallback, useEffect, useState } from 'react';

type ColorHex = `#${string}`;

interface Props {
  className?: string;
  total: number; // 전체 (개수)
  portion: number; // 색칠될 비율(개수)
  backColor: ColorHex;
  foreColor: ColorHex;
  changeForeColor: ColorHex;
  changeColorLimit?: number;
  disableTooltip?: boolean;
  onClick: () => void;
}

const DonutChart: React.FC<Props> = ({
  className,
  total,
  portion,
  backColor,
  foreColor,
  changeForeColor,
  changeColorLimit = 5,
  disableTooltip = false,
  onClick,
}) => {
  const [ratio, setRatio] = useState(portion / total);
  const [openTooltip, setOpenTooltip] = useState(true);
  const [isCloseClick, setIsCloseClick] = useState(false);

  useEffect(() => {
    setRatio(portion / total);
  }, [portion, total, changeColorLimit]);

  const onClickClose = useCallback((e: any) => {
    e.stopPropagation();
    setIsCloseClick(true);
  }, []);

  const onAnimationEnd = useCallback(() => {
    if (isCloseClick) {
      setOpenTooltip(false);
    }
  }, [isCloseClick]);

  return portion < 0 ? null : (
    <div className={cn('relative flex items-center rounded-full', className)}>
      <div
        className="w-full h-full p-[6px] border border-White rounded-full overflow-hidden pointerhover:hover:bg-Gray9 transition-colors"
        onClick={onClick}
      >
        <svg
          viewBox="0 0 36 36"
          style={{ transform: 'scaleX(-1)', width: '100%', height: '100%' }}
        >
          <circle
            cx="18"
            cy="18"
            r="14"
            fill="none"
            stroke={backColor}
            strokeWidth="5"
          />
          <circle
            cx="18"
            cy="18"
            r="14"
            fill="none"
            stroke={
              portion <= changeColorLimit && portion >= 0
                ? changeForeColor
                : foreColor
            }
            strokeWidth="5.75"
            strokeDasharray={`${(2 * Math.PI * 14 * ratio).toFixed(2)} ${(
              2 *
              Math.PI *
              14 *
              (1 - ratio)
            ).toFixed(2)}`}
            strokeDashoffset={2 * Math.PI * 14 * 0.25}
          />
        </svg>
      </div>
      {!disableTooltip && openTooltip && (
        <Tooltip
          className={cn(
            '-left-[8px] -translate-x-full w-max flex items-center gap-[4px] fill-mode-forwards cursor-auto',
            isCloseClick
              ? 'animate-out slide-out-to-right-[calc(-100%+5px)] fade-out-0'
              : 'animate-in slide-in-from-right-[calc(-100%+5px)] fade-in-0',
          )}
          arrowDirection="right"
          onAnimationEnd={onAnimationEnd}
        >
          {strings.chat.checkCount.ko}
          <button onClick={onClickClose}>
            <IconTooltipClose
              width={16}
              height={16}
              className="flex-shrink-0"
            />
          </button>
        </Tooltip>
      )}
    </div>
  );
};

export default DonutChart;
