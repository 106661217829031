import { type ObjectResponse } from '@/interface/common';
import { type PaymentDto } from '@/models/payments';
import request from '../axios';

const url = '/customers';

export const getCustomersMePaymentsById = async (
  id: number,
  status?: 'prepare' | 'inProgress' | 'confirm' | 'cancel' | 'refundComplete',
) => {
  const { data } = await request<ObjectResponse<PaymentDto>>({
    method: 'get',
    url: `${url}/me/payments/${id}`,
  });

  return data;
};
