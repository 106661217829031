import { cn } from '@/lib/utils';
import React from 'react';
import Button from '../Button';
import { type FooterButtonProps } from './types';

export default function FooterButton(props: FooterButtonProps) {
  const { className, loading, ...rest } = props;

  return (
    <footer
      className={cn(
        'fixed bottom-0 w-full max-w-max min-w-min z-header p-[20px] bg-Background',
        className,
      )}
    >
      <Button className="w-full" size={56} {...rest} loading={loading} />
    </footer>
  );
}
