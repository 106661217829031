import React, { useCallback, useEffect } from 'react';
import { queryKeys, strings } from '@/constants/strings';
import { Button, Input, Modal } from '@/components';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getCustomersCheckDuplicateByNick,
  putCustomersMe,
} from '@/apis/customers';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { regex } from '@/utils/strings';
import { useAuthStore } from '@/zustands/useAuthStore';

const schema = object({
  nick: string().matches(regex.nick, {
    message: strings.message.nick.ko,
  }),
});

interface Props {
  open: boolean;
  onClose: () => void;
  disableBackgroundClose?: boolean;
}

const EditNickModal: React.FC<Props> = ({
  open,
  onClose,
  disableBackgroundClose,
}) => {
  const queryClient = useQueryClient();
  const { auth } = useAuthStore();
  const {
    control,
    handleSubmit,
    setError,
    reset,
    clearErrors,
    setFocus,
    setValue,
    getValues,
    watch,
    formState,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      nick: auth?.nick ?? '',
    },
    mode: 'all',
  });

  const { mutate, isLoading } = useMutation(putCustomersMe, {
    onSuccess: async () => {
      await queryClient?.invalidateQueries([queryKeys?.me]);
    },
  });

  const { refetch } = useQuery(
    [queryKeys?.nickDuplicateCheck],
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    () => getCustomersCheckDuplicateByNick(watch()?.nick ?? ''),
    { enabled: false },
  );

  const handleOnBlur = () => {
    const { dirtyFields, isValid } = formState;

    if (!dirtyFields?.nick || !watch().nick) {
      return;
    }
    if (!isValid) return;
    refetch().then((data) => {
      if (!data?.isError) {
        clearErrors();
      } else {
        if (dirtyFields?.nick) {
          setError('nick', {
            type: 'duplicate',
            message: strings?.signup?.alreadyUsedNick?.ko,
          });
        }
      }
    });
  };

  const editProfileMutate = () => {
    mutate(
      {
        ...(formState.dirtyFields.nick && { nick: getValues().nick }),
      },
      {
        onSuccess: () => {
          onClose();
          setTimeout(() => {
            clearErrors('nick');
          }, 100);
        },
      },
    );
  };

  const onPrimaryClick = () => {
    if (!formState.dirtyFields?.nick) {
      editProfileMutate();
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    refetch().then((data) => {
      if (!data?.isError) {
        editProfileMutate();
        clearErrors();
      } else {
        if (!formState.dirtyFields?.nick) {
          editProfileMutate();
        } else {
          setError('nick', {
            type: 'duplicate',
            message: strings?.signup?.alreadyUsedNick?.ko,
          });
        }
      }
    });
  };

  const resetAndClose = useCallback(() => {
    onClose();
    setTimeout(() => {
      setValue('nick', auth?.nick ?? '');
      clearErrors('nick');
    }, 100);
  }, [auth, onClose, setValue, clearErrors]);

  useEffect(() => {
    reset({ nick: auth?.nick });
  }, [auth, reset]);

  return (
    <Modal
      open={open}
      onClose={disableBackgroundClose ? undefined : onClose}
      title={strings.signUpNick.pageDescription.ko}
      description={
        <Controller
          name="nick"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              label={
                <span className="text-Gray5">{strings.common.nickname.ko}</span>
              }
              placeholder={strings.signup.nicknamePlaceholder}
              maxLength={8}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onPressEnter={handleSubmit(onPrimaryClick)}
              error={!!error?.type && !!watch().nick}
              message={
                <span
                  className={
                    !!error?.type && !!watch().nick ? '' : 'text-Gray5'
                  }
                >
                  {error?.message ?? strings?.message?.nick.ko}
                </span>
              }
              defaultRightAccessory={!!watch().nick}
              onDefaultRightAccessoryClick={() => {
                setValue('nick', '');
                clearErrors('nick');
                setTimeout(() => {
                  setFocus('nick');
                }, 0);
              }}
              onBlur={handleOnBlur}
            />
          )}
        />
      }
      footer={
        <div className="flex items-center gap-[8px]">
          <Button
            size={48}
            className="flex-1"
            variant="outline"
            onClick={resetAndClose}
          >
            {strings.common.close.ko}
          </Button>
          <Button
            size={48}
            className="flex-1"
            loading={isLoading}
            onClick={handleSubmit(onPrimaryClick)}
            disabled={!watch().nick || !formState.isDirty || !formState.isValid}
          >
            {strings.myPage.editNick.ko}
          </Button>
        </div>
      }
    />
  );
};

export default EditNickModal;
