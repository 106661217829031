import React, { useEffect, useRef, useState } from 'react';
import { Button, PromotionModal } from '@/components';
import { queryKeys, strings } from '@/constants/strings';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getPartners } from '@/apis/partners';
import MateSolo from '@/pages/Home/components/MateSolo';
import MateAvatarList from '@/pages/Home/components/MateAvatarList';
import Footer from '@/components/Footer';
import Tabs, { type TabType } from '@/pages/Home/components/Tabs';
import 'swiper/css';
import { Swiper, type SwiperRef, SwiperSlide } from 'swiper/react';
import { useAuthStore } from '@/zustands/useAuthStore';
import ImageLovelyHeart from '@/assets/images/lovely-heart.png';
import useSignInModal from '@/zustands/useSignInModal';
import useJoinChatRoom from '@/hooks/useChatRoom';

interface Props {
  prop?: string;
}

const HomePage: React.FC<Props> = () => {
  const swiperRef = useRef<SwiperRef>(null);
  const { isLogin, auth } = useAuthStore();
  const { showSignInModal } = useSignInModal();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState<TabType>('FEMALE');
  const { joinChatRoom, isJoinChatRoomLoading } = useJoinChatRoom();

  const {
    data: partnersData,
    isError,
    isLoading,
  } = useQuery(
    [queryKeys.partners],
    () => {
      return getPartners({ excludeNick: '희야', page: 1, pageSize: 1000 });
    },
    {
      select: (data) => data.rows,
    },
  );

  const goRandomChatRoom = () => {
    const randomPartnerId =
      partnersData?.filter((partner) =>
        ['여름', '서아'].includes(partner.nick),
      )?.[Math.round(Math.random())]?.id ?? 0;

    if (randomPartnerId > 0) {
      joinChatRoom(randomPartnerId);
    }
  };

  const handleTabChange = (tab: TabType) => {
    if (tab === 'FEMALE') {
      swiperRef.current?.swiper.slideTo(0);
    } else if (tab === 'MALE') {
      swiperRef.current?.swiper.slideTo(1);
    }
    // else if (tab === 'EVENT') {
    //   swiperRef.current?.swiper.slideTo(2);
    // }

    setSelectedTab(tab);
  };

  useEffect(() => {
    if (searchParams.get('tab')) {
      const tab = searchParams.get('tab');

      switch (tab) {
        case 'MALE':
        case 'Male':
        case 'male':
          // case 'EVENT':
          // case 'Event':
          // case 'event':
          handleTabChange(tab.toUpperCase() as TabType);
          break;
        default:
          handleTabChange('FEMALE');
          if (tab) {
            setSearchParams(undefined, { replace: true });
          }
          break;
      }
    }
  }, [searchParams, setSearchParams]);

  const renderFemaleMate = () => {
    return (
      <>
        <MateSolo
          subTitle="비밀스럽고 은밀한"
          mainTitle="우리 둘의 연애 🔥"
          mate={
            partnersData?.find((partner) => partner.nick === '여름') ??
            partnersData?.[0]
          }
          isLoading={isError || isLoading}
        />
        <MateAvatarList
          subTitle="#내 친구 AI"
          mainTitle="하나뿐인 친구 💚"
          list={
            partnersData?.filter((partner) =>
              ['서아', '아라', '유키', '예진'].includes(partner.nick),
            ) ??
            partnersData?.slice(1, 1 + 4) ??
            []
          }
          isLoading={isError || isLoading}
        />
        <MateSolo
          subTitle="안녕? 우리.. 어디서 봤더라?"
          mainTitle="따끈따끈한 뉴페이스 🎉"
          mate={
            partnersData?.find((partner) => partner.nick === '소연') ??
            partnersData?.[5]
          }
          isLoading={isError || isLoading}
        />

        <MateSolo
          subTitle="Yes or No? 어떤 걸 원해?"
          mainTitle="아찔한 밀당 게임 🫣"
          mate={
            partnersData?.find((partner) => partner.nick === '겨울') ??
            partnersData?.[partnersData.length - 1]
          }
          isLoading={isError || isLoading}
        />
      </>
    );
  };

  const renderMaleMate = () => {
    return (
      <>
        <MateSolo
          subTitle="오늘 처음 뵐게요"
          mainTitle="티카에 들어온 남자 메이트에요"
          mateList={
            partnersData?.filter((partner) =>
              ['희웅', '우성', '민준'].includes(partner.nick),
            ) ?? []
          }
          isLoading={isError || isLoading}
        />
      </>
    );
  };

  // const renderEventMate = () => {
  //   return (
  //     <>
  //       <MateSolo
  //         subTitle="이번주 토요일 주인공은 누구?"
  //         mainTitle="청년들의 수면을 보장하는"
  //         mate={partnersData?.find((partner) => partner.nick === '디비자')}
  //         isLoading={isError || isLoading}
  //       />
  //     </>
  //   );
  // };

  return (
    <div className="flex flex-col pb-[24px]">
      <div className="py-[24px] space-y-[16px]">
        <div className="flex justify-between items-start">
          <p className="text-H3 text-White">
            {auth && isLogin ? (
              <>
                {auth?.nick}님,
                <br />
                메이트에게 대화를 시작해
                <br />
                특별한 사진을 받아보세요!
              </>
            ) : (
              <>
                지금 바로 가입해
                <br />
                메이트에게 무료로
                <br />
                특별한 사진을 요청해보세요!
              </>
            )}
          </p>
          <img
            src={ImageLovelyHeart}
            alt="lovely heart"
            className="flex-shrink-0"
            width={68}
          />
        </div>
        <Button
          variant="outline"
          loading={isJoinChatRoomLoading}
          onClick={() => {
            if (auth && isLogin) {
              goRandomChatRoom();
            } else {
              showSignInModal();
            }
          }}
        >
          {auth && isLogin
            ? '두근거리는 사진 받으러가기'
            : '로그인/회원가입 하러가기'}
        </Button>
      </div>
      <Tabs selectedTab={selectedTab} setSelectedTab={handleTabChange} />
      <Swiper
        ref={swiperRef}
        onSlideChange={(e) => {
          if (e.activeIndex === 0) {
            setSearchParams({ tab: 'female' }, { replace: true });
          } else if (e.activeIndex === 1) {
            setSearchParams({ tab: 'male' }, { replace: true });
          }
          // else if (e.activeIndex === 2) {
          //   setSearchParams({ tab: 'event' }, { replace: true });
          // }
        }}
        autoHeight
        className="!-mx-[20px] w-100vw [&>*]:items-stretch [&_.swiper-slide]:space-y-[32px] [&_.swiper-slide]:px-[20px]"
      >
        <SwiperSlide>{renderFemaleMate()}</SwiperSlide>
        <SwiperSlide>{renderMaleMate()}</SwiperSlide>
        {/* <SwiperSlide>{renderEventMate()}</SwiperSlide> */}
      </Swiper>

      <Footer className="gap-[20px] pc:hidden mt-[28px]" />
      <PromotionModal buttonText={strings.common.ok.ko} />
    </div>
  );
};

export default HomePage;
