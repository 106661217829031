import { getCustomersMe } from '@/apis/customers';
import { paths } from '@/constants/paths';
import { queryKeys } from '@/constants/strings';
import { useAuthStore } from '@/zustands/useAuthStore';
import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
  const navigate = useNavigate();
  const { setAuth, setIsLogin, removeToken, isLogin } = useAuthStore();

  const { isSuccess, data, isError, error } = useQuery(
    [queryKeys.me],
    getCustomersMe,
    { enabled: !!isLogin },
  );

  useEffect(() => {
    if (isSuccess && data) {
      setAuth(data?.row);
    }
    if (isError) {
      if (
        error &&
        isAxiosError(error) &&
        error.response?.data.message ===
          'ONLY_USERS_WITH_COMPLETED_ADULT_VERIFICATION_CAN_BE_USED' &&
        !useAuthStore.getState().isNotNiceCertificated
      ) {
        navigate(paths.requireCertificate, { replace: true });
      } else {
        setIsLogin(false);
        removeToken();
      }
    }
  }, [
    isSuccess,
    data,
    setAuth,
    isError,
    setIsLogin,
    removeToken,
    error,
    navigate,
  ]);
};

export default useAuth;
