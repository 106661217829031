import React, { useCallback, useEffect } from 'react';
import NiceForm from '@/external/nice/components/NiceForm';
import useToggle from '@/hooks/useToggle';
import {
  isCloserApp,
  isInAppBrowserOfFacebookOrInstagram,
  postMessageToRN,
} from '@/utils/etc';
import { useLocation, useSearchParams } from 'react-router-dom';

interface useNiceCertificateOptions {
  enabled?: boolean;
  onSuccess: (tokenVersionId: string) => void;
  onFail?: () => void;
  failPath?: string;
}

const useNiceCertificate = ({
  enabled = true,
  onSuccess,
  onFail,
  failPath,
}: useNiceCertificateOptions) => {
  const [flag, toggle] = useToggle();
  const location = useLocation();
  const [params] = useSearchParams();

  const showNicePopup = useCallback(() => {
    if (isCloserApp()) {
      postMessageToRN('NICE_CERTIFICATION');
      return;
    }

    if (isInAppBrowserOfFacebookOrInstagram()) {
      // 인앱 브라우저
      let niceCertificationUrl = `${
        process.env.REACT_APP_ROOT_URL
      }nice-certification/main?returnPath=${encodeURIComponent(
        location.pathname,
      )}`;

      if (failPath) {
        niceCertificationUrl += `&failPath=${encodeURIComponent(failPath)}`;
      }

      window.location.href = niceCertificationUrl;
      return;
    }

    toggle();
  }, [toggle, location.pathname, failPath]);

  const renderNiceForm = () => {
    return (
      <NiceForm
        enabled={enabled}
        retryToggle={flag}
        onSuccess={onSuccess}
        onFail={onFail}
        returnPath={location.pathname}
      />
    );
  };

  // RN 앱 통신
  useEffect(() => {
    const listener = (event: any) => {
      if (!event?.data || typeof event.data !== 'string') return;

      try {
        const { from, type, ...rest } = JSON.parse(event.data);

        if (from === 'tica-app') {
          // RN 앱 통신
          switch (type) {
            case 'NICE_CERTIFICATION_SUCCESS':
              // 앱에서 본인인증 완료
              if (rest.isAdult === true) {
                // 19세 이상
                onSuccess(rest.tokenVersionId);
              } else {
                onFail?.();
              }
              break;
          }
        }
      } catch (e) {
        console.error(e);
      }
    };

    document.addEventListener('message', listener); // android
    window.addEventListener('message', listener); // ios

    return () => {
      document.removeEventListener('message', listener); // android
      window.removeEventListener('message', listener); // ios
    };
  }, [onSuccess, onFail]);

  // 페이스북 메타 인앱에서 본인인증
  useEffect(() => {
    const tokenVersionId = params.get('tokenVersionId');
    if (tokenVersionId) {
      onSuccess(tokenVersionId);
      params.delete('tokenVersionId');
    }
  }, [params, onSuccess]);

  return { showNicePopup, renderNiceForm };
};
export default useNiceCertificate;
