import React, { useCallback, useEffect, useRef, useState } from 'react';
import { cn } from '@/lib/utils';
import { Swiper, type SwiperRef, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import AttendanceStamp, {
  type AttendanceStampProps,
} from '@/pages/Attendance/components/AttendanceStamp';
import { type AttendanceDto } from '@/models/attendance';

const flatStampList: AttendanceStampProps[] = new Array(30)
  .fill(0)
  .map((_, idx) => {
    const index = idx + 1;
    let badge;

    if (index === 30) {
      badge = 'X4';
    } else if (index < 28 && index % 7 === 0) {
      badge = 'X2';
    }

    return {
      index,
      badge,
    };
  });

const stampList = [
  flatStampList.slice(0, 10),
  flatStampList.slice(10, 20),
  flatStampList.slice(20),
];

interface Props {
  className?: string;
  list: AttendanceDto[];
  isLoading?: boolean;
  isTodayAvailable: boolean;
}

const AttendanceCalendar = ({
  className,
  list,
  isLoading,
  isTodayAvailable,
}: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef<SwiperRef>(null);

  useEffect(() => {
    if (!swiperRef?.current?.swiper) return;

    const stampCount = list.length;

    if (stampCount >= 20) {
      swiperRef.current.swiper.slideTo(2);
    } else if (stampCount >= 10) {
      swiperRef.current.swiper.slideTo(1);
    } else {
      swiperRef.current.swiper.slideTo(0);
    }
  }, [list, swiperRef]);

  const renderStamp = useCallback(
    (stamp: AttendanceStampProps) => (
      <AttendanceStamp
        key={stamp.index}
        {...stamp}
        stampedAt={list[stamp.index - 1]?.createdAt}
        isLoading={isLoading}
      />
    ),
    [list, isLoading],
  );

  return (
    <div className={className}>
      <Swiper
        ref={swiperRef}
        modules={[Navigation]}
        onActiveIndexChange={(swiper) => {
          setActiveIndex(swiper.realIndex);
        }}
      >
        {stampList.map((stamps, index) => (
          <SwiperSlide key={`stamps-row-${index + 1}`}>
            <div className="space-y-[20px]">
              <div className="flex items-center justify-center gap-[18px]">
                {stamps.slice(0, 3).map(renderStamp)}
              </div>
              <div className="flex items-center justify-center gap-[18px]">
                {stamps.slice(3, 7).map(renderStamp)}
              </div>
              <div className="flex items-center justify-center gap-[18px]">
                {stamps.slice(7).map(renderStamp)}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="h-[6px] flex items-center gap-[8px] mt-[16px] justify-center w-full">
        {[0, 0, 0].map((_, i) => (
          <span
            key={`home-banner-carousel-indicator-${i + 1}`}
            className={cn(
              'block w-[6px] h-[6px] rounded-full',
              activeIndex === i ? 'bg-Gray10' : 'bg-Gray4',
            )}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default AttendanceCalendar;
