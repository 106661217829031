import React from 'react';

const Notification = () => {
  return (
    <>
      <div className="flex-col-center h-full">
        <p className="text-center text-B7">
          알림 기능은 곧 업데이트될 예정이에요
          <br />
          조금만 기다려주세요 😉🩷
        </p>
      </div>
    </>
  );
};

export default Notification;
