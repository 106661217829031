import { useCallback, useEffect, useRef } from 'react';

const useTimeout = (
  callback: () => void,
  timeInMilliSeconds: number = 300000, // 5분
) => {
  const timeoutId = useRef<number>(-1);

  const restartTimeout = useCallback(() => {
    window.clearTimeout(timeoutId.current);
    timeoutId.current = window.setTimeout(() => {
      callback();
    }, timeInMilliSeconds);
  }, [timeInMilliSeconds, callback, timeoutId]);

  useEffect(() => {
    return () => {
      window.clearTimeout(timeoutId.current);
    };
  }, [timeoutId]);

  return { restartTimeout };
};

export default useTimeout;
