export const gtmNames = {
  event: {
    CHATROOM_voiceClick: 'chatroom_voice_click_event',
    CHATROOM_chatSend: 'chatroom_chat_send_event',
    CHATROOM_blurredMediaClick: 'chatroom_blurred_media_click_event',
    CHATROOM_blurredMediaPurchaseButtonClick:
      'chatroom_blurred_media_purchase_button_click_event',
    PAYMENTS_paymentButtonClick: 'payments_payment_button_click_event',
    CHATTICKET_purchaseButtonClick: 'chatticket_purchase_button_click_event',
  },
  parameter: {
    mateId: 'mate_id',
    isReplay: 'is_replay',
    productId: 'product_id',
    buttonText: 'button_text',
    theme: 'theme',
  },
};

export const gtmPush = (
  eventName: string,
  data?: Record<string, string | number>,
) => {
  const dataLayer = window.dataLayer;

  if (dataLayer) {
    dataLayer.push({
      event: eventName,
      ...data,
    });
  }
};
