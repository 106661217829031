import { cn } from '@/lib/utils';
import React, { type ReactNode } from 'react';

interface Props {
  className?: string;
  title: string;
  list: Array<string | ReactNode>;
}

const Notice = ({ className, title, list }: Props) => {
  return (
    <div className={cn('space-y-[16px]', className)}>
      <h6 className="text-H8 text-White">{title}</h6>
      <ul className="space-y-[6px] list-disc pl-[1em]">
        {list.map((item, idx) => (
          <li key={`notice-${idx + 1}`} className="text-Gray6 text-B10">
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Notice;
