import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const useIsUtm = () => {
  const [params] = useSearchParams();
  const isUtm = useMemo(() => {
    if (params.size <= 0) {
      return false;
    }

    const keyIterator = params.keys();
    const keys = [];

    let result = keyIterator.next();
    while (!result.done) {
      keys.push(result.value);
      result = keyIterator.next();
    }

    let returnValue = false;

    keys.forEach((keyString) => {
      if (keyString.startsWith('utm_')) {
        returnValue = true;
      }
    });

    return returnValue;
  }, [params]);

  return isUtm;
};

export default useIsUtm;
