import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import {
  IconToggleCheck,
  IconToggleCircleCheck,
  IconToggleCircleDisabled,
  IconToggleCircleUnchecked,
  IconToggleSquareCheckDisabled,
  IconToggleSquareCheck,
  IconToggleSquareUnchecked,
  IconToggleUnchecked,
} from '@/assets/icons';

import { cn } from '@/lib/utils';
import colors from '@/constants/colors';

export interface CheckboxProps
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  shape?: 'normal' | 'square' | 'circle';
}

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, ...props }, ref) => {
  return (
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        'flex items-center justify-center h-[24px] w-[24px] focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50  data-[state=checked]:text-Primary4 data-[state=indeterminate]:text-Gray5',
        className,
      )}
      {...props}
    >
      {props.shape === 'normal' && <IconToggleUnchecked />}
      {props.shape === 'circle' && <IconToggleCircleUnchecked />}
      {props.shape === 'square' && <IconToggleSquareUnchecked />}
      <CheckboxPrimitive.Indicator
        className={cn('flex items-center justify-center ')}
      >
        {props.shape === 'square' &&
          (props.disabled ? (
            <IconToggleSquareCheckDisabled className="h-[24px] w-[24px]" />
          ) : (
            <IconToggleSquareCheck
              fill={colors.Primary}
              stroke={colors.Gray2}
              className="h-[24px] w-[24px]"
            />
          ))}
        {props.shape === 'circle' &&
          (props.disabled ? (
            <IconToggleCircleDisabled className="h-[24px] w-[24px]" />
          ) : (
            <IconToggleCircleCheck
              fill={colors.Primary}
              stroke={colors.Gray2}
              className="h-[24px] w-[24px]"
            />
          ))}
        {props.shape === 'normal' && (
          <IconToggleCheck
            stroke={colors.Primary}
            className="h-[24px] w-[24px] "
          />
        )}
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  );
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
