export class Dates {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
}

export interface ServerErrorBody {
  message: string;
  translate: string;
  statusCode: number;
}

export type ErrorMessage =
  | 'FAIL_TO_CREATE_CHAT_ROOM'
  | 'NOT_FOUND_CHAT_ROOM'
  | 'DUPLICATED_CHAT_ROOM'
  | 'ALREADY_JOINED_CHAT_ROOM'
  | 'INSUFFICIENT_CREDITS_TO_PURCHASE_CHAT_TICKET'
  | 'CANNOT_BUY_NEW_CHAT_TICKET_WITHOUT_CONSUMING_ALL_EXISTING_TICKETS'
  | 'ALREADY_EXIST_FIRST_MESSAGE'
  | 'NOT_FOUND_USER_MESSAGE'
  | 'NOT_FOUND_USER_FIRST_MESSAGE'
  | 'MISSING_USER_MESSAGE'
  | 'UNAUTHORIZED_CUSTOMER'
  | 'NOT_ENOUGH_CHAT_COUNT'
  | 'CHAT_ROOM_UNAVAILABLE'
  | 'DUPLICATED_USER_CHAT_MESSAGE'
  | 'ALREADY_ENDED_CHAT_ROOM'
  | 'ONLY_USERS_WITH_COMPLETED_ADULT_VERIFICATION_CAN_BE_USED';

export class APIError {
  constructor(error: any) {
    this.status = 404;
    this.message = '';
    this.errorCode = '';
    if (error) {
      const { response } = error;
      if (response) {
        const { data } = response;
        if (data) {
          const {
            message,
            statusCode = -1,
            translate,
          } = data as ServerErrorBody;
          this.errorCode = message;
          this.status = statusCode;
          this.message = translate;
        }
      }
    }
  }

  // Http status
  status: number;

  // 오류 메시지
  message: string;

  // 오류 코드
  errorCode: string;
}
