import React from 'react';
import { IconNotFound } from '@/assets/icons';
import { Button } from '@/components';
import { strings } from '@/constants/strings';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/constants/paths';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="h-full flex-center flex-col gap-[24px]">
      <div className="py-[47px]">
        <IconNotFound />
      </div>
      <p className="text-B7 text-White whitespace-pre-line text-center">
        {strings.notFound.pageDescription.ko}
      </p>
      <Button
        size={48}
        onClick={() => {
          navigate(paths.home, { replace: true });
        }}
      >
        홈으로 이동
      </Button>
    </div>
  );
};

export default NotFound;
