import React from 'react';
import {
  Button as ButtonBase,
  type ButtonProps as ButtonBaseProps,
} from '@/components/ui/button';
import { storageKey, strings } from '@/constants/strings';
import {
  SocialGoogle,
  SocialInstaDefault,
  SocialKakaoDefault,
  SocialTikTalkDefault,
} from '@/assets/socials';
import Tooltip from '../Tooltip';
import { useLocation } from 'react-router-dom';

export type SocialType = 'kakao' | 'instagram' | 'tiktalk' | 'google';

interface ButtonProps extends ButtonBaseProps {
  socialType?: SocialType;
  replace?: boolean;
  onClick?: () => void;
}

const SocialButton = ({
  socialType,
  replace,
  onClick,
  ...props
}: ButtonProps) => {
  const location = useLocation();
  const handleClick = (provider: 'kakao' | 'instagram' | 'google') => {
    sessionStorage.setItem(storageKey.session.redirectUrl, location.pathname);

    onClick?.();

    if (replace) {
      window.location.replace(
        `${process.env.REACT_APP_ROOT_URL}oauth/providers/${provider}`,
      );
    } else {
      window.open(
        `${process.env.REACT_APP_ROOT_URL}oauth/providers/${provider}`,
        '_self',
      );
    }
  };
  const recentProvider = localStorage.getItem(storageKey.local.provider);
  const renderRecentLogin = () => {
    return (
      recentProvider === socialType && (
        <Tooltip className="top-[-16px]">
          {strings?.home?.recentLogin.ko}
        </Tooltip>
      )
    );
  };

  if (socialType === 'kakao') {
    return (
      <ButtonBase
        size={48}
        {...props}
        className={
          'bg-[#FEE500] pointerhover:hover:bg-[#FEE500] text-White relative'
        }
        onClick={() => {
          handleClick('kakao');
        }}
      >
        <SocialKakaoDefault
          width={18}
          height={18}
          className="absolute left-[20px]"
        />
        {renderRecentLogin()}
        <span className="text-H8M text-Gray2">
          {strings?.home?.kakaoLogin?.ko}{' '}
          <span>
            <strong className="text-Gray2 text-H6">3초</strong> 만에
          </span>{' '}
          {strings?.home?.start?.ko}
        </span>
      </ButtonBase>
    );
  } else if (socialType === 'tiktalk') {
    return (
      <ButtonBase
        size={48}
        {...props}
        className={
          'bg-[#161722] pointerhover:hover:bg-[#161722] text-White relative'
        }
      >
        <SocialTikTalkDefault
          width={18}
          height={18}
          className="absolute left-[20px]"
        />
        {renderRecentLogin()}

        <span className="text-H8M text-Gray2">
          {strings?.home?.tiktalkLogin?.ko}{' '}
          <span>
            <strong className="text-Primary text-H6">3초</strong> 만에
          </span>{' '}
          {strings?.home?.start?.ko}
        </span>
      </ButtonBase>
    );
  } else if (socialType === 'google') {
    return (
      <ButtonBase
        size={48}
        {...props}
        variant="ghost"
        className="relative"
        onClick={() => {
          handleClick('google');
        }}
      >
        <SocialGoogle width={18} height={18} className="absolute left-[20px]" />
        {renderRecentLogin()}
        <span className="text-H8M text-Gray2">
          {strings?.home?.googleLogin?.ko}{' '}
          <span>
            <strong className="text-Primary text-H6">3초</strong> 만에
          </span>{' '}
          {strings?.home?.start?.ko}
        </span>
      </ButtonBase>
    );
  }
  return (
    <ButtonBase
      size={48}
      {...props}
      variant={'ghost'}
      className="relative"
      onClick={() => {
        handleClick('instagram');
      }}
    >
      <SocialInstaDefault
        width={18}
        height={18}
        className="fixed left-[38px]"
      />
      {renderRecentLogin()}
      <span className="text-H8M text-Gray2">
        {strings?.home?.instaLogin?.ko}{' '}
        <span>
          <strong className="text-Primary text-H6">3초</strong> 만에
        </span>{' '}
        {strings?.home?.start?.ko}
      </span>
    </ButtonBase>
  );
};

SocialButton.displayName = 'Button';

export default SocialButton;
