import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Button, FooterButton, Input, Modal } from '@/components';
import PrevHeader from '@/components/Header/PrevHeader';
import schemas from '@/constants/schemas';
import { strings } from '@/constants/strings';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { type InferType } from 'yup';
import { useMutation } from '@tanstack/react-query';
import { type To, useNavigate } from 'react-router-dom';
import Password from '@/components/Input/Password';
import { getCustomersCheckDuplicateByEmail } from '@/apis/customers';
import _ from 'lodash';
import { paths } from '@/constants/paths';
import useSignUpStore from '@/zustands/useSignUpStore';
import useBlocker from '@/hooks/useBlocker';

type FormData = InferType<typeof schemas.signUpByEmail>;

const SignUpByEmail = () => {
  const navigate = useNavigate();
  const { setSignUpData, data: signUpData, resetData } = useSignUpStore();

  const {
    register,
    formState,
    handleSubmit,
    setValue,
    setFocus,
    setError,
    watch,
    getFieldState,
  } = useForm({
    resolver: yupResolver(schemas.signUpByEmail),
    mode: 'onChange',
    defaultValues: {
      email: signUpData.body.email?.email ?? '',
      password: signUpData.body.email?.password ?? '',
      confirmPassword: signUpData.body.email?.password ?? '',
    },
  });

  const { mutate: checkDuplicateEmail } = useMutation(
    getCustomersCheckDuplicateByEmail,
    {
      onError: () => {
        setError('email', {
          type: 'duplicated-email',
          message: strings.signUpByEmail.duplicatedEmail.ko,
        });
      },
    },
  );

  const onChangeEmail = useMemo(() => {
    return _.debounce((e: any) => {
      if (e?.target?.value) checkDuplicateEmail(e.target.value);
    }, 300);
  }, [checkDuplicateEmail]);

  const onClickNext = (data: FormData) => {
    checkDuplicateEmail(data.email, {
      onSuccess: () => {
        isBlock.current = false;
        setSignUpData({
          body: {
            email: {
              email: data.email,
              password: data.password,
            },
          },
        });
        navigate(paths.signUpNick, { replace: true });
      },
    });
  };

  // 뒤로가기 방지
  const [isBlockAlertOpen, setIsBlockAlertOpen] = useState(false); // 뒤로가기 방지 모달
  const isBlock = useRef<boolean>(true);
  const handleBlock = useCallback((url: To, action: string) => {
    setIsBlockAlertOpen(true);
  }, []);
  useBlocker({
    isBlock,
    browserBackClick: handleBlock,
  });

  return (
    <>
      <PrevHeader title={strings.signUpByEmail.pageTitle.ko} />
      <div className="mt-header p-[20px]">
        <h3 className="text-H3 text-White whitespace-pre-line">
          {strings.signUpByEmail.pageDescription.ko}
        </h3>
        <form className="mt-[24px] space-y-[24px]">
          <Input
            label={strings.common.email.ko}
            {...register('email', { onChange: onChangeEmail })}
            error={
              !!formState.errors.email && formState.errors.email?.type !== 'min'
            }
            message={
              formState.errors.email?.type !== 'min'
                ? formState.errors.email?.message
                : undefined
            }
            isValid={!!watch().email && !getFieldState('email').invalid}
            validMessage={strings.signUpByEmail.validEmailMessage.ko}
            type="email"
            autoFocus
            defaultRightAccessory={!!watch().email}
            onDefaultRightAccessoryClick={() => {
              setValue('email', '');
              setTimeout(() => {
                setFocus('email');
              }, 0);
            }}
          />
          <Password
            label={strings.common.password.ko}
            {...register('password')}
            error={
              formState.errors.password &&
              formState.errors.password?.type !== 'min'
            }
            message={
              !!formState.errors.password &&
              formState.errors.password?.type !== 'min'
                ? formState.errors.password?.message
                : strings.signUpByEmail.passwordRule.ko
            }
            enableIcon={!!watch().password}
            isValid={!!watch().password && !getFieldState('password').invalid}
            validMessage={strings.signUpByEmail.validPasswordMessage.ko}
          />
          <Password
            label={strings.common.confirmPassword.ko}
            {...register('confirmPassword')}
            error={
              !!formState.errors.confirmPassword &&
              formState.errors.confirmPassword?.type !== 'min'
            }
            message={
              formState.errors.confirmPassword?.type !== 'min'
                ? formState.errors.confirmPassword?.message
                : undefined
            }
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onPressEnter={handleSubmit(onClickNext)}
            enableIcon={!!watch().confirmPassword}
            isValid={
              !!watch().confirmPassword &&
              !getFieldState('confirmPassword').invalid
            }
            validMessage={strings.signUpByEmail.validConfirmPasswordMessage.ko}
          />
        </form>
      </div>
      <FooterButton
        disabled={
          !formState.isValid ||
          !!formState.errors.email ||
          !!formState.errors.password ||
          !!formState.errors.confirmPassword
        }
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={handleSubmit(onClickNext)}
        className="email-signup-footer-button"
      >
        {strings.common.next.ko}
      </FooterButton>
      <Modal
        open={isBlockAlertOpen}
        onClose={() => {
          setIsBlockAlertOpen(false);
        }}
        title="다음에 가입하시겠어요?"
        description="지금까지 입력했던 정보는 사라져요."
        footer={
          <div className="flex flex-col gap-[12px]">
            <Button
              size={48}
              onClick={() => {
                setIsBlockAlertOpen(false);
              }}
            >
              가입 계속하기
            </Button>
            <Button
              size={48}
              variant="text"
              onClick={() => {
                isBlock.current = false;
                resetData();
                navigate({ pathname: paths.home }, { replace: true });
              }}
            >
              {strings.common.toHome.ko}
            </Button>
          </div>
        }
      />
    </>
  );
};

export default SignUpByEmail;
