import { useGlobalLoadingStore } from '@/zustands/useGlobalLoadingStore';
import lottie from 'lottie-web';
import React, { type CSSProperties, useEffect, useRef } from 'react';

interface LogoLottieProps {
  style?: CSSProperties;
}

export default function GlobalLoading({ style }: LogoLottieProps) {
  const container = useRef(null);
  const { isLoading } = useGlobalLoadingStore();

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current as unknown as Element,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: `/LottieJson/loading.json`, // the path to the animation json
    });
  }, [isLoading]);

  return (
    <>
      {isLoading && (
        <div
          ref={container}
          className="flex z-globalDim items-center  overflow-hidden w-full h-full absolute top-0 left-0 bg-dim"
        ></div>
      )}
    </>
  );
}
