import React from 'react';
import { cn } from '@/lib/utils';
import { type CategoryDto } from '@/models/categories';

interface Props {
  className?: string;
  list: CategoryDto[];
  onClick: (slideNum?: number) => void;
}

const ChatCategoryList = ({ className, list, onClick }: Props) => {
  return (
    <div
      className={cn('flex items-center gap-[8px]', className)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onFocus={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {list.map((category, idx) => (
        <button
          key={category.id}
          className={cn(
            'rounded-[20px] px-[8px] py-[6px] transition-colors animate-in slide-in-from-bottom-4 fill-mode-backwards fade-in bg-White border border-Gray9',
            'flex items-center gap-[4px] pointerhover:hover:bg-Gray9 transition-colors',
          )}
          style={{
            animationDelay: `${idx * 75}ms`,
          }}
          onClick={() => {
            onClick(idx * 3);
          }}
        >
          {category?.media?.bufferFullUrl && (
            <img
              alt="chat-category-image"
              src={category.media.bufferFullUrl}
              width={16}
              height={16}
            />
          )}

          <span className="text-L3">{category.title}</span>
        </button>
      ))}
    </div>
  );
};

export default ChatCategoryList;
