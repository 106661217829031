import { IconClock, IconTooltipClose } from '@/assets/icons';
import { Tooltip } from '@/components';
import { strings } from '@/constants/strings';
import { cn } from '@/lib/utils';
import React, { useCallback, useState } from 'react';

interface Props {
  className?: string;
  disableTooltip?: boolean;
  onClick?: () => void;
}

const ClockButton = ({ className, disableTooltip, onClick }: Props) => {
  const [openTooltip, setOpenTooltip] = useState(true);
  const [isCloseClick, setIsCloseClick] = useState(false);

  const onClickClose = useCallback((e: any) => {
    e.stopPropagation();
    setIsCloseClick(true);
  }, []);

  const onAnimationEnd = useCallback(() => {
    if (isCloseClick) {
      setOpenTooltip(false);
    }
  }, [isCloseClick]);

  return (
    <div
      className={cn('relative flex items-center', className)}
      onClick={onClick}
    >
      <IconClock width={26} height={26} />
      {!disableTooltip && openTooltip && (
        <Tooltip
          className={cn(
            '-left-[8px] -translate-x-full w-max flex items-center gap-[4px] fill-mode-forwards cursor-auto',
            isCloseClick
              ? 'animate-out slide-out-to-right-[calc(-100%+5px)] fade-out-0'
              : 'animate-in slide-in-from-right-[calc(-100%+5px)] fade-in-0',
          )}
          arrowDirection="right"
          onAnimationEnd={onAnimationEnd}
        >
          {strings.chat.checkRemainTime.ko}
          <button onClick={onClickClose}>
            <IconTooltipClose
              width={16}
              height={16}
              className="flex-shrink-0"
            />
          </button>
        </Tooltip>
      )}
    </div>
  );
};

export default ClockButton;
