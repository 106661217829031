import crypto from 'crypto-js';

export function sha256Enc(str: string) {
  return crypto.SHA256(str).toString();
}

export type HectoPayType = 'card' | 'mobile' | 'kakao';
export const hectoPay = (data?: any, callback?: (rsp: any) => void) => {
  // 결제방식
  const method = data?.method;
  // 가맹점mid
  const mchtId = data?.mchtId;
  // 간편결제코드
  const corpPayCode = data?.corpPayCode;

  // 임시 주문번호
  const mchtTrdNo = data?.mchtTrdNo;

  // 요청일자 & 요청시간
  const trdDt = data?.trdDt;
  const trdTm = data?.trdTm;

  const trdAmt = data?.trdAmt;

  // 임시 해쉬값 (상점아이디 + 결제수단 + 상점주문번호 + 요청일자 + 요청시간 + 거래금액(평문) + 해쉬키)
  const pktHash = data?.pktHash;
  if (method && SETTLE_PG) {
    SETTLE_PG.pay(
      {
        // PG 주소
        env: data?.env,
        // 결제 방식 코드
        mchtId,
        // 결제방식
        method,
        // 제휴사 코드(카카오페이 등)
        corpPayCode,
        // 주문 날짜(YYYYMMDD) //
        trdDt,
        // 주문 시간(HHMMSS) //
        trdTm,
        // 주문번호 //
        mchtTrdNo,
        // 상점명 한글
        mchtName: data?.mchtName,
        // 상점명 영어
        mchtEName: data?.mchtEName,
        // 상품명
        pmtPrdtNm: data?.pmtPrdtNm,
        // 가격 암호화 데이터(AES)
        trdAmt,
        // 고객 통장에 찍힐 인자명
        custAcntSumry: data?.merchantEName || data?.merchantName,
        // 에러 or 알림 콜백 URL
        notiUrl: data?.notiUrl,
        // 성공 콜백 URL
        nextUrl: data?.nextUrl,
        // 취소 콜백 URL
        cancUrl: data?.cancUrl,
        // 기타 주문 정보
        // mchtParam: 'name=HongGilDong&age=25',
        // 고객 IP 주소
        // custIp: '127.0.0.1',
        // 암호화 데이터(상점아이디 + 결제수단 + 상점주문번호 + 요청일자 + 요청시간 + 거래금액(평문) + 해쉬키)
        pktHash,

        ui: {
          type: 'self',
          width: '430',
          height: '660',
        },
      },
      function (rsp: any) {
        callback?.(rsp);
      },
    );
  }
};
