import React, { type PropsWithChildren, useMemo, type ReactNode } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { type ActiveKeyType, paths } from '@/constants/paths';
import {
  IconJelly,
  IconMenuGuide,
  IconSetting,
  IconStore,
} from '@/assets/icons';
import { useAuthStore } from '@/zustands/useAuthStore';
import { cn } from '@/lib/utils';

import useModalConfig from '@/hooks/useModalConfig';
import { Button, HomeBannerModal } from '@/components';
import HomeBannerGuideModalImage from '@/assets/images/chat-guide-modal-example.png';
import { strings } from '@/constants/strings';

const ChatListPageRightLinkButton = ({ children }: PropsWithChildren) => {
  return (
    <div className="py-[10px] px-[12px] rounded-[64px] flex-center gap-[8px] bg-White">
      {children}
    </div>
  );
};

interface HeaderProps {
  title?: string;
  rightAccessory?: ReactNode;
  activeKey?: ActiveKeyType;
  className?: string;
}

const Header = ({
  title,
  rightAccessory,
  activeKey,
  className,
}: HeaderProps) => {
  const navigate = useNavigate();
  const { isLogin, auth } = useAuthStore();
  const location = useLocation();

  const {
    open: isOpenHomeGuideModal,
    handleOpenModal: handleOpenHomeGuideModal,
    handleCloseModal: handleCloseHomeGuideModal,
  } = useModalConfig();

  const defaultTitle = useMemo(() => {
    if (activeKey === '/') {
      return '탐색';
    } else if (activeKey === '/chatting') {
      return '채팅';
    } else if (activeKey === '/notification') {
      return '알림';
    } else if (activeKey === '/profile') {
      return '마이';
    }

    return '';
  }, [activeKey]);

  const renderRightIconButton = () => {
    if (location.pathname === paths.home) {
      return (
        <button onClick={handleOpenHomeGuideModal}>
          <IconMenuGuide fill="white" width={28} height={28} />
        </button>
      );
    }

    if (isLogin) {
      if (location.pathname === paths.profile) {
        return (
          <button
            onClick={() => {
              navigate(paths.setting);
            }}
          >
            <IconSetting stroke="white" fill="white" width={24} height={24} />
          </button>
        );
      }

      if (location.pathname === paths.chatting) {
        return (
          <div className="space-x-[10px] flex">
            <Link to={paths.profileJelly}>
              <ChatListPageRightLinkButton>
                <IconJelly width={16} height={16} />
                <span className="text-H9M text-Gray2">
                  {auth?.creditCount ?? 0}
                </span>
              </ChatListPageRightLinkButton>
            </Link>
            <Link to={paths.payments}>
              <ChatListPageRightLinkButton>
                <span className="text-H9M text-Gray2">젤리 구매</span>
                <IconStore width={16} height={16} />
              </ChatListPageRightLinkButton>
            </Link>
          </div>
        );
      }
    }

    return rightAccessory;
  };

  return (
    <>
      <div
        className={cn(
          'fixed bg-Background z-header flex w-full max-w-max min-w-min h-header text-H7M px-[20px] justify-between items-center',
          className,
        )}
      >
        <h1 className="text-D6 text-White">{title ?? defaultTitle}</h1>
        {renderRightIconButton()}
      </div>
      <HomeBannerModal
        open={isOpenHomeGuideModal}
        onClose={handleCloseHomeGuideModal}
      >
        <div className="space-y-[12px]">
          <div className="w-full aspect-[295/200]">
            <img
              className=""
              src={HomeBannerGuideModalImage}
              alt="홈 배너 모달 이미지"
              width="100%"
            />
          </div>
          <h4 className="text-H4 text-White text-center">
            {strings.chat.guideModalTitle.ko}
          </h4>
          <p className="text-H10M text-Gray8 whitespace-pre-line text-center">
            {strings.chat.guideModalDescription.ko}
          </p>
        </div>
        <Button
          className="w-full"
          variant="default"
          size={48}
          onClick={handleCloseHomeGuideModal}
        >
          {strings.common.ok.ko}
        </Button>
      </HomeBannerModal>
    </>
  );
};

export default Header;
