import React, { useEffect } from 'react';
import { paths } from '@/constants/paths';
import { storageKey, strings } from '@/constants/strings';
import { useAuthStore } from '@/zustands/useAuthStore';
import useSignUpStore from '@/zustands/useSignUpStore';
import { useLocation, useNavigate } from 'react-router-dom';
import SignUpSuccessLogo from '@/assets/images/signup-success-logo.png';
import { toast } from 'react-toastify';
import { isPromotionPeriod } from '@/utils/promotion';

export default function SignUpSuccessPage() {
  const { resetData } = useSignUpStore();
  const { isLogin } = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const redirectUrl = sessionStorage.getItem(storageKey.session.redirectUrl);

    if (isLogin) {
      setTimeout(() => {
        if (isPromotionPeriod() && location.state?.isFirstSignUp) {
          toast(strings.signup.signUpSuccessToast.ko, {
            autoClose: 2000,
          });
        }
        navigate(
          { pathname: redirectUrl ?? paths?.home },
          { replace: true, state: { isRightAfterSignIn: true } },
        );
      }, 3000);
    } else {
      navigate(
        { pathname: redirectUrl ?? paths?.home },
        { replace: true, state: { isRightAfterSignIn: true } },
      );
    }

    if (redirectUrl) {
      sessionStorage.removeItem(storageKey.session.redirectUrl);
    }

    return () => {
      resetData();
    };
  }, [navigate, isLogin, resetData, location.state]);

  return (
    <>
      <div className="flex flex-col items-center justify-center h-[100vh] text-center text-H3 space-y-[60px]">
        <img
          alt="Tica signup success logo"
          src={SignUpSuccessLogo}
          width={178}
        />
        <p className="whitespace-pre mt-[20px]">{`${strings?.signup?.welcome?.ko} ${location.state?.nick}${strings?.signup?.chatWithCeleb?.ko}`}</p>
      </div>
    </>
  );
}
