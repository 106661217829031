import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ChatRoomList from '@/components/ChatRoomList';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getChatRooms,
  postChatPurchaseFree,
  putChatRoomJoin,
  putChatRoomsById,
} from '@/apis/chat';
import HomeBannerAttendanceImage from '@/assets/images/home-banner-attendance.png';
import HomeBannerNewUserImage from '@/assets/images/home-banner-new-user.png';
import HomeBannerAndroidAppImage from '@/assets/images/home-banner-android-app.png';
import { type ChatRoomListDto } from '@/models/chat';
import { type ChatRoomListItemProps } from '@/components/ChatRoomList/Item';
import { useNavigate, useNavigationType } from 'react-router-dom';
import { queryKeys, strings } from '@/constants/strings';
import { paths } from '@/constants/paths';
import { useAuthStore } from '@/zustands/useAuthStore';
import { Button, HomeBannerCarousel } from '@/components';
import { type HomeBannerObject } from '@/components/Carousel/HomeBannerCarousel';
import { isCloserApp, isIos } from '@/utils/etc';
import deepLinks from '@/constants/deepLinks';
import useGlobalModal from '@/zustands/useGlobalModal';
import useSignInModal from '@/zustands/useSignInModal';

interface Props {
  prop?: string;
}

const ChatList: React.FC<Props> = () => {
  const navigationType = useNavigationType();
  const navigate = useNavigate();
  const { isLogin } = useAuthStore();
  const { showErrorAlert } = useGlobalModal();
  const { showSignInModal } = useSignInModal();
  const { mutate: joinChatRoom } = useMutation(putChatRoomJoin);
  const { mutate: putChatRoom } = useMutation(putChatRoomsById);
  const { mutate: purchaseFreeChat } = useMutation(postChatPurchaseFree);
  const { data, isLoading, isError, refetch } = useQuery(
    [queryKeys.getChatRooms],
    async () => await getChatRooms(),
  );

  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    if (navigationType === 'POP') {
      refetch();
    }
  }, [navigationType, refetch]);

  const ChatRoomDtoToChatRoomItemProps = useCallback(
    (dto: ChatRoomListDto): ChatRoomListItemProps => {
      return {
        img: dto.partner.profileMedia?.smallFullUrl ?? '',
        isRead: dto.isRead,
        lastMessage: dto.chatLog?.message ?? '',
        lastMessageSentAt: dto.chatLog?.messageSentAt,
        name: dto.partner.nick,
        onClick: () => {
          if (dto.chatRoomState === 'ONGOING') {
            joinChatRoom(dto.id, {
              onSettled: () => {
                navigate(`/chatting/${dto.id}`);
              },
            });
          } else {
            // 시작된 또는 종료된 채팅방의 경우, 무료 채팅권 구매 시도
            purchaseFreeChat(dto.id, {
              onSettled: () => {
                putChatRoom(
                  {
                    id: dto.id,
                    requestBody: {
                      isRead: true,
                    },
                  },
                  {
                    onSettled: () => {
                      navigate(`/chatting/${dto.id}`);
                    },
                  },
                );
              },
            });
          }
          // else {
          //   putChatRoom(
          //     {
          //       id: dto.id,
          //       requestBody: {
          //         isRead: true,
          //       },
          //     },
          //     {
          //       onSettled: () => {
          //         navigate(`/chatting/${dto.id}`);
          //       },
          //     },
          //   );
          // }
        },
        onImageClick: () => {
          navigate(`/celeb/${dto.partner.id}`);
        },
      };
    },
    [navigate, joinChatRoom, putChatRoom, purchaseFreeChat],
  );

  const [chatRooms, setChatRooms] = useState<{
    started: ChatRoomListDto[];
    ongoing: ChatRoomListDto[];
    ended: ChatRoomListDto[];
  }>({
    started: [],
    ongoing: [],
    ended: [],
  });

  const bannerList = useMemo<HomeBannerObject[]>(() => {
    return [
      {
        child: HomeBannerAndroidAppImage,
        onClick: () => {
          if (!isCloserApp()) {
            // 웹에서 접근했을 경우에만
            if (isIos()) {
              showErrorAlert(
                '앱스토어는 아직 준비중이에요~',
                '조금만 기다려주세요 🥺',
              );
            } else {
              window.open(deepLinks.landingPlayStore);
            }
          }
        },
      },
      {
        child: HomeBannerAttendanceImage,
        onClick: () => {
          navigate(paths.attendance);
        },
      },
      {
        child: HomeBannerNewUserImage,
        onClick: () => {
          navigate(`${paths.promotion}/signup`);
        },
      },
    ];
  }, [navigate, showErrorAlert]);

  useEffect(() => {
    if (isError) {
      setIsEmpty(true);
      return;
    }

    if (data) {
      if (data.rows.length === 0) {
        setIsEmpty(true);
      } else {
        setChatRooms({
          started: data.rows.filter(
            (dto) => dto.partner && dto.chatRoomState === 'STARTED',
          ),
          ongoing: data.rows.filter(
            (dto) => dto.partner && dto.chatRoomState === 'ONGOING',
          ),
          ended: data.rows.filter(
            (dto) => dto.partner && dto.chatRoomState === 'ENDED',
          ),
        });

        setIsEmpty(false);
      }
    }
  }, [data, isError]);

  return (
    <>
      <div className="flex flex-col h-full">
        <HomeBannerCarousel
          list={bannerList}
          className="w-full sticky top-header pc:top-0 pt-[22px] pb-[8px] bg-Background z-10"
        />
        {isEmpty ? (
          <div className="flex-center gap-[12px] flex-col flex-1">
            <p className="text-B7 text-White whitespace-pre-line text-center">
              {strings.chat.noDateHistory.ko}
            </p>
            <Button
              variant="outline"
              size={32}
              onClick={() => {
                if (isLogin) {
                  navigate(paths.home);
                } else {
                  showSignInModal();
                }
              }}
            >
              {strings.chatRoomList.goFindMate.ko}
            </Button>
          </div>
        ) : (
          <div className="flex flex-col gap-[32px]">
            {chatRooms.started.length > 0 && (
              <ChatRoomList
                list={chatRooms.started.map((dto) =>
                  ChatRoomDtoToChatRoomItemProps(dto),
                )}
                isLoading={isLoading}
              />
            )}

            {chatRooms.ongoing.length > 0 && (
              <ChatRoomList
                list={chatRooms.ongoing.map((dto) =>
                  ChatRoomDtoToChatRoomItemProps(dto),
                )}
                isLoading={isLoading}
              />
            )}

            {chatRooms.ended.length > 0 && (
              <ChatRoomList
                list={chatRooms.ended.map((dto) =>
                  ChatRoomDtoToChatRoomItemProps(dto),
                )}
                isLoading={isLoading}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ChatList;
