import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/ko';

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('Asia/Seoul');

const PROMOTION_START = '2024-04-01 00:00:00';
// const PROMOTION_END = '2024-04-01 10:59:59';

export const PROMOTION_START_DAYJS = dayjs(PROMOTION_START).tz().locale('ko');
// export const PROMOTION_END_DAYJS = dayjs(PROMOTION_END).tz().locale('ko');

// export const PROMOTION_END_STR = dayjs(PROMOTION_END)
//   .tz()
//   .locale('ko')
//   .add(1, 'second')
//   .format('M월 D일 a hh시');

export const isPromotionPeriod = () => {
  const nowInKorea = dayjs().tz();

  const promotionStartInKorea = dayjs(PROMOTION_START).tz();
  // const promotionEndInKorea = dayjs(PROMOTION_END).tz();

  return nowInKorea.isAfter(promotionStartInKorea);
  // && nowInKorea.isBefore(promotionEndInKorea)
};

export const checkIsInViewport = (element: HTMLElement) => {
  if (!element || !window) return false;

  const { top, bottom } = element.getBoundingClientRect();

  return bottom > 0 && top <= window.innerHeight;
};
