import React, { type PropsWithChildren } from 'react';

export interface MateContainerProps extends PropsWithChildren {
  subTitle?: string;
  mainTitle?: string;
}

const MateContainer = ({
  subTitle,
  mainTitle,
  children,
}: MateContainerProps) => {
  return (
    <div className="space-y-[20px]">
      {(!!subTitle || !!mainTitle) && (
        <div>
          {subTitle && <p className="text-H7M text-Gray8">{subTitle}</p>}
          {mainTitle && <h3 className="text-D6 text-White">{mainTitle}</h3>}
        </div>
      )}
      {children}
    </div>
  );
};

export default MateContainer;
