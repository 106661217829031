import { IconCopyright } from '@/assets/icons';
import { externalLinks } from '@/constants/strings';
import { cn } from '@/lib/utils';
import React from 'react';

interface Props {
  className?: string;
}

const Footer = ({ className }: Props) => {
  return (
    <div className={cn('flex flex-col gap-[12px] pb-2', className)}>
      <span className="text-H7">(주)슬로그업</span>
      <div className="flex flex-col gap-[4px] text-H9M">
        <div className="flex items-center">
          <span>사업자 등록번호 : 777-88-00374</span>
          <hr className="border-r-Gray2 border-t-0 border-r h-[16px] mx-[12px]" />
          <span>대표 : 김상천</span>
        </div>
        <span>서울특별시 마포구 성지3길 74 장석빌딩 (주)슬로그업</span>
        <span>74, Seongji 3-gil, Mapo-gu</span>
        <span>Seoul, Republic of Korea</span>
      </div>
      <div>
        <p className="text-H9M text-White">
          제휴 및 기타문의 :{' '}
          <a className="text-H9M" href="mailto:tica.slogup@gmail.com">
            tica.slogup@gmail.com
          </a>
        </p>
      </div>
      <div className="flex items-center text-H9 text-White">
        <a
          href={externalLinks.termsOfService}
          target={'_blank'}
          rel="noreferrer"
        >
          서비스 이용약관
        </a>
        <hr className="border-r-Gray2 border-t-0 border-r h-[16px] mx-[12px]" />
        <a
          href={externalLinks.privacyPolicy}
          target={'_blank'}
          rel="noreferrer"
        >
          개인정보 처리방침
        </a>
      </div>
      <div className="flex gap-[4px] items-center mt-[12px]">
        <IconCopyright />
        <span className="text-H9M text-Gray6">
          2024 Slogup. All Rights Reserved.
        </span>
      </div>
    </div>
  );
};

export default Footer;
