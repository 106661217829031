import React from 'react';
import PrevHeader from '@/components/Header/PrevHeader';
import { strings } from '@/constants/strings';
import { useLocation } from 'react-router-dom';
import ResetPasswordForm from '@/pages/ResetPassword/ResetPasswordForm';

const ResetPassword = () => {
  const location = useLocation();

  const email = location.state.email;
  const tokenVersionId = location.state.tokenVersionId;

  return (
    <>
      <PrevHeader title={strings.resetPassword.pageTitle.ko} />
      <ResetPasswordForm tokenVersionId={tokenVersionId} email={email} />
    </>
  );
};

export default ResetPassword;
