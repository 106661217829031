import { cn } from '@/lib/utils';
import { type Partner } from '@/models/partners';
import React, { useCallback, type HTMLAttributes } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props extends HTMLAttributes<HTMLDivElement> {
  mate: Partner;
  isBlur?: boolean;
}

const LargeMateCard: React.FC<Props> = ({ mate, className, isBlur }) => {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(`/celeb/${mate.id}`);
  }, [navigate, mate]);

  return (
    <div
      className={cn(
        'animate-in fade-in',
        'rounded-[12px] p-[20px] aspect-[335/435] relative overflow-hidden flex flex-col justify-end',
        isBlur
          ? ''
          : 'cursor-pointer transition-transform pointerhover:hover:scale-105',
        className,
      )}
      onClick={!isBlur ? onClick : undefined}
    >
      <img
        className="absolute block z-0 top-0 left-0 w-full h-full object-cover"
        alt="mate-card-image"
        src={mate.profileMedia.mediumFullUrl}
      />
      <div className="absolute top-0 bottom-0 left-0 right-0 bg-gradient-to-b from-[rgba(28,28,30,0.00)_0%] to-[#1C1C1E_100%]">
        {/* dim */}
      </div>
      <div className="flex z-10 flex-col gap-[16px]">
        <div className="space-y-[4px]">
          <p className="text-L1 text-White">
            {mate.partnerInfo?.titleDescription}
          </p>
          <h4 className="text-H4 text-White">
            {`${mate.nick} / ${mate.partnerInfo?.age}살`}
          </h4>
        </div>
        <div className="w-full h-px bg-Gray9">{/* divider */}</div>
        <div className="space-y-[8px]">
          <p className="text-H10M text-White whitespace-pre-line">
            {mate.partnerInfo?.subDescription}
          </p>
          <div className="flex items-center gap-[8px]">
            {mate.partnerCategories.map((category) => (
              <div
                className="py-[6px] px-[8px] rounded-[20px] bg-Gray4 text-L3 text-Gray7"
                key={`category-${category.id}`}
              >
                {category.category.title}
              </div>
            ))}
          </div>
        </div>
      </div>
      {isBlur && (
        <div className="z-20 absolute top-0 left-0 w-full h-full bg-[rgba(255,255,255,0.04)] backdrop-blur-[8px] rounded-[12px]"></div>
      )}
    </div>
  );
};

export default LargeMateCard;
