import VoiceButton from '@/components/VoiceButton';
import { Avatar, ChatLogMedia } from '@/components';
import { cn } from '@/lib/utils';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import React, { useEffect, useMemo, useState } from 'react';
import { type ChatLogMediaTheme, type ChatLogMediaDto } from '@/models/chat';
import { getMediaSoldOutImage } from '@/constants/hardcode';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';

const excludeVoiceNickList = ['유키'];

const freeEventNickList = ['디비자'];

interface Props {
  chatRoomId: number;
  messageId: number;
  audioUrl?: string;
  partnerNick?: string;
  partnerId?: number;
  partnerProfileImage?: string;
  partnerProfileImageForModal?: string;
  message: string;
  messageWithImages?: string[];
  messageSentAt: string;
  remainingVoiceCount?: number;
  onImageClick?: (src: string) => void;
  chatLogMediaList?: ChatLogMediaDto[];
  onMediaClick?: (
    id: number,
    jellyCount: number,
    theme: ChatLogMediaTheme,
  ) => void;
  isPaid?: boolean;
  onLastImageLoad?: () => void;
  hideVoiceButton?: boolean;
}

const ChatBubble: React.FC<Props> = ({
  chatRoomId,
  messageId,
  audioUrl,
  partnerId,
  partnerNick,
  partnerProfileImage,
  partnerProfileImageForModal,
  message,
  messageWithImages,
  messageSentAt,
  remainingVoiceCount = 0,
  onImageClick,
  chatLogMediaList,
  onMediaClick,
  isPaid,
  onLastImageLoad,
  hideVoiceButton,
}) => {
  const [chatLogMedias, setChatLogMedias] = useState<{
    mild?: ChatLogMediaDto;
    sweet?: ChatLogMediaDto;
    hot?: ChatLogMediaDto;
  }>({});

  useEffect(() => {
    if (chatLogMediaList) {
      const mild = chatLogMediaList.find(
        (chatLogMedia) => chatLogMedia.partnerMedia.theme === 'MILD',
      );
      const sweet = chatLogMediaList.find(
        (chatLogMedia) => chatLogMedia.partnerMedia.theme === 'SWEET',
      );
      const hot = chatLogMediaList.find(
        (chatLogMedia) => chatLogMedia.partnerMedia.theme === 'HOT',
      );

      setChatLogMedias({
        mild,
        sweet,
        hot,
      });
    }
  }, [chatLogMediaList]);

  const isFreeChatLogMedia = useMemo(() => {
    return !!partnerNick && freeEventNickList.includes(partnerNick);
  }, [partnerNick]);

  return (
    <div className="space-y-[6px]">
      <div className="flex items-end gap-[8px] overflow-hidden animate-in duration-300 fade-in-0 slide-in-from-bottom-2 fill-mode-forwards">
        {partnerId && partnerProfileImage && (
          <Avatar
            src={partnerProfileImage}
            modalImageSrc={partnerProfileImageForModal}
            className="w-[32px] h-[32px] self-start"
          />
        )}
        <div
          className={cn(
            'flex-1 flex items-end gap-[16px] overflow-hidden',
            partnerId ? 'justify-between' : 'justify-end',
          )}
        >
          <p
            className={cn(
              'chat-message-bubble',
              'py-[10px] px-[16px] rounded-b-[14px] text-B9 break-keep whitespace-pre-line',
              partnerId
                ? 'bg-Gray3 text-Gray11 rounded-tr-[14px]'
                : 'bg-Primary text-Gray2 rounded-tl-[14px] ml-[40px]',
              partnerNick && excludeVoiceNickList.includes(partnerNick)
                ? 'mr-[50px]'
                : '',
            )}
            dangerouslySetInnerHTML={{ __html: message }}
          ></p>

          {partnerNick &&
            (!excludeVoiceNickList.includes(partnerNick) && !hideVoiceButton ? (
              <VoiceButton
                mateId={partnerId}
                messageId={messageId}
                disabled={false}
                audioSrc={audioUrl}
              />
            ) : (
              <div className="w-[32px] h-[32px] flex-shrink-0"></div>
            ))}
        </div>
      </div>
      {messageWithImages && messageWithImages.length > 0 && (
        <div className="flex gap-[6px] overflow-auto hide-scrollbar ml-[42px] -mr-[20px] pr-[20px] animate-in duration-300 fade-in-0 slide-in-from-bottom-2 fill-mode-forwards">
          {messageWithImages.map((img, idx, array) => (
            <div
              key={`image-with-text-${idx + 1}`}
              className="cursor-pointer aspect-square min-w-[240px] w-[82%]"
            >
              <img
                onClick={() => {
                  onImageClick?.(img);
                }}
                className="w-full h-full object-cover rounded-[16px] object-top"
                src={img}
                alt="이미지 메시지"
                width="100%"
                height="100%"
                onLoad={idx === array.length - 1 ? onLastImageLoad : undefined}
                draggable={false}
              />
            </div>
          ))}
        </div>
      )}
      {chatLogMediaList &&
        chatLogMediaList.length > 0 &&
        onMediaClick &&
        !isPaid && (
          <Swiper
            spaceBetween={6}
            modules={[FreeMode, Mousewheel]}
            mousewheel={{
              forceToAxis: true,
            }}
            freeMode={{
              enabled: true,
            }}
            direction="horizontal"
            slidesPerView="auto"
            className="flex overflow-auto hide-scrollbar !ml-[42px] !-mr-[20px] !pr-[20px] animate-in duration-300 fade-in-0 slide-in-from-bottom-2 fill-mode-forwards"
          >
            <SwiperSlide className="!w-[82%] min-w-[240px]">
              {chatLogMedias.mild ? (
                <ChatLogMedia
                  id={chatLogMedias.mild.id}
                  theme={chatLogMedias.mild.partnerMedia.theme}
                  onClick={
                    chatLogMedias.mild.isBlurred
                      ? onMediaClick
                      : () => {
                          onImageClick?.(
                            chatLogMedias.mild?.partnerMedia.media
                              .largeFullUrl ?? '',
                          );
                        }
                  }
                  isBlurred={chatLogMedias.mild.isBlurred}
                  imgSrc={chatLogMedias.mild.partnerMedia.media.mediumFullUrl}
                  isFree={isFreeChatLogMedia}
                />
              ) : (
                <ChatLogMedia
                  id={-1}
                  theme="MILD"
                  imgSrc={getMediaSoldOutImage(partnerNick).mild}
                  isSoldOut
                />
              )}
            </SwiperSlide>
            <SwiperSlide className="!w-[82%] min-w-[240px]">
              {chatLogMedias.sweet ? (
                <ChatLogMedia
                  id={chatLogMedias.sweet.id}
                  theme={chatLogMedias.sweet.partnerMedia.theme}
                  onClick={
                    chatLogMedias.sweet.isBlurred
                      ? onMediaClick
                      : () => {
                          onImageClick?.(
                            chatLogMedias.sweet?.partnerMedia.media
                              .largeFullUrl ?? '',
                          );
                        }
                  }
                  isBlurred={chatLogMedias.sweet.isBlurred}
                  imgSrc={chatLogMedias.sweet.partnerMedia.media.mediumFullUrl}
                  isFree={isFreeChatLogMedia}
                />
              ) : (
                <ChatLogMedia
                  id={-1}
                  theme="SWEET"
                  imgSrc={getMediaSoldOutImage(partnerNick).sweet}
                  isSoldOut
                />
              )}
            </SwiperSlide>
            <SwiperSlide className="!w-[82%] min-w-[240px]">
              {chatLogMedias.hot ? (
                <ChatLogMedia
                  id={chatLogMedias.hot.id}
                  theme={chatLogMedias.hot.partnerMedia.theme}
                  onClick={
                    chatLogMedias.hot.isBlurred
                      ? onMediaClick
                      : () => {
                          onImageClick?.(
                            chatLogMedias.hot?.partnerMedia.media
                              .largeFullUrl ?? '',
                          );
                        }
                  }
                  isBlurred={chatLogMedias.hot.isBlurred}
                  imgSrc={chatLogMedias.hot.partnerMedia.media.mediumFullUrl}
                  onImageLoad={onLastImageLoad}
                  isFree={isFreeChatLogMedia}
                />
              ) : (
                <ChatLogMedia
                  id={-1}
                  theme="HOT"
                  imgSrc={getMediaSoldOutImage(partnerNick).hot}
                  isSoldOut
                  onImageLoad={onLastImageLoad}
                />
              )}
            </SwiperSlide>
          </Swiper>
        )}

      {chatLogMediaList && chatLogMediaList.length > 0 && isPaid && (
        <div className="flex gap-[6px] overflow-auto hide-scrollbar ml-[42px] -mr-[20px] pr-[20px] animate-in duration-300 fade-in-0 slide-in-from-bottom-2 fill-mode-forwards">
          {[...chatLogMediaList].map((media) => (
            <ChatLogMedia
              className="w-[82%] min-w-[240px]"
              key={`chat-log-media-${messageId}-${media.id}`}
              id={media.id}
              theme={media.partnerMedia.theme}
              onClick={() => {
                onImageClick?.(media.partnerMedia.media.largeFullUrl);
              }}
              isBlurred={false}
              imgSrc={media.partnerMedia.media.mediumFullUrl}
              onImageLoad={onLastImageLoad}
            />
          ))}
        </div>
      )}

      <p
        className={cn(
          'text-B11 text-Gray6',
          partnerId ? 'ml-[42px]' : 'text-right',
        )}
      >
        {dayjs(messageSentAt).locale('ko').format('A hh:mm')}
      </p>
    </div>
  );
};

export default ChatBubble;
