import React from 'react';
import { Header, Nav } from '@/components';
import { Outlet } from 'react-router-dom';
import useAuth from '@/hooks/useAuth';
import { type ActiveKeyType } from '@/constants/paths';
interface Props {
  prop?: string;
}

const Main: React.FC<Props> = () => {
  useAuth();

  return (
    <>
      <Header activeKey={location?.pathname as ActiveKeyType} />
      <div className="h-full pt-header pb-bottom-nav px-[20px] pc:py-0 pc:mt-header pc:mb-bottom-nav pc:h-[calc(100%-var(--header-height)-var(--bottom-nav-height))] pc:overflow-auto pc:hide-scrollbar">
        <Outlet />
      </div>
      <Nav activeKey={location?.pathname as ActiveKeyType} />
    </>
  );
};

export default Main;
