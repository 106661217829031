import React, { type PropsWithChildren, useEffect, useState } from 'react';

interface Props extends PropsWithChildren {
  delay?: number; // 단위 : ms
  onShow?: () => void;
}

const Delay: React.FC<Props> = ({ delay, onShow, children }) => {
  const [show, setShow] = useState(!delay || delay <= 0);

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setShow(true);
      }, delay);
    } else {
      onShow?.();
    }
  }, [show, delay, onShow]);

  return <>{show ? children : null}</>;
};

export default Delay;
