import React from 'react';
import { type TabsProps } from './types';
import { Tabs as TabsUI, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';

export default function Tabs(props: TabsProps) {
  const { items, ...rest } = props;

  return (
    <TabsUI className="w-full" {...rest}>
      <TabsList className="grid w-full grid-cols-2">
        {items.map((item, idx) => (
          <TabsTrigger key={idx.toString()} value={item.value}>
            {item.title}
          </TabsTrigger>
        ))}
      </TabsList>
      {items.map((item, idx) => (
        <TabsContent key={idx.toString()} value={item.value}>
          {item.content}
        </TabsContent>
      ))}
    </TabsUI>
  );
}
