import { storageKey } from '@/constants/strings';
import type { CustomerDto } from '@/models/customers';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export interface PersistAuthKeys {
  jlt?: string;
  auth: CustomerDto;
  isLogin: boolean;
  isNotNiceCertificated: boolean;
}
export interface PersistAuthFunctions extends Partial<PersistAuthKeys> {
  setToken: (token?: string) => void;
  setAuth: (authInfo?: CustomerDto) => void;
  setIsLogin: (value: boolean) => void;
  setIsNotNiceCertificated: (value: boolean) => void;
  removeToken: () => void;
  signOut: () => void;
}
export const useAuthStore = create(
  persist<PersistAuthFunctions>(
    (set, get) => ({
      jlt: undefined,
      setToken: (token?: string) => {
        set({ jlt: token });
      },
      setAuth: (authInfo?: CustomerDto) => {
        set({ auth: authInfo });
      },
      setIsLogin: (value: boolean) => {
        set({ isLogin: value });
      },
      setIsNotNiceCertificated: (value: boolean) => {
        set({ isNotNiceCertificated: value });
      },
      removeToken: () => {
        set({ jlt: undefined });
      },
      signOut: () => {
        set({
          auth: undefined,
          isLogin: false,
          jlt: undefined,
          isNotNiceCertificated: false,
        });
        localStorage.removeItem(storageKey.local.disableTooltipInMateDetail);
      },
    }),
    {
      name: 'auth', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
      partialize: (state) => ({ ...state, auth: undefined }),
    },
  ),
);
