import {
  postChatInitialize,
  postChatPurchaseFree,
  postChatRooms,
  putChatRoomJoin,
  putChatRoomsById,
} from '@/apis/chat';
import { getPartnersWithChatRoomsById } from '@/apis/partners';
import { getAlertTextByErrorMessages } from '@/constants/strings';
import useGlobalModal from '@/zustands/useGlobalModal';
// import useVolatileChatStore from '@/zustands/useVolatileChatStore';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export default function useOverwriteChatRoom() {
  const navigate = useNavigate();

  const { showErrorAlert } = useGlobalModal();

  const {
    mutate: getPartnersWithChatRoom,
    isLoading: getPartnersWithChatRoomLoading,
  } = useMutation(getPartnersWithChatRoomsById);

  const { mutate: generateChatRoom, isLoading: generateChatRoomLoading } =
    useMutation(postChatRooms);

  const { mutate: initializeChat, isLoading: initializeChatLoading } =
    useMutation(postChatInitialize);

  const { mutate: putChatRoom, isLoading: putChatRoomLoading } =
    useMutation(putChatRoomsById);

  const { mutate: joinChatRoom, isLoading: joinChatRoomLoading } =
    useMutation(putChatRoomJoin);

  const { mutate: purchaseFreeChat, isLoading: purchaseFreeChatLoading } =
    useMutation(postChatPurchaseFree);

  const navigateChatRoom = useCallback(
    (id: number) => {
      // 채팅방 이동
      navigate(`/chatting/${id}`, {
        replace: true,
      });

      // 비회원 채팅방 데이터 삭제
      // useVolatileChatStore.getState().removeAllChatRoom();
    },
    [navigate],
  );

  // const readAndNavigateChatRoom = useCallback(
  //   (id: number) => {
  //     // 읽음 처리
  //     putChatRoom(
  //       {
  //         id,
  //         requestBody: {
  //           isRead: true,
  //         },
  //       },
  //       {
  //         onSettled: () => {
  //           navigateChatRoom(id);
  //         },
  //       },
  //     );
  //   },
  //   [putChatRoom, navigateChatRoom],
  // );

  const joinAndNavigateChatRoom = useCallback(
    (id: number) => {
      // 선점 시도
      joinChatRoom(id, {
        onSettled: () => {
          navigateChatRoom(id);
        },
      });
    },
    [joinChatRoom, navigateChatRoom],
  );

  const readAndPurchaseFreeChatAndNavigateChatRoom = useCallback(
    (id: number) => {
      // 읽음 처리
      putChatRoom(
        {
          id,
          requestBody: {
            isRead: true,
          },
        },
        {
          onSettled: () => {
            // 무료 채팅권 구매 시도
            purchaseFreeChat(id, {
              onSettled: () => {
                navigateChatRoom(id);
              },
            });
          },
        },
      );
    },
    [putChatRoom, purchaseFreeChat, navigateChatRoom],
  );

  const overwriteChatRoom = useCallback(
    (partnerId: number, initialMessage?: string) => {
      getPartnersWithChatRoom(partnerId, {
        onSuccess: (res) => {
          const { chatRoom } = res.row;
          // 비회원 채팅방의 보낸 메시지가 있는 경우 - 이론상 항상 있음
          if (initialMessage) {
            if (chatRoom) {
              // 기존 채팅방 존재
              if (chatRoom.chatRoomState === 'STARTED') {
                // 시작된 채팅방의 경우, 초기화 가능할 경우 초기화 후, 채팅방 이동
                initializeChat(
                  {
                    chatRoomId: chatRoom.id,
                    language: 'ko',
                    message: initialMessage,
                    personType: 'USER',
                    messageType: 'TEXT',
                    messageSentAt: dayjs().toISOString(),
                  },
                  {
                    onError: (err: any) => {
                      if (
                        err?.response?.data?.message !==
                        'ALREADY_EXIST_FIRST_MESSAGE'
                      ) {
                        // 이미 첫 메시지가 있다는 에러 외에는 alert 표시 후, 해당 채팅방으로 이동
                        const { title, description } =
                          getAlertTextByErrorMessages(
                            err?.response?.data?.message,
                          );
                        showErrorAlert(title, description);
                      }
                    },
                    onSettled: () => {
                      readAndPurchaseFreeChatAndNavigateChatRoom(chatRoom.id);
                    },
                  },
                );
              } else if (chatRoom.chatRoomState === 'ENDED') {
                // 종료된 채팅방의 경우, 읽음 처리 후, 바로 채팅방 이동 + 무료 채팅권 시도
                // readAndNavigateChatRoom(chatRoom.id);
                readAndPurchaseFreeChatAndNavigateChatRoom(chatRoom.id);
              } else {
                // 진행중 채팅방
                // 선점 시도 후, 채팅방 이동
                joinAndNavigateChatRoom(chatRoom.id);
              }
            } else {
              // 기존 채팅방 없음

              // 채팅방 생성
              generateChatRoom(partnerId, {
                onSuccess: (generateChatRoomRes) => {
                  // 채팅 초기화
                  initializeChat(
                    {
                      chatRoomId: generateChatRoomRes.row.id,
                      language: 'ko',
                      message: initialMessage,
                      personType: 'USER',
                      messageType: 'TEXT',
                      messageSentAt: dayjs().toISOString(),
                    },
                    {
                      onError: (err: any) => {
                        const { title, description } =
                          getAlertTextByErrorMessages(
                            err?.response?.data?.message,
                          );
                        showErrorAlert(title, description);
                      },
                      onSettled: () => {
                        readAndPurchaseFreeChatAndNavigateChatRoom(
                          generateChatRoomRes.row.id,
                        );
                      },
                    },
                  );
                },
                onError: (err: any) => {
                  const { title, description } = getAlertTextByErrorMessages(
                    err?.response?.data?.message,
                  );
                  showErrorAlert(title, description);
                },
              });
            }
          } else {
            // 비회원 채팅방의 보낸 메시지가 없는 경우
            if (chatRoom) {
              // 기존 채팅방 존재
              if (chatRoom.chatRoomState === 'ONGOING') {
                // 진행중 채팅방의 경우 선점 시도 후, 채팅방 이동
                joinAndNavigateChatRoom(chatRoom.id);
              } else if (chatRoom.chatRoomState === 'ENDED') {
                // 종료된 채팅방의 경우, 읽음 처리 후, 바로 채팅방 이동 + 무료 채팅권 시도
                // readAndNavigateChatRoom(chatRoom.id);
                readAndPurchaseFreeChatAndNavigateChatRoom(chatRoom.id);
              } else {
                // 시작된 채팅방의 경우, 읽음 처리, 무료 채팅권 시도, 채팅방 이동
                readAndPurchaseFreeChatAndNavigateChatRoom(chatRoom.id);
              }
            } else {
              // 기존 채팅방 없음

              // 채팅방 생성
              generateChatRoom(partnerId, {
                onSuccess: (generateChatRoomRes) => {
                  readAndPurchaseFreeChatAndNavigateChatRoom(
                    generateChatRoomRes.row.id,
                  );
                },
                onError: (err: any) => {
                  const { title, description } = getAlertTextByErrorMessages(
                    err?.response?.data?.message,
                  );
                  showErrorAlert(title, description);
                },
              });
            }
          }
        },
        onError: (err: any) => {
          const { title, description } = getAlertTextByErrorMessages(
            err?.response?.data?.message,
          );
          showErrorAlert(title, description);
        },
      });
    },
    [
      getPartnersWithChatRoom,
      generateChatRoom,
      initializeChat,
      showErrorAlert,
      // readAndNavigateChatRoom,
      joinAndNavigateChatRoom,
      readAndPurchaseFreeChatAndNavigateChatRoom,
    ],
  );

  return {
    isLoading:
      generateChatRoomLoading ||
      initializeChatLoading ||
      putChatRoomLoading ||
      joinChatRoomLoading ||
      getPartnersWithChatRoomLoading ||
      purchaseFreeChatLoading,
    overwriteChatRoom,
  };
}
