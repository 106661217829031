import React from 'react';
import PrevHeader from '@/components/Header/PrevHeader';
import { strings } from '@/constants/strings';
import { providers } from '@/constants/socials';
import { useLocation, useNavigate } from 'react-router-dom';
import { type Provider } from '@/models/customers';
import { paths } from '@/constants/paths';
import { IconChannelTalk, IconRightArrow } from '@/assets/icons';
import { Button } from '@/components';

const DuplicateNiceCertificate = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = (provider: Provider) => {
    if (provider === 'email') {
      navigate(paths.signIn);
    } else {
      window.open(
        `${process.env.REACT_APP_ROOT_URL}oauth/providers/${provider}`,
        '_self',
      );
    }
  };

  return (
    <>
      <PrevHeader title={strings.adultOnly.adultOnlyHeader.ko} />
      <div className="pt-header">
        <section className="px-[20px] py-[32px] space-y-[26px]">
          <div className="space-y-[8px]">
            <h2 className="text-H3 whitespace-pre-line">
              {strings.duplicateCertificate.pageTitle.ko}
            </h2>
            <p className="text-H6M text-Gray6">
              {strings.duplicateCertificate.pageDescription.ko}
            </p>
          </div>
          <div className="flex flex-col gap-[24px]">
            {providers
              .filter((item) => {
                return item?.key === location?.state?.provider.toLowerCase();
              })
              ?.map((item) => (
                <div
                  className="flex items-center justify-between cursor-pointer"
                  key={item?.key}
                  onClick={() => {
                    handleClick(item?.key);
                  }}
                >
                  <div className="flex items-center gap-[14px]">
                    <item.icon width={40} height={40} />
                    <span className="text-H8M">{item?.label}</span>
                  </div>
                  <IconRightArrow width={24} height={24} />
                </div>
              ))}
          </div>
        </section>
        <section>
          <div className="p-[20px]">
            <div className="p-[24px] bg-Gray3 rounded-[24px] flex-col-center gap-[20px]">
              <IconChannelTalk width={64} height={64} />
              <div className="flex-col-center space-y-[16px]">
                <h6 className="text-White text-H6">
                  관련 문의는 채널톡을 이용해주세요
                </h6>

                <Button className="custom-button" size={40}>
                  채널톡으로 문의하기
                </Button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default DuplicateNiceCertificate;
