import type { ListResponse, ObjectResponse } from '@/interface/common';
import {
  type ChatHistoryResponseByIdDto,
  type ChatLog,
  type ChatResponseDto,
  type ChatRoomDto,
  type ChatRoomListDto,
  type GenerateChatFirstMessageDto,
  type GenerateChatMessagesDto,
  type GetChatRoomsParams,
  type ModifyChatRoomDto,
  type GetPaidChatLogMediaParams,
  type ChatLogMediaDto,
} from '@/models/chat';
import request from '../axios';

const url = '/customers/me/chat';

export const postChatRooms = async (partnerId: number) => {
  const { data } = await request<ObjectResponse<ChatRoomDto>>({
    method: 'post',
    url: `${url}-rooms`,
    requestBody: {
      partnerId,
    },
  });
  return data;
};

export const getChatRooms = async (
  queryParams: GetChatRoomsParams = {
    page: 1,
    pageSize: 1000,
  },
) => {
  const { data } = await request<ListResponse<ChatRoomListDto>>({
    method: 'get',
    url: `${url}-rooms`,
    queryParams,
  });

  return data;
};

export const getChatRoomsById = async (id: number) => {
  const { data } = await request<ObjectResponse<ChatRoomDto>>({
    method: 'get',
    url: `${url}-rooms/${id}`,
  });
  return data;
};

export const putChatRoomsById = async ({
  id,
  requestBody,
}: {
  id: number;
  requestBody: ModifyChatRoomDto;
}) => {
  const { data } = await request<ObjectResponse<ChatRoomDto>>({
    method: 'put',
    url: `${url}-rooms/${id}`,
    requestBody,
  });
  return data;
};

export const postChatSend = async (requestBody: GenerateChatMessagesDto) => {
  const { data } = await request<ObjectResponse<ChatResponseDto>>({
    method: 'post',
    url: `${url}/send`,
    requestBody,
  });

  return data;
};

export const getChatRoomsByIdHistories = async (
  id: number,
  queryParams?: {
    chatHistoryId?: number;
    page: number;
    pageSize: number;
  },
) => {
  const { data } = await request<ObjectResponse<ChatResponseDto>>({
    method: 'get',
    url: `${url}-rooms/${id}/histories`,
    queryParams,
  });

  return data;
};

export const postChatInitialize = async (
  requestBody: GenerateChatFirstMessageDto,
) => {
  const { data } = await request<ObjectResponse<ChatLog>>({
    method: 'post',
    url: `${url}/initialize`,
    requestBody,
  });
  return data;
};

export const postChatPurchase = async (chatRoomId: number) => {
  const { data } = await request({
    method: 'post',
    url: `${url}-rooms/${chatRoomId}/purchase`,
  });
  return data;
};

export const postChatPurchaseFree = async (chatRoomId: number) => {
  const { data } = await request({
    method: 'post',
    url: `${url}-rooms/${chatRoomId}/purchase/free`,
  });
  return data;
};

export const putChatRoomJoin = async (chatRoomId: number) => {
  const { data } = await request({
    method: 'put',
    url: `${url}-rooms/${chatRoomId}/join`,
  });
  return data;
};

export const postChatLogsByIdVoice = async (id: number) => {
  const { data } = await request({
    method: 'post',
    url: `/chat-logs/${id}/voice`,
  });

  return data;
};

export const getCustomersMeChatLogsById = async (id: number) => {
  const { data } = await request<ObjectResponse<ChatHistoryResponseByIdDto>>({
    method: 'get',
    url: `${url}-logs/${id}`,
  });
  return data;
};

export const postCustomersMeChatSendBotMessage = async (chatRoomId: number) => {
  const { data } = await request<ObjectResponse<ChatResponseDto>>({
    method: 'post',
    url: `${url}/send/bot-message`,
    requestBody: {
      chatRoomId,
    },
  });
  return data;
};

export const postChatLogMediaPurchase = async ({
  chatLogId,
  chatLogMediaId,
}: {
  chatLogId: number;
  chatLogMediaId: number;
}) => {
  const { data } = await request<ObjectResponse<ChatLog>>({
    method: 'post',
    url: `${url}-logs/${chatLogId}/chat-log-medias/${chatLogMediaId}/purchase`,
  });
  return data;
};

export const getChatLogMediaByPartnerNick = async (
  queryParams: GetPaidChatLogMediaParams = {
    isBlurred: false,
    page: 1,
    pageSize: 1000,
  },
) => {
  const { data } = await request<ListResponse<ChatLogMediaDto>>({
    method: 'get',
    url: `${url}-log-medias/paid`,
    queryParams,
  });
  return data;
};
