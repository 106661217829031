import dayjs from 'dayjs';

export function phoneFormatter(value: string, type?: 0) {
  let formatNum = '';

  const originValue = value
    .replaceAll('-', '')
    .replace(/[^0-9.]/g, '')
    .replace(/(\..*)\./g, '$1');

  if (originValue.length === 11) {
    if (type === 0) {
      formatNum = originValue.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
    } else {
      formatNum = originValue.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    }
  } else if (originValue.length === 8) {
    formatNum = originValue.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else {
    if (originValue.indexOf('02') === 0) {
      if (type === 0) {
        formatNum = originValue.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
      } else {
        formatNum = originValue.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
      }
    } else {
      if (type === 0) {
        formatNum = originValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
      } else {
        formatNum = originValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }
    }
  }

  return formatNum;
}

export const timeFormatter = (
  targetSecond: number,
  withHour?: boolean,
  withSpace?: boolean,
) => {
  const colon = withSpace ? ' : ' : ':';

  let minute = Math.floor(targetSecond / 60);
  const hour = Math.floor(minute / 60);
  if (withHour) {
    minute %= 60;
  }
  const second = targetSecond % 60;

  const hourString = hour < 10 ? `0${hour}` : `${hour}`;
  const minuteString = minute < 10 ? `0${minute}` : `${minute}`;
  const secondString = second < 10 ? `0${second}` : `${second}`;

  return (
    (withHour ? `${hourString}${colon}` : '') +
    `${minuteString}${colon}${secondString}`
  );
};

export function replaceVideoURL(string: string) {
  if (string.includes('youtu.be/')) {
    return string.replace('youtu.be/', 'https://youtube.com/embed/');
  }
  return string.replace('vimeo.com/', 'https://player.vimeo.com/video/');
}

export function isChatRoomPage(pathname: string) {
  const regex = /\/chatting\/(\d+)/g;

  return regex.test(pathname);
}

export function formatLastChatTime(at: string) {
  const day = dayjs(at);
  const now = dayjs();

  if (!day.isSame(now, 'date')) {
    return day.format('YYYY. MM. DD');
  } else {
    return day.format('hh:mm A');
  }
}
export const regex = {
  nick: /^[ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9]+$/,
};

export const phoneNumberConvertor = (phoneNumber: string) => {
  return phoneNumber.startsWith('0') ? phoneNumber : '0' + phoneNumber;
};

export const checkFinalityInKorean = (text?: string) => {
  if (!text) return null;

  const lastLetter = text[text.length - 1];
  const uni = lastLetter.charCodeAt(0);

  if (uni < 44032 || uni > 55203) return null;

  return (uni - 44032) % 28 !== 0;
};

export const getNickWithSuffix = (nick: string) => {
  if (checkFinalityInKorean(nick)) {
    return `${nick}이`;
  } else {
    return nick;
  }
};

export const secondsToMinutes = (s: number) => {
  return Math.floor(s / 60);
};

export const getRemainFreeTimeString = (s: number) => {
  if (s <= 0) {
    return '무료 대화가 종료되었어요!';
  }

  const totalMinutes = secondsToMinutes(s);
  const absoluteSeconds = Math.abs(s);
  if (absoluteSeconds >= 3600) {
    // 1시간 이상 남았을 경우, "00시간 00분"
    const h = Math.floor(totalMinutes / 60);
    const m = totalMinutes % 60;

    return `${h}시간 ${m}분 뒤에 무료 대화가 종료돼요`;
  } else if (absoluteSeconds >= 600) {
    // 1시간 미만 10분 이상 남았을 경우, "00분"
    return `${totalMinutes}분 뒤에 무료 대화가 종료돼요`;
  } else {
    // 10분 미만인 경우, "00분 00초"
    return `${totalMinutes}분 ${
      absoluteSeconds % 60
    }초 뒤에 무료 대화가 종료돼요`;
  }
};

export const getJellyProductsImageSrc = (weight?: number | null) => {
  return `/assets/images/img-jelly-payed-${weight ?? 1}.png`;
};

export const getDayOfTheWeek = (
  dayIndex: number,
  shorten: boolean = false,
): {
  en: string;
  ko: string;
} => {
  switch (dayIndex) {
    case 0:
      if (shorten) {
        return {
          en: 'Sun',
          ko: '일',
        };
      } else {
        return {
          en: 'Sunday',
          ko: '일요일',
        };
      }
    case 1:
      if (shorten) {
        return {
          en: 'Mon',
          ko: '월',
        };
      } else {
        return {
          en: 'Monday',
          ko: '월요일',
        };
      }
    case 2:
      if (shorten) {
        return {
          en: 'Tue',
          ko: '화',
        };
      } else {
        return {
          en: 'Tuesday',
          ko: '화요일',
        };
      }
    case 3:
      if (shorten) {
        return {
          en: 'Wed',
          ko: '수',
        };
      } else {
        return {
          en: 'Wednesday',
          ko: '수요일',
        };
      }
    case 4:
      if (shorten) {
        return {
          en: 'Thu',
          ko: '목',
        };
      } else {
        return {
          en: 'Thursday',
          ko: '목요일',
        };
      }
    case 5:
      if (shorten) {
        return {
          en: 'Fri',
          ko: '금',
        };
      } else {
        return {
          en: 'Friday',
          ko: '금요일',
        };
      }
    case 6:
      if (shorten) {
        return {
          en: 'Sat',
          ko: '토',
        };
      } else {
        return {
          en: 'Saturday',
          ko: '토요일',
        };
      }
    default:
      if (shorten) {
        return {
          en: 'Mon',
          ko: '월',
        };
      } else {
        return {
          en: 'Monday',
          ko: '월요일',
        };
      }
  }
};
