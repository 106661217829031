import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import {
  Home,
  ChatList,
  Root,
  Detail,
  Main,
  OAuth,
  SignUpSuccess,
  HasAccountPage,
  Setting,
  Profile,
  PromotionPage,
  SettingWithdrawal,
  Payments,
  PaymentsSuccess,
  PaymentsNoti,
  ChatRoom,
  ProfileJelly,
  TermsPage,
  TermsDetailPage,
  AdultOnlyPage,
  SignIn,
  SignUpByEmail,
  SignUpTerms,
  SignUpNick,
  ResetPasswordPage,
  NoAccountPage,
  NotFoundPage,
  PaymentsFail,
  Auth,
  DrawerPage,
  PurchaseChatTicketPage,
  AttendancePage,
  RequireNiceCertificatePage,
  DuplicateNiceCertificatePage,
  NotificationPage,
} from '@/pages';
import useWindowSize from './hooks/useWindowSize';

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        element: <Main />,
        children: [
          { path: '', element: <Home /> },
          { path: '/chatting', element: <ChatList /> },
          { path: '/notification', element: <NotificationPage /> },
          { path: '/profile', element: <Profile /> },
        ],
      },
      {
        element: <Auth />,
        children: [
          { path: '/celeb/:id', element: <Detail /> },
          { path: '/chatting/:id', element: <ChatRoom /> },
          { path: '/profile/jelly', element: <ProfileJelly /> },
          { path: '/setting', element: <Setting /> },
          { path: '/setting/withdrawal', element: <SettingWithdrawal /> },
          { path: '/payments', element: <Payments /> },
          { path: '/payments/success', element: <PaymentsSuccess /> },
          { path: '/payments/fail', element: <PaymentsFail /> },
          { path: '/terms', element: <TermsPage /> },
          { path: '/terms/:id', element: <TermsDetailPage /> },
          { path: '/drawer', element: <DrawerPage /> },
          { path: '/purchase/chat', element: <PurchaseChatTicketPage /> },
          { path: '/attendance', element: <AttendancePage /> },
        ],
      },
      { path: '/require-certificate', element: <RequireNiceCertificatePage /> },
      {
        path: '/duplicate-certificate',
        element: <DuplicateNiceCertificatePage />,
      },
      { path: '/adult-only', element: <AdultOnlyPage /> },
      { path: '/oauth', element: <OAuth /> },
      { path: '/no-account', element: <NoAccountPage /> },
      { path: '/reset-password', element: <ResetPasswordPage /> },
      { path: '/signin', element: <SignIn /> },
      { path: '/signup/email', element: <SignUpByEmail /> },
      { path: '/signup/terms', element: <SignUpTerms /> },
      { path: '/signup/nick', element: <SignUpNick /> },
      { path: '/signup/success', element: <SignUpSuccess /> },
      { path: '/has-account', element: <HasAccountPage /> },
      { path: '/payments/noti', element: <PaymentsNoti /> },
      { path: '/promotion/:promotionId', element: <PromotionPage /> },
      { path: '*', element: <NotFoundPage /> },
    ],
  },
]);

export default function App() {
  useWindowSize();
  return <RouterProvider router={router} />;
}
