import React, { useCallback, useRef, useState } from 'react';
import { Button, FooterButton, Input, Modal } from '@/components';
import { strings, queryKeys, storageKey } from '@/constants/strings';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getCustomersCheckDuplicateByNick,
  postCustomersSignupByEmail,
  postCustomersSignupBySocial,
} from '@/apis/customers';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { regex } from '@/utils/strings';
import { yupResolver } from '@hookform/resolvers/yup';
import PrevHeader from '@/components/Header/PrevHeader';
import { type To, useNavigate } from 'react-router-dom';
import { paths } from '@/constants/paths';
import { useAuthStore } from '@/zustands/useAuthStore';
import useSignUpStore from '@/zustands/useSignUpStore';
import useBlocker from '@/hooks/useBlocker';
import { APIError } from '@/models/common';
import useGlobalModal from '@/zustands/useGlobalModal';

const schema = yup
  .object({
    nick: yup.string().matches(regex.nick, {
      message: strings?.message?.nick.ko,
    }),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

export default function SignUpNick() {
  const navigate = useNavigate();
  const signUpEmailMutation = useMutation(postCustomersSignupByEmail);
  const signUpSocialMutation = useMutation(postCustomersSignupBySocial);
  const { data, resetData } = useSignUpStore();
  const { showErrorAlert } = useGlobalModal();
  const { setAuth, setToken, setIsLogin } = useAuthStore();
  const {
    control,
    getValues,
    watch,
    setError,
    clearErrors,
    setValue,
    setFocus,
    formState: { isValid },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      nick: '',
    },
  });

  const { refetch, remove } = useQuery(
    [queryKeys?.nickDuplicateCheck],
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    () => getCustomersCheckDuplicateByNick(watch()?.nick ?? ''),
    { enabled: false && !!watch()?.nick },
  );
  const handleSignupClick = useCallback(
    (formData: FormData) => {
      const isOauth = !!data.body.oauth;

      const emailInfo = data.body.email;
      const oauthInfo = data.body.oauth;

      const commonSignUpData = {
        tokenVersionId: data.tokenVersionId,
        nick: getValues().nick ?? '',
        termsIds: data?.termsIds?.filter((item) => typeof item === 'number'),
        ...(data?.termsIds && {
          isAllowMarketingUse:
            data.termsIds.findIndex((item) => item === 'marketing') > -1,
        }),
      };

      if (isOauth && oauthInfo) {
        signUpSocialMutation.mutate(
          {
            ...oauthInfo,
            ...commonSignUpData,
          },
          {
            onSuccess: (data) => {
              isBlock.current = false;
              resetData();
              localStorage.setItem(
                storageKey.local.provider,
                oauthInfo.provider,
              );

              setAuth(data?.data);
              setToken(data?.token);
              setIsLogin(true);
              navigate(paths.signUpSuccess, {
                replace: true,
                state: {
                  nick: data.data.nick,
                  isFirstSignUp: data.data.isFirstSignUp ?? false,
                },
              });
            },
            onError: (err: any) => {
              const error = new APIError(err);

              if (error.errorCode === 'CONFLICT_CUSTOMER_NICK') {
                setError('nick', {
                  type: 'duplicate',
                  message: strings?.signup?.alreadyUsedNick?.ko,
                });
                return;
              }

              showErrorAlert('회원가입 실패', error.message);
            },
          },
        );
      }

      if (!isOauth && emailInfo) {
        signUpEmailMutation.mutate(
          {
            email: emailInfo.email,
            password: emailInfo.password,
            ...commonSignUpData,
          },
          {
            onSuccess: (data) => {
              isBlock.current = false;
              resetData();
              localStorage.setItem(storageKey.local.provider, 'email');

              setAuth(data?.data);
              setToken(data?.token);
              setIsLogin(true);
              navigate(paths.signUpSuccess, {
                replace: true,
                state: {
                  nick: data.data.nick,
                  isFirstSignUp: data.data.isFirstSignUp ?? false,
                },
              });
            },
            onError: (err: any) => {
              const error = new APIError(err);

              if (error.errorCode === 'CONFLICT_CUSTOMER_NICK') {
                setError('nick', {
                  type: 'duplicate',
                  message: strings?.signup?.alreadyUsedNick?.ko,
                });
                return;
              }

              showErrorAlert('회원가입 실패', error.message);
            },
          },
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, getValues, isValid],
  );
  const handleOnBlur = () => {
    if (!watch()?.nick) return;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    refetch().then((data) => {
      if (data?.status === 'success') {
        clearErrors('nick');
      } else {
        setError('nick', {
          type: 'duplicate',
          message: strings?.signup?.alreadyUsedNick?.ko,
        });
      }
    });
  };

  // 뒤로가기 방지
  const [isBlockAlertOpen, setIsBlockAlertOpen] = useState(false); // 뒤로가기 방지 모달
  const isBlock = useRef<boolean>(true);
  const handleBlock = useCallback((url: To, action: string) => {
    setIsBlockAlertOpen(true);
  }, []);
  useBlocker({
    isBlock,
    browserBackClick: handleBlock,
  });

  return (
    <>
      <PrevHeader title={strings.signUpNick.pageTitle.ko} />
      <section className="mt-header flex flex-col gap-[24px] p-[20px] whitespace-pre-wrap">
        <h2 className="text-H3">{strings.signUpNick.pageDescription.ko}</h2>
        <div className="flex flex-col gap-[16px]">
          <Controller
            name="nick"
            control={control}
            render={({ field, fieldState: { error, invalid } }) => {
              return (
                <Input
                  {...field}
                  label={
                    <p className="text-L2 text-Gray5">
                      {strings.signup.nickname}
                    </p>
                  }
                  placeholder={strings.signup.nicknamePlaceholder}
                  maxLength={8}
                  error={!!error?.type && !!watch().nick}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onPressEnter={handleSubmit(handleSignupClick)}
                  message={error?.message ?? strings?.message?.nick.ko}
                  onChange={(e) => {
                    field?.onChange(e);
                    if (error?.type === 'duplicate') {
                      clearErrors('nick');
                    }
                    remove();
                  }}
                  onBlur={handleOnBlur}
                  autoFocus
                  defaultRightAccessory={!!watch().nick}
                  onDefaultRightAccessoryClick={() => {
                    setValue('nick', '');
                    setTimeout(() => {
                      setFocus('nick');
                    }, 0);
                  }}
                />
              );
            }}
          />
        </div>
      </section>
      <FooterButton
        disabled={!watch().nick || !isValid}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={handleSubmit(handleSignupClick)}
        className="email-signup-nick-footer-button"
      >
        {strings?.signup?.start?.ko}
      </FooterButton>
      <Modal
        open={isBlockAlertOpen}
        onClose={() => {
          setIsBlockAlertOpen(false);
        }}
        title="다음에 가입하시겠어요?"
        description="지금까지 입력했던 정보는 사라져요."
        footer={
          <div className="flex flex-col gap-[12px]">
            <Button
              size={48}
              onClick={() => {
                setIsBlockAlertOpen(false);
              }}
            >
              가입 계속하기
            </Button>
            <Button
              size={48}
              variant="text"
              onClick={() => {
                isBlock.current = false;
                resetData();
                navigate({ pathname: paths.home }, { replace: true });
              }}
            >
              {strings.common.toHome.ko}
            </Button>
          </div>
        }
      />
    </>
  );
}
