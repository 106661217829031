import React, { useMemo } from 'react';

import { NotFoundPage } from '@/pages';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { promotionList } from '@/constants/promotion';
import {
  Button,
  FooterButtonWrapper,
  Header,
  Notice,
  PrevHeader,
} from '@/components';
import { cn } from '@/lib/utils';

const PromotionPage = () => {
  const { promotionId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const promotionInfo = useMemo(() => {
    if (promotionId && promotionList[promotionId]) {
      return promotionList[promotionId];
    } else {
      return undefined;
    }
  }, [promotionId]);

  if (!promotionInfo) return <NotFoundPage />;

  return (
    <>
      {/* 마케팅 진입시 기존 헤더 */}
      {searchParams.size > 0 ? <Header /> : <PrevHeader title="&nbsp;" />}
      <div
        className={cn(
          'pt-header h-full pc:overflow-auto pc:hide-scrollbar',
          promotionInfo.cta ? 'pb-[96px]' : '',
        )}
      >
        <img
          alt="promotion-image"
          src={promotionInfo.imageSrc}
          draggable="false"
        />
        {promotionInfo.notice && (
          <Notice
            className="bg-Gray10 pt-[20px] pb-[60px] px-[20px]"
            title={promotionInfo.notice.title}
            list={promotionInfo.notice.list.map((v) => v.ko)}
          />
        )}
      </div>
      {promotionInfo.cta && (
        <FooterButtonWrapper className="rounded-t-[20px] pb-[32px]">
          <Button
            size={56}
            className="w-full"
            onClick={() => {
              if (promotionInfo.cta?.isExternalLink) {
                window.open(promotionInfo.cta?.linkTo);
              } else {
                navigate(promotionInfo.cta?.linkTo ?? '');
              }
            }}
          >
            {promotionInfo.cta.text}
          </Button>
        </FooterButtonWrapper>
      )}
    </>
  );
};

export default PromotionPage;
