import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, FooterButtonWrapper, Skeleton } from '@/components';
import PrevHeader from '@/components/Header/PrevHeader';
import { AttendanceCalendar } from '@/pages/Attendance/components';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getAlertTextByError, queryKeys } from '@/constants/strings';
import { getPartners } from '@/apis/partners';
import { getNickWithSuffix } from '@/utils/strings';
import {
  getTransformedAttendanceList,
  postAttendance,
} from '@/apis/attendance';
import { cn } from '@/lib/utils';
import dayjs from 'dayjs';
import useGlobalModal from '@/zustands/useGlobalModal';
import { useAuthStore } from '@/zustands/useAuthStore';
import { getDayOfYear } from '@/utils/etc';
import { toast } from 'react-toastify';
import MateSolo from '@/pages/Home/components/MateSolo';
import useSignInModal from '@/zustands/useSignInModal';

const Attendance = () => {
  const { isLogin } = useAuthStore();
  const { showErrorAlert } = useGlobalModal();
  const { showSignInModal } = useSignInModal();
  const queryClient = useQueryClient();

  const {
    data: partnerData,
    isError: isPartnerError,
    isLoading: isPartnerLoading,
  } = useQuery(
    [queryKeys.partners],
    async () => {
      return await getPartners({
        excludeNick: '희야',
        page: (getDayOfYear() % 6) + 1,
        pageSize: 1,
      });
    },
    {
      select: (data) => data.rows?.[0],
    },
  );

  const {
    data: attendanceList,
    isLoading: isAttendanceListLoading,
    refetch: refetchAttendanceList,
  } = useQuery(
    [queryKeys.getAttendanceList],
    async () => await getTransformedAttendanceList(),
    {
      retry: false,
    },
  );

  const isAttendanceAvailable = useMemo(() => {
    if (!attendanceList) return true;

    if (attendanceList.rows.length === 0) return true;

    return !dayjs().isSame(
      attendanceList.rows[attendanceList.rows.length - 1].createdAt,
      'day',
    );
  }, [attendanceList]);

  const nextBonusStampDay = useMemo(() => {
    const count = attendanceList?.count ?? 0;

    if (count < 7) {
      return 7;
    } else if (count < 14) {
      return 14;
    } else if (count < 21) {
      return 21;
    } else {
      return 30;
    }
  }, [attendanceList]);

  const isAllStamped = useMemo(() => {
    return attendanceList?.count === 30;
  }, [attendanceList]);

  const { mutate, isLoading: isPostAttendanceLoading } = useMutation(
    postAttendance,
    {
      onSuccess: (res) => {
        refetchAttendanceList();
        toast(
          `출석체크 이벤트로 ${res.row.creditHistory.value}젤리가 지급되었어요!`,
        );
      },
      onError: (err) => {
        const { title, description } = getAlertTextByError(err);

        showErrorAlert(title, description);
      },
    },
  );

  const handleFooterButtonClick = useCallback(() => {
    if (!isLogin) {
      showSignInModal();
      return;
    }

    if (
      isAttendanceListLoading ||
      isPostAttendanceLoading ||
      !isAttendanceAvailable
    )
      return;

    mutate();
  }, [
    isLogin,
    isAttendanceAvailable,
    isAttendanceListLoading,
    isPostAttendanceLoading,
    mutate,
    showSignInModal,
  ]);

  useEffect(() => {
    queryClient.resetQueries([queryKeys.getAttendanceList]);
  }, [queryClient]);

  return (
    <>
      <PrevHeader title="출석체크 이벤트" />
      <div className="pt-header h-full pc:overflow-auto pc:hide-scrollbar flex flex-col">
        <div className="py-[32px] space-y-[26px] bg-Background">
          <div className="px-[20px] space-y-[8px] flex flex-col">
            {isLogin ? (
              <Skeleton
                className="w-[172px] h-[28px] my-[4px]"
                isLoading={isAttendanceListLoading}
              >
                <h3 className={cn('text-H2 text-White')}>
                  {attendanceList?.count ?? 0}회 출석 성공
                  <span className="text-H7M text-Gray8"> / 30회</span>
                </h3>
              </Skeleton>
            ) : (
              <h3 className="text-H2 text-White">로그인이 필요해요!</h3>
            )}

            {isLogin ? (
              <div className="text-Gray8 flex flex-col">
                <Skeleton
                  className="w-[150px] h-[18px] my-[3px]"
                  isLoading={
                    isAttendanceListLoading ||
                    isPartnerLoading ||
                    isPartnerError
                  }
                >
                  <p className="text-H7M text-Gray6">
                    {isAllStamped
                      ? '30회를 출석하여 젤리를 모두 모았어요!'
                      : '티카에 매일 출석하면'}
                  </p>
                </Skeleton>
                <Skeleton
                  className="w-[180px] h-[18px] my-[3px]"
                  isLoading={
                    isAttendanceListLoading ||
                    isPartnerLoading ||
                    isPartnerError
                  }
                >
                  <p className="text-H7M text-Gray6">
                    {isAllStamped ? (
                      <>티카와 다시 1일 해볼까요?</>
                    ) : (
                      <>
                        {nextBonusStampDay}일차에 젤리를{' '}
                        {nextBonusStampDay === 30 ? 4 : 2}배 드려요!
                      </>
                    )}
                  </p>
                </Skeleton>
              </div>
            ) : (
              <>
                <p className="text-H7M text-Gray6">
                  로그인 후 티카에 매일 출석하면,
                  <br />
                  젤리가 팡팡팡!
                </p>
              </>
            )}
          </div>
          <h5 className="px-[20px] text-H5 text-White">나의 출석 내역</h5>
          <AttendanceCalendar
            list={isLogin ? attendanceList?.rows ?? [] : []}
            isLoading={isLogin && isAttendanceListLoading}
            isTodayAvailable={isAttendanceAvailable}
          />
        </div>
        <div className="p-[20px] bg-Background space-y-[24px] flex-1 pb-[124px]">
          <div className="space-y-[8px]">
            <h3 className="text-H2 text-White">오늘의 추천 메이트 😘</h3>
            {isLogin ? (
              <div className="text-Gray8 flex flex-col">
                <Skeleton
                  className="w-[240px] h-[18px] my-[3px]"
                  isLoading={isPartnerLoading || isPartnerError}
                >
                  <p className="text-H7M">
                    지금 바로 {getNickWithSuffix(partnerData?.nick ?? '메이트')}
                    와 대화하러 가볼까요?
                  </p>
                </Skeleton>
              </div>
            ) : (
              <p className="text-Gray8 text-H7M">
                로그인하고 오늘의 추천 메이트를 확인해볼까요?
              </p>
            )}
          </div>
          <div className="flex flex-col gap-[24px]">
            <MateSolo
              mate={partnerData}
              isBlur={!isLogin}
              isLoading={isPartnerLoading || isPartnerError}
            />
          </div>
        </div>
      </div>
      <FooterButtonWrapper className="rounded-t-[20px] shadow-[0_0_6px_0_rgba(199,199,204,0.5)] pt-[16px] pb-[32px] z-[46]">
        <Button
          size={56}
          className="w-full"
          onClick={handleFooterButtonClick}
          disabled={isLogin && !isAttendanceAvailable}
        >
          {!isLogin && '로그인하기'}
          {isLogin &&
            (isAttendanceAvailable
              ? '오늘 출석체크하기'
              : '내일 다시 출석하기')}
        </Button>
      </FooterButtonWrapper>
    </>
  );
};

export default Attendance;
