import React, { type ReactNode, useCallback } from 'react';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import Button from '../Button';
import { strings } from '@/constants/strings';
import { ImageCarouselInModal } from '@/components';

export interface CarouselImageObject {
  title: string;
  image: string | ReactNode;
}

export interface CarouselModalProps {
  open: boolean;
  onClose: () => void;
  list: CarouselImageObject[];
  initialSlide?: number;
  onClick?: () => void;
}

const CarouselModal: React.FC<CarouselModalProps> = React.memo(
  ({ list, onClick, onClose, open, initialSlide = 0 }) => {
    const handleButtonClick = useCallback(() => {
      onClick?.();
      onClose();
    }, [onClose, onClick]);

    return (
      <Dialog open={open}>
        <DialogContent
          onClose={onClose}
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
        >
          <div className="space-y-[24px] overflow-hidden">
            <ImageCarouselInModal
              className="px-[32.5px]"
              swiperClassName="shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] aspect-[375/614]"
              list={list}
              initialSlide={initialSlide}
              hasKeyboardEvent
            />

            <Button
              className="w-full"
              variant="default"
              size={48}
              onClick={handleButtonClick}
            >
              {strings.common.ok.ko}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  },
);

CarouselModal.displayName = 'CarouselModal';

export default CarouselModal;
