import React, { useCallback, useMemo, useState } from 'react';
import PrevHeader from '@/components/Header/PrevHeader';
import {
  getAlertTextByErrorMessages,
  queryKeys,
  storageKey,
  strings,
} from '@/constants/strings';
import {
  Button,
  FooterButtonWrapper,
  Notice,
  SectionTypeA,
  Tooltip,
} from '@/components';
import {
  IconInfo,
  IconJellyFit,
  IconMyPageCharge,
  IconTooltipClose,
} from '@/assets/icons';
import { useAuthStore } from '@/zustands/useAuthStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from '@/constants/paths';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getProductsChatTickets } from '@/apis/products';
import ChatTicketList from '@/pages/PurchaseChatTicket/components/ChatTicketList';
import { postCustomersMeChatTickets } from '@/apis/customers';
import { type ChatTicketListItemProps } from '@/pages/PurchaseChatTicket/components/ChatTicketListItem';
import useGlobalModal from '@/zustands/useGlobalModal';
import { cn } from '@/lib/utils';
import { toast } from 'react-toastify';
import useCheckAuth from '@/hooks/useCheckAuth';
import colors from '@/constants/colors';
import { gtmNames, gtmPush } from '@/external/ga';

const PurchaseChatTicket = () => {
  const { auth } = useAuthStore();
  const { showErrorAlert } = useGlobalModal();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [selectedChatTicket, setSelectedChatTicket] =
    useState<ChatTicketListItemProps | null>(null);
  const [isOpenTooltip, setIsOpenTooltip] = useState(true);
  const [isTooltipCloseClick, setIsTooltipCloseClick] = useState(false);

  useCheckAuth();

  const { data: productChatTicketData } = useQuery(
    [queryKeys.getProductsChatTickets],
    {
      queryFn: getProductsChatTickets,
    },
  );

  const { mutate, isLoading } = useMutation(postCustomersMeChatTickets);

  const disabled = useMemo(() => {
    if (!selectedChatTicket) return true;

    return (
      (auth?.creditCount ?? 0) <
      (selectedChatTicket.discountPrice
        ? Number(selectedChatTicket.discountPrice)
        : Number(selectedChatTicket.price))
    );
  }, [auth, selectedChatTicket]);

  const closeTooltip = useCallback(() => {
    setIsOpenTooltip(false);
  }, [setIsOpenTooltip]);

  const openTooltip = useCallback(() => {
    setIsOpenTooltip(true);
    setIsTooltipCloseClick(false);
  }, [setIsOpenTooltip, setIsTooltipCloseClick]);

  const handleJellyChargeClick = useCallback(() => {
    navigate(paths.payments);
    sessionStorage.setItem(storageKey.session.redirectUrl, location.pathname);
  }, [navigate, location]);

  const handlePurchaseChatTicket = useCallback(() => {
    if (!selectedChatTicket) {
      return;
    }

    gtmPush(gtmNames.event.CHATTICKET_purchaseButtonClick, {
      [gtmNames.parameter.productId]: selectedChatTicket.title,
    });

    mutate(selectedChatTicket.title, {
      onSuccess: () => {
        const redirectUrl = sessionStorage.getItem(
          storageKey.session.redirectUrlAfterPurchase,
        );

        if (redirectUrl) {
          navigate(redirectUrl);
          sessionStorage.removeItem(
            storageKey.session.redirectUrlAfterPurchase,
          );
        } else {
          navigate(paths.profile);
        }
        toast(strings.purchase.toast.ko);
        queryClient.invalidateQueries([queryKeys.me]);
      },
      onError: (err: any) => {
        const { title, description } = getAlertTextByErrorMessages(
          err?.response?.data?.message,
        );
        showErrorAlert(title, description);
      },
    });
  }, [navigate, mutate, selectedChatTicket, showErrorAlert, queryClient]);

  return (
    <>
      <PrevHeader title={strings.purchase.headerTitle.ko} />
      <div className="pt-header pc:overflow-auto pc:hide-scrollbar h-full">
        <div className="flex flex-col h-full">
          <SectionTypeA
            className="gap-[24px] [&>h2]:text-H3"
            title={strings.purchase.heading.ko}
          >
            <div className="p-[20px] bg-Gray4 w-full rounded-[14px] flex items-center justify-between">
              <div className="space-y-[4px]">
                <h5 className="text-H9M text-White">
                  {strings.jelly.myJelly.ko}
                </h5>
                <h4 className="text-H4 text-Primary flex items-center gap-[8px]">
                  <IconJellyFit
                    className="flex-shrink-0"
                    width={11}
                    height={14}
                  />
                  {auth?.creditCount} {strings.common.jelly.ko}
                </h4>
              </div>
              <Button
                size={32}
                className="flex-center gap-[4px]"
                onClick={handleJellyChargeClick}
              >
                <IconMyPageCharge fill={colors.Gray2} width={16} height={16} />
                {strings.jelly.jellyCharge.ko}
              </Button>
            </div>
          </SectionTypeA>
          <SectionTypeA
            className="gap-[16px]"
            title={
              <div className="flex items-center gap-[4px]">
                {strings.purchase.chatTicketListTitle.ko}{' '}
                <div className="relative">
                  <div onClick={openTooltip} className="cursor-pointer">
                    <IconInfo />
                  </div>
                  {isOpenTooltip && (
                    <Tooltip
                      className={cn(
                        '-right-[186px] flex items-center gap-[8px] text-[12px] leading-[16px] font-medium fill-mode-forwards -top-[1px]',
                        isTooltipCloseClick
                          ? 'slide-out-to-left-2 fade-out animate-out'
                          : 'slide-in-from-left-2 fade-in animate-in',
                      )}
                      arrowDirection="left"
                      tooltipArrowSize="md"
                      onAnimationEnd={() => {
                        if (isTooltipCloseClick) {
                          closeTooltip();
                        }
                      }}
                    >
                      {strings.purchase.tooltip.ko}
                      <IconTooltipClose
                        width={16}
                        height={16}
                        className="flex-shrink-0 cursor-pointer"
                        onClick={() => {
                          setIsTooltipCloseClick(true);
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
            }
          >
            <ChatTicketList
              initialSelectedIndex={0}
              list={
                productChatTicketData?.rows?.map((item) => ({
                  title: item.name,
                  imageSrc: '/assets/images/img-chat-ticket-product.png',
                  price: item.originalCredit,
                  discountPrice: item.discountCredit,
                })) ?? []
              }
              onSelect={(item) => {
                setSelectedChatTicket(item);
              }}
            />
          </SectionTypeA>
          <div className="p-[20px] pb-[116px] flex-1">
            <Notice
              title={strings.common.notice.ko}
              list={strings.purchase.noticeList.map((notice) => notice.ko)}
            />
          </div>
        </div>
      </div>
      <FooterButtonWrapper>
        <Button
          onClick={handlePurchaseChatTicket}
          disabled={disabled}
          loading={isLoading}
          size={56}
          className="w-full"
        >
          {disabled
            ? strings.purchase.disableButtonText.ko
            : strings.purchase.headerTitle.ko}
        </Button>
      </FooterButtonWrapper>
    </>
  );
};

export default PurchaseChatTicket;
