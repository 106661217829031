import { type SignInModalProps } from '@/components/Modal/SignInModal';
import { create } from 'zustand';

interface SignInModalKeys extends Omit<SignInModalProps, 'onClose'> {
  showSignInModal: (props?: Partial<Omit<SignInModalProps, 'onClose'>>) => void;
  closeSignInModal: () => void;
}

const initialSignInModalProps: Omit<SignInModalProps, 'onClose'> = {
  open: false,
  replace: undefined,
  onClick: undefined,
};

export const useSignInModal = create<SignInModalKeys>()((set, get) => ({
  open: false,
  onClose: () => {},
  showSignInModal: (props) => {
    set({ ...get(), ...props, open: true });
  },
  closeSignInModal: () => {
    set({ ...initialSignInModalProps });
  },
}));
export default useSignInModal;
