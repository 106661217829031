import React, { useEffect } from 'react';
import { FooterButton } from '@/components';
import { strings } from '@/constants/strings';
import { useNavigate } from 'react-router-dom';
import { paths } from '@/constants/paths';
import useSignUpStore from '@/zustands/useSignUpStore';
import { useAuthStore } from '@/zustands/useAuthStore';
import PrevHeader from '@/components/Header/PrevHeader';

const AdultOnlyPage = () => {
  const navigate = useNavigate();
  const { resetData } = useSignUpStore();
  const { removeToken, setIsLogin, setAuth } = useAuthStore();

  useEffect(() => {
    // 회원가입, 로그인 정보 모두 초기화
    removeToken();
    setIsLogin(false);
    setAuth(undefined);
    resetData();
  }, [removeToken, setIsLogin, setAuth, resetData]);

  const onClickButton = () => {
    navigate(paths.home, { replace: true });
  };

  return (
    <>
      <PrevHeader title="본인인증" />
      <div className="pt-header">
        <section className="p-[20px] pt-[24px] space-y-[12px]">
          <h2 className="text-H3 whitespace-pre-line text-White">
            {strings.adultOnly.adultOnlyTitle.ko}
          </h2>
          <p className="text-H6M text-Gray8">
            {strings.adultOnly.adultOnlyDescription.ko}
          </p>
        </section>
        <FooterButton onClick={onClickButton}>
          {strings.common.toHome.ko}
        </FooterButton>
      </div>
    </>
  );
};

export default AdultOnlyPage;
