import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import LogoWithSlogan from '@/assets/images/pc-root-logo-slogan.png';
// import { ReactComponent as Apple } from '@/assets/socials/apple.svg';
// import { ReactComponent as PlayStore } from '@/assets/socials/playstore.svg';
import { useConfigStore } from '@/zustands/useConfigStore';
import { paths } from '@/constants/paths';
import { ToastContainer } from 'react-toastify';
import { IconCopyright, IconWarningFillGray } from '@/assets/icons';
import { useAuthStore } from '@/zustands/useAuthStore';
import GlobalLoading from '@/components/Lottie/GlobalLoading';
import { Modal, SignInModal } from '@/components';
import useGlobalModal from '@/zustands/useGlobalModal';
import { externalLinks, queryKeys } from '@/constants/strings';
import { useQueryClient } from '@tanstack/react-query';
import useSignInModal from '@/zustands/useSignInModal';

const Introduction = () => {
  return (
    <div className="flex-col justify-center pc:flex hidden basis-[482px] flex-grow-0 flex-shrink">
      <div className="flex flex-col h-full max-h-[688.5px] py-[20px] justify-center gap-[80px]">
        <div>
          <img alt="logo-with-slogan" src={LogoWithSlogan} width={178} />
        </div>
        {/* <div className="flex items-center gap-[24px]">
      <a className="flex items-center gap-[8px] py-[16px] px-[20px] bg-white text-White shadow-[0_4px_32px_0_rgba(53,58,63,0.1)] rounded-[100px] text-B6 font-medium">
        <Apple width={24} height={24} />
        App Store
      </a>
      <a className="flex items-center gap-[8px] py-[16px] px-[20px] bg-white text-White shadow-[0_4px_32px_0_rgba(53,58,63,0.1)] rounded-[100px] text-B6 font-medium">
        <PlayStore width={24} height={24} />
        Google Play
      </a>
    </div> */}
        <div className="flex flex-col gap-[12px]">
          <span className="text-H7">(주)슬로그업</span>
          <div className="flex flex-col gap-[4px] text-H9M">
            <div className="flex items-center">
              <span>사업자 등록번호 : 777-88-00374</span>
              <hr className="border-r-Gray2 border-t-0 border-r h-[16px] mx-[12px]" />
              <span>대표 : 김상천</span>
            </div>
            <span>서울특별시 마포구 성지3길 74 장석빌딩 (주)슬로그업</span>
            <span>74, Seongji 3-gil, Mapo-gu</span>
            <span>Seoul, Republic of Korea</span>
          </div>
          <div>
            <p className="text-H9M text-White">
              제휴 및 기타문의 :{' '}
              <a className="text-H9M" href="mailto:tica.slogup@gmail.com">
                tica.slogup@gmail.com
              </a>
            </p>
          </div>
          <div className="flex items-center text-H9 text-White">
            <a
              href={externalLinks.termsOfService}
              target={'_blank'}
              rel="noreferrer"
            >
              서비스 이용약관
            </a>
            <hr className="border-r-Gray2 border-t-0 border-r h-[16px] mx-[12px]" />
            <a
              href={externalLinks.privacyPolicy}
              target={'_blank'}
              rel="noreferrer"
            >
              개인정보 처리방침
            </a>
          </div>
          <div className="flex gap-[4px] items-center mt-[12px]">
            <IconCopyright />
            <span className="text-H9M text-Gray6">
              2024 Slogup. All Rights Reserved.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

interface Props {
  prop?: string;
}

const Root: React.FC<Props> = () => {
  const location = useLocation();
  const { hideGlobalModal, open, ...props } = useGlobalModal();
  const { auth, signOut } = useAuthStore();
  const {
    open: signInModalOpen,
    replace,
    onClick,
    closeSignInModal,
  } = useSignInModal();
  const queryClient = useQueryClient();
  const onBackgroundClick = useCallback(() => {
    const overlayList = document.getElementsByClassName('dialog-overlay');

    if (overlayList.length > 0) {
      (overlayList[overlayList.length - 1] as HTMLElement).click();
    }
  }, []);

  const { isMobile } = useConfigStore();
  useEffect(() => {
    window.addEventListener('click', onBackgroundClick);

    return () => {
      window.removeEventListener('click', onBackgroundClick);
    };
  }, [onBackgroundClick]);

  useLayoutEffect(() => {
    const ChannelIO = window.ChannelIO;
    if (ChannelIO) {
      if (!isMobile) {
        // PC, 무조건 채널톡 켜짐
        ChannelIO('boot', {
          pluginKey: process.env.REACT_APP_CHAANEL,
          customLauncherSelector: '.custom-button',
          ...(auth && { profile: { name: auth.nick } }),
        });
      } else {
        // 모바일
        if (
          location.pathname !== paths.withdrawal &&
          location.pathname !== paths.profile &&
          location.pathname !== paths.duplicateCertificate
        ) {
          // 회원탈퇴 제외하고 셧다운
          ChannelIO('shutdown');
        } else {
          // 회원탈퇴 채널톡 켜짐
          ChannelIO('boot', {
            pluginKey: process.env.REACT_APP_CHAANEL,
            customLauncherSelector: '.custom-button',
            hideChannelButtonOnBoot: true,
            ...(auth && { profile: { name: auth.nick } }),
          });
        }
      }
    }
  }, [isMobile, location, auth]);

  useEffect(() => {
    if (
      location.pathname !== '/require-certificate' &&
      useAuthStore.getState().isNotNiceCertificated
    ) {
      signOut();
      queryClient.removeQueries([queryKeys.me]);
    }
  }, [location, signOut, queryClient]);

  return (
    <>
      <ScrollRestoration getKey={(location) => location.pathname} />
      <div
        className="flex flex-1 gap-[80px] pc:justify-center bg-Gray1 pc:h-full"
        onClick={onBackgroundClick}
      >
        <Introduction />
        <div
          id="mobile-wrapper"
          className="relative w-full max-w-max min-w-min mx-auto pc:m-0 bg-Background pc:h-full pc:outline-1 pc:outline-Gray3 pc:outline"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <GlobalLoading />
          <ToastContainer
            className={'max-w-max'}
            style={{
              left: 'auto',
              transform: 'none',
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              padding: 0,
            }}
            toastClassName={() =>
              'bg-White rounded-[25px] py-[12px] px-[16px] w-[calc(100%-40px)]'
            }
            icon={() => <IconWarningFillGray width={16} height={16} />}
            bodyClassName={() => 'flex items-center text-H7 text-Gray2'}
            position="top-center"
            autoClose={1000}
            hideProgressBar
            closeButton={false}
            limit={1}
            closeOnClick={false}
            draggable={false}
          />
          <Outlet />
          <Modal onClose={hideGlobalModal} open={open} {...props} />
          <SignInModal
            open={signInModalOpen}
            replace={replace}
            onClick={onClick}
            onClose={closeSignInModal}
          />
        </div>
      </div>
    </>
  );
};

export default Root;
