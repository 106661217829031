export default {
  dim: 'rgba(0, 0, 0, 0.20)',
  Transparent: 'transparent',
  Current: 'currentColor',
  Background: '#1A1B1B',
  White: '#ffffff',
  Primary: '#ffffff',
  Primary1: '#ffffff',
  Primary2: '#ffffff',
  Primary3: '#ffffff',
  Primary4: '#ffffff',
  Primary5: '#ffffff',
  Primary6: '#ffffff',
  Primary7: '#ffffff',
  Primary8: '#ffffff',
  Primary9: '#ffffff',
  Gray1: '#000000',
  Gray2: '#1C1C1E',
  Gray3: '#3A3A3C',
  Gray4: '#48484A',
  Gray5: '#636366',
  Gray6: '#8E8E93',
  Gray7: '#AEAEB2',
  Gray8: '#C7C7CC',
  Gray9: '#EFEFF0',
  Gray10: '#F5F5F6',
  Gray11: '#FCFCFC',
  Gray12: '#FFFFFF',
  Red1: '#BF4040',
  Red2: '#D64C4C',
  Red3: '#E68686',
  Red4: '#F2B2B2',
  Red5: '#FAD8D8',
  Red6: '#FFEFEF',
  Yellow1: '#E1AE00',
  Yellow2: '#FFCC00',
  Yellow3: '#FFD633',
  Yellow4: '#FFE066',
  Yellow5: '#FFF0B3',
  Yellow6: '#FFF7D9',
  Green1: '#0A9A32',
  Green2: '#15BD66',
  Green3: '#35DB80',
  Green4: '#64E8A4',
  Green5: '#93EDBF',
  Green6: '#EBFAEF',
  Blue1: '#1B7FDC',
  Blue2: '#3993E7',
  Blue3: '#55A2EA',
  Blue4: '#8EC1F1',
  Blue5: '#C6E0F8',
  Blue6: '#EEF7FF',
};
