import { Button } from '@/components';
import { type ModalProps } from '@/components/Modal';
import React, { type ReactNode } from 'react';
import { create } from 'zustand';

interface ModalFunctions {
  showGlobalModal: (props: ModalProps) => void;
  hideGlobalModal: () => void;
  showErrorAlert: (
    title: ReactNode,
    description: ReactNode,
    onClick?: () => void,
  ) => void;
}

interface GlobalModalProps extends ModalProps, ModalFunctions {}

const initialModalProps: ModalProps = {
  open: false,
  title: undefined,
  description: undefined,
  body: undefined,
  primaryButtonText: '가입 계속하기',
  cancelButtonText: '뒤로가기',
  primaryButtonVariant: 'default',
  cancelButtonVariant: 'text',
  onPrimaryClick: undefined,
  onCancelClick: undefined,
  onClickLoading: undefined,
  onClose: undefined, // undefined일 때, 필수 모달
  footer: undefined,
  buttonDirection: 'col',
  buttonOrder: 'primary2cancel',
};

export const useGlobalModal = create<GlobalModalProps>()((set, get) => ({
  open: false,
  showGlobalModal: (props) => {
    set({ ...initialModalProps, ...props, open: true });
  },
  hideGlobalModal: () => {
    set({ open: false });
  },
  showErrorAlert: (title, description, onClick) => {
    set({
      ...initialModalProps,
      body: undefined,
      open: true,
      title,
      description,
      footer: (
        <>
          <Button
            variant={'default'}
            size={48}
            onClick={() => {
              if (onClick) onClick();
              get().hideGlobalModal();
            }}
          >
            확인
          </Button>
        </>
      ),
    });
  },
}));
export default useGlobalModal;
