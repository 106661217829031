import { cn } from '@/lib/utils';
import React, {
  useState,
  type HTMLAttributes,
  type PropsWithChildren,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { useParams } from 'react-router-dom';

interface CategoryDto {
  title: string;
  id: number;
}

const Category = ({
  title,
  selected,
  onClick,
}: CategoryDto & {
  selected: boolean;
  onClick: (ref: React.RefObject<HTMLDivElement>) => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const onClickWrapper = useCallback(() => {
    onClick(ref);
  }, [ref, onClick]);

  useEffect(() => {
    if (selected) {
      ref.current?.click();
    }
  }, [selected]);

  return (
    <div
      ref={ref}
      className={cn(
        'flex-shrink-0 py-[10px] flex items-center cursor-pointer transition-colors',
      )}
      onClick={onClickWrapper}
    >
      <span
        className={cn(selected ? 'text-White text-H7' : 'text-Gray6 text-H7M')}
      >
        {title}
      </span>
    </div>
  );
};

interface Props extends PropsWithChildren, HTMLAttributes<HTMLDivElement> {
  categories: CategoryDto[];
  onCategoryChange: (category: CategoryDto) => void;
  isLoading: boolean;
}

const CategoryFilter = ({
  className,
  categories,
  onCategoryChange,
  isLoading,
}: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [selectedCategory, setSelectedCategory] = useState<CategoryDto>();
  const selectedIndex = useRef<number>(-1);
  const [selectedPosition, setSelectedPosition] = useState<{
    left: string | number;
    width: string | number;
  }>({
    left: 0,
    width: 0,
  });

  useEffect(() => {
    if (selectedCategory) {
      onCategoryChange(selectedCategory);
    }
  }, [selectedCategory, onCategoryChange]);

  const { name } = useParams();

  useEffect(() => {
    selectedIndex.current = -1;
  }, [name]);

  const render = useCallback((category: CategoryDto, idx: number) => {
    return (
      <Category
        key={category.id}
        {...category}
        selected={selectedIndex.current === idx}
        onClick={(ref) => {
          setSelectedPosition({
            left: ref.current?.offsetLeft ?? 0,
            width: ref.current?.offsetWidth ?? 0,
          });
          setSelectedCategory(category);

          selectedIndex.current = idx;
        }}
      />
    );
  }, []);

  return (
    <div
      ref={wrapperRef}
      className={cn(
        'flex items-stretch gap-[20px] overflow-auto hide-scrollbar relative border-b border-b-Gray9 -mx-[20px] px-[20px]',
        className,
      )}
    >
      <Category
        title="전체"
        id={-1}
        selected={selectedIndex.current === -1}
        onClick={(ref) => {
          setSelectedPosition({
            left: ref.current?.offsetLeft ?? 0,
            width: ref.current?.offsetWidth ?? 0,
          });
          setSelectedCategory({ title: '전체', id: -1 });

          selectedIndex.current = -1;
        }}
      />
      {!isLoading && categories.map(render)}
      <div
        className="absolute h-[2px] bottom-0 w-[100px] bg-Gray2 transition-all duration-200"
        style={{ left: selectedPosition.left, width: selectedPosition.width }}
      ></div>
    </div>
  );
};

export default CategoryFilter;
