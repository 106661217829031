import {
  Avatar as AvatarBase,
  AvatarFallback,
  AvatarImage,
} from '@/components/ui/avatar';
import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';
import React, { useState } from 'react';
import ImageModal from '../Modal/ImageModal';

interface Props {
  className?: string;
  src: string;
  hasModal?: boolean;
  modalImageSrc?: string;
  lists?: React.ReactNode[];
}

const Avatar: React.FC<Props> = ({
  className,
  src,
  hasModal = true,
  modalImageSrc,
  lists = [],
}) => {
  const [imageModal, setImageModal] = useState(false);

  const handleImageClick = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>,
  ) => {
    setImageModal(true);
  };

  const handleClose = () => {
    setImageModal(false);
  };

  return (
    <>
      {hasModal && (
        <ImageModal
          open={imageModal}
          onClose={handleClose}
          lists={
            lists?.length > 0
              ? lists
              : [<img alt="avatar" src={modalImageSrc ?? src} key={src} />]
          }
        />
      )}
      <AvatarBase
        className={cn('w-full h-full', hasModal && 'cursor-pointer', className)}
      >
        <AvatarImage
          src={src}
          onClick={(e) => {
            if (!hasModal) return;
            handleImageClick(e);
          }}
          draggable={false}
          alt="mate-profile-avatar"
        />
        <AvatarFallback>
          <Skeleton className="w-full h-full" />
        </AvatarFallback>
      </AvatarBase>
    </>
  );
};

export default Avatar;
