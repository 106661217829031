import { IconInVisible, IconVisible } from '@/assets/icons';
import { Input } from '@/components';
import { type InputProps } from '@/components/Input';
import useToggle from '@/hooks/useToggle';
import React, { forwardRef } from 'react';

interface Props extends InputProps {
  enableIcon: boolean;
}

const Password: React.FC<Props> = forwardRef<HTMLInputElement, Props>(
  ({ enableIcon = true, ...props }, ref) => {
    const [visible, toggleVisible] = useToggle();

    const renderRightAccessory = () => {
      return (
        enableIcon && (
          <div
            className="cursor-pointer"
            // peer-focus 해제가 onClick 보다 선행되기 때문에 더 앞단인 mousedown 이벤트에 실행
            onMouseDown={toggleVisible}
          >
            {visible ? (
              <IconInVisible width={24} height={24} />
            ) : (
              <IconVisible width={24} height={24} />
            )}
          </div>
        )
      );
    };

    return (
      <Input
        ref={ref}
        {...props}
        rightAccessory={renderRightAccessory()}
        maxLength={20}
        type={visible ? 'text' : 'password'}
      />
    );
  },
);
Password.displayName = 'Password';

export default Password;
