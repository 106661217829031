import React, { useEffect } from 'react';
import { IconRightArrow } from '@/assets/icons';
import { strings } from '@/constants/strings';
import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from '@/constants/paths';
import useSignUpStore from '@/zustands/useSignUpStore';
import { providers } from '@/constants/socials';

export default function HasAccountPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = (provider: 'kakao' | 'google' | 'email') => {
    if (provider === 'email') {
      navigate(paths.signIn);
    } else {
      window.open(
        `${process.env.REACT_APP_ROOT_URL}oauth/providers/${provider}`,
        '_self',
      );
    }
  };

  useEffect(() => {
    useSignUpStore.getState().resetData();
  }, []);

  return (
    <div className="flex flex-col py-[32px] px-[20px] gap-[26px]">
      <div className="flex flex-col gap-[8px]">
        <h1 className="text-H3">{strings?.signup?.alreadyHasAccount}</h1>
        <p className="whitespace-pre text-H7M text-Gray8">
          {strings?.signup?.alreadyHasAccountDescription}
        </p>
      </div>
      <div className="flex flex-col gap-[24px]">
        {providers
          ?.filter((item) => {
            return item?.key === location?.state?.provider.toLowerCase();
          })
          ?.map((item) => (
            <div
              className="flex items-center justify-between cursor-pointer"
              key={item?.key}
              onClick={() => {
                handleClick(item?.key);
              }}
            >
              <div className="flex items-center gap-[14px]">
                <item.icon width={40} height={40} />
                <span className="text-H8M">{item?.label}</span>
              </div>
              <IconRightArrow width={24} height={24} />
            </div>
          ))}
      </div>
    </div>
  );
}
