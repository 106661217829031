import { useCallback, useEffect, useRef, useState } from 'react';

const useTimer = (defaultTime: number, callback?: () => void) => {
  const [time, setTime] = useState(defaultTime);
  const intervalIdRef = useRef<NodeJS.Timer>();
  const [isStart, setIsStart] = useState(false);

  const handleStart = useCallback(() => {
    setIsStart(true);
  }, []);
  const handleStop = useCallback(() => {
    setIsStart(false);
  }, []);
  const handleReset = useCallback(
    (time?: number) => {
      setIsStart(true);
      setTime(time ?? defaultTime);
    },
    [defaultTime],
  );

  useEffect(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }

    intervalIdRef.current = setInterval(() => {
      if (defaultTime === 0) {
        return;
      }

      if (isStart && time > 0) {
        setTime((prev) => prev - 1);
      } else if (time === 0) {
        if (callback) {
          callback();
        }

        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
        }
      }
    }, 1000);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [isStart, defaultTime, time, callback]);

  return {
    time,
    isStart,
    start: handleStart,
    stop: handleStop,
    reset: handleReset,
  };
};

export default useTimer;
