import { cn } from '@/lib/utils';
import React, { type ReactNode, useEffect, useState, useRef } from 'react';
import Lottie, { type LottieRefCurrentProps } from 'lottie-react';
import DotLoading from '@/assets/lottie/dot-loading.json';
import { Avatar } from '@/components';
import _ from 'lodash';

interface Props {
  partnerProfileImage: string;
  partnerProfileImageForModal?: string;
  enableRandomInOutAnimation?: boolean;
  isLongResponse?: boolean;
  text?: string | ReactNode;
}

const PartnerResponseLoading: React.FC<Props> = ({
  partnerProfileImage,
  partnerProfileImageForModal,
  enableRandomInOutAnimation = true,
  isLongResponse = false,
  text,
}) => {
  const [visible, setVisible] = useState(true);
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  useEffect(() => {
    // 표시 시간 3~4초
    // 숨김 시간 1~1.5초
    const randomNumber = _.random(
      visible ? 3000 : 1000,
      visible ? 4000 : 1500,
      false,
    );
    setTimeout(() => {
      setVisible(!visible);
    }, randomNumber);
  }, [visible]);

  useEffect(() => {
    lottieRef.current?.setSpeed(2);
  }, []);

  return (
    <div className="flex items-end gap-[8px] overflow-hidden animate-in duration-300 fade-in-0 slide-in-from-bottom-2 fill-mode-forwards">
      <Avatar
        src={partnerProfileImage}
        modalImageSrc={partnerProfileImageForModal}
        className="w-[32px] h-[32px] self-start"
      />
      <div>
        {isLongResponse ? (
          <div className="py-[10px] px-[16px] rounded-tr-[14px] rounded-b-[14px] bg-Gray3 w-full min-w-[182px] max-w-[240px] flex flex-col gap-[10px]">
            <p className="break-keep text-B9 text-Gray11">{text}</p>
            <div className="w-full h-[10px] p-px bg-Primary8 rounded-[50px]">
              <div className="bg-Primary h-full rounded-[50px] animate-progress-bar-loop"></div>
            </div>
          </div>
        ) : (
          <div
            className={cn(
              'w-[62px] h-[38px] rounded-tr-[14px] rounded-b-[14px] bg-Gray3 text-Gray11 relative fill-mode-forwards duration-300',
              enableRandomInOutAnimation &&
                (visible
                  ? 'animate-in slide-in-from-left-2 fade-in-0'
                  : 'animate-out slide-out-to-left-2 fade-out-0'),
            )}
          >
            <Lottie
              className="w-[62px] absolute top-0 bottom-0 right-0 left-0"
              size={34}
              animationData={DotLoading}
              loop
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PartnerResponseLoading;
