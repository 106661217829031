import { cn } from '@/lib/utils';
import React, { useEffect, useRef, useState } from 'react';
import { type JellyItemProps } from '../JellyItem/types';
import { JellyItem } from '@/components';

interface Props {
  className?: string;
  list: JellyItemProps[];
  onSelect: (jellyItem: JellyItemProps) => void;
  initialSelectedIndex?: number;
}

const JellyList = ({
  className,
  list,
  onSelect,
  initialSelectedIndex,
}: Props) => {
  const divRef = useRef<HTMLDivElement>(null);

  const [selectedItem, setSelectedItem] = useState<{
    index: number;
    props: JellyItemProps;
  } | null>(null);

  useEffect(() => {
    if (selectedItem) {
      onSelect(selectedItem?.props);
    }
  }, [selectedItem, onSelect]);

  useEffect(() => {
    if (
      !selectedItem &&
      initialSelectedIndex &&
      list.length > initialSelectedIndex
    ) {
      setSelectedItem({
        index: initialSelectedIndex,
        props: list[initialSelectedIndex],
      });
    }
  }, [initialSelectedIndex, selectedItem, list]);

  return (
    <div
      ref={divRef}
      className={cn('relative flex flex-col gap-[16px]', className)}
    >
      {list.map((props, idx) => {
        const isSelected = selectedItem?.index === idx;

        return (
          <JellyItem
            key={`jelly-products-${idx}`}
            {...props}
            selected={isSelected}
            onClick={() => {
              props.onClick?.();
              setSelectedItem({
                index: idx,
                props,
              });
            }}
          />
        );
      })}
    </div>
  );
};

export default JellyList;
