import React, { type HTMLAttributes, type ReactNode } from 'react';
import { cn } from '@/lib/utils';

interface Data {
  key: string;
  icon: ReactNode;
  data: number | string;
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  dataList: Data[];
  isLoading?: boolean;
}

const RemainIndicator = ({ className, dataList, isLoading }: Props) => {
  if (isLoading) {
    return null;
  }

  return (
    <div
      className={cn(
        'rounded-[64px] px-[12px] h-[32px] border-[0.5px] border-Gray3 bg-Background flex-center gap-[12px]',
        dataList.length === 1 && '!justify-start',
        className,
      )}
    >
      {dataList.map((data, idx) => {
        return (
          <div key={data.key} className="flex-center gap-[12px]">
            <div className="flex-center gap-[8px]">
              {data.icon}
              <span className="text-White text-H9M">{data.data}</span>
            </div>
            {idx !== dataList.length - 1 && (
              <div className="w-px h-[12px] bg-Gray3"></div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default RemainIndicator;
