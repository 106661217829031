import React, { useEffect } from 'react';
import { Button, FooterButtonWrapper } from '@/components';
import { paths } from '@/constants/paths';
import { strings } from '@/constants/strings';
import { Link, useSearchParams } from 'react-router-dom';
import useGlobalModal from '@/zustands/useGlobalModal';
import useBlockBrowserBack from '@/hooks/useBlockBrowserBack';
import { IconInfo, IconPaymentsFail } from '@/assets/icons';
import useCheckAuth from '@/hooks/useCheckAuth';
import colors from '@/constants/colors';

export default function PaymentsFailPage() {
  const [searchParams] = useSearchParams();
  const message = searchParams.get('message');
  const productName = searchParams.get('productName');
  const tradeAmount = searchParams.get('tradeAmount');

  const { showErrorAlert } = useGlobalModal();

  useBlockBrowserBack();
  useCheckAuth();

  useEffect(() => {
    showErrorAlert(
      strings.payments.paymentFailureModalTitle.ko,
      strings.payments.paymentFailureModalDescription.ko,
    );
  }, [showErrorAlert]);

  return (
    <>
      <section className="flex flex-col items-center gap-[32px] px-[20px] py-[60px]">
        <div className="flex flex-col gap-[24px] text-center w-full items-center">
          <figure className="w-[64px] h-[64px]">
            <IconPaymentsFail width={64} height={64} fill={colors.Background} />
          </figure>
          <div className="flex flex-col gap-[8px]">
            <h1 className="text-D6 text-White">
              {strings.payments.paymentFailurePageTitle.ko}
            </h1>
            <p className="text-H7M text-Gray8 whitespace-pre">
              {strings.payments.paymentFailurePageDescription.ko}
            </p>
          </div>
        </div>
        <div className="w-full space-y-[18px]">
          <ol className="flex flex-col gap-[12px]">
            <li className="flex gap-[4px] items-center justify-between">
              <h3 className="text-H6M">
                {strings?.payments?.purchasedJelly?.ko}
              </h3>
              <p className="text-H6M">{productName}</p>
            </li>
            <li className="flex gap-[4px] items-center justify-between">
              <h3 className="text-H6M">
                {strings?.payments?.paymentAmount?.ko}
              </h3>
              <p className="text-H6M">
                {Number(tradeAmount).toLocaleString()}원
              </p>
            </li>
          </ol>
          <div className="rounded-[12px] p-[20px] bg-Gray10 space-y-[8px]">
            <div className="flex items-center gap-[4px]">
              <IconInfo width={20} height={20} />
              <span className="text-H8M text-Gray2">
                {strings.payments.paymentFailureReason.ko}
              </span>
            </div>
            <p className="text-Gray2 text-B9">{message}</p>
          </div>
        </div>
      </section>
      <FooterButtonWrapper className="flex items-center gap-[8px]">
        <Link to={paths.home} className="block flex-1">
          <Button variant="outline" className="w-full" size={56}>
            {strings.common.toHome.ko}
          </Button>
        </Link>
        <Link to={paths.payments} className="block flex-1">
          <Button className="w-full" size={56}>
            {strings.payments.paymentRetry.ko}
          </Button>
        </Link>
      </FooterButtonWrapper>
    </>
  );
}
