import type { ListResponse, ObjectResponse } from '@/interface/common';
import type {
  CouponDto,
  CreditHistoryDto,
  CustomerDto,
  EmailSignUpDto,
  GetCreditHistoriesParams,
  LoginDto,
  ModifyCustomerByMeDto,
  ModifyCustomerPasswordByAuthTokenDto,
  ModifyCustomerPasswordByNiceTokenVersionIdDto,
  Provider,
  SingUpResponse,
  SocialLoginDto,
  SocialSignUpDto,
} from '@/models/customers';
import request from '../axios';

const url = '/customers';

export const postCustomersSignupBySocial = async (
  requestBody: SocialSignUpDto,
) => {
  const { data, headers } = await request<ObjectResponse<SingUpResponse>>({
    method: 'post',
    url: `${url}/signup/social`,
    requestBody,
  });

  return {
    data: data.row,
    token: headers.authorization,
  };
};

export const postCustomersSignupByEmail = async (
  requestBody: EmailSignUpDto,
) => {
  const { data, headers } = await request<ObjectResponse<SingUpResponse>>({
    method: 'post',
    url: `${url}/signup/email`,
    requestBody,
  });

  return {
    data: data.row,
    token: headers.authorization,
  };
};

export const postCustomersLogin = async (requestBody: SocialLoginDto) => {
  const { data, headers } = await request<ObjectResponse<CustomerDto>>({
    method: 'post',
    url: `${url}/login`,
    requestBody,
  });

  return {
    data: data.row,
    token: headers.authorization,
  };
};

export const postCustomersLoginByEmail = async (requestBody: LoginDto) => {
  const { data, headers } = await request<ObjectResponse<CustomerDto>>({
    method: 'post',
    url: `${url}/login/email`,
    requestBody,
  });

  return {
    data: data.row,
    token: headers.authorization,
  };
};

export const postCustomersMeLogout = async (requestBody: any) => {
  await request({ method: 'post', url: `${url}/me/logout` });
};

export const getCustomersMe = async () => {
  const { data } = await request<ObjectResponse<CustomerDto>>({
    method: 'get',
    url: `${url}/me`,
  });
  return data;
};

export const getCustomersCheckDuplicate = async (
  type: 'EMAIL' | 'NICK',
  key: string,
) => {
  const { data } = await request({
    method: 'get',
    url: `${url}/check/duplicate`,
    queryParams: {
      type,
      key,
    },
  });

  return data;
};

export const getCustomersCheckDuplicateByNick = async (nick: string) => {
  return await getCustomersCheckDuplicate('NICK', nick);
};

export const getCustomersCheckDuplicateByEmail = async (email: string) => {
  return await getCustomersCheckDuplicate('EMAIL', email);
};

export const getCustomersProviderByNice = async (tokenVersionId: string) => {
  const { data } = await request<
    ObjectResponse<{ provider: Provider; email?: string | null }>
  >({
    method: 'get',
    url: `${url}/provider/v2/${tokenVersionId}`,
  });

  return data;
};

export const putCustomersMe = async (requestBody: ModifyCustomerByMeDto) => {
  const { data } = await request<ObjectResponse<CustomerDto>>({
    method: 'put',
    url: `${url}/me`,
    requestBody,
  });

  return data;
};

export const getCustomerMeCreditHistories = async (
  queryParams: GetCreditHistoriesParams,
) => {
  const { data } = await request<ListResponse<CreditHistoryDto>>({
    method: 'get',
    url: `${url}/me/credit-histories`,
    queryParams,
  });
  return data;
};

export const deleteCustomersMeProfileMedia = async () => {
  await request({ method: 'delete', url: `${url}/me/profile-media` });
};

export const putCustomersMeNiceCertification = async ({
  tokenVersionId,
  tokenOnDemand,
}: {
  tokenVersionId: string;
  tokenOnDemand?: string;
}) => {
  const { data } = await request<ObjectResponse<CustomerDto>>(
    {
      method: 'put',
      url: `${url}/me/nice-certification?tokenVersionId=${encodeURIComponent(
        tokenVersionId,
      )}`,
    },
    undefined,
    undefined,
    tokenOnDemand,
  );

  return data;
};

export const putCustomersPasswordResetByAuthToken = async (
  requestBody: ModifyCustomerPasswordByAuthTokenDto,
) => {
  const { data } = await request<ObjectResponse<CustomerDto>>({
    method: 'put',
    url: `${url}/password-reset-via-auth-token`,
    requestBody,
  });

  return data;
};

export const putCustomersPasswordResetByNiceTokenVersionId = async (
  requestBody: ModifyCustomerPasswordByNiceTokenVersionIdDto,
) => {
  const { data } = await request<ObjectResponse<CustomerDto>>({
    method: 'put',
    url: `${url}/password-reset-via-nice-token-version-id`,
    requestBody,
  });

  return data;
};

export const postCustomersCoupon = async (code: string) => {
  const { data } = await request<ObjectResponse<CouponDto>>({
    method: 'post',
    url: `${url}/me/coupons`,
    requestBody: {
      code,
    },
  });

  return data;
};

export const deleteCustomersMe = async () => {
  await request({ method: 'delete', url: `${url}/me` });
};

export const postCustomersMeChatTickets = async (name: string) => {
  await request({
    method: 'post',
    url: `${url}/me/chat-tickets/${name}`,
  });
};
