import {
  postChatPurchaseFree,
  postChatRooms,
  putChatRoomJoin,
  putChatRoomsById,
} from '@/apis/chat';
import {
  IconBang,
  IconChatBubble,
  IconPausedCurrent,
  IconPlayCurrent,
} from '@/assets/icons';
import {
  Button,
  ImageModal,
  FooterButtonWrapper,
  Accordion,
  StaticVoiceButton,
  Skeleton,
  PromotionModal,
} from '@/components';
import React, { useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import useGlobalModal from '@/zustands/useGlobalModal';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { queryKeys, strings } from '@/constants/strings';
import { getPartnerById, getPartnersWithChatRoomsById } from '@/apis/partners';
import { useAuthStore } from '@/zustands/useAuthStore';
import { paths } from '@/constants/paths';
import { checkFinalityInKorean } from '@/utils/strings';
import useOverwriteChatRoom from '@/hooks/useOverwriteChatRoom';
import PrevHeader from '@/components/Header/PrevHeader';
import colors from '@/constants/colors';
import useIsUtm from '@/hooks/useIsUtm';
import useSignInModal from '@/zustands/useSignInModal';

interface Props {
  prop?: string;
}

const DetailPage: React.FC<Props> = () => {
  const { id } = useParams();

  const partnerId = parseInt(id ?? '0');
  const navigate = useNavigate();
  const { state } = useLocation();
  const { showErrorAlert, showGlobalModal, hideGlobalModal } = useGlobalModal();
  const { isLogin } = useAuthStore();
  const { overwriteChatRoom: enterChatRoom } = useOverwriteChatRoom();
  const isUtm = useIsUtm();

  const [imageModalState, setImageModalState] = useState<{
    visible: boolean;
    lists: React.ReactNode[];
    index: number;
  }>({
    visible: false,
    lists: [],
    index: 0,
  });
  const [isNoticeOpen, setIsNoticeOpen] = useState<string>();

  const { showSignInModal } = useSignInModal();

  const {
    data: chatRoomsData,
    isLoading: isChatRoomDataLoading,
    isSuccess,
  } = useQuery(
    [queryKeys.getPartnersWithChatRoomsById, partnerId],
    // eslint-disable-next-line @typescript-eslint/return-await
    async () => await getPartnersWithChatRoomsById(parseInt(id ?? '0')),
    { enabled: isLogin },
  );

  const { mutate: putChatRooms, isLoading: isPutChatRoomsLoading } =
    useMutation(putChatRoomsById);
  const { mutate: joinChatRoom, isLoading: isJoinChatRoomLoading } =
    useMutation(putChatRoomJoin);
  const { mutate: purchaseFreeChat, isLoading: isPurchaseFreeChatLoading } =
    useMutation(postChatPurchaseFree);
  const { mutate: generateChatRoom, isLoading: isGenerateChatRoomLoading } =
    useMutation(postChatRooms, {
      onSuccess: (res) => {
        putChatRooms(
          {
            id: res.row.id,
            requestBody: {
              isRead: true,
            },
          },
          {
            onSettled: () => {
              purchaseFreeChat(res.row.id, {
                onSettled: () => {
                  navigate(`/chatting/${res.row.id}`);
                  setTimeout(() => {
                    showGlobalModal({
                      title: strings.chat.firstNoticeModalTitle.ko,
                      description: undefined,
                      body: (
                        <div className="space-y-[10px]">
                          {strings.chat.firstNoticeModalDescription.map(
                            (desc) => (
                              <div key={desc.key} className="space-y-[10px]">
                                <h6 className="text-H9 text-Primary6">
                                  {desc.title.ko}
                                </h6>
                                <p className="text-B11 text-Gray8 whitespace-pre-line break-keep">
                                  {desc.description.ko}
                                </p>
                              </div>
                            ),
                          )}
                        </div>
                      ),
                      footer: (
                        <Button size={48} onClick={hideGlobalModal}>
                          {strings.chat.firstNoticeModalButtonText.ko}
                        </Button>
                      ),
                    });
                  }, 300);
                },
              });
            },
          },
        );
      },
      onError: (err: any) => {
        showErrorAlert('채팅방 생성 실패', err.response.data.translate);
      },
    });

  const { data, isLoading: isLoadingPartner } = useQuery(
    [`${queryKeys?.partners}/${id}`],
    // eslint-disable-next-line @typescript-eslint/promise-function-async
    () => getPartnerById(Number(id)),
    { enabled: !!id },
  );

  const handleImageClick = (lists: React.ReactNode[], index: number) => {
    setImageModalState({
      visible: true,
      lists,
      index,
    });
  };

  const handleGoChatButtonClick = useCallback(() => {
    if (!isLogin) {
      // 비회원
      showSignInModal({
        replace: true,
      });
      return;
    }

    if (
      isLoadingPartner ||
      (!!isLogin && isChatRoomDataLoading) ||
      isPutChatRoomsLoading ||
      isJoinChatRoomLoading ||
      isPurchaseFreeChatLoading ||
      isGenerateChatRoomLoading
    ) {
      // 로딩 중
      return;
    }

    if (isSuccess && chatRoomsData?.row?.chatRoom) {
      const id = chatRoomsData.row.chatRoom.id;

      if (chatRoomsData.row.chatRoom.chatRoomState === 'ONGOING') {
        // 진행중인 채팅방 이라면, 선점 시도
        joinChatRoom(id, {
          onSettled: () => {
            navigate(`/chatting/${id}`);
          },
        });
      } else {
        // 시작된, 종료된 채팅방
        // 무료 채팅 시도
        purchaseFreeChat(id, {
          onSettled: () => {
            // 읽음 처리
            putChatRooms(
              {
                id,
                requestBody: { isRead: true },
              },
              {
                onSettled: () => {
                  navigate(`/chatting/${id}`);
                },
              },
            );
          },
        });
      }
    } else {
      generateChatRoom(partnerId);
    }
  }, [
    chatRoomsData,
    isLogin,
    isSuccess,
    partnerId,
    joinChatRoom,
    purchaseFreeChat,
    putChatRooms,
    navigate,
    generateChatRoom,
    showSignInModal,
    isLoadingPartner,
    isChatRoomDataLoading,
    isPutChatRoomsLoading,
    isJoinChatRoomLoading,
    isPurchaseFreeChatLoading,
    isGenerateChatRoomLoading,
  ]);

  useEffect(() => {
    if (data?.row?.status === 'COMING_SOON') {
      navigate(paths.home, { replace: true });
    }
  }, [data, navigate]);

  useEffect(() => {
    if (isLogin && partnerId && state?.isRightAfterSignIn) {
      enterChatRoom(partnerId);
    }
  }, [isLogin, state, partnerId, enterChatRoom]);

  return (
    <>
      <PrevHeader
        className="bg-Background"
        title="&nbsp;"
        onPrevClick={() => {
          if (isUtm) {
            navigate(paths.home);
            return;
          }

          navigate(-1);
        }}
      />
      <div className="px-[20px] h-full pc:overflow-auto pc:hide-scrollbar">
        <div className="bg-Background aspect-[375/320] -mx-[20px] relative">
          <div
            className="aspect-[335/320] overflow-hidden rounded-[16px] absolute left-[20px] right-[20px] top-header animate-in fade-in slide-in-from-bottom-2 duration-700 fill-mode-forwards"
            style={{ transitionDelay: '500ms' }}
          >
            <Skeleton
              className="w-full h-full"
              isLoading={
                isLoadingPartner || (!!isLogin && isChatRoomDataLoading)
              }
            >
              <img
                className="block w-full h-full object-cover"
                src={data?.row.coverMedia?.largeFullUrl}
                alt="mate-cover-image"
                width="100%"
                draggable={false}
              />
            </Skeleton>
            <div className="absolute h-1/2 w-full left-0 p-[20px] text-D5 text-White bottom-0 flex items-end rounded-b-[16px] bg-gradient-to-b from-[#00000000] to-[#000000CC]">
              <Skeleton isLoading={isLoadingPartner}>
                <span>
                  {data?.row?.nick} / {data?.row?.partnerInfo?.age}
                  {strings.common.yearsOld.ko}
                </span>
              </Skeleton>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-[16px] pb-[176px]">
          <div className="flex flex-col">
            <div className="flex flex-col">
              <div className="w-full h-auto aspect-[335/62]"></div>
              <div className="pt-[20px] mb-[16px]">
                <p className="text-H7M text-White whitespace-pre-line">
                  {data?.row.partnerInfo?.detailDescription}
                </p>
              </div>
              <div className="flex items-center justify-between gap-[8px] mb-[32px] py-[11px] px-[19px] rounded-[8px] border border-Primary">
                {isLoadingPartner || (!!isLogin && isChatRoomDataLoading) ? (
                  <div className="space-y-[4px] w-full">
                    <Skeleton
                      className="w-[70%] h-[18px] rounded-[4px] bg-Primary7"
                      isLoading
                    ></Skeleton>
                    <Skeleton
                      className="w-[85%] h-[18px] rounded-[4px] bg-Primary7"
                      isLoading
                    ></Skeleton>
                  </div>
                ) : (
                  <p className="flex-1 whitespace-pre-line text-White text-[14px] leading-[20px] font-medium overflow-hidden [display:-webkit-box] [-webkit-box-orient:vertical] [-webkit-line-clamp:2]">
                    {data?.row?.dailyMessage}
                  </p>
                )}
                <div>
                  {data?.row.dailyMessageVoiceMedia?.bufferFullUrl && (
                    <StaticVoiceButton
                      audioSrc={data?.row.dailyMessageVoiceMedia?.bufferFullUrl}
                      className="w-[32px] h-[32px] flex-center rounded-full transition-[filter] bg-Primary pointerhover:hover:brightness-105"
                      renderPlayIcon={
                        <IconPlayCurrent
                          fill={colors.Gray2}
                          width={10}
                          height={12}
                        />
                      }
                      renderPauseIcon={
                        <IconPausedCurrent
                          fill={colors.Gray2}
                          width={11}
                          height={12}
                        />
                      }
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="h-[12px] bg-[#222529] -mx-[20px]"></div>

            <div className="space-y-[12px] pt-[20px]">
              <Skeleton
                className="h-[30px] w-[116px]"
                isLoading={
                  isLoadingPartner || (!!isLogin && isChatRoomDataLoading)
                }
              >
                <h6 className="text-D6 text-White">
                  {data?.row.nick}의 일상 📸
                </h6>
              </Skeleton>

              {isLoadingPartner || (!!isLogin && isChatRoomDataLoading) ? (
                <div className="flex gap-[12px] overflow-hidden -mx-[20px] px-[20px]">
                  <Skeleton
                    className="rounded-[12px] aspect-[220/292] min-w-[220px] w-[65.67%] flex-shrink-0"
                    isLoading
                  ></Skeleton>
                  <Skeleton
                    className="rounded-[12px] aspect-[220/292] min-w-[220px] w-[65.67%] flex-shrink-0"
                    isLoading
                  ></Skeleton>
                </div>
              ) : (
                <Swiper
                  spaceBetween={12}
                  modules={[FreeMode, Mousewheel]}
                  freeMode={{
                    enabled: true,
                  }}
                  mousewheel={{ forceToAxis: true }}
                  slidesPerView="auto"
                  className="!-mr-[20px] !pr-[20px]"
                >
                  {data?.row.partnerMedias.map(({ media }, index, array) => (
                    <SwiperSlide
                      key={media.id}
                      className="!aspect-[220/292] min-w-[220px] !w-[65.67%] !rounded-[12px] overflow-hidden cursor-pointer"
                      onClick={() => {
                        handleImageClick(
                          array.map((item) => (
                            <img
                              key={item.media?.id}
                              width="100%"
                              alt="intro"
                              src={item.media?.bufferFullUrl}
                              draggable={false}
                            />
                          )),
                          index,
                        );
                      }}
                    >
                      <img
                        alt="mate-feed-image"
                        src={media.mediumFullUrl}
                        width="100%"
                        className="w-full h-full object-cover"
                        draggable={false}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </div>
          </div>
        </div>
      </div>

      <ImageModal
        open={imageModalState?.visible}
        lists={imageModalState?.lists}
        initialSlide={imageModalState?.index}
        onClose={() => {
          setImageModalState({ index: 0, lists: [], visible: false });
        }}
        hasKeyboardEvent={true}
      />
      {isNoticeOpen === '1' && (
        <div
          className="dialog-overlay absolute bottom-0 top-0 left-0 right-0 bg-dim z-[45] animate-in fade-in-0"
          onClick={() => {
            if (isNoticeOpen === '1') {
              setIsNoticeOpen('0');
            }
          }}
        >
          {/* dim */}
        </div>
      )}
      <FooterButtonWrapper className="rounded-t-[20px] bg-Gray1 shadow-[0_4px_4px_0_rgba(0,0,0,0.25)] pt-[16px] pb-[32px] z-[46]">
        <Accordion
          value={isNoticeOpen}
          setValue={setIsNoticeOpen}
          itemClassName="border-b-0"
          triggerClassName="[&[data-state=closed]>svg]:rotate-180 [&[data-state=closed]_svg.accordion-chevron-icon]:rotate-180 [&[data-state=open]>svg]:rotate-0 [&[data-state=open]_svg.accordion-chevron-icon]:rotate-0 py-0 mb-[24px]"
          contentClassName="pb-[12px]"
          renderTitle={() => (
            <div className="flex items-center gap-[6px] text-B9 text-White">
              <IconBang fill="white" width={18} height={18} />
              {strings.mateDetail.chatNotice.title.ko}
            </div>
          )}
        >
          <ul className="space-y-[8px] list-disc pl-[1em]">
            {strings.mateDetail.chatNotice.list.map((item, idx) => (
              <li
                key={`notice-${idx + 1}`}
                className="text-Gray6 text-B9 break-keep"
              >
                {item.linkTo ? (
                  <a
                    href={item.linkTo}
                    target="_blank"
                    rel="noreferrer"
                    key={`notice-link-${item.ko}`}
                    className="underline underline-offset-2 text-Blue2 font-medium"
                  >
                    {item.ko}
                  </a>
                ) : (
                  item.ko
                )}
              </li>
            ))}
          </ul>
        </Accordion>
        <Button
          className="w-full flex items-center justify-center gap-[10px]"
          size={56}
          variant="outline"
          onClick={handleGoChatButtonClick}
          loading={
            isLoadingPartner ||
            (!!isLogin && isChatRoomDataLoading) ||
            isPutChatRoomsLoading ||
            isJoinChatRoomLoading ||
            isPurchaseFreeChatLoading ||
            isGenerateChatRoomLoading
          }
        >
          <IconChatBubble width={24} height={24} />
          {data?.row.nick
            ? `${data.row.nick}${
                checkFinalityInKorean(data.row.nick) ? '이랑' : '랑'
              } ${isLogin ? '' : strings.common.free.ko} ${
                strings.mateDetail.goDateButtonTextWithMateNickName.ko
              }`
            : strings.mateDetail.goDateButtonText.ko}
        </Button>
      </FooterButtonWrapper>
      <PromotionModal
        buttonText={strings.common.ok.ko}
        clearSearchParam={false}
      />
    </>
  );
};

export default DetailPage;
