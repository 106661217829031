import React, {
  type HTMLAttributes,
  type ReactNode,
  useRef,
  useState,
  useCallback,
} from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  audioSrc: string;
  renderPlayIcon: ReactNode;
  renderPauseIcon: ReactNode;
}

const StaticVoiceButton: React.FC<Props> = ({
  className,
  audioSrc,
  renderPlayIcon,
  renderPauseIcon,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlay, setIsPlay] = useState(false);

  const onClickButton = useCallback(() => {
    if (isPlay) {
      setIsPlay(false);
      audioRef?.current?.pause();
    } else {
      setIsPlay(true);
      audioRef?.current?.play();
    }
  }, [isPlay, audioRef]);

  const onEndAudio = useCallback(() => {
    setIsPlay(false);
  }, []);

  return (
    <button className={className} onClick={onClickButton}>
      {isPlay ? renderPauseIcon : renderPlayIcon}
      <audio
        ref={audioRef}
        className="hidden"
        src={audioSrc}
        onEnded={onEndAudio}
      />
    </button>
  );
};

export default StaticVoiceButton;
