import React from 'react';
import {
  Button as ButtonBase,
  type ButtonProps as ButtonBaseProps,
} from '@/components/ui/button';
import Lottie from 'lottie-react';
import DotLoadingWhite from '@/assets/lottie/dot-loading-white.json';

interface ButtonProps extends ButtonBaseProps {
  loading?: boolean;
}

const Button = ({ ...props }: ButtonProps) => {
  return (
    <ButtonBase {...props} onClick={!props.loading ? props.onClick : undefined}>
      {props?.loading && (
        <Lottie
          className="w-[72px] absolute"
          animationData={DotLoadingWhite}
          loop
          size={62}
        />
      )}
      <div
        className={props.className}
        style={{
          visibility: props?.loading ? 'hidden' : 'visible',
        }}
      >
        {props?.children}
      </div>
    </ButtonBase>
  );
};

Button.displayName = 'Button';

export default Button;
