import React, { useEffect, useRef, useState } from 'react';
import { cn } from '@/lib/utils';
import ChatTicketListItem, {
  type ChatTicketListItemProps,
} from '@/pages/PurchaseChatTicket/components/ChatTicketListItem';

interface Props {
  className?: string;
  list: ChatTicketListItemProps[];
  onSelect: (jellyItem: ChatTicketListItemProps) => void;
  initialSelectedIndex?: number;
}

const ChatTicketList = ({
  className,
  list,
  onSelect,
  initialSelectedIndex,
}: Props) => {
  const divRef = useRef<HTMLDivElement>(null);

  const [selectedItem, setSelectedItem] = useState<{
    index: number;
    props: ChatTicketListItemProps;
  } | null>(null);

  useEffect(() => {
    if (selectedItem) {
      onSelect(selectedItem?.props);
    }
  }, [selectedItem, onSelect]);

  useEffect(() => {
    if (
      !selectedItem &&
      initialSelectedIndex !== undefined &&
      list.length > initialSelectedIndex
    ) {
      setSelectedItem({
        index: initialSelectedIndex,
        props: list[initialSelectedIndex],
      });
    }
  }, [initialSelectedIndex, selectedItem, list]);

  return (
    <div
      ref={divRef}
      className={cn('relative flex flex-col gap-[16px]', className)}
    >
      {list.map((props, idx) => {
        const isSelected = selectedItem?.index === idx;

        return (
          <ChatTicketListItem
            key={`jelly-products-${idx}`}
            {...props}
            selected={isSelected}
            onClick={() => {
              props.onClick?.();
              setSelectedItem({
                index: idx,
                props,
              });
            }}
          />
        );
      })}
    </div>
  );
};

export default ChatTicketList;
