import React, { type PropsWithChildren } from 'react';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { IconCloseCurrent } from '@/assets/icons';
import { cn } from '@/lib/utils';

export interface BasicModalProps extends PropsWithChildren {
  open: boolean;
  dialogContentClassName?: string;
  onClose: () => void;
  withCloseButton?: boolean;
}

const BasicModal: React.FC<BasicModalProps> = React.memo(
  ({ open, dialogContentClassName, onClose, children, withCloseButton }) => {
    return (
      <Dialog open={open}>
        <DialogContent
          onClose={onClose}
          className={cn(
            'bg-Gray1 border border-Gray8 border-opacity-30',
            dialogContentClassName,
          )}
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
        >
          {withCloseButton && (
            <button
              className="absolute right-[20px] top-[20px]"
              onClick={onClose}
            >
              <IconCloseCurrent
                stroke={
                  dialogContentClassName?.includes('bg-Gray')
                    ? 'white'
                    : 'black' // TODO: 개선 필요
                }
                width={24}
                height={24}
              />
            </button>
          )}
          <div
            className={cn('space-y-[24px]', withCloseButton ? 'mt-[32px]' : '')}
          >
            {children}
          </div>
        </DialogContent>
      </Dialog>
    );
  },
);

BasicModal.displayName = 'BasicModal';

export default BasicModal;
