import React from 'react';
import { FooterButton, Input } from '@/components';
import PrevHeader from '@/components/Header/PrevHeader';
import { storageKey, strings } from '@/constants/strings';
import { useForm } from 'react-hook-form';
import schemas from '@/constants/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate } from 'react-router-dom';
import { paths } from '@/constants/paths';
import useGlobalModal from '@/zustands/useGlobalModal';
import { useMutation } from '@tanstack/react-query';
import {
  getCustomersProviderByNice,
  postCustomersLoginByEmail,
} from '@/apis/customers';
import { type InferType } from 'yup';
import { useAuthStore } from '@/zustands/useAuthStore';
import Password from '@/components/Input/Password';
import useNiceCertificate from '@/hooks/useNiceCertificate';
import useSignUpStore from '@/zustands/useSignUpStore';
import useRedirectNavigate from '@/hooks/useRedirectNavigate';

type FormData = InferType<typeof schemas.signIn>;

const SignIn = () => {
  const { showErrorAlert } = useGlobalModal();
  const { setAuth, setToken, setIsLogin } = useAuthStore();
  const { resetData } = useSignUpStore();
  const navigate = useNavigate();
  const redirectNavigate = useRedirectNavigate();

  const { mutate: getProviders } = useMutation(getCustomersProviderByNice);

  const { showNicePopup, renderNiceForm } = useNiceCertificate({
    onSuccess: (tokenVersionId) => {
      getProviders(tokenVersionId, {
        onSuccess: (res) => {
          const provider = res.row.provider;

          if (provider === 'email') {
            navigate(paths.resetPassword, {
              state: {
                email: res.row.email ?? '',
                tokenVersionId,
              },
            });
          } else {
            navigate(paths.hasAccount, {
              state: {
                provider,
              },
            });
          }
        },
        onError: () => {
          navigate(paths.noAccount);
        },
      });
    },
    onFail: () => {
      navigate(paths.adultOnly);
    },
  });

  const { mutate } = useMutation(postCustomersLoginByEmail, {
    onSuccess: (data) => {
      localStorage.setItem(storageKey.local.provider, 'email');
      setAuth(data?.data);
      setToken(data?.token);
      setIsLogin(true);

      if (!data.data.isAdult) {
        navigate(paths.requireCertificate, {
          replace: true,
          state: {
            provider: 'email',
          },
        });
      } else {
        redirectNavigate(paths.home, {
          replace: true,
          state: { isRightAfterSignIn: true },
        });
      }
    },
    onError: () => {
      showErrorAlert(
        strings.signIn.failSignIn.ko,
        strings.signIn.checkEmailAndPassword.ko,
      );
    },
  });

  const { register, formState, handleSubmit, setValue, watch, setFocus } =
    useForm({
      resolver: yupResolver(schemas.signIn),
      mode: 'onSubmit',
      defaultValues: {
        email: '',
        password: '',
      },
    });

  const onClickSignIn = (data: FormData) => {
    if (data?.email && data?.password) {
      mutate({
        email: data.email,
        password: data.password,
      });
    } else {
      showErrorAlert(
        strings.signIn.failSignIn.ko,
        strings.signIn.enterEmailAndPassword.ko,
      );
    }
  };

  return (
    <>
      <PrevHeader title={strings.signIn.pageTitle.ko} />
      <div className="mt-header p-[20px]">
        <h3 className="text-H3 text-White">
          {strings.signIn.pageDescription.ko}
        </h3>
        <form className="mt-[24px] space-y-[24px]">
          <Input
            label={strings.common.email.ko}
            {...register('email')}
            type="email"
            autoFocus
            defaultRightAccessory={!!watch().email}
            onDefaultRightAccessoryClick={() => {
              setValue('email', '');
              setTimeout(() => {
                setFocus('email');
              }, 0);
            }}
          />
          <Password
            label={strings.common.password.ko}
            {...register('password')}
            type="password"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onPressEnter={handleSubmit(onClickSignIn)}
            enableIcon={!!watch().password}
          />
        </form>
        <div className="flex items-center justify-center mt-[40px]">
          <Link
            className="text-B8 underline underline-offset-[1.5px] text-Gray6"
            to={paths.signUpTerms}
            onClick={resetData}
          >
            {strings.signUpByEmail.pageTitle.ko}
          </Link>
          <div className="border-r-Gray9 mx-[11.5px] border-r h-[12px]"></div>
          <button
            className="text-B8 underline underline-offset-[1.5px] text-Gray6"
            onClick={showNicePopup}
          >
            {strings.signIn.resetPassword.ko}
          </button>
        </div>
      </div>
      <FooterButton
        disabled={!formState.isValid}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={handleSubmit(onClickSignIn)}
        className="email-signin-footer-button"
      >
        {strings.signIn.goSignIn.ko}
      </FooterButton>
      {renderNiceForm()}
    </>
  );
};

export default SignIn;
