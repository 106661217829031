import { useAuthStore } from '@/zustands/useAuthStore';
import axios from 'axios';
import qs from 'qs';

interface AxiosParams {
  method: Method;
  requestBody?: unknown;
  queryParams?: unknown;
  url: string;
}
type Method = 'get' | 'post' | 'put' | 'delete';

const getAxiosInstance = (auth: boolean = true, tokenOnDemand?: string) => {
  const axiosInstance = axios.create({
    timeout: 200000,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: 'brackets' });
    },
    baseURL: process.env.REACT_APP_ROOT_URL,
    withCredentials: false,
  });

  axiosInstance.interceptors.request.use(
    function (config) {
      config.headers.set('Access-Control-Allow-Origin', '*');
      if (auth) {
        const token = useAuthStore.getState().jlt;
        if (token) config.headers.setAuthorization(token);
      } else {
        if (tokenOnDemand) {
          console.log(tokenOnDemand);
          config.headers.setAuthorization(tokenOnDemand);
        }
      }
      return config;
    },
    function (error) {
      throw error;
    },
  );
  axiosInstance.interceptors.response.use(
    function (response) {
      // 2xx 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
      // 응답 데이터가 있는 작업 수행
      return response;
    },
    function (error) {
      // 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
      // 응답 오류가 있는 작업 수행
      throw error;
    },
  );

  return axiosInstance;
};

const request = async <T>(
  { method, requestBody, queryParams, url }: AxiosParams,
  isAxiosLoadingOff?: boolean,
  auth: boolean = true,
  tokenOnDemand?: string,
) => {
  const axiosInstance = getAxiosInstance(auth, tokenOnDemand);
  switch (method) {
    case 'get':
      return await axiosInstance.get<T>(url, { params: queryParams });
    case 'post':
      axiosInstance.defaults.headers.common['no-loading'] = isAxiosLoadingOff;
      return await axiosInstance.post<T>(url, requestBody);
    case 'put':
      axiosInstance.defaults.headers.common['no-loading'] = isAxiosLoadingOff;
      return await axiosInstance.put<T>(url, requestBody);
    case 'delete':
      axiosInstance.defaults.headers.common['no-loading'] = isAxiosLoadingOff;
      return await axiosInstance.delete<T>(url);
  }
};

export default request;
