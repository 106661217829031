export const paths = {
  celeb: '/celeb',
  home: '/',
  chatting: '/chatting',
  signIn: '/signin',
  signUpByEmail: '/signup/email',
  signUpTerms: '/signup/terms',
  signUpNick: '/signup/nick',
  resetPassword: '/reset-password',
  signUpSuccess: '/signup/success',
  hasAccount: '/has-account',
  profile: '/profile',
  profileEdit: '/profile/edit',
  profileJelly: '/profile/jelly',
  setting: '/setting',
  withdrawal: '/setting/withdrawal',
  payments: '/payments',
  paymentsFail: '/payments/fail',
  terms: '/terms',
  adultOnly: '/adult-only',
  noAccount: '/no-account',
  promotion: '/promotion',
  mediaDrawer: '/drawer',
  purchaseChatTicket: '/purchase/chat',
  attendance: '/attendance',
  requireCertificate: '/require-certificate',
  duplicateCertificate: '/duplicate-certificate',
  notifications: '/notification',
} as const;

export type ActiveKeyType = (typeof paths)[keyof typeof paths];
