import {
  // getChatRoomsById,
  getChatRoomsByIdHistories,
  postChatInitialize,
  postChatLogMediaPurchase,
  postChatPurchase,
  postChatSend,
  postCustomersMeChatSendBotMessage,
  putChatRoomJoin,
  putChatRoomsById,
} from '@/apis/chat';
import { IconDrawer, IconMenuGuide, IconPrimaryJelly } from '@/assets/icons';
import {
  BottomModal,
  Button,
  ChatHistory,
  ChatInput,
  Modal,
  Avatar,
  Delay,
  // ConfirmNotMinorModal,
} from '@/components';
import PartnerResponseFake from '@/components/ChatBubble/PartnerResponseFake';
import PartnerResponseLoading from '@/components/ChatBubble/PartnerResponseLoading';
import ChatRoomHeader from '@/components/Header/ChatRoomHeader';
import { paths } from '@/constants/paths';
import {
  getAlertTextByError,
  getAlertTextByErrorMessages,
  queryKeys,
  storageKey,
  strings,
} from '@/constants/strings';
import useModalConfig from '@/hooks/useModalConfig';
import useTimeout from '@/hooks/useTimeout';
import { cn } from '@/lib/utils';
import {
  type ChatRoomStateType,
  type ChatLog,
  type GenerateChatMessagesDto,
  type ChatLogMediaTheme,
} from '@/models/chat';
import { type Partner } from '@/models/partners';
import {
  getLastElementInArray,
  // isCloserApp,
  // isInAppBrowserOfFacebookOrInstagram,
  // isIos,
  // postMessageToRN,
  isUserAgentMobile,
} from '@/utils/etc';
import { checkFinalityInKorean, isChatRoomPage } from '@/utils/strings';
import { useAuthStore } from '@/zustands/useAuthStore';
import { useConfigStore } from '@/zustands/useConfigStore';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import useGlobalModal from '@/zustands/useGlobalModal';
import { type ServerErrorBody } from '@/models/common';
import { gtmNames, gtmPush } from '@/external/ga';
import _ from 'lodash';
import { putCustomersMeNiceCertification } from '@/apis/customers';
// import useToggle from '@/hooks/useToggle';
// import NiceForm from '@/external/nice/components/NiceForm';

export interface ChatLogForBubble extends ChatLog {
  scrollToBottomOnImageLoad?: boolean;
}

const isLastChatSentByUser = (chatQueue: ChatLogForBubble[]) => {
  if (chatQueue.length === 0) return true;

  return (
    chatQueue.length > 0 &&
    chatQueue?.[chatQueue.length - 1].personType === 'USER'
  );
};

export const makeTempChatLog = (
  message: string,
  messageSentAt: string,
  isFirstMessage: boolean = false,
): ChatLogForBubble => {
  return {
    id: dayjs().valueOf(),
    createdAt: '',
    updatedAt: '',
    deletedAt: '',
    personType: 'USER',
    messageType: 'TEXT',
    language: 'ko',
    message,
    isFirstMessage,
    messageSentAt,
    isLongResponse: false,
    chatLogMedias: [],
    isChatLogMediaPaid: false,
  };
};

const makeErrorChatLog = (message?: string): ChatLogForBubble => {
  return {
    id: dayjs().valueOf(),
    createdAt: '',
    updatedAt: '',
    deletedAt: '',
    personType: 'BOT',
    messageType: 'TEXT',
    language: 'ko',
    message: message ?? strings.chat.errorChatLogText.ko,
    isFirstMessage: false,
    messageSentAt: dayjs().toISOString(),
    isLongResponse: false,
    chatLogMedias: [],
    isChatLogMediaPaid: false,
  };
};

const PAGE_SIZE = 20;
const MAXIMUM_MINUTE_FOR_ERROR_RESPONSE = 15;

const ChatRoom: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const queryClient = useQueryClient();
  const {
    showErrorAlert,
    // showGlobalModal,
    // hideGlobalModal
  } = useGlobalModal();

  const chatInputRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const pageTopRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);

  const { auth, isLogin } = useAuthStore();
  const { isMobile } = useConfigStore();
  const chatRoomId = parseInt(id ?? '-1');
  const [chatQueue, setChatQueue] = useState<ChatLogForBubble[]>([]);
  const [disableInput, setDisableInput] = useState(false); // 메시지 입력창 비활성화
  const [partnerInfo, setPartnerInfo] = useState<Partner>();

  const isMoreThanOnePage = useRef(false);
  const isLoadingEnableFakeBubble = useRef(false);
  const disableRetryFirstSend = useRef(false);
  const selectedChatLogMediaInfo = useRef<{
    chatLogId: number;
    chatLogMediaId: number;
    jellyCount: number;
    theme: ChatLogMediaTheme;
  }>({
    chatLogId: -1,
    chatLogMediaId: -1,
    jellyCount: 0,
    theme: 'MILD',
  });

  const isDataFetchingRef = useRef(false);
  const prevScrollHeight = useRef(NaN);
  const prevScrollTop = useRef(NaN);

  // 중앙 모달
  const {
    open: modalState,
    handleOpenModal,
    handleCloseModal,
  } = useModalConfig();

  // 바텀 모달
  const {
    open: bottomModalState,
    handleOpenModal: handleOpenBottomModal,
    handleCloseModal: handleCloseBottomModal,
  } = useModalConfig();

  // Hot 미디어 구매시 만 19세 이상 최초 1회 동의 모달
  // const {
  //   open: confirmNotMinorModal,
  //   handleOpenModal: handleOpenConfirmNotMinorModal,
  //   handleCloseModal: handleCloseConfirmNotMinorModal,
  // } = useModalConfig();

  // 미디어 구매 전용 바텀 모달
  const {
    open: chatLogMediaBottomModalState,
    handleOpenModal: handleOpenChatLogMediaBottomModal,
    handleCloseModal: handleCloseChatLogMediaBottomModal,
  } = useModalConfig();

  const isNotInitialized = useRef(false);
  const [isFirstSend, setIsFirstSend] = useState(false); // 채팅방 첫 진입 후, 첫 메시지 전송 -> 총 chatLog가 2개밖에 없다는 뜻

  // long response 관련 state
  const [isLongResponse, setIsLongResponse] = useState(false);
  const [isEnablePolling, setIsEnablePolling] = useState(false);

  // NICE 인증
  // const [showNice, setShowNice] = useState(false);
  // const [flag, toggleFlag] = useToggle();

  const [chatRoomInfo, setChatRoomInfo] = useState<{
    chatRoomState?: ChatRoomStateType; // 상태
    count: number; // 채팅 총 개수
    // totalVoiceCount?: number; // 전체 음성 개수
    remainingVoiceCount?: number; // 남은 음성 개수
    remainingChatCount?: number; // 남은 채팅권 개수
    isChatAvailable: boolean; // 선점 여부
    isRead: boolean; // 채팅 읽음
    isEnded: boolean; // 채팅 종료 여부
  }>({
    count: 0,
    remainingChatCount: 0,
    remainingVoiceCount: 0,
    isChatAvailable: false,
    isRead: false,
    isEnded: false,
  });

  // 페이지 첫 진입시 서버에서 아직 채팅 답변이 생성 중인지 확인하는 state
  const [isNotReplied, setIsNotReplied] = useState(false);

  // 페이지 첫 진입시 마지막 채팅의 ID, 폴링의 응답으로 받을 마지막 chatLog가 보낸메시지의 다음 순서인지 확인하는데 쓰임
  const initialLastChatLogId = useRef(-1);

  // query ----------------------------------------------------------------------------------------------------------------
  const {
    data: chatHistoryData,
    isInitialLoading: chatHistoryInitialLoading,
    remove: removeChatHistoryData,
    refetch: refetchChatHistoryData,
    isError: isChatHistoryError,
    isFetchedAfterMount,
    fetchNextPage,
    hasNextPage,
    error,
  } = useInfiniteQuery({
    queryKey: [queryKeys.getChatRoomsByIdHistories, chatRoomId],
    queryFn: async ({ pageParam = -1 }) => {
      const { row } = await getChatRoomsByIdHistories(chatRoomId, {
        ...(pageParam !== -1 && { chatHistoryId: pageParam }),
        page: 1,
        pageSize: PAGE_SIZE,
      });

      const len = row.chatLogs.length;
      const nextPage = len > 0 ? row.chatLogs[len - 1].id : -1;

      return {
        row,
        nextPage,
        isLast: row.chatLogs.length < PAGE_SIZE,
      };
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage.isLast) return lastPage.nextPage;
      return undefined;
    },
  });

  // 마지막 chatLog API 폴링 useQuery
  const { data: lastChatLogData, isSuccess: isLastChatLogQuerySuccess } =
    useQuery({
      queryKey: [queryKeys.getLastChatLog, chatRoomId],
      queryFn: async () => {
        const lastChatLogPersonType =
          chatHistoryData?.pages?.[0]?.row?.chatLogs?.[0]?.personType;

        const lastChatLogSentAt =
          chatHistoryData?.pages?.[0]?.row?.chatLogs?.[0]?.messageSentAt;

        if (
          lastChatLogPersonType === 'USER' && // 마지막 보낸 메시지가 유저이고
          lastChatLogSentAt && // 마지막 보낸 시간이 있을때
          dayjs().diff(lastChatLogSentAt, 'm') >=
            MAXIMUM_MINUTE_FOR_ERROR_RESPONSE // 마지막 보낸 시간이 15분이상 흘렀다면
        ) {
          // 보내기만 하고 다음 폴링에서 확인
          postBotMessage(chatRoomId);
        }

        return await getChatRoomsByIdHistories(chatRoomId, {
          page: 1,
          pageSize: 1,
        });
      },
      enabled: isNotReplied || isEnablePolling, // 폴링 on/off
      refetchInterval: 3000, // 폴링 간격
    });

  // mutation ----------------------------------------------------------------------------------------------------------------
  const {
    mutate: sendChat,
    isLoading: sendChatLoading,
    isSuccess: isSendChatSuccess,
  } = useMutation(
    (requestBody: GenerateChatMessagesDto) => {
      setTimeout(() => {
        setIsEnablePolling(true);
      }, 500);
      return postChatSend(requestBody);
    },
    {
      onSuccess: (res) => {
        setChatQueue((prev) => [
          ...prev,
          { ...res.row.chatLogs[0], scrollToBottomOnImageLoad: true },
        ]);
        const {
          chatRoomState,
          count,
          isChatAvailable,
          remainingVoiceCount,
          remainingChatCount,
          isEnded,
          isRead,
        } = res.row;
        setChatRoomInfo({
          chatRoomState,
          count,
          remainingVoiceCount,
          remainingChatCount,
          isChatAvailable: isChatAvailable ?? false,
          isEnded,
          isRead,
        });

        if (location.pathname !== window.location.pathname) {
          // 응답이 왔는데 원래 있던 채팅방 페이지가 아닐때
          putChatRoomAsync({
            id: chatRoomId,
            requestBody: {
              isRead: false,
            },
          }).then(() => {
            queryClient.invalidateQueries([queryKeys.getChatRooms]);
          });
        }

        setIsEnablePolling(false);
        setIsLongResponse(false);
      },
      onError: (err: any) => {
        if (err?.code === 'ECONNABORTED') {
          // 요청 타임아웃에 걸렸을때, 폴링 시작 (현재 타임아웃 : 200초)
          setIsNotReplied(true);
          return;
        }
        if (err?.response) {
          // 요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다.
          const error = err?.response.data as ServerErrorBody;

          if (
            error.statusCode === 400 &&
            error.message === 'CHAT_ROOM_UNAVAILABLE'
          ) {
            handleOpenModal();
          } else if (
            error.statusCode === 404 &&
            error.message === 'NO_AVAILABLE_CHAT_TICKET'
          ) {
            // 채팅권 없어서 에러 발생
            joinChatRoom(chatRoomId, {
              onSettled: () => {
                // 채팅방 정보 새로고침
                queryClient.invalidateQueries([
                  queryKeys.getChatRoomsById,
                  chatRoomId,
                ]);
              },
            });
          } else {
            const { title, description } = getAlertTextByErrorMessages(
              error?.message,
              error?.translate,
            );

            showErrorAlert(title, description);
          }

          if (isFirstSend) {
            // 첫 메시지 요청이었을 때,
            // 첫 메시지 요청의 서버응답이 에러인 경우에는 정상응답으로 에러메시지를 수신
            // disableRetryFirstSend.current = true;
          } else {
            // 이후 메시지 요청이었을 때,
            setChatQueue((prev) => {
              if (prev.length > 0) {
                const lastChatPersonType = prev[prev.length - 1]?.personType;

                if (lastChatPersonType === 'USER') {
                  return prev.slice(0, -1);
                } else {
                  return prev;
                }
              } else {
                return [];
              }
            });
          }

          setIsEnablePolling(false);
          setIsLongResponse(false);
        } else if (err?.request) {
          // 요청이 전송되었지만, 응답이 수신되지 않았습니다.
          setIsNotReplied(true);
        } else {
          // 오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.
          setChatQueue((prev) => [...prev, makeErrorChatLog()]);

          setIsEnablePolling(false);
          setIsLongResponse(false);
        }
      },
      onSettled: () => {
        if (location.pathname === window.location.pathname) {
          // 응답이 왔는데 원래 있던 채팅방 페이지일때
          restartTimeout();
          setDisableInput(false);
        }
      },
    },
  );
  const { mutate: initializeChat, isLoading: initializeChatLoading } =
    useMutation(postChatInitialize, {
      onError: (err: any) => {
        if (err?.response) {
          const error = err?.response.data as ServerErrorBody;

          const { title, description } = getAlertTextByErrorMessages(
            error?.message,
            error?.translate,
          );

          showErrorAlert(title, description);
        } else if (err?.request) {
          showErrorAlert(
            strings.error.pleaseRetryAfterRefresh.ko,
            strings.error.errorOccurredInChatRoom.ko,
          );
        } else {
          showErrorAlert(
            strings.error.errorOccurred.ko,
            strings.error.pleaseRetryLater.ko,
          );
        }
      },
    });
  const { mutate: purchaseChatCount } = useMutation(postChatPurchase);
  const { mutate: purchaseChatLogMedia } = useMutation(
    postChatLogMediaPurchase,
  );

  const { mutate: joinChatRoom } = useMutation(putChatRoomJoin);
  const { mutate: putChatRoom, mutateAsync: putChatRoomAsync } =
    useMutation(putChatRoomsById);
  const { mutate: postBotMessage } = useMutation(
    postCustomersMeChatSendBotMessage,
  );

  const { mutate: registerNiceCertification } = useMutation(
    putCustomersMeNiceCertification,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.me]);
      },
      onError: (err) => {
        const { title, description } = getAlertTextByError(err);
        showErrorAlert(title, description);
      },
    },
  );
  // --------------------------------------------------------------------------------------------------------------------

  const { restartTimeout } = useTimeout(
    useCallback(() => {
      // 타이머 동작했으나 채팅방 페이지가 아닌 경우
      if (!isChatRoomPage(window.location.pathname)) return;
      if (chatRoomInfo.chatRoomState === 'ONGOING') {
        // 진행중인 채팅방
        if (!chatRoomInfo.isEnded) {
          // 채팅방 종료되지 않았을 때
          putChatRoom(
            // 채팅방 선점 종료
            {
              id: chatRoomId,
              requestBody: {
                isChatAvailable: false,
              },
            },
            {
              onSuccess: () => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                removeChatHistoryData();
                refetchChatHistoryData(); // 선점 종료시 refetch
              },
            },
          );
        }
      }
    }, [
      chatRoomInfo,
      chatRoomId,
      putChatRoom,
      refetchChatHistoryData,
      removeChatHistoryData,
    ]),
  );

  // memo ----------------------------------------------------------------------------------------------------------------
  const bottomModalText = useMemo<{
    title: string;
    description: string;
    buttonText: string;
    refundNotice?: string;
  }>(() => {
    const myJellyCount = auth?.creditCount ?? 0;
    const requiredJellyCount = partnerInfo?.chatJelly ?? 40;
    const lackJellyCount = myJellyCount - requiredJellyCount;

    const isEnoughJelly = lackJellyCount >= 0;

    if (chatRoomInfo.chatRoomState === 'ONGOING') {
      // 진행중
      // 채팅권 없음
      if (chatRoomInfo.remainingChatCount === 0) {
        const partnerNick =
          partnerInfo?.nick +
          (checkFinalityInKorean(partnerInfo?.nick) ? '이' : '');

        return {
          title: `${partnerNick}${strings.chat.purchaseChatTicketTitle.ko}`,
          description: strings.chat.purchaseChatTicketDescription.ko,
          buttonText: strings.chat.purchaseChatTicket.ko,
        };
      }

      // 선점 필요
      return {
        title: `${partnerInfo?.nick}${strings.chat.resumeChat.ko}`,
        description: `${auth?.nick}${strings.chat.waitYou.ko}`,
        buttonText: strings.chat.goDate.ko,
      };
    } else if (chatRoomInfo.chatRoomState === 'STARTED') {
      // 시작된
      return {
        title: `${partnerInfo?.nick}${strings.chat.startChat.ko}`,
        description: `${strings.common.jelly.ko} ${
          isEnoughJelly ? requiredJellyCount : Math.abs(lackJellyCount)
        }${strings.common.unit.ko}${
          isEnoughJelly
            ? strings.chat.needUseJelly.ko
            : strings.chat.needPurchaseJelly.ko
        }`,
        buttonText: isEnoughJelly
          ? strings.chat.goDate.ko
          : strings.chat.goPurchase.ko,
        refundNotice: isEnoughJelly ? strings.chat.refundNotice.ko : undefined,
      };
    } else {
      // 종료된
      return {
        title: `${partnerInfo?.nick}${strings.chat.suggestAfterChat.ko}`,
        description: `${strings.common.jelly.ko} ${
          isEnoughJelly ? requiredJellyCount : Math.abs(lackJellyCount)
        }${strings.common.unit.ko}${
          isEnoughJelly
            ? strings.chat.needUseJelly.ko
            : strings.chat.needPurchaseJelly.ko
        }`,
        buttonText: isEnoughJelly
          ? strings.chat.goDate.ko
          : strings.chat.goPurchase.ko,
        refundNotice: isEnoughJelly ? strings.chat.refundNotice.ko : undefined,
      };
    }
  }, [partnerInfo, auth, chatRoomInfo]);

  // handler & function ----------------------------------------------------------------------------------------------------------------
  const onClickPurchase = useCallback(() => {
    navigate(paths.payments);
    sessionStorage?.setItem(storageKey.session.redirectUrl, location.pathname);
  }, [navigate, location]);

  const handleClickBottomModalButton = useCallback(() => {
    if (chatRoomInfo.chatRoomState === 'ONGOING') {
      if (chatRoomInfo.remainingChatCount === 0) {
        navigate(paths.purchaseChatTicket);
        sessionStorage.setItem(
          storageKey.session.redirectUrlAfterPurchase,
          location.pathname,
        );
        return;
      }

      // 진행중인 채팅 (채팅권 > 0)
      joinChatRoom(chatRoomId, {
        onSuccess: () => {
          handleCloseBottomModal();
          removeChatHistoryData();
          refetchChatHistoryData();
          // restartTimeout(); 메시지 전송 응답시에만 타이머 동작
        },
        onError: () => {
          handleCloseBottomModal();
          handleOpenModal();
        },
      });
    } else {
      // 시작된 또는 종료된 채팅
      const jelly = auth?.creditCount ?? 0;
      const requireJelly = partnerInfo?.chatJelly ?? 40;

      // 보유 젤리 검사
      if (jelly < requireJelly) {
        onClickPurchase();
      } else {
        if (chatRoomInfo.chatRoomState === 'STARTED') {
          purchaseChatCount(chatRoomId, {
            onSuccess: () => {
              handleCloseBottomModal();
              queryClient?.invalidateQueries([queryKeys?.me]);
              setIsFirstSend(false);
              isNotInitialized.current = false;

              sendChat({
                partnerId: partnerInfo?.id ?? 0,
                chatRoomId,
                language: 'ko',
                personType: 'USER',
                messageType: 'TEXT',
                isFirstMessage: false,
              });
            },
            onError: (err: any) => {
              if (err?.response) {
                showErrorAlert(
                  strings.error.errorOccurred.ko,
                  err.response.data?.translate ??
                    strings.error.pleaseRetryLater.ko,
                );
              } else {
                showErrorAlert(
                  strings.error.errorOccurred.ko,
                  strings.error.pleaseRetryLater.ko,
                );
              }
            },
          });
        } else if (chatRoomInfo.chatRoomState === 'ENDED') {
          purchaseChatCount(chatRoomId, {
            onSuccess: () => {
              handleCloseBottomModal();
              queryClient?.invalidateQueries([queryKeys?.me]);
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              removeChatHistoryData();
              refetchChatHistoryData();
            },
          });
        }
      }
    }
  }, [
    location,
    onClickPurchase,
    purchaseChatCount,
    handleOpenModal,
    handleCloseBottomModal,
    removeChatHistoryData,
    refetchChatHistoryData,
    sendChat,
    chatRoomId,
    auth,
    partnerInfo,
    joinChatRoom,
    chatRoomInfo,
    queryClient,
    showErrorAlert,
    navigate,
  ]);

  // 메시지 보내기 함수
  const onSend = useCallback(
    (message: string) => {
      if (partnerInfo?.id) {
        gtmPush(gtmNames.event.CHATROOM_chatSend, {
          [gtmNames.parameter.mateId]: partnerInfo.id,
        });
      }

      setDisableInput(true);
      if (chatRoomInfo.chatRoomState === 'STARTED') {
        // 시작된 채팅방
        initializeChat(
          {
            chatRoomId,
            language: 'ko',
            message,
            personType: 'USER',
            messageType: 'TEXT',
            messageSentAt: dayjs().toISOString(),
          },
          {
            onSuccess: () => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              refetchChatHistoryData();
              isLoadingEnableFakeBubble.current = true;
              setDisableInput(false);
            },
          },
        );
      } else if (chatRoomInfo.chatRoomState === 'ONGOING') {
        // 진행중인 채팅방

        if (isNotInitialized.current) {
          // 진행중인 채팅방이면서 아직 initialize 되지 않은 경우

          const messageSentAt = dayjs().toISOString();

          // 초기화 API 호출
          initializeChat(
            {
              chatRoomId,
              language: 'ko',
              message,
              personType: 'USER',
              messageType: 'TEXT',
              messageSentAt,
            },
            {
              onSuccess: () => {
                // 성공했을 때, 화면에 챗로그 쌓아주고, 서버 데이터는 chatlog 개수가 그대로 이니, isFirstSend 수동으로 설정
                setIsFirstSend(true);
                setChatQueue((prev) => [
                  ...prev,
                  makeTempChatLog(message, messageSentAt),
                ]);

                sendChat({
                  partnerId: partnerInfo?.id ?? 0,
                  chatRoomId,
                  language: 'ko',
                  personType: 'USER',
                  messageType: 'TEXT',
                  isFirstMessage: false,
                });
              },
            },
          );
          isNotInitialized.current = false;
        } else {
          const messageSentAt = dayjs().toISOString();
          sendChat({
            partnerId: partnerInfo?.id ?? 0,
            chatRoomId,
            language: 'ko',
            personType: 'USER',
            messageType: 'TEXT',
            isFirstMessage: false,
            message,
            messageSentAt,
          });

          setChatQueue((prev) => [
            ...prev,
            makeTempChatLog(message, messageSentAt),
          ]);
        }
      } else if (chatRoomInfo.chatRoomState === 'ENDED') {
        handleOpenBottomModal();
        setDisableInput(false);
      }
    },
    [
      chatRoomId,
      refetchChatHistoryData,
      initializeChat,
      sendChat,
      chatRoomInfo,
      partnerInfo,
      handleOpenBottomModal,
    ],
  );

  // const onNiceSuccess = useCallback(
  //   (tokenVersionId: string) => {
  //     registerNiceCertification({ tokenVersionId });
  //     handleOpenChatLogMediaBottomModal();
  //   },
  //   [registerNiceCertification, handleOpenChatLogMediaBottomModal],
  // );

  // const showNicePopup = useCallback(() => {
  //   if (!showNice) {
  //     setShowNice(true);
  //   } else {
  //     toggleFlag();
  //   }
  // }, [showNice, toggleFlag]);

  // 스크롤 하단 이동 함수
  const scrollToBottom = useCallback(() => {
    bottomRef?.current?.scrollIntoView({
      behavior: 'auto',
    });
  }, []);

  // 스크롤 하단 이동 함수 with smooth
  const scrollToBottomSmooth = useCallback(() => {
    bottomRef?.current?.scrollIntoView({
      behavior: 'smooth',
    });
  }, []);

  // 입력창 포커스시 동일하게 바텀 모달 동작
  const onInputFocus = useCallback(
    (e: any) => {
      if (chatRoomInfo.chatRoomState === 'STARTED') {
        // 시작된 채팅방
        if (isFirstSend) {
          // 첫 메시지 보냈을때 (initialize api 응답 후)
          handleOpenBottomModal();
          e.preventDefault();
        }
      } else if (chatRoomInfo.chatRoomState === 'ONGOING') {
        // 진행중인 채팅방 (채팅권 > 0)
        if (chatRoomInfo.isEnded) {
          // 선점 불가능한 상태일 때
          handleOpenBottomModal();
          e.preventDefault();
        } else if (chatRoomInfo.remainingChatCount === 0) {
          handleOpenBottomModal();
          e.preventDefault();
        }
      } else if (chatRoomInfo.chatRoomState === 'ENDED') {
        // 종료된 채팅방 (채팅권 === 0)
        handleOpenBottomModal();
        e.preventDefault();
      }
    },
    [chatRoomInfo, isFirstSend, handleOpenBottomModal],
  );

  // 잠금된 미디어 클릭시 바텀 모달 동작
  const onChatLogMediaClick = useCallback(
    (
      chatLogId: number,
      chatLogMediaId: number,
      jellyCount: number,
      theme: ChatLogMediaTheme,
    ) => {
      if (partnerInfo?.id) {
        gtmPush(gtmNames.event.CHATROOM_blurredMediaClick, {
          [gtmNames.parameter.mateId]: partnerInfo.id,
          [gtmNames.parameter.theme]: theme,
        });
      }

      selectedChatLogMediaInfo.current = {
        chatLogId,
        chatLogMediaId,
        jellyCount,
        theme,
      };

      // if (theme === 'HOT' && !auth?.isAdult) {
      // if (isCloserApp()) {
      //   // 본인인증 필요 모달 표시
      //   showGlobalModal({
      //     title: '본인인증이 필요해요',
      //     description: 'Hot 컨텐츠 구매를 위해서는 본인인증이 필요해요',
      //     primaryButtonText: '본인인증하기',
      //     onPrimaryClick: () => {
      //       postMessageToRN('NICE_CERTIFICATION');
      //       // if (isCloserApp()) {
      //       //   postMessageToRN('NICE_CERTIFICATION');
      //       // } else {
      //       //   showNicePopup();
      //       // }
      //       hideGlobalModal();
      //     },
      //     cancelButtonText: '다음에 하기',
      //     cancelButtonVariant: 'gray',
      //     onCancelClick: hideGlobalModal,
      //     buttonDirection: 'row',
      //     buttonOrder: 'cancel2primary',
      //     onClose: hideGlobalModal,
      //   });
      // } else {
      //   handleOpenConfirmNotMinorModal();
      // }

      // if (isInAppBrowserOfFacebookOrInstagram()) {
      //   // 페이스북, 인스타그램 인앱 브라우저
      //   const isIOS = isIos();
      //   if (isIOS) {
      //     // IOS
      //     showErrorAlert(
      //       <>
      //         현재 창에서는
      //         <br />
      //         본인인증이 불가능해요
      //       </>,
      //       'Safari, Chrome 등 다른 브라우저를 이용하여 Hot 컨텐츠를 구매해주세요!',
      //     );
      //   } else {
      //     // Android
      //     showGlobalModal({
      //       title: (
      //         <>
      //           현재 창에서는
      //           <br />
      //           본인인증이 불가능해요
      //         </>
      //       ),
      //       description:
      //         'Safari, Chrome 등 다른 브라우저 또는 앱을 이용하여 Hot 컨텐츠를 구매해주세요!',
      //       primaryButtonText: '앱으로 보기',
      //       onPrimaryClick: () => {
      //         window.open(process.env.REACT_APP_DEEP_LINK, '_self');
      //       },
      //       cancelButtonText: '닫기',
      //       onCancelClick: hideGlobalModal,
      //       buttonDirection: 'row',
      //       buttonOrder: 'cancel2primary',
      //       onClose: hideGlobalModal,
      //     });
      //   }
      // } else {
      //   // 인앱 브라우저가 아닌 경우, 본인인증 필요 모달 표시
      //   showGlobalModal({
      //     title: '본인인증이 필요해요',
      //     description: 'Hot 컨텐츠 구매를 위해서는 본인인증이 필요해요',
      //     primaryButtonText: '본인인증하기',
      //     onPrimaryClick: () => {
      //       if (isCloserApp()) {
      //         postMessageToRN('NICE_CERTIFICATION');
      //       } else {
      //         showNicePopup();
      //       }
      //       hideGlobalModal();
      //     },
      //     cancelButtonText: '다음에 하기',
      //     cancelButtonVariant: 'gray',
      //     onCancelClick: hideGlobalModal,
      //     buttonDirection: 'row',
      //     buttonOrder: 'cancel2primary',
      //     onClose: hideGlobalModal,
      //   });
      // }
      //   return;
      // }

      handleOpenChatLogMediaBottomModal();
    },
    [
      partnerInfo?.id,
      // auth,
      // handleOpenConfirmNotMinorModal,
      handleOpenChatLogMediaBottomModal,
      // showNicePopup,
      // showErrorAlert,
      // showGlobalModal,
      // hideGlobalModal,
    ],
  );

  const resetSelectedChatLogMediaInfo = useCallback(() => {
    setTimeout(() => {
      selectedChatLogMediaInfo.current = {
        chatLogId: -1,
        chatLogMediaId: -1,
        jellyCount: 0,
        theme: 'MILD',
      };
    }, 0);
  }, []);

  // 잠금된 이미지 잠금 해제 버튼 클릭
  const onChatLogMediaBottomModalButtonClick = useCallback(() => {
    const { chatLogId, chatLogMediaId, jellyCount, theme } =
      selectedChatLogMediaInfo.current;

    if (partnerInfo?.id) {
      gtmPush(gtmNames.event.CHATROOM_blurredMediaPurchaseButtonClick, {
        [gtmNames.parameter.mateId]: partnerInfo.id,
        [gtmNames.parameter.theme]: theme,
        [gtmNames.parameter.buttonText]:
          (auth?.creditCount ?? 0) >= jellyCount ? '잠금 해제' : '젤리 충전',
      });
    }

    if ((auth?.creditCount ?? 0) < jellyCount) {
      navigate(paths.payments);
      sessionStorage?.setItem(
        storageKey.session.redirectUrl,
        location.pathname,
      );
      return;
    }

    if (chatLogId !== -1 && chatLogMediaId !== -1) {
      purchaseChatLogMedia(
        {
          chatLogId,
          chatLogMediaId,
        },
        {
          onSuccess: (res) => {
            prevScrollTop.current = isMobile
              ? window.scrollY
              : scrollContainerRef.current?.scrollTop ?? NaN;
            queryClient?.invalidateQueries([queryKeys.me]);
            setChatQueue((prev) => {
              if (prev && prev.length > 0) {
                const idx = prev.findIndex((value) => value.id === res.row.id);
                if (idx !== -1) {
                  return [
                    ...prev.slice(0, idx),
                    res.row,
                    ...prev.slice(idx + 1),
                  ];
                } else {
                  return prev;
                }
              }
              return prev;
            });
          },
          onError: (err: any) => {
            if (err?.response) {
              const error = err?.response.data as ServerErrorBody;

              const { title, description } = getAlertTextByErrorMessages(
                error?.message,
                error?.translate,
              );

              showErrorAlert(title, description);
            } else if (err?.request) {
              showErrorAlert(
                strings.error.pleaseRetryAfterRefresh.ko,
                strings.error.errorOccurredInChatRoom.ko,
              );
            } else {
              showErrorAlert(
                strings.error.errorOccurred.ko,
                strings.error.pleaseRetryLater.ko,
              );
            }
          },
          onSettled: () => {
            handleCloseChatLogMediaBottomModal();
            resetSelectedChatLogMediaInfo();
          },
        },
      );
    }
  }, [
    isMobile,
    queryClient,
    showErrorAlert,
    purchaseChatLogMedia,
    resetSelectedChatLogMediaInfo,
    handleCloseChatLogMediaBottomModal,
    location,
    auth,
    navigate,
    partnerInfo?.id,
  ]);

  const showMinorAlert = useCallback(() => {
    showErrorAlert(
      '만 19세 미만입니다',
      <>
        Hot 컨텐츠는 청소년 유해 매체물로서
        <br />만 19세 미만의 청소년은 이용할 수 없습니다.
      </>,
    );
  }, [showErrorAlert]);

  // effect ----------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    if (isLastChatLogQuerySuccess) {
      // 폴링 정상 응답
      if (isSendChatSuccess) {
        setIsNotReplied(false);
        setIsEnablePolling(false);
        setIsLongResponse(false);

        initialLastChatLogId.current = lastChatLogData.row.chatLogs?.[0].id;
        return;
      }

      if (
        lastChatLogData.row.chatLogs?.[0].personType === 'BOT' // 응답으로 받은 마지막 chatLog가 메이트의 응답이라면
      ) {
        // 마지막 chatLog
        const lastChatLog = lastChatLogData.row.chatLogs?.[0];

        // 사용자가 보낸 메시지의 다음 메시지인 경우 : 정상 응답 생성
        if (initialLastChatLogId.current < lastChatLog.id) {
          // 응답 메시지 추가
          setChatQueue((prev) => [
            ...prev,
            {
              ...lastChatLog,
              scrollToBottomOnImageLoad: true,
            },
          ]);

          // 로딩중 해제
          setIsNotReplied(false);

          setIsEnablePolling(false);
          setIsLongResponse(false);

          initialLastChatLogId.current = lastChatLog.id;
        }

        // 사용자가 보낸 메시지의 이전 메시지인 경우 : 서버에서 에러가 생겨 사용자가 보낸 메시지를 롤백했을 경우
        else if (initialLastChatLogId.current < lastChatLog.id) {
          // 에러 메시지 추가 (프론트)
          setChatQueue((prev) => [...prev, makeErrorChatLog()]);

          // 로딩중 해제
          setIsNotReplied(false);

          setIsEnablePolling(false);
          setIsLongResponse(false);

          // 채팅방 정보 업데이트, 메시지가 롤백된 경우에만 최신화된 채팅방 정보가 필요 (채팅 카운트 및 채팅방 상태 롤백)
          const {
            chatRoomState,
            count,
            isChatAvailable,
            isEnded,
            isRead,
            remainingVoiceCount,
            remainingChatCount,
          } = lastChatLogData.row;
          setChatRoomInfo({
            chatRoomState,
            count,
            remainingVoiceCount,
            remainingChatCount,
            isChatAvailable: isChatAvailable ?? false,
            isEnded,
            isRead,
          });

          initialLastChatLogId.current = lastChatLog.id;
        }
      } else if (lastChatLogData.row.chatLogs?.[0].personType === 'USER') {
        if (lastChatLogData.row.chatLogs?.[0].isLongResponse !== null) {
          setIsEnablePolling(false);
          // isLongResponse 검사
          setIsLongResponse(lastChatLogData.row.chatLogs?.[0].isLongResponse);

          if (
            lastChatLogData.row.chatLogs?.[0].isLongResponse &&
            !isNotReplied
          ) {
            setTimeout(() => {
              scrollToBottomSmooth();
            }, 100);
          }
        } else {
          setIsLongResponse(false);
        }
      }
    }
  }, [
    isSendChatSuccess,
    isNotReplied,
    lastChatLogData,
    isLastChatLogQuerySuccess,
    setIsNotReplied,
    setChatQueue,
    scrollToBottomSmooth,
  ]);

  // 채팅 내역 불러오기 useEffect
  useEffect(() => {
    if (chatHistoryData?.pages && chatHistoryData.pages.length > 0) {
      const isOnlyOnePage = chatHistoryData.pages.length === 1;

      const lastIndex = chatHistoryData.pages.length - 1;

      const chatLogs = [...chatHistoryData.pages[lastIndex].row.chatLogs];

      if (isOnlyOnePage) {
        // 페이지 1개만 있다면 바꿔치기 (initialize 응답 대응)
        setChatQueue(
          chatLogs
            .map((chat) => ({
              ...chat,
              scrollToBottomOnImageLoad: true,
            }))
            .reverse(),
        );
      } else {
        // 페이지 1개 초과라면 마지막 페이지만 앞에 붙이기
        setChatQueue((prev) => [...chatLogs.reverse(), ...prev]);
      }

      // initialize 되지 않았는지 확인
      if (chatHistoryData.pages[0].row.count === 1) {
        isNotInitialized.current = true;
      }

      // 처음 보낸지는 첫 페이지만 필요
      setIsFirstSend(chatHistoryData.pages[0].row.count === 2);

      // 파트저 정보나, 채팅방 정보는 마지막 페이지 기준
      setPartnerInfo(chatHistoryData.pages[lastIndex].row.partner);

      isMoreThanOnePage.current =
        chatHistoryData === undefined || chatHistoryData.pages.length > 1;

      const {
        count,
        isChatAvailable,
        chatRoomState,
        isEnded,
        isRead,
        remainingVoiceCount,
        remainingChatCount,
      } = chatHistoryData.pages[lastIndex].row;
      setChatRoomInfo({
        count,
        remainingVoiceCount,
        remainingChatCount,
        isChatAvailable: isChatAvailable ?? false,
        chatRoomState,
        isEnded,
        isRead,
      });

      if (
        !isMoreThanOnePage.current && // 첫 페이지일때
        (chatHistoryData.pages?.[0].row.chatRoomState === 'ONGOING' ||
          chatHistoryData.pages?.[0].row.chatRoomState === 'ENDED') && // 진행중인 또는 종료된 채팅방인 경우
        chatHistoryData.pages?.[0].row.chatLogs?.[0]?.personType === 'USER' // 마지막 chatLog가 사용자가 보낸거라면
      ) {
        // 폴링 응답과 비교를 위해 마지막 chatLog 아이디 저장
        initialLastChatLogId.current =
          chatHistoryData.pages?.[0].row.chatLogs?.[0]?.id;

        // 로딩중 설정
        setIsNotReplied(true);
      }
    }
  }, [chatHistoryData]);

  // 페이지 첫 진입시 진행중인 채팅방이고, 서버에서 받아온 chatLog.length === 2일때,
  useEffect(() => {
    if (disableRetryFirstSend.current) return;

    // partnerInfo?.id 가 없을때는 호출해봤자 오류남
    if (!partnerInfo?.id) return;

    if (chatHistoryData?.pages.length === 1) {
      // 채팅 히스토리가 첫페이지 밖에 없을 때
      const firstPageData = chatHistoryData.pages[0];

      if (
        firstPageData.row.chatRoomState === 'ONGOING' && // 진행중인 채팅방일때
        firstPageData.row.chatLogs?.length === 2 && // 서버에 쌓인 chatLog가 2개밖에 없다면
        !sendChatLoading && // 메시지 보내기 API의 로딩중이 아닐때
        !initializeChatLoading // 메시지 시작 API의 로딩중이 아닐떄
      ) {
        sendChat({
          partnerId: partnerInfo.id,
          chatRoomId,
          language: 'ko',
          personType: 'USER',
          messageType: 'TEXT',
          isFirstMessage: false,
        });
        disableRetryFirstSend.current = true;
      }
    }
  }, [
    chatHistoryData,
    chatRoomId,
    partnerInfo,
    sendChat,
    sendChatLoading,
    initializeChatLoading,
  ]);

  useEffect(() => {
    // 진입 이후에는 send, initialize 응답 로딩만 사용
    if (sendChatLoading || initializeChatLoading) {
      setIsNotReplied(false);
    }
  }, [sendChatLoading, initializeChatLoading]);

  // 바텀 모달 분기
  useEffect(() => {
    if (!chatRoomInfo.chatRoomState) return;
    if (isMoreThanOnePage.current) return;
    if (chatRoomInfo.chatRoomState === 'STARTED') {
      // 시작된 채팅방
      if (isFirstSend) {
        // 첫 메시지 보냈을때 (initialize api 응답 후)
        setTimeout(
          handleOpenBottomModal,
          isLoadingEnableFakeBubble.current ? 8000 : 700,
        );
      }
    } else if (chatRoomInfo.chatRoomState === 'ONGOING') {
      // 진행중인 채팅방 (채팅권 > 0)
      if (chatRoomInfo.isEnded) {
        // 선점 불가능한 상태일 때
        handleOpenBottomModal();
      }
    } else if (chatRoomInfo.chatRoomState === 'ENDED') {
      // 종료된 채팅방 (채팅권 === 0)
      // handleOpenBottomModal(); // 입력창 포커스일때만 뜨기로 변경 - 1차 QA
    }
  }, [chatRoomInfo, isFirstSend, handleOpenBottomModal]);

  // 챗 상단 스크롤시 채팅 내역 fetch
  useEffect(() => {
    let observer: IntersectionObserver;
    if (pageTopRef.current) {
      observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!isNotReplied && !sendChatLoading && hasNextPage) {
              if (isDataFetchingRef.current) return;
              isDataFetchingRef.current = true;

              prevScrollHeight.current =
                scrollContainerRef.current?.scrollHeight ?? NaN;

              fetchNextPage().finally(() => {
                setTimeout(() => {
                  isDataFetchingRef.current = false;
                }, 100);
              });
            }
          }
        });
      });

      observer.observe(pageTopRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageTopRef.current,
    fetchNextPage,
    sendChatLoading,
    hasNextPage,
    isNotReplied,
  ]);

  // 메시지 전송/응답시 스크롤 하단으로 이동, 상단 스크롤시 스크롤 유지
  useEffect(() => {
    if (chatQueue.length === 0) return;

    if (!Number.isNaN(prevScrollHeight.current)) {
      if (isMobile) {
        window.scrollTo({
          top:
            (scrollContainerRef?.current?.scrollHeight ?? 0) -
            prevScrollHeight.current,
        });
      } else {
        scrollContainerRef.current?.scrollTo({
          top:
            scrollContainerRef.current.scrollHeight - prevScrollHeight.current,
          behavior: 'auto',
        });
      }

      prevScrollHeight.current = NaN;
    } else {
      scrollToBottom();
    }
  }, [chatQueue, isMobile, scrollToBottom]);

  // 미디어 구매 완료시 스크롤 유지
  useEffect(() => {
    if (chatQueue.length === 0) return;

    if (!Number.isNaN(prevScrollTop.current)) {
      if (isMobile) {
        window.scrollTo({
          top: prevScrollTop.current,
        });
      } else {
        scrollContainerRef.current?.scrollTo({
          top: prevScrollTop.current,
          behavior: 'auto',
        });
      }

      prevScrollTop.current = NaN;
    }
  }, [chatQueue, isMobile]);

  // 언마운트: 채팅 내역 캐시 삭제
  useEffect(() => {
    return () => {
      queryClient.removeQueries(['getChatRoomsByIdHistories']);
    };
  }, [queryClient]);

  useEffect(() => {
    if (!isLogin) {
      navigate(paths.home, { replace: true });
      return;
    }

    if (isChatHistoryError) {
      if (error) {
        if ((error as { response: any }).response) {
          navigate(paths.home, { replace: true });
        }
      }
    }
  }, [isLogin, navigate, isChatHistoryError, error]);

  // RN 앱 통신
  useEffect(() => {
    const listener = (event: any) => {
      if (!event?.data || typeof event.data !== 'string') return;

      try {
        const { from, type, ...rest } = JSON.parse(event.data);

        if (from === 'tica-app') {
          // RN 앱 통신
          switch (type) {
            case 'KEYBOARD_SHOW':
              setTimeout(() => {
                bottomRef?.current?.scrollIntoView({
                  behavior: 'smooth',
                });
              }, 100);
              break;
            case 'NICE_CERTIFICATION_SUCCESS':
              // 앱에서 본인인증 완료
              if (rest.isAdult === true) {
                // 19세 이상
                registerNiceCertification({
                  tokenVersionId: rest.tokenVersionId,
                });
                handleOpenChatLogMediaBottomModal();
              } else {
                showMinorAlert();
              }
              break;
          }
        }
      } catch (e) {
        console.error(e);
      }
    };

    // android
    document.addEventListener('message', listener);

    // ios
    window.addEventListener('message', listener);

    return () => {
      // android
      document.removeEventListener('message', listener);

      // ios
      window.removeEventListener('message', listener);
    };
  }, [
    handleOpenChatLogMediaBottomModal,
    showMinorAlert,
    registerNiceCertification,
  ]);

  return (
    <>
      <BottomModal open={bottomModalState} onClose={handleCloseBottomModal}>
        <div className="flex flex-col items-center gap-[20px]">
          <div className="rounded-full w-[64px] h-[64px] overflow-hidden">
            <Avatar
              src={partnerInfo?.profileMedia.smallFullUrl ?? ''}
              hasModal={false}
            />
          </div>
          <div className="flex flex-col gap-[16px]">
            <div className="text-center space-y-[6px]">
              <p className="text-H6 text-White break-keep whitespace whitespace-pre-line">
                {bottomModalText.title}
              </p>
              {bottomModalText.description && (
                <p className="whitespace-pre-line text-H8M">
                  {bottomModalText.description}
                </p>
              )}
            </div>
            <div className="text-center">
              <Button
                size={40}
                variant="outline"
                onClick={handleClickBottomModalButton}
              >
                {bottomModalText.buttonText}
              </Button>
            </div>
            {chatRoomInfo.chatRoomState !== 'ONGOING' && (
              <p className="text-Gray8 text-H7M flex items-center justify-center">
                <IconPrimaryJelly width={24} height={24} />
                {strings.chat.jellyCount.ko} {auth?.creditCount ?? 0}
                {strings.common.unit.ko}
              </p>
            )}
            {bottomModalText.refundNotice && (
              <p className="text-center text-H10M text-Gray6">
                {bottomModalText.refundNotice}
              </p>
            )}
          </div>
        </div>
      </BottomModal>
      {/* 만 19세 이상 최초 동의 모달 */}
      {/* <ConfirmNotMinorModal
        open={confirmNotMinorModal}
        onClose={handleCloseConfirmNotMinorModal}
        onComplete={handleOpenChatLogMediaBottomModal}
      /> */}
      {/* 미디어 구매 전용 바텀 모달 */}
      <BottomModal
        open={chatLogMediaBottomModalState}
        onClose={() => {
          handleCloseChatLogMediaBottomModal();
          resetSelectedChatLogMediaInfo();
        }}
      >
        <div className="flex flex-col items-center gap-[20px]">
          <div className="flex flex-col gap-[16px]">
            <div className="text-center space-y-[6px]">
              <p className="text-H6 text-White break-keep whitespace whitespace-pre-line">
                {(auth?.creditCount ?? 0) >=
                selectedChatLogMediaInfo.current.jellyCount
                  ? `젤리 ${
                      selectedChatLogMediaInfo.current.jellyCount
                    }개를 사용하여\n${_.capitalize(
                      selectedChatLogMediaInfo.current.theme,
                    )}한 사진을 열어보시겠어요?`
                  : `${_.capitalize(
                      selectedChatLogMediaInfo.current.theme,
                    )}한 사진을 열어보기 위해\n젤리 ${
                      selectedChatLogMediaInfo.current.jellyCount
                    }개가 필요해요`}
              </p>
            </div>
            <p className="text-White text-H7M flex items-center justify-center">
              {strings.chat.jellyCount.ko} {auth?.creditCount ?? 0}
              {strings.common.unit.ko}
            </p>
            {(auth?.creditCount ?? 0) >=
              selectedChatLogMediaInfo.current.jellyCount && (
              <p className="text-center text-H10M text-Gray6">
                {strings.chat.refundNotice.ko}
              </p>
            )}
            <div className="text-center">
              <Button
                size={40}
                variant="outline"
                onClick={onChatLogMediaBottomModalButtonClick}
              >
                {(auth?.creditCount ?? 0) >=
                selectedChatLogMediaInfo.current.jellyCount
                  ? '잠금 해제'
                  : '젤리 충전'}
              </Button>
            </div>
          </div>
        </div>
      </BottomModal>

      <Modal
        onClose={handleCloseModal}
        open={modalState}
        title={strings.chat.occupiedModalTitle.ko}
        description={strings.chat.occupiedModalContent.ko}
        footer={
          <Button onClick={handleCloseModal} size={48}>
            <div className="flex items-center gap-[4px]">
              {strings.chat.occupiedModalButton.ko}
            </div>
          </Button>
        }
      />
      <ChatRoomHeader
        chatRoomId={chatRoomId}
        img={partnerInfo?.profileMedia.smallFullUrl ?? ''}
        modalImageSrc={partnerInfo?.profileMedia.largeFullUrl ?? ''}
        nick={partnerInfo?.nick ?? ''}
        chatRoomState={chatRoomInfo.chatRoomState ?? 'ENDED'}
        remainingChatCount={chatRoomInfo.remainingChatCount}
        jellyCount={auth?.creditCount ?? 0}
        voiceCount={chatRoomInfo.remainingVoiceCount ?? 0}
        isLoading={chatHistoryInitialLoading}
      >
        <div className="flex items-center gap-[8px] py-[12px] px-[20px] bg-Gray3">
          <IconMenuGuide width={18} height={18} fill="#9DA5AC" />
          <span className="text-B11 text-Gray6">
            {strings.chat.topNotice.ko}
          </span>
        </div>
      </ChatRoomHeader>

      <div
        className={cn(
          'flex flex-col items-stretch justify-between mt-[calc(var(--header-height)+var(--chat-room-top-notice-height))] pc:h-[calc(100%-var(--header-height)-var(--chat-room-top-notice-height))] pc:overflow-auto pc:hide-scrollbar relative',
        )}
      >
        <div
          id="scrollContainer"
          ref={scrollContainerRef}
          className={cn(
            'pt-[20px] px-[20px] pb-[60px] pc:pb-0 flex-[0_1_calc(100%-60px)] h-[calc(100%)] overflow-auto pc:hide-scrollbar',
            isUserAgentMobile() ? 'pb-[76px]' : '',
          )}
        >
          {isFetchedAfterMount && <div ref={pageTopRef}></div>}
          <ChatHistory
            chatRoomId={chatRoomId}
            chatHistory={chatQueue}
            partnerId={partnerInfo?.id ?? 0}
            partnerNick={partnerInfo?.nick}
            partnerImage={partnerInfo?.profileMedia.smallFullUrl ?? ''}
            partnerModalImage={partnerInfo?.profileMedia.largeFullUrl ?? ''}
            bottom={
              <>
                {(isNotReplied || initializeChatLoading || sendChatLoading) && (
                  <Delay
                    delay={isNotReplied ? 0 : 1500}
                    onShow={scrollToBottomSmooth}
                  >
                    <PartnerResponseLoading
                      enableRandomInOutAnimation={
                        isLastChatSentByUser(chatQueue ?? []) &&
                        (getLastElementInArray(chatQueue ?? [])?.message
                          ?.length ?? 0) >= 50
                      }
                      partnerProfileImage={
                        partnerInfo?.profileMedia.smallFullUrl ?? ''
                      }
                      partnerProfileImageForModal={
                        partnerInfo?.profileMedia.largeFullUrl ?? ''
                      }
                      isLongResponse={isLongResponse}
                      text={
                        <>
                          생년월일을 분석하는 중이에요
                          <br />
                          다소 시간이 소요될 수 있으니 <br />
                          조금만 기다려주세요❤️‍🔥
                        </>
                      }
                    />
                  </Delay>
                )}
                {chatRoomInfo.chatRoomState === 'STARTED' && isFirstSend && (
                  <PartnerResponseFake
                    partnerProfileImage={
                      partnerInfo?.profileMedia.smallFullUrl ?? ''
                    }
                    partnerProfileImageForModal={
                      partnerInfo?.profileMedia.largeFullUrl ?? ''
                    }
                    isLoadingEnable={isLoadingEnableFakeBubble.current}
                  />
                )}
              </>
            }
            isEnded={chatRoomInfo.isEnded}
            onLastImageLoad={scrollToBottom}
            onMediaClick={onChatLogMediaClick}
            hideVoiceButton={!partnerInfo?.actorId}
          />
          <div className="h-[20px]" ref={bottomRef}></div>
        </div>
        <div
          className={cn(
            'fixed bottom-0 z-header pc:relative w-full px-[24px] pt-[14px] max-w-max min-w-min rounded-t-[24px] bg-Gray1 flex items-center transition-[padding-bottom] gap-[10px]',
            bottomModalState ? 'invisible' : 'visible',
            isUserAgentMobile()
              ? 'pb-[32px] focus-within:pb-[14px]'
              : 'pb-[14px]',
          )}
        >
          <div
            className="flex items-center flex-shrink-0"
            onFocus={(e) => {
              e.stopPropagation();
            }}
          >
            <button
              className="pointerhover:hover:brightness-105 transition-[filter] disable-tab-highlight bg-Gray3 w-[32px] h-[32px] flex-center rounded-full"
              onMouseDown={(e) => {
                // 클릭시 모바일에서 키보드 안내려가게 하기 위함
                e.preventDefault();
              }}
              onClick={() => {
                navigate(paths.mediaDrawer, {
                  state: { nick: partnerInfo?.nick },
                });
              }}
            >
              <IconDrawer
                className="shrink-0"
                stroke="white"
                width={24}
                height={24}
              />
            </button>
          </div>

          <ChatInput
            className="w-[0%]"
            ref={chatInputRef}
            onSend={onSend}
            sendOnEnterPress={!isMobile}
            disableButton={
              isNotReplied ||
              initializeChatLoading ||
              sendChatLoading ||
              disableInput ||
              chatRoomInfo.remainingChatCount === 0 ||
              isLastChatSentByUser(chatQueue ?? [])
            }
            onDivClick={onInputFocus}
          />
        </div>
      </div>
      {/* <NiceForm
        enabled={showNice}
        retryToggle={flag}
        onSuccess={onNiceSuccess}
        onFail={showMinorAlert}
      /> */}
    </>
  );
};

export default ChatRoom;
