import {
  IconJellyFit,
  IconMenuChatTicket,
  IconMenuCoupon,
  IconMenuCs,
  IconMenuDrawer,
  IconMenuGuide,
  IconMenuJelly,
  IconMenuTerms,
  IconMyPageCharge,
  IconProviderEmail,
  IconProviderKakao,
  IconRightArrow,
} from '@/assets/icons';
import { Button, CouponModal } from '@/components';
import Footer from '@/components/Footer';
import EditNickModal from '@/components/Modal/EditNickModal';
import { paths } from '@/constants/paths';
import { storageKey, strings } from '@/constants/strings';
import useModalConfig from '@/hooks/useModalConfig';
import { cn } from '@/lib/utils';
import { useAuthStore } from '@/zustands/useAuthStore';
import useSignInModal from '@/zustands/useSignInModal';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

interface Props {
  prop?: string;
}

const renderProvider = (provider: string) => {
  switch (provider) {
    case 'kakao':
      return <IconProviderKakao width={28} height={28} />;
    default:
      return <IconProviderEmail width={28} height={28} />;
  }
};

const ProfilePage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { isLogin, auth } = useAuthStore();
  const {
    open: isCouponModalOpen,
    handleOpenModal: openCouponModal,
    handleCloseModal: closeCouponModal,
  } = useModalConfig();

  const {
    open: isEditNickModalOpen,
    handleOpenModal: openEditNickModal,
    handleCloseModal: closeEditNickModal,
  } = useModalConfig();

  const { showSignInModal } = useSignInModal();

  const handlePurchaseChatTicket = () => {
    navigate(paths.purchaseChatTicket);
    sessionStorage.removeItem(storageKey.session.redirectUrlAfterPurchase);
  };

  const handleJellyChargeClick = () => {
    navigate(paths.payments);
  };

  const handleDrawerClick = () => {
    navigate(paths.mediaDrawer);
  };

  const handleJellyClick = () => {
    navigate(paths.profileJelly);
  };

  const handleGuideClick = () => {
    window.open(`https://slogup.notion.site/d26d726bfa8f4ae88d1f6231edbe5f6c`);
  };

  const handleTermsClick = () => {
    navigate(paths.terms);
  };

  const menu = [
    {
      key: 'chat-ticket',
      label: strings.myPage.chatTicket.ko,
      onClick: handlePurchaseChatTicket,
      icon: <IconMenuChatTicket stroke="white" width={20} height={20} />,
      needAuth: true,
      rightAccessory: (
        <span
          className={cn(
            'text-BTN3',
            auth?.remainingChatCount !== 0 &&
              (auth?.remainingChatCount ?? 0) <= 20
              ? 'text-Primary'
              : 'text-White',
          )}
        >
          {strings.common.remain.ko} : {auth?.remainingChatCount ?? 0}
          {strings.common.chatCount.ko}
        </span>
      ),
    },
    {
      key: 'jelly',
      label: strings.myPage.jelly.ko,
      onClick: handleJellyClick,
      icon: <IconMenuJelly fill="white" width={20} height={20} />,
      needAuth: true,
    },
    {
      key: 'drawer',
      label: strings.myPage.drawer.ko,
      onClick: handleDrawerClick,
      icon: <IconMenuDrawer stroke="white" width={20} height={20} />,
      needAuth: true,
    },
    {
      key: 'coupon',
      label: strings.myPage.coupon.ko,
      onClick: openCouponModal,
      icon: <IconMenuCoupon fill="white" width={20} height={20} />,
      needAuth: true,
    },
    {
      key: 'guide',
      label: strings.myPage.guide.ko,
      onClick: handleGuideClick,
      icon: <IconMenuGuide fill="white" width={20} height={20} />,
    },
    {
      key: 'terms',
      label: strings.myPage.terms.ko,
      onClick: handleTermsClick,
      icon: <IconMenuTerms fill="white" width={20} height={20} />,
      needAuth: true,
    },
    {
      key: 'cs',
      className: 'custom-button',
      label: strings.myPage.cs.ko,
      icon: <IconMenuCs fill="white" width={20} height={20} />,
    },
  ];

  return (
    <>
      <CouponModal
        open={isCouponModalOpen}
        onClose={closeCouponModal}
        disableBackgroundClose
      />
      <EditNickModal
        open={isEditNickModalOpen}
        onClose={closeEditNickModal}
        disableBackgroundClose
      />

      <div>
        <div className="flex-col-center items-stretch w-full pt-[12px] pb-[86px]">
          {isLogin && auth ? (
            <div className="w-full flex flex-col items-start gap-[14px] pb-[23px] border-b border-b-Gray5">
              <div className="flex items-center gap-[8px]">
                {renderProvider(
                  auth?.account?.accountProviders?.[0]?.provider ?? 'email',
                )}
                <h3 className="text-H4 text-White">{auth?.nick}</h3>
                <Button variant="outline" size={28} onClick={openEditNickModal}>
                  {strings.myPage.editNick.ko}
                </Button>
              </div>
              <div className="p-[19px] border border-Gray5 w-full rounded-[14px] flex items-center justify-between">
                <Link to={paths.profileJelly} className="space-y-[4px]">
                  <h5 className="text-H9M text-White">
                    {strings.jelly.myJelly.ko}
                  </h5>
                  <h4 className="text-H4 text-Primary flex items-center gap-[8px]">
                    <IconJellyFit
                      className="flex-shrink-0"
                      width={11}
                      height={14}
                    />
                    {auth?.creditCount} {strings.common.jelly.ko}
                  </h4>
                </Link>
                <Button
                  size={32}
                  className="flex-center gap-[4px] bg-Primary"
                  onClick={handleJellyChargeClick}
                >
                  <IconMyPageCharge fill="black" width={16} height={16} />
                  {strings.jelly.jellyCharge.ko}
                </Button>
              </div>
            </div>
          ) : (
            <div className="w-full flex items-center justify-between pb-[23px] border-b border-b-Gray5">
              <h4 className="text-H4 text-White">
                {strings.myPage.pleaseSignIn.ko}
              </h4>
              <Button
                size={28}
                onClick={() => {
                  showSignInModal();
                }}
              >
                {strings.common.signIn.ko}
              </Button>
            </div>
          )}
          <div className="w-full">
            {menu
              .filter((item) => {
                if (item.needAuth && isLogin) {
                  return true;
                } else if (!item.needAuth) {
                  return true;
                } else {
                  return false;
                }
              })
              .map((item) => (
                <div
                  className={cn(
                    'flex items-center justify-between py-[16px] gap-[8px] cursor-pointer',
                    item.className,
                  )}
                  key={item?.key}
                  onClick={item?.onClick}
                >
                  {item.icon}
                  <p className="text-H7M flex-1 text-White">{item?.label}</p>
                  <div className="flex items-center gap-[8px]">
                    {item.rightAccessory}
                    <IconRightArrow width={24} height={24} stroke="white" />
                  </div>
                </div>
              ))}
          </div>
        </div>
        <Footer className="gap-[20px] pc:hidden" />
      </div>
    </>
  );
};

export default ProfilePage;
