import { cn } from '@/lib/utils';
import React from 'react';
import { type SectionTypeAProps } from './types';

export default function SectionTypeA(props: SectionTypeAProps) {
  const { title, children, className } = props;

  return (
    <section
      className={cn(
        'flex flex-col gap-[16px] p-[20px] w-full bg-Background',
        className,
      )}
    >
      <h2 className="text-H5 whitespace-pre-line text-White">{title}</h2>
      {children && <div>{children}</div>}
    </section>
  );
}
