import { IconRightArrowWithTail } from '@/assets/icons';
import { cn } from '@/lib/utils';
import React, { forwardRef, useCallback, useRef } from 'react';

const MAX_MESSAGE_LENGTH = 150;

interface Props {
  className?: string;
  onDivClick?: (e: any) => void;
  onSend: (message: string) => void;
  sendOnEnterPress?: boolean;
  disableButton: boolean;
}

const ChatInput = forwardRef<HTMLDivElement, Props>(
  ({ className, onDivClick, onSend, sendOnEnterPress, disableButton }, ref) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const onClick = useCallback(() => {
      if (textareaRef?.current) {
        const message = textareaRef.current.value.trim();

        if (message === '') {
          textareaRef.current.focus();
        } else {
          onSend(message);
        }

        textareaRef.current.value = '';
        textareaRef.current.style.height = 'auto';
      }
    }, [onSend, textareaRef]);

    return (
      <div
        ref={ref}
        className={cn(
          'flex flex-1 items-end p-[6px] bg-Gray3 rounded-[28px] pc:mb-0 pc:focus-within:mb-0',
          className,
        )}
        onMouseDown={onDivClick}
      >
        <textarea
          spellCheck={false}
          className="peer caret-White flex-1 px-[10px] py-[4px] resize-none bg-Transparent text-White placeholder:text-Gray8 focus:outline-none text-B6 max-h-[104px] hide-scrollbar"
          placeholder="메시지 보내기..."
          rows={1}
          onChange={(e) => {
            e.currentTarget.style.height = 'auto';
            e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px';
          }}
          ref={textareaRef}
          maxLength={MAX_MESSAGE_LENGTH}
          onKeyDown={(e) => {
            if (disableButton) {
              return;
            }

            if (sendOnEnterPress) {
              if (e.nativeEvent.isComposing) {
                // isComposing 이 true 이면
                return; // 조합 중이므로 동작을 막는다.
              }

              if (e.key === 'Enter' && e.shiftKey) {
                return;
              }

              if (e.key === 'Enter') {
                onClick();
                e.preventDefault();
              }
            }
          }}
        />
        <button
          onClick={onClick}
          disabled={disableButton}
          className="flex-center bg-Gray2 peer-placeholder-shown:bg-Gray7 focus:bg-Primary rounded-full w-[32px] h-[32px] transition-colors disabled:bg-Gray7 disabled:peer-focus:bg-Gray7"
        >
          <IconRightArrowWithTail width={24} height={24} />
        </button>
      </div>
    );
  },
);
ChatInput.displayName = 'ChatInput';

export default ChatInput;
