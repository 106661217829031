import request from '@/apis/axios';
import type { ListResponse, ObjectResponse } from '@/interface/common';
import { type PartnerWithChatRooms, type Partner } from '@/models/partners';

const url = '/partners';

export const getPartnerById = async (id: number) => {
  const { data } = await request<ObjectResponse<Partner>>({
    method: 'get',
    url: `${url}/${id}`,
  });
  return data;
};

export const getPartners = async (queryParams?: {
  category?: string;
  contentTitle?: string;
  excludeNick?: string;
  page?: number;
  pageSize?: number;
}) => {
  const { data } = await request<ListResponse<Partner>>(
    {
      method: 'get',
      url,
      queryParams,
    },
    undefined,
    false,
  );
  return data;
};

export const getPartnersWithChatRoomsById = async (id: number) => {
  const { data } = await request<ObjectResponse<PartnerWithChatRooms>>({
    method: 'get',
    url: `/customers/me${url}/${id}`,
  });
  return data;
};
