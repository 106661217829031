import { cn } from '@/lib/utils';
import React, { type PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  className?: string;
}

const FooterButtonWrapper = ({ className, children }: Props) => {
  return (
    <footer
      className={cn(
        'fixed bottom-0 w-full max-w-max min-w-min z-header p-[20px] bg-Gray1 rounded-t-[20px] pb-[32px]',
        className,
      )}
    >
      {children}
    </footer>
  );
};

export default FooterButtonWrapper;
