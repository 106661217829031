import React, { useCallback, useEffect, useRef } from 'react';
import { Button, FooterButton } from '@/components';
import PrevHeader from '@/components/Header/PrevHeader';
import { paths } from '@/constants/paths';
import {
  getAlertTextByErrorMessages,
  storageKey,
  strings,
} from '@/constants/strings';
import useNiceCertificate from '@/hooks/useNiceCertificate';
import { type To, useNavigate, useLocation } from 'react-router-dom';
import useRedirectNavigate from '@/hooks/useRedirectNavigate';
import { useMutation } from '@tanstack/react-query';
import {
  getCustomersProviderByNice,
  putCustomersMeNiceCertification,
} from '@/apis/customers';
import useGlobalModal from '@/zustands/useGlobalModal';
import useBlocker from '@/hooks/useBlocker';
import { useAuthStore } from '@/zustands/useAuthStore';

const RequireNiceCertificate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectNavigate = useRedirectNavigate();
  const { showErrorAlert, showGlobalModal, hideGlobalModal } = useGlobalModal();

  // 뒤로가기 방지
  const isBlock = useRef<boolean>(true);
  const handleBlock = useCallback(
    (url: To, action: string) => {
      showGlobalModal({
        title: '본인인증 하지 않으시겠어요?',
        body: '화면에서 벗어나면 다시 로그인이 필요해요',
        onClose: hideGlobalModal,
        footer: (
          <div className="flex-center space-x-[8px]">
            <Button
              className="flex-1"
              size={48}
              variant="gray"
              onClick={hideGlobalModal}
            >
              {strings.common.close.ko}
            </Button>
            <Button
              className="flex-1"
              size={48}
              onClick={() => {
                isBlock.current = false;
                if (location.state?.provider) {
                  sessionStorage.removeItem(storageKey.session.redirectUrl);
                }

                if (action === 'PUSH') {
                  navigate(url, { replace: true });
                } else if (action === 'POP') {
                  navigate(-1);
                } else {
                  navigate(paths.home, { replace: true });
                }

                hideGlobalModal();
              }}
            >
              네, 안할게요
            </Button>
          </div>
        ),
      });
    },
    [showGlobalModal, hideGlobalModal, navigate, location.state],
  );
  useBlocker({
    isBlock,
    browserBackClick: handleBlock,
  });

  const { mutate: getProviders } = useMutation(getCustomersProviderByNice);
  const { mutate: putNiceToken } = useMutation(putCustomersMeNiceCertification);

  const onSuccess = (tokenVersionId: string) => {
    getProviders(tokenVersionId, {
      onSuccess: (res) => {
        isBlock.current = false;
        navigate(paths.duplicateCertificate, {
          state: {
            provider: res.row.provider,
          },
        });
      },
      onError: (err: any) => {
        if (err.response?.status === 404) {
          // 가입된 계정이 없는 경우
          putNiceToken(
            { tokenVersionId },
            {
              onSuccess: () => {
                isBlock.current = false;
                useAuthStore.getState().setIsNotNiceCertificated(false);
                redirectNavigate(paths.home, {
                  replace: true,
                  state: { isRightAfterSignIn: true },
                });
              },
              onError: (err: any) => {
                const { title, description } = getAlertTextByErrorMessages(
                  err?.response?.data?.message,
                );
                showErrorAlert(title, description);
              },
            },
          );
        }
      },
    });
  };
  const { showNicePopup, renderNiceForm } = useNiceCertificate({
    onSuccess,
    onFail: () => {
      isBlock.current = false;
      navigate(paths.adultOnly);
    },
    failPath: paths.adultOnly,
  });

  useEffect(() => {
    useAuthStore.getState().setIsNotNiceCertificated(true);
  }, []);

  return (
    <>
      <PrevHeader title={strings.adultOnly.adultOnlyHeader.ko} />
      <div className="pt-header">
        <section className="p-[20px] pt-[24px] space-y-[12px]">
          <h2 className="text-H3 whitespace-pre-line text-White">
            {strings.certificate.certificateTitle.ko}
          </h2>
          <p className="text-H6M text-Gray8 whitespace-pre-line">
            {strings.certificate.certificateDescription.ko}
          </p>
        </section>
        <FooterButton onClick={showNicePopup}>
          {strings.common.doCertificate.ko}
        </FooterButton>
      </div>
      {renderNiceForm()}
    </>
  );
};

export default RequireNiceCertificate;
