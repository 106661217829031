import { putCustomersMe } from '@/apis/customers';
import { BasicModal, Button, Terms } from '@/components';
import { getAlertTextByError, queryKeys, strings } from '@/constants/strings';
import useGlobalModal from '@/zustands/useGlobalModal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useState } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
}

const ConfirmNotMinorModal: React.FC<Props> = (props) => {
  const { onClose, onComplete } = props;

  const [pass, setPass] = useState(false);
  const queryClient = useQueryClient();
  const { showErrorAlert } = useGlobalModal();

  const { mutate } = useMutation(putCustomersMe, {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.me]);
      onComplete();
    },
    onError: (err) => {
      const { title, description } = getAlertTextByError(err);
      showErrorAlert(title, description);
    },
  });

  const onClickAccept = useCallback(() => {
    mutate({
      isAdult: true,
    });
    onClose();
  }, [onClose, mutate]);

  return (
    <BasicModal {...props}>
      <div className="space-y-[8px]">
        <h5 className="text-H5 text-White">
          Hot 콘텐츠를 구매하기 위해서는
          <br />
          최초 1회 동의가 필요해요!
        </h5>
        <Terms
          terms={[
            {
              id: -19,
              optionable: false,
              title: strings.signup.isOlder19.ko,
              isMarketing: false,
              isOlder19: true,
            },
          ]}
          hideAllCheck
          setTermsPass={setPass}
          className="pt-[16px] border-t-[1.5px] border-t-Gray10"
        />
        <p className="pl-[36px] text-B10 text-Gray6">
          해당 항목은 회원 본인이{' '}
          <span className="font-bold">만 19세 이상임을 확인</span>하고 동의하는
          것으로,{' '}
          <span className="font-bold">
            추후 19세 미만인 회원이 이용함으로 인해 발생되는 모든 법적 책임은
            회원님께 있음
          </span>
          을 명시적으로 안내드립니다.
          <br />
          동의 시{' '}
          <span className="font-bold text-Primary">
            회원님께서 이에 대한 유의사항을 충분히 이해하고 이에 동의한 것으로
            간주
          </span>
          됩니다.
        </p>
      </div>
      <Button
        disabled={!pass}
        variant="default"
        size={56}
        className="w-full"
        onClick={onClickAccept}
      >
        동의하기
      </Button>
    </BasicModal>
  );
};

export default ConfirmNotMinorModal;
