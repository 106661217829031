import ChatRoomListItem, {
  type ChatRoomListItemProps,
} from '@/components/ChatRoomList/Item';
import { cn } from '@/lib/utils';
import React from 'react';

interface Props {
  className?: string;
  title?: string;
  list: ChatRoomListItemProps[];
  isLoading?: boolean;
}

const ChatRoomList: React.FC<Props> = ({
  className,
  title,
  list,
  isLoading = false,
}) => {
  return (
    <div className={cn('space-y-[20px]', className)}>
      {title && <p className="text-H6 text-White">{title}</p>}
      <ul>
        {list.map((item) => (
          <ChatRoomListItem
            key={`chat-${item.name}`}
            {...item}
            isLoading={item.isLoading ?? isLoading}
          />
        ))}
      </ul>
    </div>
  );
};

export default ChatRoomList;
