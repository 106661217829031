import { ChatBubble, ImageModal } from '@/components';
import { strings } from '@/constants/strings';
import { cn } from '@/lib/utils';
import { type ChatLogMediaTheme } from '@/models/chat';
import { type ChatLogForBubble } from '@/pages/ChatRoom';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import React, { useCallback, useRef, useState } from 'react';

const compareDate = (a: string, b: string) => {
  const dayA = dayjs(a);
  const dayB = dayjs(b);

  return (
    dayA.isSame(dayB, 'year') &&
    dayA.isSame(dayB, 'month') &&
    dayA.isSame(dayB, 'day')
  );
};

interface Props {
  className?: string;
  chatRoomId: number;
  chatHistory: ChatLogForBubble[];
  remainingVoiceCount?: number;
  partnerId: number;
  partnerNick?: string;
  partnerImage: string;
  partnerModalImage?: string;
  bottom?: React.ReactNode;
  isEnded?: boolean;
  onLastImageLoad?: () => void;
  onMediaClick?: (
    chatLogId: number,
    chatLogMediaId: number,
    jellyCount: number,
    theme: ChatLogMediaTheme,
  ) => void;
  hideVoiceButton?: boolean;
}

const ChatHistory: React.FC<Props> = ({
  className,
  chatRoomId,
  chatHistory,
  partnerId,
  partnerNick,
  partnerImage,
  partnerModalImage,
  bottom,
  isEnded,
  onLastImageLoad,
  onMediaClick,
  hideVoiceButton,
}) => {
  const prevDate = useRef<string>('');
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageModalSrc, setImageModalSrc] = useState<React.ReactNode>(<></>);
  const handleOpenImageModal = useCallback(() => {
    setImageModalOpen(true);
  }, [setImageModalOpen]);

  const handleCloseImageModal = useCallback(() => {
    setImageModalOpen(false);
  }, [setImageModalOpen]);

  const onImageClick = useCallback(
    (src: string) => {
      setImageModalSrc(<img src={src} alt="image with chat" />);
      handleOpenImageModal();
    },
    [setImageModalSrc, handleOpenImageModal],
  );

  return (
    <div className={cn('flex flex-col items-stretch gap-[16px]', className)}>
      {chatHistory.map((chat, idx, array) => {
        const isLast = idx === array.length - 1;
        if (!compareDate(chat.messageSentAt, prevDate.current)) {
          // 마지막 채팅이라면 이전 날짜 초기화
          prevDate.current = isLast ? '' : chat.messageSentAt;
          return (
            <div
              key={`chat-${chat.id}-${chat.messageSentAt}`}
              className="space-y-[16px]"
            >
              <p className="text-center text-L2 text-Gray6">
                {dayjs(chat.messageSentAt)
                  .locale('ko')
                  .format('YYYY.MM.DD dddd')}
              </p>
              <ChatBubble
                chatRoomId={chatRoomId}
                messageId={chat.id}
                audioUrl={chat?.voiceMedia?.bufferFullUrl}
                partnerId={chat.personType === 'BOT' ? partnerId : undefined}
                partnerNick={
                  chat.personType === 'BOT' ? partnerNick : undefined
                }
                partnerProfileImage={
                  chat.personType === 'BOT' ? partnerImage : undefined
                }
                partnerProfileImageForModal={
                  chat.personType === 'BOT' ? partnerModalImage : undefined
                }
                message={chat.message}
                messageWithImages={
                  chat.messageType === 'TEXT_IMAGE'
                    ? chat.imageUrls ?? []
                    : undefined
                }
                messageSentAt={chat.messageSentAt}
                onImageClick={onImageClick}
                onLastImageLoad={
                  chat.scrollToBottomOnImageLoad ? onLastImageLoad : undefined
                }
                chatLogMediaList={chat.chatLogMedias}
                onMediaClick={(id, jellyCount, theme) => {
                  onMediaClick?.(chat.id, id, jellyCount, theme);
                }}
                isPaid={chat.isChatLogMediaPaid}
                hideVoiceButton={hideVoiceButton}
              />
            </div>
          );
        } else {
          if (isLast) {
            prevDate.current = '';
          }
          return (
            <div
              className="space-y-[16px]"
              key={`chat-${chat.id}-${chat.messageSentAt}`}
            >
              <ChatBubble
                chatRoomId={chatRoomId}
                messageId={chat.id}
                audioUrl={chat?.voiceMedia?.bufferFullUrl}
                partnerId={chat.personType === 'BOT' ? partnerId : undefined}
                partnerNick={
                  chat.personType === 'BOT' ? partnerNick : undefined
                }
                partnerProfileImage={
                  chat.personType === 'BOT' ? partnerImage : undefined
                }
                partnerProfileImageForModal={
                  chat.personType === 'BOT' ? partnerModalImage : undefined
                }
                message={chat.message}
                messageWithImages={
                  chat.messageType === 'TEXT_IMAGE'
                    ? chat.imageUrls ?? []
                    : undefined
                }
                messageSentAt={chat.messageSentAt}
                onImageClick={onImageClick}
                onLastImageLoad={
                  chat.scrollToBottomOnImageLoad ? onLastImageLoad : undefined
                }
                chatLogMediaList={chat.chatLogMedias}
                onMediaClick={(id, jellyCount, theme) => {
                  onMediaClick?.(chat.id, id, jellyCount, theme);
                }}
                isPaid={chat.isChatLogMediaPaid}
                hideVoiceButton={hideVoiceButton}
              />
            </div>
          );
        }
      })}
      {bottom}
      {isEnded && (
        <p className="mx-auto text-center py-[6px] px-[12px] rounded-[12px] bg-Primary10 text-Primary text-H9M">
          {strings.chat.chatEnded.ko}
        </p>
      )}
      <ImageModal
        open={imageModalOpen}
        onClose={handleCloseImageModal}
        lists={[imageModalSrc]}
        hasKeyboardEvent
      />
    </div>
  );
};

export default ChatHistory;
