import { useEffect } from 'react';

export default function useBlockBrowserBack() {
  useEffect(() => {
    // 스택 추가
    history.pushState(null, '', window.location.href);

    const handler = function () {
      window.history.go(1);
    };

    // 뒤로라기 이벤트감지 -> 현재페이지로 이동
    window.addEventListener('popstate', handler);

    return () => {
      window.removeEventListener('popstate', handler);
    };
  }, []);
}
