import React, { useRef, type ReactNode, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation } from 'swiper';
import { IconArrowLeftBg, IconArrowRightBg } from '@/assets/icons';
import { cn } from '@/lib/utils';

export interface FeedImageObject {
  child: string | ReactNode;
  onClick?: () => void;
}

interface Props {
  className?: string;
  list: FeedImageObject[];
}

const FeedImageCarousel = ({ className, list }: Props) => {
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const prevButtonRef = useRef<HTMLButtonElement>(null);

  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  return (
    <div className={cn('relative', className)}>
      <Swiper
        slidesPerView={3}
        spaceBetween={8}
        modules={[Navigation]}
        navigation={{
          nextEl: nextButtonRef.current,
          prevEl: prevButtonRef.current,
        }}
        onSlideChangeTransitionStart={(swiper) => {
          setIsBeginning(swiper.isBeginning);
          setIsEnd(swiper.isEnd);
        }}
      >
        {list.map((element, i) => (
          <SwiperSlide
            key={`home-banner-carousel-${i + 1}`}
            className="w-full overflow-hidden rounded-[18px]"
          >
            {typeof element.child === 'string' ? (
              <img
                alt="home-banner-image"
                width="100%"
                src={element.child}
                className={
                  element.onClick && 'cursor-pointer aspect-[3/4] object-cover'
                }
                onClick={element.onClick}
              />
            ) : (
              element.child
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      {list?.length > 3 && (
        <>
          <button
            ref={prevButtonRef}
            className={`absolute flex-center top-[50%] w-[24px] h-[24px] 
          rounded-[50%] border-none cursor-pointer z-40 translate-y-[-50%] -left-[12px] ${
            isBeginning && 'invisible'
          }`}
          >
            <IconArrowLeftBg />
          </button>
          <button
            className={`absolute flex-center top-[50%] w-[24px] h-[24px] 
          rounded-[50%] border-none cursor-pointer z-40 translate-y-[-50%] -right-[12px] ${
            isEnd && 'invisible'
          }`}
            ref={nextButtonRef}
          >
            <IconArrowRightBg />
          </button>
        </>
      )}
    </div>
  );
};

export default FeedImageCarousel;
