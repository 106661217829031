import request from '@/apis/axios';
import { type ObjectResponse, type ListResponse } from '@/interface/common';
import { type AttendanceDto } from '@/models/attendance';

const url = '/customers/me/attendance';

export const getAttendanceList = async () => {
  const { data } = await request<ListResponse<AttendanceDto>>({
    method: 'get',
    url: `${url}/list`,
  });
  return data;
};

export const getTransformedAttendanceList = async () => {
  const { data } = await request<ListResponse<AttendanceDto>>({
    method: 'get',
    url: `${url}/transformed-list`,
  });
  return data;
};

export const postAttendance = async () => {
  const { data } = await request<ObjectResponse<AttendanceDto>>({
    method: 'post',
    url,
  });
  return data;
};
