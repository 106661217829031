import lottie from 'lottie-web';
import React, { type CSSProperties, useEffect, useRef } from 'react';

interface LogoLottieProps {
  width?: number;
  height?: number;
  name: string;
  loop?: boolean;
  style?: CSSProperties;
}

export default function Lottie({
  width,
  height,
  name,
  loop = true,
  style,
}: LogoLottieProps) {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current as unknown as Element,
      renderer: 'svg',
      loop,
      autoplay: true,
      path: `/LottieJson/${name}.json`, // the path to the animation json
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {name === 'loading' && (
        <div className="relative w-full" style={style}>
          <div ref={container} className="h-[400px]" />
          <div className="mt-[12px] absolute left-[50%] translate-x-[-50%] bottom-0 flex flex-col gap-[8px] text-center">
            <h2 className="text-H3 text-[#6E777C]">결제 진행 중</h2>
            <p className="text-B7 text-[#6E777C]">잠시만 기다려 주세요.</p>
          </div>
        </div>
      )}

      <div
        ref={container}
        className="flex overflow-hidden rounded-[12px] w-full z-0 absolute"
      ></div>
    </>
  );
}
