import type { GenerateHectoFinancialPaymentDto } from '@/models/hecto';
import request from '../axios';

const url = '/hecto-financial/payments';

export const postHectoPayments = async (
  requestBody: GenerateHectoFinancialPaymentDto,
) => {
  const { data } = await request({ method: 'post', url, requestBody });
  return data;
};

export const postHectoPaymentsByIdInProgress = async (id: number) => {
  const { data } = await request({
    method: 'post',
    url: `${url}/${id}/in-progress`,
  });

  return data;
};
